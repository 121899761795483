import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import { Grid } from 'semantic-ui-react';

import 'datejs';

import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function dateDiffElapsed(date1, date2) {
  const diff = new Date(date2.getTime() - date1.getTime());

  const years = diff.getUTCFullYear() - 1970; // Gives difference as year
  const months = diff.getUTCMonth(); // Gives month count of difference
  const days = diff.getUTCDate() - 1; // Gives day count of difference

  const message = [];

  if (years) {
    message.push(`${years} ano(s)`);
  }

  if (months) {
    message.push(`${months} mes(es)`);
  }

  if (days) {
    message.push(`${days} dia(s)`);
  }

  return message.join(', ');
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Simulation() {
  const { handleSubmit, register, errors, setValue } = useForm();
  const history = useHistory();
  const query = useQuery();

  const { openModal } = useContext(ModalContext);

  const [hourlySunnyPeak, setHourlySunnyPeak] = useState(null);
  const [isFindingHSP, setIsFindingHSP] = useState(false);

  const { simulationId } = useParams();

  const formRef = useRef(null);

  const [self, setSelf] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [backTo] = useState(query.get('backTo'));

  const [showResults, setShowResults] = useState(false);
  const [values, setValues] = useState(null);

  const searchSelf = useCallback(async () => {
    const { data: simulation } = await api({
      method: 'post',
      url: 'simulation/self',
      params: { simulation_id: simulationId },
    });

    if (simulation.status === 0) {
      setSelf(simulation.data);
      setEditMode(false);
      setShowResults(true);
    } else {
      setSelf({});
    }
  }, []);

  async function handleFindHSP(cityId) {
    setIsFindingHSP(true);

    const { data } = await api({
      method: 'get',
      url: 'hsp/select',
      params: {
        city_id: cityId,
      },
    });

    setIsFindingHSP(false);

    if (data.status === 0) {
      const { MEAN } = data.data;

      setHourlySunnyPeak(+MEAN);
      setValue('HOURLY_SUNNY_PEAK', +MEAN);
    } else {
      toast.error(data.message.text);
    }
  }

  useEffect(() => {
    if (simulationId) {
      searchSelf();
    }
  }, []);

  const results = useMemo(() => {
    if (!values) {
      return {};
    }

    const consumption = values.BILL_VALUE / values.TAX_VALUE;

    const modules =
      (consumption /
        30 /
        values.HOURLY_SUNNY_PEAK /
        0.83 /
        values.MODULE_POWER) *
      1000;

    const power = consumption / 30 / values.HOURLY_SUNNY_PEAK / 0.83;

    const weight = modules * 22.5;

    const area = modules * 2;

    const investiment = values.MODULE_VALUE * modules;

    const roi =
      investiment / (values.BILL_VALUE - values.AVAILABILITY_RATE) / 12;

    const saving25Years =
      (values.BILL_VALUE - values.AVAILABILITY_RATE) * 12 * 25;

    const monthlySavings =
      values.BILL_VALUE - (values.BILL_VALUE - values.AVAILABILITY_RATE);

    const monthlySavingsPerc = (monthlySavings / values.BILL_VALUE) * 100;

    return {
      CONSUMPTION: consumption,
      POWER: power,
      MODULES: modules,
      WEIGHT: weight,
      AREA: area,
      INVESTIMENT: investiment,
      SAVING_25YEARS: saving25Years,
      ROI: roi,
      ROI_DESC: dateDiffElapsed(Date.today(), Date.today().addYears(roi)),
      AVAILABILITY_RATE: values.AVAILABILITY_RATE,
      MONTHLY_SAVINGS: monthlySavings,
      MONTHLY_SAVINGS_PERC: monthlySavingsPerc,
    };
  }, [values]);

  async function postSimulation(params) {
    await api({
      method: 'post',
      url: 'simulation/add',
      params,
    });
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        position => {
          setValue('LATITUDE', position.coords.latitude);
          setValue('LONGITUDE', position.coords.longitude);
        },
        console.error,
        {
          enableHighAccuracy: true,
          // timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (Object.keys(results).length !== 0 && Object.keys(values).length !== 0) {
      postSimulation({
        simulation: {
          ...values,
          ...results,
        },
      });
    }
  }, [results]);

  function onSubmit(data) {
    setValues(data);
    setShowResults(true);
  }

  function handleClickClean() {
    setShowResults(false);
    setValues({});

    if (formRef.current) {
      formRef.current.reset();
    }
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">
            {editMode ? 'Orçamento rápido' : 'Detalhes da simulação'}
          </span>
          {!editMode && (
            <div>
              <button
                type="button"
                className="ui button green"
                onClick={() => history.push(backTo)}
              >
                Voltar para simulações
              </button>
            </div>
          )}
        </div>

        <div className="ui attached bottom segment">
          <Grid>
            <Grid.Column mobile={16}>
              <h4 className="ui top attached inverted bg-blue header">
                Dados para cálculo
              </h4>
              <div className="ui attached segment bg-grey">
                <form
                  className="ui form"
                  onSubmit={handleSubmit(onSubmit)}
                  ref={formRef}
                >
                  <div className="two fields">
                    <div className="field">
                      <label>Cliente</label>
                      {editMode ? (
                        <input
                          type="text"
                          name="CLIENT_NAME"
                          maxLength={70}
                          ref={register}
                        />
                      ) : (
                        <input
                          type="text"
                          name="CLIENT_NAME"
                          maxLength={70}
                          ref={register}
                          readOnly={!editMode}
                          value={self.CLIENT_NAME}
                        />
                      )}
                      {errors.CLIENT_NAME && <span>Cliente é obrigatório</span>}
                    </div>
                    <div className="field">
                      <label>Telefone (whatsapp)</label>
                      <input type="hidden" name="PHONE" ref={register} />
                      {editMode ? (
                        <InputNumberFormat
                          format="(##) # ####-####"
                          mask="_"
                          type="tel"
                          onValueChange={v =>
                            setValue('PHONE', v.formattedValue)
                          }
                        />
                      ) : (
                        <InputNumberFormat
                          format="(##) # ####-####"
                          mask="_"
                          type="tel"
                          readOnly={!editMode}
                          value={self.PHONE}
                        />
                      )}
                      {errors.PHONE && <span>Telefone é obrigatório</span>}
                    </div>
                  </div>

                  <div className="field">
                    <label>Email</label>
                    {editMode ? (
                      <input type="email" name="EMAIL" ref={register} />
                    ) : (
                      <input
                        type="email"
                        name="EMAIL"
                        ref={register}
                        readOnly={!editMode}
                        value={self.EMAIL}
                      />
                    )}
                    {errors.EMAIL && <span>Email é obrigatório</span>}
                  </div>

                  <div className="three fields">
                    <div className="field required">
                      <label>Conta de luz (R$)</label>
                      <input
                        type="hidden"
                        step="any"
                        name="BILL_VALUE"
                        ref={register({ required: true })}
                      />
                      {editMode ? (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          onValueChange={v =>
                            setValue('BILL_VALUE', v.floatValue)
                          }
                        />
                      ) : (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          readOnly={!editMode}
                          value={self.BILL_VALUE}
                        />
                      )}
                      {errors.BILL_VALUE && (
                        <span>Conta de luz é obrigatória</span>
                      )}
                    </div>
                    <div className="field required">
                      <label>Valor tarifa (R$)</label>

                      <input
                        type="hidden"
                        step="any"
                        name="TAX_VALUE"
                        ref={register({ required: true })}
                      />
                      {editMode ? (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          onValueChange={v =>
                            setValue('TAX_VALUE', v.floatValue)
                          }
                        />
                      ) : (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          readOnly={!editMode}
                          value={self.TAX_VALUE}
                        />
                      )}
                      {errors.TAX_VALUE && (
                        <span>Valor da tarifa é obrigatória</span>
                      )}
                    </div>
                    <div className="field required">
                      <label>Rede</label>

                      <select
                        type="hidden"
                        step="any"
                        name="AVAILABILITY_RATE"
                        ref={register({ required: true })}
                        readOnly={!editMode}
                      >
                        <option
                          selected={!editMode && self.AVAILABILITY_RATE === 30}
                          value="30"
                        >
                          Monofásico 30kW/h
                        </option>
                        <option
                          selected={!editMode && self.AVAILABILITY_RATE === 50}
                          value="50"
                        >
                          Bifásico 50kW/h
                        </option>
                        <option
                          selected={!editMode && self.AVAILABILITY_RATE === 100}
                          value="100"
                        >
                          Trifásico 100kW/h
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="three fields">
                    <div className="field required">
                      <label>Potência do módulo</label>

                      <input
                        type="hidden"
                        step="any"
                        name="MODULE_POWER"
                        ref={register({ required: true })}
                      />
                      {editMode ? (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={0}
                          onValueChange={v =>
                            setValue('MODULE_POWER', v.floatValue)
                          }
                        />
                      ) : (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={0}
                          readOnly={!editMode}
                          value={self.MODULE_POWER}
                        />
                      )}
                      {errors.MODULE_POWER && (
                        <span>Potência do módulo é obrigatória</span>
                      )}
                    </div>
                    <div className="field required">
                      <label>Valor do módulo (R$)</label>

                      <input
                        type="hidden"
                        step="any"
                        name="MODULE_VALUE"
                        ref={register({ required: true })}
                      />
                      {editMode ? (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          onValueChange={v =>
                            setValue('MODULE_VALUE', v.floatValue)
                          }
                        />
                      ) : (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          readOnly={!editMode}
                          value={self.MODULE_VALUE}
                        />
                      )}
                      {errors.MODULE_VALUE && (
                        <span>Valor do módulo é obrigatório</span>
                      )}
                    </div>
                    <div className="field required">
                      <label>HSP</label>

                      <div className="ui action right labeled input">
                        <input
                          type="hidden"
                          step="any"
                          name="HOURLY_SUNNY_PEAK"
                          ref={register({ required: true })}
                        />
                        {editMode ? (
                          <InputNumberFormat
                            type="tel"
                            displayType="input"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            value={hourlySunnyPeak}
                            onValueChange={v => {
                              setHourlySunnyPeak(v.floatValue);
                              setValue('HOURLY_SUNNY_PEAK', v.floatValue);
                            }}
                            disabled={isFindingHSP}
                          />
                        ) : (
                          <InputNumberFormat
                            type="tel"
                            displayType="input"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            readOnly={!editMode}
                            value={self.HOURLY_SUNNY_PEAK}
                          />
                        )}
                        {errors.HOURLY_SUNNY_PEAK && (
                          <span>HSP é obrigatório</span>
                        )}

                        <button
                          type="button"
                          className="ui icon button blue"
                          onClick={() => {
                            openModal('citySelect', {
                              onConfirm: cityId => handleFindHSP(cityId),
                            });
                          }}
                          disabled={!editMode}
                        >
                          <i className="mdi mdi-magnify icon" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <input type="hidden" name="LATITUDE" ref={register} />
                  <input type="hidden" name="LONGITUDE" ref={register} />

                  {editMode ? (
                    <div className="field">
                      <button type="submit" className="ui fluid button green">
                        Simular
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </form>
              </div>
            </Grid.Column>

            {results.POWER > 75 && (
              <Grid.Column mobile={16}>
                <div className="ui icon message red">
                  <i className="mdi mdi-alert icon" />
                  <div className="content">
                    <div className="header">
                      Atenção: Este projeto ultrapassou o limite da MICRO
                      GERAÇÃO, conforme resolução 482 capitulo 1.
                    </div>
                    <p>Caso deseja continuar, somente na plataforma.</p>
                  </div>
                </div>
              </Grid.Column>
            )}

            {(results.POWER < 75 || !editMode) && showResults && (
              <>
                <Grid.Column mobile={16}>
                  <h4 className="ui top attached header inverted bg-orange">
                    Resultado da simulação
                  </h4>
                  <div className="ui attached segment bg-grey">
                    <div
                      className={classNames(
                        'ui mini centered statistics stackable',
                        {
                          one: isMobile,
                          three: !isMobile,
                        }
                      )}
                    >
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? numberFormat(results.CONSUMPTION, 0, ',', '.')
                            : numberFormat(self.CONSUMPTION, 0, ',', '.')}
                        </span>
                        <span className="label">Consumo (kw/h)</span>
                      </div>
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? numberFormat(results.MODULES, 0, ',', '.')
                            : numberFormat(self.MODULES, 0, ',', '.')}
                        </span>
                        <span className="label">Qtde. Módulos</span>
                      </div>
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? numberFormat(results.POWER, 2, ',', '.')
                            : numberFormat(self.POWER, 2, ',', '.')}
                        </span>
                        <span className="label">Potência (kwp)</span>
                      </div>
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? numberFormat(results.WEIGHT, 2, ',', '.')
                            : numberFormat(self.WEIGHT, 2, ',', '.')}
                        </span>
                        <span className="label">Peso (kg)</span>
                      </div>
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? numberFormat(results.AREA, 0, ',', '.')
                            : numberFormat(self.AREA, 0, ',', '.')}
                        </span>
                        <span className="label">Espaço (M2)</span>
                      </div>
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? numberFormat(
                                results.AVAILABILITY_RATE,
                                0,
                                ',',
                                '.'
                              )
                            : numberFormat(self.AVAILABILITY_RATE, 0, ',', '.')}
                        </span>
                        <span className="label">
                          Taxa de disponilidade (kw/h)
                        </span>
                      </div>
                      <div className="ui statistic">
                        <span className="value">
                          {editMode ? results.ROI_DESC : self.ROI_DESC}
                        </span>
                        <span className="label">Retorno do investimento</span>
                      </div>
                    </div>

                    <div className="ui centered two small statistics stackable m-t">
                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? `R$ ${numberFormat(
                                results.INVESTIMENT,
                                2,
                                ',',
                                '.'
                              )}`
                            : `R$ ${numberFormat(
                                self.INVESTIMENT,
                                2,
                                ',',
                                '.'
                              )}`}
                        </span>
                        <span className="label">Investimento</span>
                      </div>

                      <div className="ui statistic">
                        <span className="value">
                          {editMode
                            ? `R$ ${numberFormat(
                                results.SAVING_25YEARS,
                                2,
                                ',',
                                '.'
                              )}`
                            : `R$ ${numberFormat(
                                self.SAVING_25YEARS,
                                2,
                                ',',
                                '.'
                              )}`}
                        </span>
                        <span className="label">Economia em 25 anos</span>
                      </div>
                      {!editMode && (
                        <div className="ui statistic">
                          <span className="label">
                            Simulação realizada em {self.CREATED_AT_FORMAT}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid.Column>

                {editMode && (
                  <Grid.Column mobile={16}>
                    <button
                      type="button"
                      className="ui fluid button blue"
                      onClick={handleClickClean}
                    >
                      Nova simulação
                    </button>
                  </Grid.Column>
                )}
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Simulation;
