/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import InputNumberFormat from 'react-number-format';

// import classNames from 'classnames';
import { Modal, Button } from 'semantic-ui-react';

export default function ModalProjectMaterialSelect({
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const [material] = useState(data || {});

  const [quantity, setQuantity] = useState(data.QUANTITY || 1);
  const [unitValue, setUnitValue] = useState(
    data.PRICE || data.UNIT_VALUE || 0
  );

  const [inputUnitValue, setInputUnitValue] = useState(null);
  const [inputQuantity, setInputQuantity] = useState(null);

  useEffect(() => {
    if (inputQuantity) {
      inputQuantity.focus();
    }
  }, [inputQuantity]);

  function handleConfirm() {
    onConfirm({
      UNIT_VALUE: unitValue,
      QUANTITY: quantity,
    });

    handleClose();
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>Informe a quantidade e valor unitário.</Modal.Header>

      <Modal.Content>
        <div className="ui form m-b">
          <div className="fields">
            <div className="field">
              <label>Descrição do material</label>
              <i className="m-t-n-xs">
                {material.DESCRIPTION ||
                  material.MATERIAL_DESCRIPTION ||
                  'Não definida'}
              </i>
            </div>
          </div>
        </div>

        <div className="ui form">
          <div className="field required">
            <label>Quantidade</label>
            <InputNumberFormat
              type="tel"
              value={quantity || null}
              className="text-right"
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              onValueChange={v => setQuantity(v.floatValue)}
              onKeyPress={e =>
                e.key === 'Enter' && inputUnitValue && inputUnitValue.focus()
              }
              getInputRef={el => setInputQuantity(el)}
            />
          </div>
          <div className="field required">
            <label>Valor unitário (R$)</label>
            <InputNumberFormat
              type="tel"
              value={unitValue || null}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setUnitValue(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputUnitValue(el)}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button positive onClick={handleConfirm}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
