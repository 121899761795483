import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Message } from 'semantic-ui-react';
import swal from 'sweetalert';

import api from '~/services/api';

import Form from './Form';

function AccountAdd() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { accountId } = useParams();

  const [backTo] = useState(query.get('backTo'));

  const [self, setSelf] = useState(null);

  const updateSelf = useCallback(async () => {
    const { data: group } = await api({
      method: 'post',
      url: 'account/self',
      params: { account_id: accountId },
    });

    if (group.status === 0) {
      setSelf(group.data);
    } else {
      setSelf({});
    }
  }, []);

  useEffect(() => {
    updateSelf();
  }, [updateSelf]);

  function normalizeData(data) {
    return {
      ID: data.ID || '',
      NAME: data.NAME || '',
      EMAIL: data.EMAIL || '',
      POSTAL_CODE: data.POSTAL_CODE,
      ADDRESS: data.ADDRESS,
      NUMBER: data.NUMBER,
      COMPLEMENT: data.COMPLEMENT,
      DISTRICT: data.DISTRICT,
      UF_ID: Number(data.UF_ID) || '',
      CITY_ID: Number(data.CITY_ID) || '',
      RESPONSIBLE_MOBILE: data.RESPONSIBLE_MOBILE,
      RESPONSIBLE_EMAIL: data.RESPONSIBLE_EMAIL,
      RESPONSIBLE_NAME: data.RESPONSIBLE_NAME,
      RESPONSIBLE_PHONE: data.RESPONSIBLE_PHONE,
      STATUS: data.STATUS,
      FINANCIAL_STATUS: data.FINANCIAL_STATUS,
      FINANCIAL_OBSERVATION: data.FINANCIAL_OBSERVATION,
      QTTY_PROJECT: data.QTTY_PROJECT || 0,
    };
  }

  async function onSubmit(data) {
    const { FINANCIAL_STATUS, FINANCIAL_OBSERVATION } = data;

    const { data: responseSave } = await api({
      method: 'post',
      url: 'account/financial',
      params: {
        account_id: accountId,
        account: JSON.stringify({
          FINANCIAL_STATUS,
          FINANCIAL_OBSERVATION,
        }),
      },
    });

    const { title, text, type } = responseSave.message;

    if (responseSave.status === 0) {
      swal(title, text, type).then(() => {
        if (backTo) {
          history.push(backTo);
        } else {
          history.push('/accounts');
        }
      });
    } else {
      swal(title, text, type);
    }
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">Gestão fincanceira da conta</span>

          {backTo && (
            <div>
              <button
                className="ui button red"
                type="button"
                onClick={() => history.push(backTo)}
              >
                Cancelar
              </button>
            </div>
          )}
        </div>

        <div className="ui attached bottom segment">
          {self === null ? (
            <Message color="blue" content="Carregando informações..." />
          ) : (
            <Form initialData={normalizeData(self)} onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </div>
  );
}

export default AccountAdd;
