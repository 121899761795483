import React, { useContext, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { Modal, Button, Icon } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

export default function ModalTransformerAdd({
  mode = 'add',
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const { user } = useContext(AuthContext);

  const [reference, setReference] = useState(data.REFERENCE || '');
  const [description, setDescription] = useState(data.DESCRIPTION || '');
  const [selectedGroup, setSelectedGroup] = useState(data.GROUP_ID || null);

  const [groups, setGroups] = useState([]);

  const createdBy = user.NAME;

  const [file, setFile] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const acceptedFileTypes = [
    'text/csv', // csv
    'application/csv', // csv1
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // xlsb
    'application/vnd.ms-excel', // xls
    'application/vnd.ms-excel.sheet.macroEnabled.12', // xlsm
  ].join(',');

  const getGroupList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroups(response.data);
    }
  }, []);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  async function handleConfirm() {
    setIsSaving(true);

    if (!reference) {
      toast.error('Informe a data de referência para salvar!');
      setIsSaving(false);
      return;
    }

    if (!selectedGroup) {
      toast.error('Selecione o grupo de negócio para salvar!');
      setIsSaving(false);
      return;
    }

    if (mode === 'add' && !file) {
      toast.error('Envie uma planilha para salvar!');
      setIsSaving(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/importKit/add' : '/importKit/update',
      params: {
        import_kit_id: data.ID || null,
        import: JSON.stringify({
          REFERENCE: reference
            ? new Date(reference).toLocaleDateString()
            : null,
          DESCRIPTION: description,
          GROUP_ID: selectedGroup,
        }),
      },
      data: formData,
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar salvar a planilha: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Adicionar referência' : 'Atualizar referência'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Data de referencia</label>
            <input
              type="date"
              value={reference}
              onChange={e => setReference(e.target.value)}
            />
          </div>

          <div className="field required">
            <label>Descrição</label>
            <input
              type="text"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>

          <div className="field required">
            <label>Enviador por</label>
            <input type="text" value={createdBy} disabled />
          </div>

          <div className="field required">
            <label>Grupo</label>
            <select
              className="ui dropdown fluid"
              disabled={groups.length === 0}
              readOnly={groups.length === 1}
              value={selectedGroup}
              onChange={e => setSelectedGroup(e.target.value)}
            >
              <option selected disabled>
                Selecione
              </option>
              {groups.map(group => (
                <option key={group.ID} value={group.ID}>
                  {group.NAME}
                </option>
              ))}
            </select>
          </div>

          {mode === 'add' && (
            <div className="field">
              <label>Tabela (Excel)</label>

              <label
                htmlFor="file"
                className={classNames('ui icon button', {
                  green: !!file,
                })}
                style={{
                  fontSize: '14px',
                  color: file ? '#FFFFFF' : 'inherit',
                }}
              >
                <Icon name={file ? 'check' : 'upload'} />
                <span className="m-l-sm">
                  {file ? 'Arquivo selecionado' : 'Carregar tabela'}
                </span>
              </label>

              {file && (
                <small>
                  <b>Arquivo:</b> {file.name}
                </small>
              )}

              <input
                type="file"
                accept={acceptedFileTypes}
                id="file"
                className="ui file input"
                style={{ display: 'none' }}
                onChange={event => setFile(event.target.files[0])}
              />
            </div>
          )}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
