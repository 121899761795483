import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import swal from 'sweetalert';

import api from '~/services/api';

import FormRegister from './components/FormRegister';
import { Container } from './styles';

export default function Register() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(credential) {
    setIsLoading(true);

    const { data: response } = await api({
      method: 'POST',
      url: 'public/credential/simulate',
      params: {
        credential,
      },
    });

    const { title, text, type } = response.message;

    if (response.status !== 0) {
      swal(title, text, type);
    } else {
      swal(title, text, type).then(() => {
        history.push('/login');
      });
    }

    setIsLoading(false);
  }

  return (
    <Container>
      <FormRegister onSubmit={onSubmit} isLoading={isLoading} />
    </Container>
  );
}
