import styled from 'styled-components';

import { headerHeight, sidebarWidth } from '~/styles/global';

export const Container = styled.header`
  width: 100%;
  height: ${headerHeight}px !important;
  padding: 10px 10px;
  background: #222;
  color: #fff;
  position: fixed;
  top: 0;

  left: ${({ sidebarIsOpen }) => (sidebarIsOpen ? sidebarWidth : 0)}px;

  transition: all 0.2s ease-in-out 0s;

  z-index: 2;
`;

export const Site = styled.div`
  width: 100px;
  position: absolute;
  left: calc(50% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 26px;
  margin: auto;

  &:hover {
    opacity: 0.6;
  }
`;

export const Page = styled.div`
  position: fixed;
  left: ${({ sidebarIsOpen }) =>
    sidebarIsOpen ? `${sidebarWidth + 10}` : 10}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: all 0.2s ease-in-out 0s;

  button {
    background: transparent;
    border: 0;
    padding: 0;
    /* margin-top: 4px; */
    cursor: pointer;
  }
`;
