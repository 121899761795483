import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useContext,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Checkbox,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import ModalContext from '~/contexts/Modal';
import api from '~/services/api';
// import FormFilter from './components/Filter';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const history = useHistory();
  const query = useQuery();

  const { openModal } = useContext(ModalContext);

  const { accountId } = useParams();

  const [backTo] = useState(query.get('backTo') || `/accounts`);

  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);

  // const [showFilter, setShowFilter] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({
    ACCOUNT_ID: accountId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchUsers = useCallback(async (filters, pag) => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/credential/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setUsers(responseSearch.data);
      setPagination(responseSearch.pagination);
    } else {
      setUsers([]);
    }

    setLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchUsers(filter, newPagination);
    }, 1000)
  ).current;

  useEffect(() => {
    searchUsers(filter, pagination);
  }, [searchUsers]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchUsers(filter, pagination);
  }

  function handlePaginationChange(e, { activePage }) {
    searchUsers(filter, {
      ...pagination,
      page: activePage,
    });
  }

  const allSelected = useMemo(
    () => users.length && users.every(m => m.uiChecked === true),
    [users]
  );

  function handleSelectAll(e, { checked }) {
    setUsers(oldUsers =>
      oldUsers.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(userId) {
    setUsers(oldUsers =>
      oldUsers.map(item => {
        return item.ID === userId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function handleDelete(userId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um usuário permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'credential/delete',
          params: {
            credential_id: JSON.stringify([userId]),
          },
        });

        if (responseDelete.status === 0) {
          searchUsers(filter, pagination);
          toast.success('Usuário excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários usuários permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const usersToDelete = users
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!usersToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um usuário para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'credential/delete',
          params: {
            credential_id: JSON.stringify(usersToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Usuários excluídos com sucesso!');
          searchUsers(filter, pagination);
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handlePasswordRecovery(email) {
    swal({
      title: 'Atencão',
      text: `Um email de recuperação de senha será enviado para o seguinte endereço: ${email}!`,
      icon: 'info',
      buttons: ['Cancelar', 'Confirmar'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'credential/password/resend',
          params: {
            login: email,
          },
        });
        if (response.status === 0) {
          toast.success(response.message.text);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleBlock(userId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a bloquear um usuário. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Bloquear'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'credential/block',
          params: {
            credential_id: JSON.stringify([userId]),
            action: 'block',
          },
        });

        if (response.status === 0) {
          searchUsers(filter, pagination);
          toast.success('Usuário bloqueado com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleBlockSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a bloquear vários usuários. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Bloquear'],
    }).then(async result => {
      if (result) {
        const usersToBlock = users
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!usersToBlock.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um usuário para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'credential/block',
          params: {
            credential_id: JSON.stringify(usersToBlock),
            action: 'block',
          },
        });

        if (response.status === 0) {
          toast.success('Usuários bloqueados com sucesso!');
          searchUsers(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleUnblock(userId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a desbloquear um usuário. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Desbloquear'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'credential/block',
          params: {
            credential_id: JSON.stringify([userId]),
            action: 'unblock',
          },
        });

        if (response.status === 0) {
          searchUsers(filter, pagination);
          toast.success('Usuário desbloqueado com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleUnblockSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a desbloquear vários usuários. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Desbloquear'],
    }).then(async result => {
      if (result) {
        const usersToUnblock = users
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!usersToUnblock.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um usuário para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'credential/block',
          params: {
            credential_id: JSON.stringify(usersToUnblock),
            action: 'unblock',
          },
        });

        if (response.status === 0) {
          toast.success('Usuários desbloqueados com sucesso!');
          searchUsers(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function renderSituationByStatus(status) {
    if (status === 'A') {
      return (
        <Label size="mini" color="green">
          Ativo
        </Label>
      );
    }
    if (status === 'B') {
      return (
        <Label size="mini" color="red">
          Bloqueado
        </Label>
      );
    }
    if (status === 'P') {
      return (
        <Label size="mini" color="yellow">
          Pendente
        </Label>
      );
    }

    return <Label size="mini">-</Label>;
  }

  function renderWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned" width="2%">
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            </th>
            <th className="center aligned">Grupo</th>
            <th className="center aligned">Nome</th>
            <th className="center aligned">E-mail</th>
            <th className="center aligned">Cadastro</th>
            <th className="center aligned">Cidade</th>
            <th className="center aligned">Situação</th>
            <th className="center aligned" width="2%">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 &&
            !loading &&
            users.map(user => (
              <tr
                key={user.ID}
                className={classNames({
                  active: user.uiChecked,
                })}
              >
                <td className="center aligned">
                  <Checkbox
                    checked={!!user.uiChecked}
                    onChange={() => handleSelect(user.ID)}
                  />
                </td>
                <td className="center aligned">
                  {user.ACCOUNT_GROUP_NAME || '-'}
                </td>
                <td className="center aligned">{user.NAME || '-'}</td>
                <td className="center aligned">{user.EMAIL || '-'}</td>
                <td className="center aligned">
                  {user.CREATED_AT_DATE_FORMAT || '-'}
                </td>
                <td className="center aligned">
                  {user.CITY_NAME ? `${user.CITY_NAME}/${user.UF_NAME}` : '-'}
                </td>

                <td className="center aligned">
                  {renderSituationByStatus(user.STATUS)}
                </td>

                <td className="center aligned">
                  <Dropdown
                    icon="cog"
                    floating
                    title="Ações"
                    button
                    className="mini blue icon"
                    direction="left"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Header content="Ações" />
                      <Dropdown.Divider />

                      {user.STATUS === 'P' && (
                        <Dropdown.Item
                          icon="mail"
                          text="E-mail de ativação"
                          title="E-mail de ativação"
                          onClick={() =>
                            openModal('activationMail', {
                              data: {
                                ID: user.ID,
                                EMAIL: user.EMAIL,
                                STATUS: user.STATUS,
                              },
                              onConfirm: () => searchUsers(filter, pagination),
                            })
                          }
                        />
                      )}

                      {user.STATUS !== 'P' && (
                        <Dropdown.Item
                          icon="unlock"
                          text="Recuperar senha"
                          title="Recuperar senha"
                          onClick={() => handlePasswordRecovery(user.EMAIL)}
                        />
                      )}

                      <Dropdown.Item
                        color="blue"
                        icon="pencil"
                        text="Editar usuário"
                        title="Editar usuário"
                        onClick={() =>
                          history.push(
                            `/account/${user.ACCOUNT_ID}/user/${user.ID}?backTo=/account/${user.ACCOUNT_ID}/users`
                          )
                        }
                      />

                      {user.STATUS !== 'P' &&
                        (user.STATUS === 'A' ? (
                          <Dropdown.Item
                            icon="lock"
                            text="Bloquear usuário"
                            title="Bloquear usuário"
                            onClick={() => handleBlock(user.ID)}
                          />
                        ) : (
                          <Dropdown.Item
                            icon="lock open"
                            text="Desbloquear usuário"
                            title="Desbloquear usuário"
                            onClick={() => handleUnblock(user.ID)}
                          />
                        ))}

                      <Dropdown.Item
                        color="red"
                        icon="trash"
                        text="Remover usuário"
                        title="Remover usuário"
                        onClick={() => handleDelete(user.ID)}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}

          {!loading && users.length === 0 && (
            <tr>
              <td colSpan="10">
                <Message content="Nenhum usuário encontrado! Atualize os filtros e pesquisa novamente." />
              </td>
            </tr>
          )}

          {loading && (
            <tr>
              <td colSpan="10">
                <Message color="blue" content="Buscando usuários..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderClientsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              {/* <button
                className="ui button label blue"
                type="button"
                onClick={() => setShowFilter(!showFilter)}
              >
                <i className="mdi mdi-filter" />
                Filtros
              </button> */}

              <button
                className={classNames('ui button label', {
                  loading,
                })}
                type="button"
                disabled={loading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            className="right aligned"
            mobile={16}
            table={8}
            computer={8}
          >
            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!users.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="lock open"
                  text="Desbloquear selecionados"
                  title="Desbloquear selecionados"
                  onClick={handleUnblockSelected}
                />

                <Dropdown.Item
                  color="red"
                  icon="lock"
                  text="Bloquear selecionados"
                  title="Bloquear selecionados"
                  onClick={handleBlockSelected}
                />

                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  title="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            <button
              type="button"
              className="ui button green"
              onClick={() =>
                history.push(
                  `/account/${accountId}/user/?backTo=/account/${accountId}/users`
                )
              }
            >
              <i className="mdi mdi-account-plus"> </i> Novo usuário
            </button>
          </Grid.Column>

          {/* {showFilter && (
            <Grid.Column mobile={16} table={16} computer={16}>
              <FormFilter handleSubmit={() => {}} initialData={{}} />
            </Grid.Column>
          )} */}
        </Grid>

        {renderWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              boundaryRange={2}
              siblingRange={2}
              disabled={loading}
              // defaultActivePage={1}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Controle de usuários </span>
            <div>
              <div>
                <button
                  className="ui button red"
                  type="button"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>

          <div className="ui attached bottom segment">
            {renderClientsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
