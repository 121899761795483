import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

export default function ModalEnergyProviderAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState(data.DESCRIPTION || '');
  const [region, setRegion] = useState(data.REGION || '');
  const [phone, setPhone] = useState(data.PHONE || '');

  const inputDescription = useRef(null);
  const inputRegion = useRef(null);

  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.focus();
    }
  }, [inputDescription]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/energy/provider/add' : '/energy/provider/update',
      params: {
        provider_id: data.ID || null,
        provider: JSON.stringify({
          DESCRIPTION: description,
          REGION: region || null,
          PHONE: phone || null,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar a concessionária: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo concessionária' : 'Editar concessionária'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Nome</label>
            <input
              type="text"
              ref={inputDescription}
              value={description}
              onChange={e => setDescription(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && inputRegion.current.focus()}
              disabled={isSaving}
            />
          </div>

          <div className="field">
            <label>Região</label>
            <select
              ref={inputRegion}
              value={region}
              onChange={e => setRegion(e.target.value)}
              disabled={isSaving}
            >
              <option selected value="">
                Selecione uma região
              </option>
              <option selected value="Norte">
                Norte
              </option>
              <option selected value="Nordeste">
                Nordeste
              </option>
              <option selected value="Centro-Oeste">
                Centro-Oeste
              </option>
              <option selected value="Sul">
                Sul
              </option>
              <option selected value="Sudeste">
                Sudeste
              </option>
            </select>
          </div>

          <div className="field">
            <label>Telefone</label>
            <MaskedInput
              mask="(99) 99999-9999"
              type="tel"
              placeholder="(00) 00000-0000"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              disabled={isSaving}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
