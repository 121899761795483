import React from 'react';

import numberFormat from 'locutus/php/strings/number_format';
import { Button, Card, Icon } from 'semantic-ui-react';

export default function ProjectCard({
  project,
  handleEdit,
  handleDelete,
  handleSelect,
  type = null,
}) {
  function getButtonsByType() {
    switch (type) {
      case 'select':
        return (
          <Button
            size="mini"
            icon
            color="green"
            title="Selecionar"
            onClick={() => handleSelect(project)}
          >
            <Icon name="check" /> Selecionar
          </Button>
        );
      default:
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar projeto"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>

            <Button
              size="mini"
              icon
              color="red"
              title="Excluir projeto"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
    }
  }

  const clientName =
    project.CLIENT_TYPE === 'L'
      ? `${project.CLIENT_COMPANY_NAME} (${project.CLIENT_FANTASY_NAME})`
      : project.CLIENT_NAME;

  return (
    <Card fluid className="text-center">
      <Card.Content extra>
        <Card.Description as="b">{project.NAME}</Card.Description>
        <Card.Meta>Código: {project.CODE}</Card.Meta>
      </Card.Content>

      {!!clientName && (
        <Card.Content extra>
          <b>
            <i className="mdi mdi-account" /> Cliente:
          </b>{' '}
          {clientName}
        </Card.Content>
      )}

      {!!project.CITY_NAME && (
        <Card.Content extra>
          <b>
            <i className="mdi mdi-map-marker" /> Cidade:
          </b>{' '}
          {project.CITY_NAME}
        </Card.Content>
      )}

      {!!project.MONTHLY_SAVINGS_PERC && (
        <Card.Content extra>
          <b>Economia na conta de energia: </b>
          <br />
          <span style={{ fontSize: 16 }}>
            {numberFormat(project.MONTHLY_SAVINGS_PERC, 2, ',', '.')}%
          </span>
        </Card.Content>
      )}

      {!!project.RETURN_TIME_YEARS_DESC && (
        <Card.Content extra>
          <b>Tempo de retorno: </b>
          <br />
          <span style={{ fontSize: 16 }}>{project.RETURN_TIME_YEARS_DESC}</span>
        </Card.Content>
      )}

      {!!project.TOTAL_ITEM && (
        <Card.Content extra>
          <b>Valor total: </b>
          <br />
          <span style={{ fontSize: 16 }}>
            R$ {numberFormat(project.TOTAL_ITEM, 2, ',', '.')}
          </span>
        </Card.Content>
      )}

      <Card.Content extra>{getButtonsByType()}</Card.Content>
    </Card>
  );
}
