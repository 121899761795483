import React, { useState, useEffect, useCallback } from 'react';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Modal, Button, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalProjectTransformerSelect({
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const [loading, setLoading] = useState(false);

  const [selectedTransformer, setSelectedTransformer] = useState(
    data.TRANSFORMER_ID || null
  );
  const [transformers, setTransformers] = useState([]);

  const [quantity, setQuantity] = useState(data.QUANTITY || 1);
  const [unitValue, setUnitValue] = useState(
    data.PRICE || data.UNIT_VALUE || data.TOTAL || 0
  );

  const [inputUnitValue, setInputUnitValue] = useState(null);
  const [inputQuantity, setInputQuantity] = useState(null);

  const searchTransformers = useCallback(async () => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: '/transformer/select',
      params: {
        orderby: { field: 'DESCRIPTION', mode: 'ASC' },
      },
    });

    if (responseSearch.status === 0) {
      setTransformers(responseSearch.data);
    } else {
      setTransformers([]);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (inputQuantity) {
      inputQuantity.focus();
    }
  }, [inputQuantity]);

  useEffect(() => {
    searchTransformers();
  }, [searchTransformers]);

  function handleConfirm() {
    if (!selectedTransformer) {
      toast.error('Selecione um transformador para adicionar ao projeto.');
      return;
    }

    onConfirm({
      UNIT_VALUE: unitValue,
      QUANTITY: quantity,
      TRANSFORMER_ID: selectedTransformer,
    });

    handleClose();
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {data.TRANSFORMER_ID
          ? 'Editar transformador do projeto'
          : 'Adicionar transformador ao projeto'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Transformador</label>
            <Dropdown
              fluid
              search
              selection
              options={transformers.map(transformer => ({
                key: transformer.ID,
                value: transformer.ID,
                text: transformer.DESCRIPTION,
              }))}
              value={selectedTransformer}
              placeholder="Selecione um transformador."
              noResultsMessage="Nenhum transformador encontrado."
              onChange={(e, d) => setSelectedTransformer(d.value)}
              disabled={loading || !!data.TRANSFORMER_ID}
            />
          </div>

          <div className="field required">
            <label>Quantidade</label>
            <InputNumberFormat
              type="tel"
              value={quantity || null}
              className="text-right"
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              onValueChange={v => setQuantity(v.floatValue)}
              onKeyPress={e =>
                e.key === 'Enter' && inputUnitValue && inputUnitValue.focus()
              }
              getInputRef={el => setInputQuantity(el)}
            />
          </div>

          <div className="field required">
            <label>Valor unitário (R$)</label>
            <InputNumberFormat
              type="tel"
              value={unitValue || null}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setUnitValue(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputUnitValue(el)}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} loading={loading}>
          Cancelar
        </Button>

        <Button positive onClick={handleConfirm} loading={loading}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
