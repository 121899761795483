import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import { Grid, Pagination, Message, Icon, Button } from 'semantic-ui-react';
import swal from 'sweetalert';

import BillCard from '~/components/Card/Bill';
import api from '~/services/api';

export default function ClientBillSearch() {
  const history = useHistory();
  const { clientId } = useParams();

  const [showTable, setShowTable] = useState(!isMobile);

  const [client, setClient] = useState({});

  const [keyword, setKeyword] = useState('');

  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filter] = useState({
    CLIENT_ID: clientId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const searchBills = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/energy/bill/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setBills(responseSearch.data);
    } else {
      setBills([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchBills(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchBills(filter, pagination);
  }, [searchBills]);

  useEffect(() => {
    (async () => {
      const clientData = await getClientSelf({ client_id: clientId });
      setClient(clientData);
    })();
  }, [clientId]);

  async function getClientSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'client/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handleEdit(billId) {
    history.push(`/client/${clientId}/bill/${billId}?mode=edit`);
  }

  function handlePaginationChange(e, { activePage }) {
    searchBills(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleDelete(billId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir uma conta permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'energy/bill/delete',
          params: {
            energy_bill_id: billId,
          },
        });

        if (response.status === 0) {
          toast.success('Conta excluída com sucesso!');
          searchBills(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function getNameByType(c) {
    if (c.TYPE_FORMAT === 'PF') {
      return c.NAME || '-';
    }

    return `${c.COMPANY_NAME || '-'} (${c.FANTASY_NAME || '-'})`;
  }

  function renderBillsWithCard() {
    return (
      <Grid centered>
        {bills.length > 0 &&
          !isLoading &&
          bills.map(bill => (
            <Grid.Column key={bill.ID} mobile={16} tablet={8} computer={4}>
              <BillCard
                handleEdit={() => handleEdit(bill.ID)}
                handleDelete={() => handleDelete(bill.ID)}
                bill={bill}
              />
            </Grid.Column>
          ))}

        {!isLoading && bills.length === 0 && (
          <Grid.Column>
            <Message content="Nenhuma conta encontrada!" />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando contas..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderBillsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">Código</th>
            <th className="center aligned">Descrição</th>
            <th className="center aligned">Valor TUSD</th>
            <th className="center aligned">Valor TE</th>
            <th className="center aligned">Grupo</th>
            <th className="center aligned">Taxa de disponibilidade</th>
            <th className="center aligned">Consumo médio mensal (KW/h)</th>
            <th className="center aligned">Valor médio mensal (KW/h)</th>
            <th className="center aligned">HSP (horas de sol a pico)</th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {bills.length > 0 &&
            !isLoading &&
            bills.map(bill => {
              return (
                <tr key={bill.ID}>
                  <td className="center aligned">{bill.UNIT_CODE || '-'}</td>
                  <td className="center aligned">
                    {bill.PROPERTY_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.TOTAL_VALUE_TAX_TUSD, 4, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.TOTAL_VALUE_TAX_TE, 4, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {bill.GROUND_GROUP_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.AVAILABILITY_RATE, 0, ',', '.')}
                  </td>

                  <td className="center aligned">
                    {numberFormat(bill.KWH_MEAN, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.KWH_TOTAL, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.HOURLY_SUNNY_PEAK, 3, ',', '.')}
                  </td>
                  <td className="center aligned">
                    <Button
                      size="mini"
                      icon
                      color="blue"
                      title="Editar conta"
                      onClick={() => handleEdit(bill.ID)}
                    >
                      <Icon name="pencil" />
                    </Button>

                    <Button
                      size="mini"
                      icon
                      color="red"
                      title="Remover conta"
                      onClick={() => handleDelete(bill.ID)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}

          {!isLoading && bills.length === 0 && (
            <tr>
              <td colSpan="9">
                <Message content="Nenhuma conta encontrada! Atualize os filtros e pesquisa novamente." />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="9">
                <Message color="blue" content="Buscando contas..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderBillsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={() => searchBills(filter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
        </Grid>

        {showTable ? renderBillsWithTable() : renderBillsWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">Gerenciar contas</span>
          <div>
            {!isMobile && (
              <button
                type="button"
                className="ui button icon"
                title="Alterar visualização"
                onClick={() => setShowTable(!showTable)}
              >
                <i
                  className={classNames('mdi icon', {
                    'mdi-table': showTable,
                    'mdi-cards': !showTable,
                  })}
                />
              </button>
            )}

            <button
              type="button"
              className="ui button green"
              onClick={() => history.push(`/client/${clientId}/bill/new`)}
            >
              Adicionar conta
            </button>
          </div>
        </div>

        <div className="ui attached bottom segment">
          <div className="ui message">
            <div className="header">Informações do cliente:</div>
            <div className="content">{getNameByType(client)}</div>
          </div>

          {renderBillsContainer()}
        </div>
      </div>
    </div>
  );
}
