import styled from 'styled-components';

import { headerHeight, sidebarWidth } from '~/styles/global';

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  width: ${sidebarWidth}px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : `-${sidebarWidth}`)}px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #222;
  transition: all 0.2s ease-in-out 0s;
  border-right: 1px solid #444;

  z-index: 2;

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 15px 10px;
    background: #222;
    border-bottom: 1px solid #444;
  }

  a:hover {
    color: #ccc;
  }
`;

export const User = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  width: 100%;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const UserMenu = styled.section`
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
  }
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  /* width: 50px;
  height: 50px !important; */
  border-radius: 50%;
  background: #fbbd08;
  /* margin-bottom: 6px; */
  color: #000;
`;

export const Name = styled.span`
  font-weight: bold;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${headerHeight}px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  padding-bottom: 10px;
  border-bottom: 1px solid #444;

  &:first-child {
    margin-top: 5px;
  }

  h1 {
    font-size: 10px;
    color: #ccc;
    text-transform: uppercase;
    font-weight: normal;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 0;
    letter-spacing: 2px;
    font-weight: bold;
  }

  a {
    display: flex;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    padding: 5px;
    margin-left: 15px;
    /* margin-left: 10px; */
    cursor: pointer;
  }
`;
