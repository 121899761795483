import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const { openModal } = useContext(ModalContext);

  const history = useHistory();
  const query = useQuery();
  const [backTo] = useState(query.get('backTo'));

  const [energyProviders, setEnergyProviders] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchEnergyProviders = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: 'energy/provider/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setEnergyProviders(responseSearch.data);
    } else {
      setEnergyProviders([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchEnergyProviders(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchEnergyProviders(filter, {
      ...pagination,
      keyword,
    });
  }, [searchEnergyProviders]);

  useEffect(() => {
    if (limitChanged) {
      searchEnergyProviders(filter, {
        ...pagination,
      });
    }

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchEnergyProviders(filter, { ...pagination, keyword });
  }

  function handlePaginationChange(e, { activePage }) {
    searchEnergyProviders(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function handleEnergyProviderDelete(energyProviderId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir uma concessionária de energia permanentemente. Deseja mesmo realizar essa ação?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'energy/provider/delete',
          params: {
            provider_id: JSON.stringify([energyProviderId]),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Concessionária de energia excluída com sucesso!');
          searchEnergyProviders(filter, { ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir várias concessionária de energia permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const statusToDelete = energyProviders
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!statusToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos uma concessionária de energia para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'energy/provider/delete',
          params: {
            provider_id: JSON.stringify(statusToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Concessionárias de energia excluídas com sucesso!');
          searchEnergyProviders(filter, { ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () =>
      energyProviders.length &&
      energyProviders.every(m => m.uiChecked === true),
    [energyProviders]
  );

  function handleSelectAll(e, { checked }) {
    setEnergyProviders(oldStatus =>
      oldStatus.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(energyProviderId) {
    setEnergyProviders(oldStatus =>
      oldStatus.map(item => {
        return item.ID === energyProviderId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function renderEnergyProviderRegion(energyProvider) {
    if (['SO_SO_CLOSE', 'FAR_FAR_AWAY'].includes(energyProvider.REGION)) {
      return '-';
    }

    return energyProvider.REGION;
  }

  function renderStatusWithTable() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'DESCRIPTION', description: 'Nome' },
          { field: 'REGION', description: 'Região' },
          { field: 'PHONE', description: 'Telefone' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={energyProvider => ({
          ID: () => (
            <Checkbox
              checked={!!energyProvider.uiChecked}
              onChange={() => handleSelect(energyProvider.ID)}
            />
          ),
          DESCRIPTION: energyProvider.DESCRIPTION,
          REGION: renderEnergyProviderRegion(energyProvider),
          PHONE: energyProvider.PHONE,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                color="blue"
                title="Editar concessionária de energia"
                onClick={() => {
                  openModal('energyProviderAdd', {
                    mode: 'edit',
                    data: {
                      ID: energyProvider.ID,
                      DESCRIPTION: energyProvider.DESCRIPTION,
                      REGION: energyProvider.REGION,
                      PHONE: energyProvider.PHONE,
                    },
                    onConfirm: () =>
                      searchEnergyProviders(filter, { ...pagination, keyword }),
                  });
                }}
              >
                <Icon name="pencil" />
              </Button>

              <Button
                size="mini"
                icon
                color="red"
                title="Remover concessionária de energia"
                onClick={() => handleEnergyProviderDelete(energyProvider.ID)}
              >
                <Icon name="trash" />
              </Button>
            </>
          ),
        })}
        data={energyProviders}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchEnergyProviders(filter, { ...newPagination, keyword });
        }}
      />
    );
  }

  function renderEnergyProvidersContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!energyProviders.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            <button
              type="button"
              className="ui button green icon"
              title="Nova concessionária"
              onClick={() => {
                openModal('energyProviderAdd', {
                  mode: 'add',
                  onConfirm: () => searchEnergyProviders(filter, pagination),
                });
              }}
            >
              <i className="mdi mdi-plus icon" /> Nova concessionária
            </button>
          </Grid.Column>
        </Grid>

        {renderStatusWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title">Gerenciar concessionárias de energia</span>

            {backTo && (
              <div>
                <button
                  className="ui button red"
                  type="button"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              </div>
            )}
          </div>

          <div className="ui attached bottom segment">
            {renderEnergyProvidersContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
