import React from 'react';

import { Button, Card, Icon, Label } from 'semantic-ui-react';

export default function ClientCard({
  client,
  handleEdit,
  handleDelete,
  handleBill,
  handleSelect,
  type = null,
}) {
  function getNameByType() {
    if (client.TYPE_FORMAT === 'PF') {
      return client.NAME || '-';
    }

    return `${client.COMPANY_NAME || '-'} (${client.FANTASY_NAME || '-'})`;
  }

  function getButtonsByType() {
    switch (type) {
      case 'select':
        return (
          <Button
            size="mini"
            icon
            color="green"
            title="Selecionar"
            onClick={() => handleSelect(client)}
          >
            <Icon name="check" /> Selecionar
          </Button>
        );
      default:
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar cliente"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>

            <Button
              size="mini"
              icon
              color="red"
              title="Remover cliente"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
    }
  }

  return (
    <Card fluid className="text-center">
      <Card.Content extra>
        <Label size="mini" title="Pessoa física">
          <Icon name="user" /> {client.TYPE_FORMAT}
        </Label>
        <Label
          attached="top right"
          as="button"
          type="button"
          color={client.ENERGY_BILL_QTTY > 0 ? 'blue' : 'orange'}
          onClick={handleBill}
          title="Gestão de contas"
        >
          <Icon name="file alternate" /> {client.ENERGY_BILL_QTTY}
        </Label>
      </Card.Content>

      <Card.Content>
        <Card.Description>{getNameByType()}</Card.Description>
        {client.EMAIL && <Card.Meta> {client.EMAIL} </Card.Meta>}
      </Card.Content>

      {client.CELL_PHONE && (
        <Card.Content extra>
          <Icon name="mobile" /> {client.CELL_PHONE}
        </Card.Content>
      )}

      {client.PHONE && (
        <Card.Content extra>
          <Icon name="phone" /> {client.PHONE}
        </Card.Content>
      )}

      {client.CONTACT && (
        <Card.Content extra>
          <Icon name="user" /> {client.CONTACT}
        </Card.Content>
      )}

      {client.DATE_CREATE_FORMAT && (
        <Card.Content extra title="Data de cadastro">
          <Icon name="calendar" /> {client.DATE_CREATE_FORMAT}
        </Card.Content>
      )}
      <Card.Content extra>{getButtonsByType()}</Card.Content>
    </Card>
  );
}
