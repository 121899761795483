import React from 'react';

import { Button, Card, Icon } from 'semantic-ui-react';

export default function ItemCard({
  item = {},
  type,
  handleSelect,
  handleDelete,
}) {
  function getButtonsByType() {
    switch (type) {
      default:
        return (
          <>
            <Button
              size="mini"
              icon
              color="green"
              title="Selecionar"
              onClick={() => handleSelect(item)}
            >
              <Icon name="check" /> Selecionar
            </Button>

            <Button
              size="mini"
              icon
              color="red"
              title="Remover item"
              onClick={() => handleDelete(item.ID)}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
    }
  }
  return (
    <Card fluid className="text-center">
      <Card.Content>
        <Card.Header>{item.DESCRIPTION || ''}</Card.Header>
      </Card.Content>

      <Card.Content extra>{getButtonsByType()}</Card.Content>
    </Card>
  );
}
