import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Card, Checkbox, Icon } from 'semantic-ui-react';

import api from '~/services/api';

export default function GroupConfig() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [backTo] = useState(query.get('backTo'));

  const [isLoading, setIsLoading] = useState(false);

  const [group, setGroup] = useState({});
  const [configs, setConfigs] = useState([]);
  const [configValues, setConfigValues] = useState([]);

  const { groupId } = useParams();

  const searchConfigs = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'config/search',
      params: {
        pagination: JSON.stringify({
          page: 1,
          limit: 100,
          orderby: [{ field: 'ID', mode: 'ASC' }],
        }),
        filter: JSON.stringify({}),
      },
    });

    if (responseSearch.status === 0) {
      setConfigs(responseSearch.data);
    } else {
      setConfigs([]);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    searchConfigs();
  }, [searchConfigs]);

  function getConfigID(key) {
    const config = configs.find(c => c.KEY_ === key);
    if (config) return config.ID;

    return null;
  }

  function getConfigValueID(key) {
    const configValue = configValues.find(c => c.CONFIG_KEY_ === key);
    if (configValue) return configValue.ID;

    return null;
  }

  const hasHomologationConfig = useMemo(() => {
    return !!configValues.find(c => c.CONFIG_KEY_ === 'use.homologation');
  }, [configValues]);

  const hasViewRoyaltiesConfig = useMemo(() => {
    return !!configValues.find(c => c.CONFIG_KEY_ === 'view.royalties');
  }, [configValues]);

  const searchConfigValues = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'config/value/search',
      params: {
        pagination: JSON.stringify({
          page: 1,
          limit: 100,
          orderby: [{ field: 'ID', mode: 'ASC' }],
        }),
        filter: JSON.stringify({
          ACCOUNT_GROUP_ID: groupId,
        }),
      },
    });

    if (responseSearch.status === 0) {
      setConfigValues(responseSearch.data);
    } else {
      setConfigValues([]);
    }

    setIsLoading(false);
  }, [configs]);

  useEffect(() => {
    if (configs.length > 0) {
      searchConfigValues();
    }
  }, [searchConfigValues, configs]);

  async function handleSaveConfig(configKey) {
    const { data: responseAdd } = await api({
      method: 'post',
      url: '/config/value/save',
      params: {
        value: {
          CONFIG_ID: getConfigID(configKey),
          ACCOUNT_GROUP_ID: groupId,
          VALUE: 1,
        },
      },
    });

    if (responseAdd.status === 0) {
      searchConfigValues();
    } else {
      toast.error(`${responseAdd.message.text}`);
    }
  }

  async function handleRemoveConfig(configValueKey) {
    const { data: responseDelete } = await api({
      method: 'post',
      url: '/config/value/delete',
      params: {
        config_value_id: getConfigValueID(configValueKey),
      },
    });

    if (responseDelete.status === 0) {
      searchConfigValues();
    } else {
      toast.error(`${responseDelete.message.text}`);
    }
  }

  useEffect(() => {
    (async () => {
      const groupData = await getGroupSelf({ account_group_id: groupId });
      setGroup(groupData);
    })();
  }, [groupId]);

  async function getGroupSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'account/group/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  function renderConfigContainer() {
    return (
      <Card.Group stackable itemsPerRow={isMobile ? 1 : 4}>
        <Card
          href={`/brands/group/${groupId}?backTo=/configurations/group/${groupId}`}
        >
          <Card.Content>
            <Card.Header>
              <Icon name="tag" /> Marcas
            </Card.Header>
          </Card.Content>
          <Card.Content description="Controle quais marcas podem ser visualizadas pelo grupo. " />
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              <Icon name="clipboard check" /> Orçamento: Homologação
            </Card.Header>
          </Card.Content>
          <Card.Content description="Com essa opção liberada, as contas do grupo podem indicar o valor de homologação do projeto." />

          <Card.Content extra>
            <Checkbox
              toggle
              checked={hasHomologationConfig}
              onChange={() =>
                hasHomologationConfig
                  ? handleRemoveConfig('use.homologation')
                  : handleSaveConfig('use.homologation')
              }
              disabled={isLoading}
              label={hasHomologationConfig ? 'Ativado' : 'Desativado'}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              <Icon name="dollar sign" /> Visualizar Royalties
            </Card.Header>
          </Card.Content>
          <Card.Content description="Visualizar em projetos (orçamento) o total de Royalties." />

          <Card.Content extra>
            <Checkbox
              toggle
              checked={hasViewRoyaltiesConfig}
              onChange={() =>
                hasViewRoyaltiesConfig
                  ? handleRemoveConfig('view.royalties')
                  : handleSaveConfig('view.royalties')
              }
              disabled={isLoading}
              label={hasViewRoyaltiesConfig ? 'Ativado' : 'Desativado'}
            />
          </Card.Content>
        </Card>
      </Card.Group>
    );
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">
            Configurações <br />
            <small>Grupo {group.NAME}</small>
          </span>

          {backTo && (
            <div>
              <button
                className="ui button red"
                type="button"
                onClick={() => history.push(backTo)}
              >
                Voltar
              </button>
            </div>
          )}
        </div>

        <div className="ui attached bottom segment">
          {renderConfigContainer()}
        </div>
      </div>
    </div>
  );
}
