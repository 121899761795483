import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';

import classNames from 'classnames';
import swal from 'sweetalert';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

export default function FormRegister({ initialData, isReadOnly }) {
  const { register, errors, setValue, getValues } = useForm({
    defaultValues: initialData,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [personSelected, setPersonSeleted] = useState(initialData.PERSON);
  const [maskPersonDocument, setMaskPersonDocument] = useState();

  const [stateSelected, setStateSelected] = useState(initialData.UF_ID);

  const [isFindingCEP, setIsFindingCEP] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [filteredCities, setFilteredCities] = useState(
    cityList.filter(city => city.UF_ID === stateSelected)
  );

  useEffect(() => {
    let states = [];
    let cities = [];

    setIsLoading(true);

    async function getState() {
      const { data: uf } = await api({
        method: 'get',
        url: 'uf/list',
        params: {
          filter: JSON.stringify({}),
        },
      });

      if (uf.status === 0) {
        states = uf.data;
      } else {
        swal('Ops!', 'Houve um erro ao tentar carregar os estados!', 'error');
      }
    }

    async function getCity() {
      const { data: city } = await api({
        method: 'get',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: stateSelected,
          }),
        },
      });

      if (city.status === 0) {
        cities = city.data;
      } else {
        swal('Ops!', 'Houve um erro ao tentar carregar as cidades!', 'error');
      }
    }

    async function initStateAndCity() {
      await getState();
      await getCity();

      setIsLoading(false);

      setStateList(states);
      setCityList(cities);

      setFilteredCities(cities);

      setValue('UF_ID', initialData.UF_ID);
      setValue('CITY_ID', initialData.CITY_ID);
    }

    initStateAndCity();
  }, [initialData.UF_ID, initialData.CITY_ID, setValue]);

  useEffect(() => {
    setFilteredCities(cityList.filter(city => city.UF_ID === stateSelected));
  }, [stateSelected, cityList]);

  useEffect(() => {
    if (personSelected === 'PF') {
      setMaskPersonDocument('999.999.999-99');
    } else {
      setMaskPersonDocument('99.999.999/9999-99');
    }
  }, [personSelected]);

  function handleChangePerson(event) {
    setPersonSeleted(event.target.value);
  }

  function renderPersonPJFields() {
    return (
      <div className="field">
        <div className="two fields">
          <div className="field required">
            <label>Razão social</label>
            <input
              type="text"
              name="COMPANY_NAME"
              placeholder="Razão social"
              ref={register({ required: true })}
              readOnly={isReadOnly}
            />
            {errors.COMPANY_NAME && <span>Razão social é obrigatória</span>}
          </div>

          <div className="field required">
            <label>Nome fantasia</label>
            <input
              type="text"
              name="FANTASY_NAME"
              placeholder="Nome fantasia"
              ref={register({ required: true })}
              readOnly={isReadOnly}
            />
            {errors.FANTASY_NAME && <span>Nome fantasia é obrigatório</span>}
          </div>
        </div>

        <div className="two fields">
          <div className="field">
            <label>Inscrição estadual</label>
            <input
              type="text"
              name="STATE_REGISTRATION"
              placeholder="Inscrição estadual"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>

          <div className="field">
            <label>Inscrição municipal</label>
            <input
              type="text"
              name="TOWN_REGISTRATION"
              placeholder="Inscrição municipal"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>
        </div>

        <div className="field">
          <label>Proprietário</label>
          <input
            type="text"
            name="HOLDER"
            placeholder="Proprietário"
            ref={register}
            readOnly={isReadOnly}
          />
        </div>
      </div>
    );
  }

  function renderPersonPFFields() {
    return (
      <div className="field required">
        <label>Nome completo</label>
        <input
          type="text"
          name="NAME"
          placeholder="Nome completo"
          ref={register({ required: true })}
          readOnly={isReadOnly}
        />
        {errors.NAME && <span>Nome completo é obrigatório</span>}
      </div>
    );
  }

  async function handleFindCEP(e) {
    if (e) {
      e.preventDefault();
    }

    const { POSTAL_CODE: cep } = getValues();

    if (!cep) return;

    setIsFindingCEP(true);

    const { data } = await api({
      method: 'get',
      url: 'cep/lookup',
      params: {
        cep,
      },
    });

    setIsFindingCEP(false);

    if (data.status === 0) {
      const { UF, CITY, ADDRESS } = data.data;

      setStateSelected(+UF.ID);
      setValue('UF_ID', +UF.ID);
      setValue('CITY_ID', +CITY.ID);
      setValue('ADDRESS', ADDRESS.VALUE);
      setValue('DISTRICT', ADDRESS.DISTRICT);
    }
  }

  return (
    <form
      className={classNames('ui form', {
        loading: isLoading,
      })}
    >
      <h4 className="ui dividing header">Informações básicas</h4>

      <div className="field">
        <div className="fields">
          <div className="four wide field required">
            <label>Tipo pessoa</label>

            <select
              className="ui dropdown"
              name="PERSON"
              onChange={handleChangePerson}
              ref={register({ required: true })}
              readOnly={isReadOnly}
            >
              <option value="PJ">Pessoa jurídica</option>
              <option value="PF">Pessoa física</option>
            </select>
          </div>
          <div className="twelve wide field required">
            <label>CNPJ/CPF</label>
            <MaskedInput
              mask={maskPersonDocument}
              type="tel"
              name="PERSON_DOCUMENT"
              placeholder={`Ex: ${maskPersonDocument}`}
              register={register({ required: true })}
              readOnly={isReadOnly}
            />
            {errors.PERSON_DOCUMENT && <span>CNPJ/CPF é obrigatório</span>}
          </div>
        </div>
      </div>

      {personSelected === 'PF' && renderPersonPFFields()}
      {personSelected === 'PJ' && renderPersonPJFields()}

      <h4 className="ui dividing header">Contato</h4>

      <div className="field">
        <div className="two fields">
          <div className="field">
            <label>Telefone (fixo)</label>
            <MaskedInput
              mask="(99) 9999-9999"
              type="tel"
              name="PHONE"
              placeholder="(00) 0000-0000"
              defaultValue={initialData.PHONE}
              register={register}
              readOnly={isReadOnly}
            />
          </div>
          <div className="field">
            <label>Telefone (celular/whastapp)</label>
            <MaskedInput
              mask="(99) 9 9999-9999"
              type="tel"
              name="CELL_PHONE"
              defaultValue={initialData.CELL_PHONE}
              placeholder="(00) 0 0000-0000"
              register={register}
              readOnly={isReadOnly}
            />
          </div>
        </div>

        <div className="two fields">
          <div className="field">
            <label>Email</label>
            <input
              type="email"
              name="EMAIL"
              placeholder="exemplo@exemplo.com"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>
          <div className="field">
            <label>Contato</label>
            <input
              type="text"
              name="CONTACT"
              placeholder=""
              ref={register}
              readOnly={isReadOnly}
            />
          </div>
        </div>
      </div>

      <h4 className="ui dividing header">Localização</h4>

      <div className="field">
        <div className="three fields">
          <div className="field">
            <label>CEP</label>
            <div className="ui action input">
              <MaskedInput
                mask="99999-999"
                type="tel"
                name="POSTAL_CODE"
                defaultValue={initialData.POSTAL_CODE}
                placeholder="00000-000"
                register={register}
                onBlur={handleFindCEP}
                readOnly={isReadOnly}
                onKeyPress={e => e.key === 'Enter' && handleFindCEP(e)}
              />
              {!isReadOnly && (
                <button
                  type="button"
                  className={classNames('ui button', {
                    loading: isFindingCEP,
                  })}
                  disabled={isFindingCEP}
                  onClick={handleFindCEP}
                >
                  Buscar
                </button>
              )}
            </div>
          </div>

          <div className="field">
            <label>Estado</label>
            <select
              className="ui dropdown"
              name="UF_ID"
              onChange={e => setStateSelected(Number(e.target.value))}
              ref={register}
              readOnly={isReadOnly}
            >
              <option value="*">Selecione</option>
              {stateList.map(state => (
                <option key={state.ID} value={state.ID}>
                  {state.NAME}
                </option>
              ))}
            </select>
          </div>

          <div className="field">
            <label>Cidade</label>
            <select
              className="ui dropdown"
              name="CITY_ID"
              ref={register}
              disabled={filteredCities.length === 0}
              readOnly={isReadOnly}
            >
              <option value="*">Selecione</option>
              {filteredCities.map(city => (
                <option key={city.ID} value={city.ID}>
                  {city.NAME}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="two fields">
          <div className="twelve wide field">
            <label>Endereço</label>
            <input
              type="text"
              name="ADDRESS"
              placeholder="Ex: Rua. Padre João"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>

          <div className="four wide field">
            <label>Número</label>
            <input
              type="number"
              name="NUMBER"
              placeholder="Ex: 123"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>

          <div className="field">
            <label>Complemento</label>
            <input
              type="text"
              name="COMPLEMENT"
              placeholder="Ex: Apartamento 10"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>

          <div className="field">
            <label>Bairro</label>
            <input
              type="text"
              name="DISTRICT"
              placeholder="Ex: Vila Mariana"
              ref={register}
              readOnly={isReadOnly}
            />
          </div>
        </div>
      </div>

      <h4 className="ui dividing header">Observação</h4>

      <div className="field">
        <textarea name="OBSERVATION" ref={register} readOnly={isReadOnly} />
      </div>
    </form>
  );
}
