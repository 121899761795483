import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import useForm from 'react-hook-form';

import classNames from 'classnames';
import { size } from 'lodash';
import MenuCloseIcon from 'mdi-react/WindowCloseIcon';
import { Sidebar, Menu, Button, Icon, Dropdown } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function withEmpty(arr) {
  const empty = { ID: null, NAME: 'Selecione' };

  return [empty, ...arr];
}

const DashboardDrawerFilter = ({
  open = false,
  initialData = {},
  loading,
  onToggle,
  onSubmit,
  onLoadFilters,
}) => {
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);

  const [isOpen, setIsOpen] = useState(() => open);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const { register, handleSubmit, getValues, watch, setValue } = useForm({
    defaultValues: initialData,
  });

  const watchDateTime = watch(['DATE_TIME_START', 'DATE_TIME_FINAL']);
  const watchDateTimeFinish = watch(['DATE_FINISH_START', 'DATE_FINISH_FINAL']);

  const watchClientId = watch('CLIENT_ID');

  const [groupList, setGroupList] = useState([]);
  const [groupSelected, setGroupSelected] = useState(
    initialData.ACCOUNT_GROUP_ID
      ? initialData.ACCOUNT_GROUP_ID.split(',').map(x => Number(x))
      : []
  );

  const [accountList, setAccountList] = useState([]);
  const [accountSelected, setAccountSelected] = useState(
    initialData.ACCOUNT_ID
      ? initialData.ACCOUNT_ID.split(',').map(x => Number(x))
      : []
  );

  const [stateList, setStateList] = useState([]);
  const [stateSelected, setStateSelected] = useState(
    Number(initialData.UF_ID) || ''
  );

  const [cityList, setCityList] = useState([]);
  const [citySelected, setCitySelected] = useState(
    Number(initialData.CITY_ID) || ''
  );

  const [stepList, setStepList] = useState([]);
  const [stepSelected, setStepSelected] = useState(
    initialData.STEP_ID
      ? initialData.STEP_ID.split(',').map(x => Number(x))
      : []
  );

  const [stageList, setStageList] = useState([]);
  const [stageSelected, setStageSelected] = useState(
    initialData.STATUS_ID
      ? initialData.STATUS_ID.split(',').map(x => Number(x))
      : []
  );

  const [clientSelected, setClientSelected] = useState(
    initialData.CLIENT_ID || ''
  );

  async function getState() {
    setIsLoadingFilters(true);

    const { data: uf } = await api({
      method: 'post',
      url: 'uf/list',
      params: { filter: JSON.stringify({}) },
    });

    if (uf.status === 0) {
      return uf.data;
    }

    setIsLoadingFilters(false);

    return [];
  }

  async function getCity() {
    if (stateSelected) {
      setIsLoadingFilters(true);

      const { data: city } = await api({
        method: 'post',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: stateSelected,
          }),
        },
      });

      if (city.status === 0) {
        return city.data;
      }
    }

    setIsLoadingFilters(false);

    return [];
  }

  useEffect(() => {
    (async () => {
      const states = await getState();

      setStateList(states);
    })();
  }, [accountSelected]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [stateSelected, accountSelected]);

  const getGroups = useCallback(async () => {
    setIsLoadingFilters(true);

    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupList(response.data);
    } else {
      setGroupList([]);
    }

    setIsLoadingFilters(false);
  }, []);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const getAccounts = useCallback(async () => {
    if (user.TYPE_KEY_ !== 'GROUP' && !groupSelected) return;

    setIsLoadingFilters(true);

    const { data: response } = await api({
      method: 'get',
      url: 'account/select',
      params: {
        filter: {
          account_group_id: groupSelected || user.ACCOUNT_GROUP_ID,
        },
      },
    });

    if (response.status === 0) {
      setAccountList(response.data);
    } else {
      setAccountList([]);
    }

    setIsLoadingFilters(false);
  }, [groupSelected]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const getSteps = useCallback(async () => {
    setIsLoadingFilters(true);

    const { data: response } = await api({
      method: 'get',
      url: 'step/select',
      params: {
        filters: JSON.stringify({}),
        orderby: { field: 'KEY_STEP', mode: 'ASC' },
      },
    });

    if (response.status === 0) {
      const stepsMap = response.data.map(s => {
        let description = '';

        switch (s.KEY_STEP) {
          case 'BUDGET':
            description = 'Em orçamento';
            break;
          case 'EXECUTE':
            description = 'Em execução';
            break;
          case 'FINISH':
            description = 'Finalizado';
            break;
          case 'CANCEL':
            description = 'Cancelado';
            break;
          default:
            description = 'Etapa desconhecida';
        }

        return {
          ...s,
          DESCRIPTION: description,
        };
      });

      setStepList(stepsMap);
    } else {
      setStepList([]);
    }

    setIsLoadingFilters(false);
  }, []);

  useEffect(() => {
    getSteps();
  }, [getSteps]);

  const getStages = useCallback(async () => {
    setIsLoadingFilters(true);

    const { data: response } = await api({
      method: 'get',
      url: 'stage/select',
      params: {
        filters: JSON.stringify({}),
        orderby: { field: 'POSITION', mode: 'ASC' },
      },
    });

    if (response.status === 0) {
      setStageList(response.data);
    } else {
      setStageList([]);
    }

    setIsLoadingFilters(false);
  }, []);

  useEffect(() => {
    getStages();
  }, [getStages]);

  const getClientData = useCallback(async () => {
    if (!clientSelected) return;

    setIsLoadingFilters(true);

    const { data: client } = await api({
      method: 'post',
      url: 'client/self',
      params: {
        client_id: clientSelected,
      },
    });

    if (client.status === 0) {
      setValue('CLIENT_ID', client.data.ID);
      setClientSelected(client.data.ID);

      if (client.data.TYPE_FORMAT === 'PF') {
        setValue('CLIENT_NAME', client.data.NAME);
      } else {
        setValue('CLIENT_NAME', client.data.COMPANY_NAME);
      }
    }
    setIsLoadingFilters(false);
  }, []);

  useEffect(() => {
    if (clientSelected) {
      getClientData();
    }
  }, []);

  function handleClientSelect() {
    openModal('clientSelect', {
      onSelect: client => {
        setValue('CLIENT_ID', client.ID);
        setClientSelected(client.ID);

        if (client.TYPE_FORMAT === 'PF') {
          setValue('CLIENT_NAME', client.NAME);
        } else {
          setValue('CLIENT_NAME', client.COMPANY_NAME);
        }
      },
    });
  }

  function handleClearClientSelect() {
    setValue('CLIENT_ID', '');
    setValue('CLIENT_NAME', '');
    setClientSelected('');
  }

  function handleViewClientSelect() {
    const { CLIENT_ID: clientId } = getValues();

    openModal('clientAdd', {
      mode: 'view',
      clientId,
    });
  }

  const filterData = useMemo(() => {
    const filters = {};

    if (watchDateTime.DATE_TIME_START) {
      Object.assign(filters, {
        DATE_TIME_START: watchDateTime.DATE_TIME_START,
      });
    }

    if (watchDateTime.DATE_TIME_FINAL) {
      Object.assign(filters, {
        DATE_TIME_FINAL: watchDateTime.DATE_TIME_FINAL,
      });
    }

    if (watchDateTimeFinish.DATE_FINISH_START) {
      Object.assign(filters, {
        DATE_FINISH_START: initialData.DATE_FINISH_START
          ? initialData.DATE_FINISH_START
          : watchDateTimeFinish.DATE_FINISH_START,
      });
    }

    if (watchDateTimeFinish.DATE_FINISH_FINAL) {
      Object.assign(filters, {
        DATE_FINISH_FINAL: initialData.DATE_FINISH_FINAL
          ? initialData.DATE_FINISH_FINAL
          : watchDateTimeFinish.DATE_FINISH_FINAL,
      });
    }

    if (groupSelected.length > 0 && groupList.length > 0) {
      const groups = groupList.filter(g => groupSelected.includes(g.ID));

      if (groups) {
        Object.assign(filters, { GROUP_NAMES: groups.map(g => g.NAME) });
      }
    }

    if (accountSelected.length > 0 && accountList.length > 0) {
      const accounts = accountList.filter(a => accountSelected.includes(a.ID));

      if (accounts) {
        Object.assign(filters, { ACCOUNT_NAMES: accounts.map(ac => ac.NAME) });
      }
    }

    if (stateSelected && stateList.length > 0) {
      const state = stateList.find(s => s.ID === Number(stateSelected));

      if (state) {
        Object.assign(filters, { STATE_NAME: state.NAME });
      }
    }

    if (citySelected && cityList.length > 0) {
      const city = cityList.find(c => c.ID === Number(citySelected));

      if (city) {
        Object.assign(filters, { CITY_NAME: city.NAME });
      }
    }

    if (stageSelected.length > 0 && stageList.length > 0) {
      const stages = stageList.filter(s => stageSelected.includes(s.ID));

      if (stages) {
        Object.assign(filters, { STAGE_NAMES: stages.map(s => s.DESCRIPTION) });
      }
    }

    if (stepSelected.length > 0 && stepList.length > 0) {
      const steps = stepList.filter(a => stepSelected.includes(a.ID));

      if (steps) {
        Object.assign(filters, { STEP_NAMES: steps.map(s => s.DESCRIPTION) });
      }
    }

    if (clientSelected) {
      const { CLIENT_NAME } = getValues();
      Object.assign(filters, { CLIENT_NAME });
    }

    return filters;
  }, [
    initialData,
    watchDateTime.DATE_TIME_START,
    watchDateTime.DATE_TIME_FINAL,
    watchDateTimeFinish.DATE_FINISH_START,
    watchDateTimeFinish.DATE_FINISH_FINAL,
    groupSelected,
    groupList,
    accountSelected,
    accountList,
    stateSelected,
    stateList,
    citySelected,
    cityList,
    stepSelected,
    stepList,
    stageSelected,
    stageList,
    clientSelected,
    watchClientId,
  ]);

  const totalFiltered = useMemo(() => {
    const vals = getValues();

    let total = 0;

    if (vals.DATE_TIME_START) {
      total += 1;
    }

    if (vals.DATE_TIME_FINAL) {
      total += 1;
    }

    if (vals.DATE_FINISH_START) {
      total += 1;
    }

    if (vals.DATE_FINISH_FINAL) {
      total += 1;
    }

    if (groupSelected.length) {
      total += 1;
    }

    if (accountSelected.length) {
      total += 1;
    }

    if (stateSelected) {
      total += 1;
    }

    if (citySelected) {
      total += 1;
    }

    if (stepSelected.length) {
      total += 1;
    }

    if (stageSelected.length) {
      total += 1;
    }

    if (clientSelected) {
      total += 1;
    }

    return total;
  }, [getValues()]);

  useEffect(() => {
    if (!isLoadingFilters && size(filterData) === size(initialData)) {
      onLoadFilters(filterData);
    }
  }, [initialData, filterData, isLoadingFilters]);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  const prepareSubmit = data => {
    const { CLIENT_NAME, ...dataToSubmit } = data;

    onLoadFilters(filterData);
    onSubmit({ ...dataToSubmit });
  };

  return (
    <>
      <Button
        size="medium"
        fluid
        icon
        color="blue"
        title="Filtros"
        className="btn-filter"
        loading={loading}
        disabled={loading}
        onClick={() => {
          onToggle();
          setIsOpen(true);
        }}
      >
        <Icon name="filter" /> Filtros ({totalFiltered})
      </Button>

      <Sidebar
        animation="overlay"
        icon="labeled"
        as={Menu}
        vertical
        visible={isOpen}
        direction="right"
        width="very wide"
      >
        <form
          className={classNames('ui form', {
            loading,
          })}
          onSubmit={handleSubmit(prepareSubmit)}
          style={{ height: '100%' }}
        >
          <div className="drawer-title">
            <h4>FILTROS</h4>

            <button
              type="button"
              className="close-drawer-btn"
              onClick={() => {
                setIsOpen(false);
                onToggle();
              }}
            >
              <MenuCloseIcon size={20} color="#000" />
            </button>
          </div>

          {user.TYPE_KEY_ === 'ROOT' && (
            <div className="fluid field">
              <label>Grupo</label>

              <Dropdown
                fluid
                search
                selection
                clearable
                multiple
                options={groupList.map(group => ({
                  key: group.ID,
                  value: group.ID,
                  text: group.NAME,
                }))}
                value={groupSelected}
                disabled={groupList.length === 0}
                placeholder="Selecione um grupo."
                noResultsMessage="Nenhum grupo encontrado."
                onChange={(e, data) => {
                  setValue('ACCOUNT_GROUP_ID', data.value);
                  setGroupSelected(data.value);
                }}
              />

              <input type="hidden" name="ACCOUNT_GROUP_ID" ref={register} />
            </div>
          )}

          {user.TYPE_KEY_ !== 'ACCOUNT' && (
            <div className="fluid field">
              <label>Conta</label>

              <Dropdown
                fluid
                search
                selection
                clearable
                multiple
                options={accountList.map(account => ({
                  key: account.ID,
                  value: account.ID,
                  text: account.NAME,
                }))}
                value={accountSelected}
                disabled={accountList.length === 0}
                placeholder="Selecione uma conta."
                noResultsMessage="Nenhuma conta encontrada."
                onChange={(e, data) => {
                  setValue('ACCOUNT_ID', data.value);
                  setAccountSelected(data.value);
                }}
              />

              <input type="hidden" name="ACCOUNT_ID" ref={register} />
            </div>
          )}

          <div className="fluid field">
            <label>Estado</label>

            <Dropdown
              fluid
              search
              selection
              clearable
              options={withEmpty(stateList).map(state => ({
                key: state.ID,
                value: state.ID,
                text: state.NAME,
              }))}
              value={stateSelected}
              disabled={stateList.length === 0}
              placeholder="Selecione um estado."
              noResultsMessage="Nenhum estado encontrada."
              onChange={(e, data) => {
                setValue('UF_ID', data.value);
                setStateSelected(data.value);
              }}
            />

            <input type="hidden" name="UF_ID" ref={register} />
          </div>

          <div className="fluid field">
            <label>Cidade</label>

            <Dropdown
              fluid
              search
              selection
              clearable
              options={withEmpty(cityList).map(city => ({
                key: city.ID,
                value: city.ID,
                text: city.NAME,
              }))}
              value={citySelected}
              disabled={cityList.length === 0}
              placeholder="Selecione uma cidade."
              noResultsMessage="Nenhuma cidade encontrada."
              onChange={(e, data) => {
                setValue('CITY_ID', data.value);
                setCitySelected(data.value);
              }}
            />

            <input type="hidden" name="CITY_ID" ref={register} />
          </div>

          <div className="fluid field">
            <label>Etapa</label>

            <Dropdown
              fluid
              search
              selection
              clearable
              multiple
              options={stepList.map(step => ({
                key: step.ID,
                value: step.ID,
                text: step.DESCRIPTION,
              }))}
              value={stepSelected}
              disabled={stepList.length === 0}
              placeholder="Selecione uma etapa."
              noResultsMessage="Nenhuma etapa encontrada."
              onChange={(e, data) => {
                setValue('STEP_ID', data.value);
                setStepSelected(data.value);
              }}
            />

            <input type="hidden" name="STEP_ID" ref={register} />
          </div>

          <div className="fluid field">
            <label>Status</label>

            <Dropdown
              fluid
              search
              selection
              clearable
              multiple
              options={stageList.map(stage => ({
                key: stage.ID,
                value: stage.ID,
                text: stage.DESCRIPTION,
              }))}
              value={stageSelected}
              disabled={stageList.length === 0}
              placeholder="Selecione um status."
              noResultsMessage="Nenhum status encontrad."
              onChange={(e, data) => {
                setValue('STATUS_ID', data.value);
                setStageSelected(data.value);
              }}
            />

            <input type="hidden" name="STATUS_ID" ref={register} />
          </div>

          <div className="two fields">
            <div className="fluid field">
              <label>Data de criação (Início)</label>
              <input type="date" name="DATE_TIME_START" ref={register} />
            </div>

            <div className="fluid field">
              <label>Data de criação (Término)</label>
              <input type="date" name="DATE_TIME_FINAL" ref={register} />
            </div>
          </div>

          <div className="two fields">
            <div className="fluid field">
              <label>Data de finalização (Início)</label>
              <input type="date" name="DATE_FINISH_START" ref={register} />
            </div>

            <div className="fluid field">
              <label>Data de finalização (Término)</label>
              <input type="date" name="DATE_FINISH_FINAL" ref={register} />
            </div>
          </div>

          <div className="fluid field">
            <label>Cliente</label>

            <div className="ui action input">
              <input
                type="text"
                name="CLIENT_NAME"
                readOnly
                placeholder="Selecione um cliente"
                ref={register}
              />

              <input
                type="hidden"
                name="CLIENT_ID"
                ref={register({ required: true })}
              />

              {clientSelected ? (
                <>
                  <button
                    type="button"
                    className="ui button icon blue"
                    onClick={handleViewClientSelect}
                  >
                    <i className="mdi mdi-eye" />
                  </button>

                  <button
                    type="button"
                    className="ui button icon red"
                    onClick={handleClearClientSelect}
                  >
                    <i className="mdi mdi-delete" />
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="ui green button icon"
                  title="Selecionar cliente"
                  onClick={handleClientSelect}
                >
                  <i className="mdi mdi-account-search" />
                </button>
              )}
            </div>
          </div>

          <Button
            fluid
            size="medium"
            color="blue"
            type="submit"
            onClick={() => {
              if (!clientSelected) {
                prepareSubmit(getValues());
              }
              onToggle();
              setIsOpen(false);
            }}
          >
            Aplicar
          </Button>
        </form>
      </Sidebar>
    </>
  );
};

export default DashboardDrawerFilter;
