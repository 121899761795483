import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import InputNumberFormat from 'react-number-format';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { Pagination, Icon, Grid, Message, Button } from 'semantic-ui-react';
import swal from 'sweetalert';

import ItemCard from '~/components/Card/Item';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

import useProjectSelf from '../../hooks/useProjectSelf';

export default function ProjectItemSearch({ projectId }) {
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [project, loadingProject, updateProject] = useProjectSelf(projectId);

  const [showTable, setShowTable] = useState(!isMobile);

  const [items, setItems] = useState([]);

  const [projectTransformer, setProjectTransformer] = useState({});
  // const [projectKit, setProjectKit] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [canChangePercent, setCanChangePercent] = useState(false);
  const [percent, setPercent] = useState(0);
  const [loadingPercent, setLoadingPercent] = useState(false);

  const [inputPercent, setInputPercent] = useState(null);

  const [viewRoyalties, setViewRoyalties] = useState(false);

  useEffect(() => {
    if (!loadingProject) {
      setPercent(project.ITEM_PROFIT_PERCENT);
    }
  }, [project, loadingProject]);

  useEffect(() => {
    if (canChangePercent && inputPercent) {
      inputPercent.focus();
    }
  }, [canChangePercent]);

  const [summary, setSummary] = useState({});

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({
    PROJECT_ID: projectId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total_page: 1,
    orderby: [
      { field: 'ITEM_TYPE', mode: 'ASC' },
      { field: 'ITEM_DESCRIPTION', mode: 'ASC' },
    ],
  });

  const searchItems = useCallback(
    useRef(
      throttle(async (filters, pag) => {
        setIsLoading(true);

        const { data: response } = await api({
          method: 'post',
          url: 'project/item/search',
          params: {
            project_id: projectId,
            filter: filters,
            pagination: pag,
          },
        });

        if (response.status === 0) {
          setItems(response.data);
          setSummary(response.summary);
          setPagination(response.pagination);
        } else {
          toast.error(response.message.text);
          setItems([]);
        }

        setIsLoading(false);
      }, 1000)
    ).current,
    []
  );

  const getTransformer = useCallback(async () => {
    setIsLoading(true);

    const { data: response } = await api({
      method: 'post',
      url: 'project/transformer/search',
      params: {
        filter: {
          PROJECT_ID: projectId,
        },
        pagination: {
          page: 1,
          limit: 1,
          orderby: [{ field: 'TRANSFORMER_DESCRIPTION', mode: 'ASC' }],
        },
      },
    });

    const [transformer] = response.data;

    if (response.status === 0) {
      setProjectTransformer(transformer);
    } else {
      toast.error(response.message.text);
      setProjectTransformer({});
    }

    setIsLoading(false);
  }, []);

  // const getPhotovoltaicKit = useCallback(async () => {
  //   setIsLoading(true);

  //   const { data: response } = await api({
  //     method: 'post',
  //     url: 'project/kit/search',
  //     params: {
  //       filter: {
  //         PROJECT_ID: projectId,
  //       },
  //       pagination: {
  //         page: 1,
  //         limit: 10,
  //         orderby: [{ field: 'SAP', mode: 'ASC' }],
  //       },
  //     },
  //   });

  //   const [kit] = response.data;

  //   if (response.status === 0) {
  //     setProjectKit(kit);
  //   } else {
  //     toast.error(response.message.text
  //     );
  //     setProjectKit({});
  //   }

  //   setIsLoading(false);
  // }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchItems(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchItems(filter, pagination);
  }, [searchItems]);

  useEffect(() => {
    getTransformer();
  }, [getTransformer]);

  // useEffect(() => {
  //   getPhotovoltaicKit();
  // }, [getPhotovoltaicKit]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    const newPagination = {
      ...pagination,
      page: activePage,
    };

    searchItems(filter, newPagination);
  }

  async function handleSavePercent() {
    setLoadingPercent(true);

    const { data: responseSave } = await api({
      method: 'post',
      url: 'project/item/profit',
      params: {
        project_id: projectId,
        percent,
      },
    });

    if (responseSave.status === 0) {
      toast.success('Lucro configurado com sucesso!');
      setCanChangePercent(false);
      updateProject();
    } else {
      toast.error(responseSave.message.text);
    }

    setLoadingPercent(false);
  }

  function handleItemDelete(itemId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um item do projeto permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'project/item/delete',
          params: {
            project_item_id: JSON.stringify([itemId]),
          },
        });

        if (responseDelete.status === 0) {
          updateProject();
          searchItems(filter, { ...pagination, keyword });
          toast.success('Item excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleTransformerDelete() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um transformador do projeto. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'project/transformer/delete',
          params: {
            project_transformer_id: JSON.stringify([projectTransformer.ID]),
          },
        });

        if (responseDelete.status === 0) {
          updateProject();
          searchItems(filter, { ...pagination, keyword });
          getTransformer();
          toast.success('Transformador excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  // function handleKitDelete() {
  //   swal({
  //     dangerMode: true,
  //     title: 'Atencão',
  //     text:
  //       'Você está prestes a excluir um kit do projeto. Deseja mesmo realizar essa acão?',
  //     icon: 'warning',
  //     buttons: ['Cancelar', 'Excluir'],
  //   }).then(async result => {
  //     if (result) {
  //       const { data: responseDelete } = await api({
  //         method: 'post',
  //         url: 'project/kit/delete',
  //         params: {
  //           project_kit_id: JSON.stringify([projectKit.ID]),
  //         },
  //       });

  //       if (responseDelete.status === 0) {
  //         updateProject();
  //         searchItems(filter, { ...pagination, keyword });
  //         // getPhotovoltaicKit();
  //         toast.success('Kit excluído com sucesso!');
  //       } else {
  //         toast.error(responseDelete.message.text
  //         );
  //       }
  //     }
  //   });
  // }

  // function handleDeleteSelected() {
  //   swal({
  //     dangerMode: true,
  //     title: 'Atencão',
  //     text:
  //       'Você está prestes a excluir vários itens do projeto permanentemente. Deseja mesmo realizar essa acão?',
  //     icon: 'warning',
  //     buttons: ['Cancelar', 'Excluir'],
  //   }).then(async result => {
  //     if (result) {
  //       const itemsToDelete = items
  //         .filter(item => item.uiChecked === true)
  //         .map(item => item.ID);

  //       if (!itemsToDelete.length > 0) {
  //         toast.warning(
  //           `É preciso selecionar pelo menos um item para realizar essa ação!`
  //         );
  //         return;
  //       }

  //       const { data: responseDelete } = await api({
  //         method: 'post',
  //         url: 'project/item/delete',
  //         params: {
  //           project_item_id: JSON.stringify(itemsToDelete),
  //         },
  //       });

  //       if (responseDelete.status === 0) {
  //         updateProject();
  //         searchItems(filter, { ...pagination, keyword });
  //         toast.success('Itens excluídos com sucesso!');
  //       } else {
  //         toast.error(responseDelete.message.text
  //         );
  //       }
  //     }
  //   });
  // }

  // const allSelected = useMemo(
  //   () => items.length && items.every(b => b.uiChecked === true),
  //   [items]
  // );

  // function handleSelectAll(e, { checked }) {
  //   setItems(oldItems =>
  //     oldItems.map(item => ({
  //       ...item,
  //       uiChecked: checked,
  //     }))
  //   );
  // }

  // function handleSelect(itemId) {
  //   setItems(oldItems =>
  //     oldItems.map(item => {
  //       return item.ID === itemId
  //         ? { ...item, uiChecked: !item.uiChecked }
  //         : item;
  //     })
  //   );
  // }

  async function handleUpdateItem(providerId, data) {
    const { data: responseUpdate } = await api({
      method: 'post',
      url: 'project/item/update',
      params: {
        provider_id: providerId,
        project_item: JSON.stringify(data),
      },
    });

    if (responseUpdate.status === 0) {
      toast.success(responseUpdate.message.text);
      searchItems(filter, { ...pagination, keyword });
      updateProject();
    } else {
      toast.error(responseUpdate.message.text);
    }
  }

  async function handleChangeTransformer(providerId, data) {
    const service = projectTransformer
      ? 'project/transformer/update'
      : 'project/transformer/add';

    const { data: responseService } = await api({
      method: 'post',
      url: service,
      params: {
        provider_id: providerId,
        project_id: projectId,
        project_transformer: JSON.stringify(data),
      },
    });

    if (responseService.status === 0) {
      toast.success(responseService.message.text);
      searchItems(filter, { ...pagination, keyword });
      updateProject();
      getTransformer();
    } else {
      toast.error(responseService.message.text);
    }
  }

  // async function handleChangeKit(providerId, data) {
  //   const service = projectKit ? 'project/kit/update' : 'project/kit/add';

  //   const { data: responseService } = await api({
  //     method: 'post',
  //     url: service,
  //     params: {
  //       provider_id: providerId,
  //       project_id: projectId,
  //       project_kit: JSON.stringify(data),
  //     },
  //   });

  //   if (responseService.status === 0) {
  //     toast.success(responseService.message.text);
  //     searchItems(filter, { ...pagination, keyword });
  //     updateProject();
  //     getTransformer();
  //   } else {
  //     toast.error(responseService.message.text);
  //   }
  // }

  async function validateAndPush(path) {
    const { data: response } = await api({
      method: 'post',
      url: 'project/item/validateLabor',
      params: {
        project_id: projectId,
      },
    });

    if (response.status !== 0) {
      toast.error(response.message.text);
      return;
    }

    history.push(path);
  }

  const searchConfigValues = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'config/value/search',
      params: {
        pagination: JSON.stringify({
          page: 1,
          limit: 100,
          orderby: [{ field: 'ID', mode: 'ASC' }],
        }),
        filter: JSON.stringify({
          ACCOUNT_GROUP_ID: user.ACCOUNT_GROUP_ID,
        }),
      },
    });

    if (responseSearch.status === 0) {
      const configValues = responseSearch.data;
      const hasViewRoyaltiesConfig = !!configValues.find(
        c => c.CONFIG_KEY_ === 'view.royalties'
      );
      setViewRoyalties(hasViewRoyaltiesConfig);
    } else {
      setViewRoyalties(false);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    searchConfigValues();
  }, [searchConfigValues]);

  function renderItemsWithCard() {
    return (
      <Grid centered>
        {items.length > 0 &&
          !isLoading &&
          items.map(item => (
            <Grid.Column key={item.ID} mobile={16} tablet={8} computer={4}>
              <ItemCard
                type="project"
                handleDelete={() => handleItemDelete(item.ID)}
                handleEdit={() => {
                  openModal('projectItemSelect', {
                    data: item,
                    onConfirm: data =>
                      handleUpdateItem(item.PROVIDER_ID, [
                        {
                          ID: item.ID,
                          ...data,
                        },
                      ]),
                  });
                }}
                item={item}
              />
            </Grid.Column>
          ))}

        {!isLoading && items.length === 0 && (
          <Grid.Column>
            <Message content="Nenhum item adicionado!" />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando itens..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function getItemDescription(item) {
    if (item.ITEM_KEY_ === 'transformer' && !!projectTransformer) {
      return projectTransformer.TRANSFORMER_DESCRIPTION;
    }

    // if (item.ITEM_KEY_ === 'kit_fotovoltaico' && !!projectKit) {
    //   return `Kit Fotovoltaico - SAP ${projectKit.PHOTOVOLTAIC_KIT_SAP}`;
    // }

    return item.DESCRIPTION || item.ITEM_DESCRIPTION || '-';
  }

  function renderItemsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            {/* <th className="center aligned">
              <Checkbox
                title="Selecionar"
                onChange={handleSelectAll}
                checked={allSelected}
              />
            </th> */}
            <th className="center aligned">Descrição</th>
            {/* <th className="center aligned">Fornecedor</th> */}
            <th className="center aligned">Quantidade</th>
            <th className="center aligned">Valor unitário</th>
            <th className="center aligned">Total</th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 &&
            !isLoading &&
            items.map(item => {
              return (
                <tr
                  key={item.ID}
                  className={classNames({
                    active: item.uiChecked || item.ITEM_TYPE === 'KPI',
                  })}
                >
                  {/* <td className="center aligned">
                    {item.ITEM_TYPE !== 'KPI' && (
                      <Checkbox
                        checked={item.uiChecked}
                        onChange={() => handleSelect(item.ID)}
                        disabled={item.ITEM_TYPE === 'KPI'}
                      />
                    )}
                  </td> */}
                  <td className="center aligned">{getItemDescription(item)}</td>

                  {item.ITEM_TYPE !== 'KPI' ||
                  (item.ITEM_TYPE === 'KPI' &&
                    ['labor', 'transformer'].includes(item.ITEM_KEY_)) ? (
                    <>
                      {/* <td className="center aligned">
                        {item.ITEM_KEY_ !== 'labor'
                          ? item.PROVIDER_DESCRIPTION
                          : '-'}
                      </td> */}
                      <td className="center aligned">
                        {numberFormat(item.QUANTITY, 2, ',', '.')}
                      </td>
                      <td className="center aligned">
                        R$ {numberFormat(item.UNIT_VALUE, 2, ',', '.')}
                      </td>
                    </>
                  ) : (
                    <td className="center aligned" colSpan="2">
                      -
                    </td>
                  )}

                  <td className="center aligned">
                    R$ {numberFormat(item.TOTAL, 2, ',', '.')}
                  </td>

                  <td className="center aligned">
                    {(item.ITEM_TYPE !== 'KPI' ||
                      ['labor', 'homologation'].includes(item.ITEM_KEY_)) && (
                      <>
                        <Button
                          icon
                          size="mini"
                          color="blue"
                          title="Editar item"
                          onClick={() => {
                            openModal('projectItemSelect', {
                              data: {
                                ...item,
                                PROJECT_KWP: project.SYSTEM_POWER_TOTAL,
                              },
                              onConfirm: data =>
                                handleUpdateItem(item.PROVIDER_ID, [
                                  { ID: item.ID, ...data },
                                ]),
                            });
                          }}
                        >
                          <Icon name="pencil" />
                        </Button>
                      </>
                    )}

                    {item.ITEM_KEY_ === 'transformer' && (
                      <>
                        <Button
                          icon
                          size="mini"
                          color="blue"
                          title="Editar transformador"
                          onClick={() => {
                            openModal('projectTransformerSelect', {
                              data: {
                                TRANSFORMER_ID: projectTransformer
                                  ? projectTransformer.TRANSFORMER_ID
                                  : null,
                                ...item,
                              },
                              onConfirm: data =>
                                handleChangeTransformer(item.PROVIDER_ID, [
                                  {
                                    ID: projectTransformer
                                      ? projectTransformer.ID
                                      : null,
                                    ...data,
                                  },
                                ]),
                            });
                          }}
                        >
                          <Icon name="pencil" />
                        </Button>
                      </>
                    )}

                    {/* {item.ITEM_KEY_ === 'kit_fotovoltaico' && (
                      <>
                        <Button
                          icon
                          size="mini"
                          color="blue"
                          title="Editar kit fotovoltaico"
                          onClick={() => {
                            openModal('projectKitSelect', {
                              data: {
                                KIT_ID: projectKit ? projectKit.KIT_ID : null,
                                ...item,
                              },
                              onConfirm: data =>
                                handleChangeKit(item.PROVIDER_ID, [
                                  {
                                    ID: projectKit ? projectKit.ID : null,
                                    ...data,
                                  },
                                ]),
                            });
                          }}
                        >
                          <Icon name="pencil" />
                        </Button>
                      </>
                    )} */}

                    {item.ITEM_TYPE !== 'KPI' && item.ITEM_KEY_ !== 'labor' && (
                      <Button
                        icon
                        size="mini"
                        color="red"
                        title="Remover item"
                        onClick={() => handleItemDelete(item.ID)}
                      >
                        <Icon name="trash" />
                      </Button>
                    )}

                    {item.ITEM_KEY_ === 'transformer' && projectTransformer && (
                      <Button
                        icon
                        size="mini"
                        color="red"
                        title="Remover transformador"
                        onClick={handleTransformerDelete}
                      >
                        <Icon name="trash" />
                      </Button>
                    )}

                    {/* {item.ITEM_KEY_ === 'kit_fotovoltaico' && projectKit && (
                      <Button
                        icon
                        size="mini"
                        color="red"
                        title="Remover transformador"
                        onClick={handleKitDelete}
                      >
                        <Icon name="trash" />
                      </Button>
                    )} */}
                  </td>
                </tr>
              );
            })}

          {!isLoading && items.length === 0 && (
            <tr>
              <td colSpan="10">
                <Message content="Nenhum item encontrado!" />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="10">
                <Message color="blue" content="Buscando itens..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <h4 className="ui horizontal divider">Orçamento</h4>

      <Grid centered>
        <Grid.Column mobile={16} table={16} computer={16}>
          <div className="ui form centered">
            <div className="fields m-b-n text-center">
              <div className="field m">
                <label>Quantidade</label>
                <i className="m-t-n-xs">{numberFormat(summary.QTTY || 0, 0)}</i>
              </div>

              <div className="field m">
                <label>Custo total</label>
                <i className="m-t-n-xs">
                  R$ {numberFormat(project.COST_ITEM || 0, 2, ',', '.')}
                </i>
              </div>

              <div className="field m">
                <label>Lucro</label>

                <div className="ui action mini input">
                  <InputNumberFormat
                    type="tel"
                    disabled={!canChangePercent}
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    value={percent}
                    suffix=" %"
                    decimalScale={2}
                    onValueChange={v => setPercent(v.floatValue)}
                    onBlur={handleSavePercent}
                    onKeyPress={e => e.key === 'Enter' && handleSavePercent()}
                    getInputRef={el => setInputPercent(el)}
                  />

                  {!canChangePercent && (
                    <button
                      type="button"
                      className="ui icon button yellow"
                      onClick={() => {
                        setCanChangePercent(true);
                      }}
                    >
                      <i className="mdi mdi-pencil icon" />
                    </button>
                  )}

                  {canChangePercent && (
                    <button
                      type="button"
                      disabled={loadingPercent}
                      className={classNames('ui icon button blue', {
                        loading: loadingPercent,
                      })}
                      onClick={handleSavePercent}
                    >
                      <i className="mdi mdi-content-save icon" />
                    </button>
                  )}
                </div>
              </div>

              <div className="field m">
                <label>Lucro</label>
                <i className="m-t-n-xs">
                  R$ {numberFormat(project.ITEM_PROFIT || 0, 2, ',', '.')}
                </i>
              </div>

              <div className="field m">
                <label>Valor total</label>
                <i className="m-t-n-xs">
                  R$ {numberFormat(project.TOTAL_ITEM || 0, 2, ',', '.')}
                </i>
              </div>

              {viewRoyalties && (
                <div className="field m">
                  <label>Royalties</label>
                  <i className="m-t-n-xs">
                    R$ {numberFormat(project.ROYALTIES_VALUE || 0, 2, ',', '.')}{' '}
                    ({numberFormat(project.ROYALTIES_PERCENT || 0, 2, ',', '.')}
                    %)
                  </i>
                </div>
              )}

              <div className="field m">
                <label>Valor final</label>
                <i className="m-t-n-xs">
                  R$ {numberFormat(project.TOTAL_ROYALTIES || 0, 2, ',', '.')}
                </i>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <Grid centered>
        <Grid.Column mobile={16} table={8} computer={8}>
          <div className="ui action fluid input">
            <input
              type="text"
              placeholder="Pesquisar por"
              value={keyword}
              onChange={handleKeywordChange}
            />
            <button
              className={classNames('ui button label', {
                loading: isLoading,
              })}
              type="button"
              disabled={isLoading}
              onClick={() => searchItems(filter, { ...pagination, keyword })}
            >
              Pesquisar
            </button>
          </div>
        </Grid.Column>

        <Grid.Column
          mobile={16}
          table={8}
          computer={8}
          className="right aligned"
        >
          {!isMobile && (
            <button
              type="button"
              className="ui button icon"
              title="Alterar visualização"
              onClick={() => setShowTable(!showTable)}
            >
              <i
                className={classNames('mdi icon', {
                  'mdi-table': showTable,
                  'mdi-cards': !showTable,
                })}
              />
            </button>
          )}

          {/* <Dropdown
            icon="cogs"
            floating
            title="Ações rápidas"
            button
            className="blue icon"
            disabled={!items.some(b => b.uiChecked === true)}
          >
            <Dropdown.Menu>
              <Dropdown.Header content="Ações rápidas" />
              <Dropdown.Divider />

              <Dropdown.Item
                icon="check"
                text="Selecionar fornecedor"
                onClick={() => {
                  openModal('provider', {
                    onSelect: provider => {
                      const selected = items.filter(m => m.uiChecked === true);

                      handleUpdateItem(provider.ID, selected);
                    },
                  });
                }}
              />

              <Dropdown.Item
                color="red"
                icon="trash"
                text="Excluir selecionados"
                onClick={handleDeleteSelected}
              />
            </Dropdown.Menu>
          </Dropdown> */}

          <Link to="select" className="ui button green icon">
            <i className="mdi mdi-plus icon" /> Adicionar itens ao projeto
          </Link>
        </Grid.Column>
      </Grid>

      {showTable ? renderItemsWithTable() : renderItemsWithCard()}

      <Grid centered>
        <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
          <Pagination
            disabled={items.loading}
            activePage={pagination.page}
            onPageChange={handlePaginationChange}
            totalPages={pagination.total_page}
            ellipsisItem={false}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{
              content: <Icon name="angle right" />,
              icon: true,
            }}
          />
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <button
            type="button"
            className={classNames('ui button blue', {
              loading: isLoading,
              'left floated': !isMobile,
              fluid: isMobile,
            })}
            disabled={isLoading}
            onClick={() => history.push('../material')}
          >
            Voltar
          </button>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={8} computer={8}>
          <button
            type="button"
            className={classNames('ui button green', {
              loading: isLoading,
              'right floated': !isMobile,
              fluid: isMobile,
            })}
            disabled={isLoading}
            onClick={() => validateAndPush('../results')}
          >
            Avançar
          </button>
        </Grid.Column>
      </Grid>
    </>
  );
}
