import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const query = useQuery();

  const [modules, setModules] = useState([]);
  const [userHasBrands, setUserHasBrands] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState(query.get('keyword'));

  const [filter] = useState({
    LIMIT: '10',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchBrands = useCallback(async () => {
    const { data: responseBrands } = await api({
      method: 'post',
      url: 'brand/select',
    });

    if (responseBrands.status === 0) {
      const hasBrands = responseBrands.data.some(
        brand => user.ID === brand.ACCOUNT_RESPONSIBLE_ID
      );

      setUserHasBrands(hasBrands);
    } else {
      setUserHasBrands(false);
    }
  }, []);

  const searchModules = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: 'module/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      if (user.TYPE_KEY_ === 'GROUP' && userHasBrands) {
        const userModules = responseSearch.data.filter(
          inverter => user.ID === inverter.ACCOUNT_RESPONSIBLE_ID
        );
        const restModules = responseSearch.data.filter(
          inverter => user.ID !== inverter.ACCOUNT_RESPONSIBLE_ID
        );

        setModules([...userModules, ...restModules]);
      } else {
        setModules(responseSearch.data);
      }
    } else {
      setModules([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchModules(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchBrands();
  }, [searchBrands]);

  useEffect(() => {
    searchModules(filter, {
      ...pagination,
      keyword,
    });
  }, [searchModules]);

  useEffect(() => {
    if (limitChanged) {
      searchModules(filter, {
        ...pagination,
      });
    }

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchModules(filter, { ...pagination, keyword });
  }

  function handlePaginationChange(e, { activePage }) {
    searchModules(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  async function handleAvailabilityChange(module, availability) {
    setIsLoading(true);

    if (!availability) {
      setIsLoading(false);

      return;
    }

    const { ID } = module;

    const { data: responseUpdate } = await api({
      method: 'post',
      url: '/module/update',
      params: {
        module_id: ID,
        module: JSON.stringify({
          ...module,
          AVAILABILITY: availability,
        }),
      },
    });

    if (responseUpdate.status === 0) {
      toast.success(responseUpdate.message.text);

      searchModules(filter, { ...pagination, keyword });
    } else {
      toast.error(
        `Erro ao tentar salvar o módulo: ${responseUpdate.message.text}`
      );
    }

    setIsLoading(false);
  }

  function handleModuleDelete(moduleId, inProject) {
    if (inProject === 1) {
      swal({
        dangerMode: true,
        title: 'Atencão',
        text: 'Este módulo está sendo usando em um ou mais projetos.',
        icon: 'warning',
        buttons: ['Cancelar', 'Ver Projetos'],
      }).then(async result => {
        if (result) {
          history.push(`/projects?module_id=${moduleId}`);
        }
      });
    } else {
      swal({
        dangerMode: true,
        title: 'Atencão',
        text:
          'Você está prestes a excluir um módulo permanentemente. Deseja mesmo realizar essa acão?',
        icon: 'warning',
        buttons: ['Cancelar', 'Excluir'],
      }).then(async result => {
        if (result) {
          const { data: responseDelete } = await api({
            method: 'post',
            url: 'module/delete',
            params: {
              module_id: JSON.stringify([moduleId]),
            },
          });

          if (responseDelete.status === 0) {
            toast.success('Módulo excluído com sucesso!');
            searchModules(filter, { ...pagination, keyword });
          } else {
            toast.error(responseDelete.message.text);
          }
        }
      });
    }
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários módulos permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const modulesToDelete = modules
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!modulesToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um módulo para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'module/delete',
          params: {
            module_id: JSON.stringify(modulesToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Módulos excluídos com sucesso!');
          searchModules(filter, { ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () => modules.length && modules.every(m => m.uiChecked === true),
    [modules]
  );

  function handleSelectAll(e, { checked }) {
    setModules(oldModules =>
      oldModules.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(moduleId) {
    setModules(oldModules =>
      oldModules.map(item => {
        return item.ID === moduleId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function getAvailabilityByLabel(label) {
    switch (label) {
      case 'A':
        return 'Disponível';
      case 'U':
        return 'Indisponível';
      default:
        return 'Disponível';
    }
  }

  function renderModulesWithAvailability() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'BRAND_NAME', description: 'Marca' },
          { field: 'MODEL', description: 'Modelo' },
          { field: 'POWER', description: 'Potência (W)' },
          {
            field: 'CIRCUIT_VOLTAGE_OPEN',
            description: 'Tensão Circuito (VCC) Aberto',
          },
          {
            field: 'CIRCUIT_VOLTAGE_CLOSED',
            description: 'Tensão Circuito (VCC) Fechado',
          },
          { field: 'CURRENT_OPEN', description: 'Corrente (A) Aberto' },
          { field: 'CURRENT_CLOSED', description: 'Corrente (A) Fechado' },
          { field: 'DIMENSION', description: 'Dimensão' },
          { field: 'WEIGHT', description: 'Peso' },
          { field: 'AVAILABILITY', description: 'Disponibilidade' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={module => ({
          ID: () => (
            <Checkbox
              checked={!!module.uiChecked}
              onChange={() => handleSelect(module.ID)}
            />
          ),
          BRAND_NAME: module.BRAND_NAME,
          MODEL: module.MODEL,
          POWER: numberFormat(module.POWER, 0, ',', '.'),
          CIRCUIT_VOLTAGE_OPEN: numberFormat(
            module.CIRCUIT_VOLTAGE_OPEN,
            2,
            ',',
            '.'
          ),
          CIRCUIT_VOLTAGE_CLOSED: numberFormat(
            module.CIRCUIT_VOLTAGE_CLOSED,
            2,
            ',',
            '.'
          ),
          CURRENT_OPEN: numberFormat(module.CURRENT_OPEN, 2, ',', '.'),
          CURRENT_CLOSED: numberFormat(module.CURRENT_CLOSED, 2, ',', '.'),
          DIMENSION: module.DIMENSION,
          WEIGHT: numberFormat(module.WEIGHT, 2, ',', '.'),
          AVAILABILITY:
            user.TYPE_KEY_ === 'ROOT' ||
            (user.TYPE_KEY_ === 'GROUP' &&
              user.ID === module.ACCOUNT_RESPONSIBLE_ID) ? (
              <Dropdown
                value={module.AVAILABILITY}
                text={getAvailabilityByLabel(module.AVAILABILITY)}
                fluid
                selection
                options={[
                  { key: '1', value: 'A', text: 'Disponível' },
                  { key: '2', value: 'U', text: 'Indisponível' },
                ]}
                placeholder="Alterar disponibilidade"
                onChange={(e, data) => {
                  handleAvailabilityChange(module, data.value);
                }}
              />
            ) : (
              getAvailabilityByLabel(module.AVAILABILITY)
            ),
          ACTIONS: () =>
            user.TYPE_KEY_ === 'ROOT' ||
            (user.TYPE_KEY_ === 'GROUP' &&
              user.ID === module.ACCOUNT_RESPONSIBLE_ID) ? (
              <>
                <Button
                  size="mini"
                  icon
                  color="blue"
                  title="Editar módulo"
                  onClick={() => {
                    openModal('moduleAdd', {
                      mode: 'edit',
                      data: {
                        ID: module.ID,
                        BRAND_ID: module.BRAND_ID,
                        MODEL: module.MODEL,
                        POWER: module.POWER,
                        CIRCUIT_VOLTAGE_CLOSED: module.CIRCUIT_VOLTAGE_CLOSED,
                        CIRCUIT_VOLTAGE_OPEN: module.CIRCUIT_VOLTAGE_OPEN,
                        CURRENT_CLOSED: module.CURRENT_CLOSED,
                        CURRENT_OPEN: module.CURRENT_OPEN,
                        DIMENSION: module.DIMENSION,
                        WEIGHT: module.WEIGHT,
                      },
                      onConfirm: () =>
                        searchModules(filter, { ...pagination, keyword }),
                    });
                  }}
                >
                  <Icon name="pencil" />
                </Button>

                <Button
                  size="mini"
                  icon
                  color="red"
                  title="Remover módulo"
                  onClick={() =>
                    handleModuleDelete(module.ID, module.IN_PROJECT)
                  }
                >
                  <Icon name="trash" />
                </Button>

                <Button
                  size="mini"
                  icon
                  color="facebook"
                  title="Ver projetos"
                  disabled={module.IN_PROJECT === 0}
                >
                  <Link
                    to={{
                      pathname: '/projects',
                      search: `?module_id=${module.ID}`,
                    }}
                    target="_blank"
                    className="text-white"
                  >
                    <Icon name="eye" className="m-r-none" />
                  </Link>
                </Button>
              </>
            ) : (
              '-'
            ),
          ACTIVE: module.AVAILABILITY === 'U',
        })}
        data={modules}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchModules(filter, { ...newPagination, keyword });
        }}
      />
    );
  }

  function renderModulesWithTable() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'BRAND_NAME', description: 'Marca' },
          { field: 'MODEL', description: 'Modelo' },
          { field: 'POWER', description: 'Potência (W)' },
          {
            field: 'CIRCUIT_VOLTAGE_CLOSED',
            description: 'Tensão Circuito (VCC) Aberto',
          },
          {
            field: 'CIRCUIT_VOLTAGE_OPEN',
            description: 'Tensão Circuito (VCC) Fechado',
          },
          { field: 'CURRENT_OPEN', description: 'Corrente (A) Aberto' },
          { field: 'CURRENT_CLOSED', description: 'Corrente (A) Fechado' },
          { field: 'DIMENSION', description: 'Dimensão' },
          { field: 'WEIGHT', description: 'Peso' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={module => ({
          ID: () => (
            <Checkbox
              checked={!!module.uiChecked}
              onChange={() => handleSelect(module.ID)}
            />
          ),
          BRAND_NAME: module.BRAND_NAME,
          MODEL: module.MODEL,
          POWER: numberFormat(module.POWER, 0, ',', '.'),
          CIRCUIT_VOLTAGE_CLOSED: numberFormat(
            module.CIRCUIT_VOLTAGE_CLOSED,
            2,
            ',',
            '.'
          ),
          CIRCUIT_VOLTAGE_OPEN: numberFormat(
            module.CIRCUIT_VOLTAGE_OPEN,
            2,
            ',',
            '.'
          ),
          CURRENT_OPEN: numberFormat(module.CURRENT_OPEN, 2, ',', '.'),
          CURRENT_CLOSED: numberFormat(module.CURRENT_CLOSED, 2, ',', '.'),
          DIMENSION: module.DIMENSION,
          WEIGHT: numberFormat(module.WEIGHT, 2, ',', '.'),
          ACTIONS: () =>
            user.TYPE_KEY_ === 'ROOT' ||
            (user.TYPE_KEY_ === 'GROUP' &&
              user.ID === module.ACCOUNT_RESPONSIBLE_ID) ? (
              <>
                <Button
                  size="mini"
                  icon
                  color="blue"
                  title="Editar módulo"
                  onClick={() => {
                    openModal('moduleAdd', {
                      mode: 'edit',
                      data: {
                        ID: module.ID,
                        BRAND_ID: module.BRAND_ID,
                        MODEL: module.MODEL,
                        POWER: module.POWER,
                        CIRCUIT_VOLTAGE_CLOSED: module.CIRCUIT_VOLTAGE_CLOSED,
                        CIRCUIT_VOLTAGE_OPEN: module.CIRCUIT_VOLTAGE_OPEN,
                        CURRENT_CLOSED: module.CURRENT_CLOSED,
                        CURRENT_OPEN: module.CURRENT_OPEN,
                        DIMENSION: module.DIMENSION,
                        WEIGHT: module.WEIGHT,
                      },
                      onConfirm: () =>
                        searchModules(filter, { ...pagination, keyword }),
                    });
                  }}
                >
                  <Icon name="pencil" />
                </Button>

                <Button
                  size="mini"
                  icon
                  color="red"
                  title="Remover módulo"
                  onClick={() =>
                    handleModuleDelete(module.ID, module.IN_PROJECT)
                  }
                >
                  <Icon name="trash" />
                </Button>

                <Button
                  size="mini"
                  icon
                  color="facebook"
                  title="Ver projetos"
                  disabled={module.IN_PROJECT === 0}
                >
                  <Link
                    to={{
                      pathname: '/projects',
                      search: `?module_id=${module.ID}`,
                    }}
                    target="_blank"
                    className="text-white"
                  >
                    <Icon name="eye" className="m-r-none" />
                  </Link>
                </Button>
              </>
            ) : (
              '-'
            ),
          ACTIVE: module.AVAILABILITY === 'U',
        })}
        data={modules}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchModules(filter, { ...newPagination, keyword });
        }}
      />
    );
  }

  function renderModulesContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!modules.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            {(user.TYPE_KEY_ === 'ROOT' ||
              (user.TYPE_KEY_ === 'GROUP' && userHasBrands)) && (
              <button
                type="button"
                className="ui button green icon"
                title="Novo módulo"
                onClick={() => {
                  openModal('moduleAdd', {
                    mode: 'add',
                    onConfirm: () => searchModules(filter, pagination),
                  });
                }}
              >
                <i className="mdi mdi-plus icon" /> Novo módulo
              </button>
            )}

            <button
              type="button"
              className="ui button orange icon"
              title="Novo módulo"
              onClick={() => history.push('brands/?backTo=/modules')}
            >
              <i className="mdi mdi-tag-multiple icon" /> Marcas
            </button>
          </Grid.Column>
        </Grid>
        {user.TYPE_KEY_ === 'ROOT' ||
        (user.TYPE_KEY_ === 'GROUP' && userHasBrands)
          ? renderModulesWithAvailability()
          : renderModulesWithTable()}
        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar módulos </span>
          </div>

          <div className="ui attached bottom segment">
            {renderModulesContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
