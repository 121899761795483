import React from 'react';
import InputMask from 'react-input-mask';

export default function MaskedInput({ mask, register = null, ...props }) {
  const { readOnly, disabled } = props;

  return (
    <InputMask mask={mask} {...props}>
      {inputProps => (
        <input
          {...inputProps}
          disabled={disabled}
          readOnly={readOnly}
          ref={register}
        />
      )}
    </InputMask>
  );
}
