import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import InputNumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

export default function ModalInverterAdd({
  onClose,
  handleClose,
  onConfirm,
  mode = 'add',
  data = {},
}) {
  const [brands, setBrands] = useState([]);
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [brandId, setBrandId] = useState(data.BRAND_ID || null);
  const [model, setModel] = useState(data.MODEL || '');
  const [maximumPower, setMaximumPower] = useState(data.MAXIMUM_POWER || 0);
  const [outputPower, setOutputPower] = useState(data.OUTPUT_POWER || 0);
  const [tensionPhase, setTensionPhase] = useState(
    data.TENSION_PHASE || 'Monofásico'
  );
  const [maximumVoltage, setMaximumVoltage] = useState(
    data.MAXIMUM_VOLTAGE || 0
  );
  const [mppTrackerQtty, setMppTrackerQtty] = useState(
    data.MPP_TRACKER_QTTY || 0
  );
  const [mppTrackerStri, setMppTrackerStri] = useState(
    data.MPP_TRACKER_STRINGS || 0
  );
  const [mppTrackerPlus, setMppTrackerPlus] = useState(
    data.MPP_TRACKER_PLUS || 0
  );
  const [currentByMppt, setCurrentByMppt] = useState(
    data.CURRENT_BY_MPPT || ''
  );
  const [rateVoltage, setRateVoltage] = useState(data.AC_RATE_VOLTAGE || '');
  const [maximumElectricCurrent, setMaximumElectricCurrent] = useState(
    data.MAXIMUM_ELECTRIC_CURRENT || ''
  );

  const inputModel = useRef(null);
  const inputTensionPhase = useRef(null);
  const inputRateVoltage = useRef(null);
  const inputCurrentByMppt = useRef(null);
  const [inputMaximumPower, setInputMaximumPower] = useState(null);
  const [inputOutputPower, setInputOutputPower] = useState(null);
  const [inputMaximumVoltage, setInputMaximumVoltage] = useState(null);
  const [inputMppTrackerQtty, setInputMppTrackerQtty] = useState(null);
  const [inputMppTrackerStri, setInputMppTrackerStri] = useState(null);
  const [inputMppTrackerPlus, setInputMppTrackerPlus] = useState(null);
  const [
    inputMaximumElectricCurrent,
    setInputMaximumElectricCurrent,
  ] = useState(null);

  const searchBrands = useCallback(async () => {
    const { data: responseBrands } = await api({
      method: 'post',
      url: 'brand/select',
    });

    if (responseBrands.status === 0) {
      if (user.TYPE_KEY_ === 'ROOT') {
        setBrands(responseBrands.data);
      } else if (user.TYPE_KEY_ === 'GROUP') {
        const filteredBrands = responseBrands.brands.filter(
          brand => brand.ACCOUNT_RESPONSIBLE_ID !== user.ID
        );

        setBrands(filteredBrands);
      }

      if (!brandId) {
        setBrandId(responseBrands.data[0].ID);
      }
    } else {
      setBrands([]);
    }
  }, []);

  useEffect(() => {
    searchBrands();
  }, [searchBrands]);

  const [isSaving, setIsSaving] = useState(false);

  function normalizeCurrentByMppt(value) {
    if (value && typeof value === 'string') {
      let normalized = value.replace(/_/g, '');

      while (normalized[normalized.length - 1] === '/') {
        normalized = normalized.substring(0, normalized.length - 1);
      }

      return normalized;
    }

    return value;
  }

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseSave } = await api({
      method: 'post',
      url: mode === 'add' ? '/inverter/add' : '/inverter/update',
      params: {
        inverter_id: data.ID || null,
        inverter: JSON.stringify({
          BRAND_ID: brandId,
          MODEL: model,
          MAXIMUM_POWER: maximumPower,
          OUTPUT_POWER: outputPower,
          TENSION_PHASE: tensionPhase,
          MAXIMUM_VOLTAGE: maximumVoltage,
          MPP_TRACKER_QTTY: mppTrackerQtty,
          MPP_TRACKER_STRINGS: mppTrackerStri,
          MPP_TRACKER_PLUS: mppTrackerPlus,
          CURRENT_BY_MPPT: normalizeCurrentByMppt(currentByMppt),
          AC_RATE_VOLTAGE: rateVoltage,
          MAXIMUM_ELECTRIC_CURRENT: maximumElectricCurrent,
        }),
      },
    });

    if (responseSave.status === 0) {
      toast.success(responseSave.message.text);
      setIsSaving(false);

      if (onConfirm) onConfirm();

      handleClose();
    } else {
      toast.error(responseSave.message.text);
    }

    setIsSaving(false);
  }

  function handleGoToBrands() {
    handleClose();
    history.push('brands/?backTo=/inverters');
  }

  return (
    <Modal size="large" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo inversor' : 'Editar inversor'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field">
            <div className="four fields">
              <div className="ui fluid four wide field required">
                <label>Marca</label>
                <div className="ui action right labeled input">
                  <select
                    className="ui dropdown"
                    name="BRAND_ID"
                    value={brandId}
                    onChange={e => setBrandId(e.target.value)}
                    disabled={isSaving}
                  >
                    {brands &&
                      brands.map(brand => (
                        <option
                          key={brand.ID}
                          value={brand.ID}
                          selected={brand.ID === brandId}
                        >
                          {brand.NAME}
                        </option>
                      ))}
                  </select>

                  {user.TYPE_KEY_ !== 'GROUP' && (
                    <button
                      type="button"
                      title="Gerenciar marcas"
                      className="ui label icon button green m-r-sm"
                      onClick={handleGoToBrands}
                    >
                      <i className="mdi mdi-cogs icon" />
                    </button>
                  )}
                </div>
              </div>

              <div className="six wide field required">
                <label>Modelo</label>
                <input
                  type="text"
                  name="MODEL"
                  className="ui action input"
                  placeholder="Primo 3.0-1"
                  ref={inputModel}
                  value={model}
                  onChange={e => setModel(e.target.value)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputMaximumPower.focus()
                  }
                />
              </div>

              <div className="three wide field">
                <label>Potência máxima (kwp)</label>
                <InputNumberFormat
                  type="tel"
                  value={maximumPower || ''}
                  displayType="input"
                  placeholder="4.5"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  onValueChange={v => setMaximumPower(v.floatValue)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputMaximumVoltage.focus()
                  }
                  getInputRef={el => setInputMaximumPower(el)}
                  disabled={isSaving}
                />
              </div>

              <div className="three wide field">
                <label>Tensão Máxima (CC)</label>
                <InputNumberFormat
                  type="tel"
                  value={maximumVoltage || ''}
                  displayType="input"
                  placeholder="1000"
                  className="ui action input"
                  fixedDecimalScale
                  onValueChange={v => setMaximumVoltage(v.floatValue)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputMppTrackerQtty.focus()
                  }
                  getInputRef={el => setInputMaximumVoltage(el)}
                  disabled={isSaving}
                />
              </div>
            </div>

            <div className="seven fields">
              <div className="ui fluid text-center">
                <b>MPP Tracker</b>
                <div className="three fields">
                  <div className="ui fluid six wide field">
                    <label className="ui fluid text-center">Qtd</label>
                    <InputNumberFormat
                      type="tel"
                      value={mppTrackerQtty || ''}
                      displayType="input"
                      placeholder="2"
                      className="ui action input text-center"
                      onValueChange={v => setMppTrackerQtty(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputMppTrackerStri.focus()
                      }
                      getInputRef={el => setInputMppTrackerQtty(el)}
                      disabled={isSaving}
                    />
                  </div>

                  <div className="ui fluid six wide field">
                    <label className="ui fluid text-center">Stri</label>
                    <InputNumberFormat
                      type="tel"
                      value={mppTrackerStri || ''}
                      displayType="input"
                      placeholder="2"
                      className="ui action input text-center"
                      onValueChange={v => setMppTrackerStri(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputMppTrackerPlus.focus()
                      }
                      getInputRef={el => setInputMppTrackerStri(el)}
                      disabled={isSaving}
                    />
                  </div>

                  <div className="ui fluid six wide field">
                    <label className="ui fluid text-center">+</label>
                    <InputNumberFormat
                      type="tel"
                      value={mppTrackerPlus || ''}
                      displayType="input"
                      placeholder="2"
                      className="ui action input text-center"
                      onValueChange={v => setMppTrackerPlus(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputCurrentByMppt.current.focus()
                      }
                      getInputRef={el => setInputMppTrackerPlus(el)}
                      disabled={isSaving}
                    />
                  </div>
                </div>
              </div>

              <div className="six wide field m-t-md">
                <label>Corrente por MPPT</label>
                <input
                  value={currentByMppt || ''}
                  placeholder="12/12"
                  className="ui action input"
                  onChange={e => setCurrentByMppt(e.target.value)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputMaximumElectricCurrent.focus()
                  }
                  ref={inputCurrentByMppt}
                  disabled={isSaving}
                />
              </div>

              <div className="six wide field m-t-md">
                <label>Corrente máxima</label>
                <InputNumberFormat
                  value={maximumElectricCurrent || ''}
                  displayType="input"
                  placeholder="3"
                  className="ui action input"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  onValueChange={v => setMaximumElectricCurrent(v.floatValue)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputOutputPower.current.focus()
                  }
                  getInputRef={el => setInputMaximumElectricCurrent(el)}
                  disabled={isSaving}
                />
              </div>

              <div className="six wide field m-t-md">
                <label>Potência de Saída (KW)</label>
                <InputNumberFormat
                  value={outputPower || ''}
                  displayType="input"
                  placeholder="3"
                  className="ui action input"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  onValueChange={v => setOutputPower(v.floatValue)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputTensionPhase.current.focus()
                  }
                  getInputRef={el => setInputOutputPower(el)}
                  disabled={isSaving}
                />
              </div>

              <div className="six wide field m-t-md">
                <label>Fases</label>
                <select
                  className="ui dropdown"
                  name="TENSION_PHASE"
                  ref={inputTensionPhase}
                  value={tensionPhase || 'Monofásico'}
                  onChange={e => setTensionPhase(e.target.value)}
                >
                  <option
                    value="Monofásico"
                    selected={brandId && tensionPhase === 'Monofásico'}
                  >
                    Monofásico
                  </option>
                  <option
                    value="Trifásico"
                    selected={brandId && tensionPhase === 'Trifásico'}
                  >
                    Trifásico
                  </option>
                </select>
              </div>

              <div className="six wide field m-t-md">
                <label>Tensão Nominal AC (V)</label>
                <select
                  className="ui dropdown"
                  name="AC_RATE_VOLTAGE"
                  ref={inputRateVoltage}
                  defaultValue={rateVoltage || ''}
                  onChange={e => setRateVoltage(e.target.value)}
                >
                  <option disabled value="">
                    Selecione
                  </option>
                  <option value={220}>220</option>
                  <option value={380}>380</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
