import React, { useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { Message, Icon } from 'semantic-ui-react';
import uniqid from 'uniqid';

// cols = [{field, description}]
// data = [???]
function Table({ cols, mapping, data = [], onUpdateOrderBy, loading = false }) {
  const [orderBy, setOrderBy] = useState(() => {
    const defaultCol = cols.find(c => !!c.default);

    if (defaultCol) {
      return [{ field: defaultCol.field, mode: 'DESC' }];
    }

    return cols.length
      ? [{ field: cols[0].field, mode: 'DESC' }]
      : [{ field: 'ID', mode: 'DESC' }];
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const mappingObject = data.map(d => mapping(d));

    setRows(mappingObject);
  }, [data, mapping]);

  const updateOrderBy = useCallback(() => {
    onUpdateOrderBy(orderBy);
  }, [orderBy]);

  useEffect(() => {
    updateOrderBy(orderBy);
  }, [orderBy, updateOrderBy]);

  function handleOrderByChange(event, field) {
    const existsField = orderBy.filter(item => item.field === field)[0];
    const restFields = orderBy.filter(item => item.field !== field);

    if (existsField) {
      const newOrderBy = [
        ...restFields,
        {
          field: existsField.field,
          mode: existsField.mode === 'ASC' ? 'DESC' : 'ASC',
        },
      ];

      if (existsField.mode === 'ASC' && newOrderBy.length > 1) {
        const index = newOrderBy.indexOf(existsField);

        newOrderBy.splice(index, 1);
      }

      setOrderBy(newOrderBy);
    } else {
      let newOrderBy;

      if (event.shiftKey) {
        newOrderBy = [
          ...restFields,
          {
            field,
            mode: 'DESC',
          },
        ];
      } else {
        newOrderBy = [
          {
            field,
            mode: 'DESC',
          },
        ];
      }

      setOrderBy(newOrderBy);
    }
  }

  function isActionsCol(col) {
    return col.field === 'ACTIONS';
  }

  function getIcon(field) {
    const index = orderBy.findIndex(item => item.field === field);

    if (index >= 0) {
      return (
        !isActionsCol(field) && (
          <Icon
            name={
              orderBy[index].mode === 'DESC'
                ? 'sort descending'
                : 'sort ascending'
            }
          />
        )
      );
    }

    return null;
  }

  return (
    <table className="ui compact striped celled table">
      <thead>
        <tr>
          {cols.map(col => {
            const isFunction = typeof col.description === typeof Function;

            return (
              <th
                key={col.field}
                onClick={event =>
                  !isFunction &&
                  !isActionsCol(col) &&
                  !('sortable' in col && col.sortable === false) &&
                  handleOrderByChange(event, col.field)
                }
                className={classNames('center aligned', {
                  clickable:
                    !isActionsCol(col) &&
                    !('sortable' in col && col.sortable === false),
                  selected:
                    !isActionsCol(col) &&
                    orderBy.some(item => item.field === col.field),
                })}
              >
                {isFunction ? col.description() : col.description}

                {!isFunction ? getIcon(col.field) : ''}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {!loading &&
          data.length > 0 &&
          rows.map(row => {
            const rowsKeys = Object.keys(row);
            const key =
              rowsKeys.includes('ID') && typeof row.ID === 'number'
                ? row.ID
                : uniqid();
            const active = rowsKeys.includes('ACTIVE') && row.ACTIVE === true;

            const mapTd = cols.map(col => {
              const value =
                typeof row[col.field] === typeof Function
                  ? row[col.field]()
                  : row[col.field];
              return (
                <td key={col.field} className="center aligned">
                  {value || '-'}
                </td>
              );
            });

            return (
              <tr
                key={key}
                className={classNames({
                  active,
                })}
              >
                {mapTd}
              </tr>
            );
          })}
        {!loading && data.length === 0 && (
          <tr>
            <td colSpan={cols.length}>
              <Message content="Nenhum resultado encontrado! Atualize os filtros e pesquisa novamente." />
            </td>
          </tr>
        )}

        {loading && (
          <tr>
            <td className="center aligned" colSpan={cols.length}>
              <Message color="blue" className="mdi mdi-loading mdi-spin" />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default Table;
