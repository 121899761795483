import { toast } from 'react-toastify';

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('sw.js', {
          scope: './',
        })
        .then(reg => {
          console.log(
            `[PWA Builder] Service worker has been registered for scope: ${reg.scope}`
          );
        });

      navigator.serviceWorker.onmessage = evt => {
        const message = JSON.parse(evt.data);

        const isRefresh = message.type === 'refresh';
        const isAsset = message.url.includes('asset');
        const lastETag = localStorage.currentETag;

        const isNew = lastETag !== message.eTag;

        if (isRefresh && isAsset && isNew) {
          if (lastETag) {
            toast.success(
              'A aplicação foi atualizada! A próxima vez que for acessada já estará disponível ou atualize a página para ver agora.'
            );
          }

          localStorage.currentETag = message.eTag;
        }
      };
    });
  }
};
