import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Message } from 'semantic-ui-react';
import swal from 'sweetalert';

import api from '~/services/api';

import Form from './Form';

function UserAdd() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { accountId, userId } = useParams();

  const [backTo] = useState(query.get('backTo'));

  const [editMode, setEditMode] = useState(false);

  const [self, setSelf] = useState(null);

  const updateSelf = useCallback(async () => {
    if (userId) {
      const { data: response } = await api({
        method: 'post',
        url: 'credential/self',
        params: { credential_id: userId },
      });

      if (response.status === 0) {
        setSelf(response.data);
      } else {
        setSelf({});
      }
    }
  }, [userId]);

  const getCredentialId = useCallback(async () => {
    async function requestAdd(id) {
      const { data: credential } = await api({
        method: 'post',
        url: 'credential/add',
        params: { credential_id: id, account_id: accountId },
      });

      if (credential.status === 0) {
        if (!credential.data.validated) {
          history.replace(
            `/account/${accountId}/user/${credential.data.ID}${location.search}`
          );
        } else {
          setEditMode(true);
        }
      } else {
        toast.error(
          `Ouve um erro ao adicionar a conta: ${credential.message.text}`
        );

        history.push(backTo);
      }
    }

    await requestAdd(userId);
  }, []);

  useEffect(() => {
    getCredentialId();
  }, [getCredentialId]);

  useEffect(() => {
    updateSelf();
  }, [updateSelf]);

  function normalizeData(data) {
    return {
      ID: data.ID || '',
      NAME: data.NAME || '',
      ACCOUNT_NAME: data.ACCOUNT_NAME || '-',
      ACCOUNT_GROUP_NAME: data.ACCOUNT_GROUP_NAME || '-',
      EMAIL: data.EMAIL || '',
      LOGIN: data.LOGIN || '',
      POSTAL_CODE: data.POSTAL_CODE,
      ADDRESS: data.ADDRESS,
      NUMBER: data.NUMBER,
      COMPLEMENT: data.COMPLEMENT,
      DISTRICT: data.DISTRICT,
      UF_ID: Number(data.UF_ID) || '',
      CITY_ID: Number(data.CITY_ID) || '',
      RESPONSIBLE_MOBILE: data.RESPONSIBLE_MOBILE,
      RESPONSIBLE_EMAIL: data.RESPONSIBLE_EMAIL,
      RESPONSIBLE_NAME: data.RESPONSIBLE_NAME,
      RESPONSIBLE_PHONE: data.RESPONSIBLE_PHONE,
      LEVEL_ID: data.LEVEL_ID,
      TYPE_ID: data.TYPE_ID,
    };
  }

  async function onSubmit(data) {
    const { data: responseSave } = await api({
      method: 'post',
      url: 'credential/save',
      params: {
        credential_id: userId,
        credential: data,
      },
    });

    const { title, text, type } = responseSave.message;

    if (responseSave.status === 0) {
      swal(title, text, type).then(() => {
        if (backTo) {
          history.push(backTo);
        } else {
          history.push('/accounts');
        }
      });
    } else {
      swal(title, text, type);
    }
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">
            {editMode ? 'Editar usuário' : 'Novo usuário'}
          </span>

          {backTo && (
            <div>
              <button
                className="ui button red"
                type="button"
                onClick={() => history.push(backTo)}
              >
                Cancelar
              </button>
            </div>
          )}
        </div>

        <div className="ui attached bottom segment">
          {self === null ? (
            <Message color="blue" content="Carregando informações..." />
          ) : (
            <Form initialData={normalizeData(self)} onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </div>
  );
}

export default UserAdd;
