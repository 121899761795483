import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
  .ui.message {
    text-align: center;
  }

  .sidebar {
    padding: 10px;

    .drawer-title {
      display: flex;
      justify-content: space-between;

      .close-drawer-btn {
        background: transparent;
        border: none;
      }
    }

    label {
      text-align: left;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;

  animation-name: fadeIn;
  animation-duration: 0.2s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.5;
    }
  }
`;

export const Logo = styled.img`
  width: 800px;
  margin-top: 50px;
`;

export const DashboardContainer = styled.div`
  padding: 20px;

  .ui.header:not(:first-child) {
    margin: 30px 0 20px;
  }

  @media (max-width: 792px) {
    .btn-filter {
      margin-top: 10px;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 792px) {
    flex-direction: column;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;

  width: 100%;
  @media (max-width: 792px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

export const FiltersByDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    margin: 0 5px;
  }

  @media (max-width: 792px) {
    flex-direction: column;
  }
`;

const colors = {
  yellow: 'rgb(251, 190, 8)',
  red: 'rgb(219, 40, 40)',
  green: 'rgb(33, 186, 69)',
  grey: 'rgb(118, 118, 118)',
  purple: 'rgb(163, 51, 200)',
  blue: 'rgb(30, 108, 168)',
};

const background = {
  yellow: 'rgb(251, 190, 8, 0.1)',
  red: 'rgb(219, 40, 40, 0.1)',
  green: 'rgb(33, 186, 69, 0.1)',
  grey: 'rgb(118, 118, 118, 0.1)',
  purple: 'rgb(163, 51, 200, 0.1)',
  blue: 'rgb(30, 108, 168, 0.1)',
};

export const DashboardSummaryCard = styled.div`
  width: auto;
  height: 260px;
  padding: 1rem;

  border-radius: 0.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: #fff;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);

  position: relative;

  /* margin-right: 1rem; */

  &.horizontal-center {
    justify-content: center;
  }

  &.horizontal-left {
    justify-content: center;

    align-items: flex-start;

    text-align: left;

    .title,
    .value {
      text-align: left;
    }
  }

  &.big {
    .icon-wrapper {
      .icon {
        width: 34px;
        height: 34px;
      }

      width: 70px;
      height: 70px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .value {
      font-weight: 700;

      font-size: 24px;
    }

    .title,
    .value {
      margin-top: 16px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.flex,
  .flex {
    .title,
    .value,
    .action {
      margin-top: 0;
      margin-left: 16px;
    }

    .value,
    .action {
      margin-top: 8px;
    }
  }

  .illustration {
    width: 100%;
    position: absolute;

    top: 0;

    img {
      width: 100%;
      max-width: 160px;

      &.mini {
        max-width: 60px;
      }
    }

    .right {
      float: right;
    }
    .left {
      float: left;
    }

    .left-10 {
      margin-left: 10px !important;
    }

    .right-10 {
      margin-right: 10px !important;
    }
  }

  .help {
    position: absolute;

    top: 4px;
    right: 4px;

    /* background: rgb(118, 118, 118, 0.1); */
    color: rgb(118, 118, 118, 0.6);

    padding: 2px;

    border-radius: 50%;

    cursor: help;

    &:hover {
      color: rgb(118, 118, 118);
    }

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
    line-height: 24px;
  }

  .value {
    margin-top: 16px;
    font-size: 22px;

    font-weight: 600;

    &.green {
      color: rgb(72, 184, 101);
    }

    &.blue {
      color: rgb(54, 123, 220);
    }
  }

  .title,
  .value {
    text-align: center;
  }

  .icon-wrapper {
    .icon {
      margin: 0;
      width: 24px;
      height: 24px;
    }

    display: flex;

    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;

    color: ${p => (p.color ? colors[p.color] : 'unset')};

    padding: 1rem;

    background-color: ${p => (p.color ? background[p.color] : 'unset')};

    border-radius: 50%;
  }

  .action {
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &.party {
    padding: 3rem 1rem;

    .title {
      font-size: 28px;
      font-weight: 500;
      line-height: 38px;
    }

    .value {
      font-weight: 700;
    }

    .title,
    .value {
      margin-top: 16px;
    }
  }

  &.blue,
  &.red,
  &.green,
  &.purple,
  &.purple-2,
  &.purple-light,
  &.yellow,
  &.party {
    text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.3);

    .help {
      color: rgb(255, 255, 255);
    }

    .icon-wrapper {
      color: rgb(255, 255, 255);
      background-color: rgb(255, 255, 255, 0.3);

      box-shadow: 0 0.5rem 1rem rgba(34, 41, 47, 0.15);
    }
  }

  &.blue {
    background: rgb(112, 161, 255);
    background: linear-gradient(
      180deg,
      rgba(112, 161, 255, 1) 0%,
      rgba(30, 144, 255, 1) 100%
    );
    /* background: rgb(54, 123, 220); */
    color: rgb(255, 255, 255);
  }

  &.red {
    /* background: rgb(219, 79, 79); */
    background: rgb(238, 115, 115);
    background: linear-gradient(
      180deg,
      rgba(238, 115, 115, 1) 0%,
      rgba(219, 79, 79, 1) 100%
    );

    color: rgb(255, 255, 255);
  }

  &.grey {
    background: rgb(118, 118, 118);
    background: linear-gradient(
      180deg,
      rgba(198, 203, 207, 1) 0%,
      rgba(168, 171, 173, 1) 100%
    );

    color: rgb(255, 255, 255);
  }

  &.purple {
    background: rgb(189, 83, 224);
    color: rgb(255, 255, 255);
  }

  &.party {
    background: linear-gradient(118deg, #64dfdf, #5390d9);
    color: rgb(255, 255, 255);
  }

  &.purple-light {
    background: rgb(115, 103, 240, 0.7);
    color: rgb(255, 255, 255);
  }

  &.purple-2 {
    background: rgb(115, 103, 240);
    color: rgb(255, 255, 255);
  }

  &.yellow {
    /* background: rgb(230, 197, 101); */

    background: rgb(245, 215, 121);
    background: linear-gradient(
      180deg,
      rgba(245, 215, 121, 1) 0%,
      rgba(222, 191, 98, 1) 100%
    );

    color: rgb(255, 255, 255);
  }

  &.green {
    /* background: rgb(72, 184, 101); */

    background: rgb(123, 237, 159);
    background: linear-gradient(
      180deg,
      rgba(123, 237, 159, 1) 0%,
      rgba(46, 213, 115, 1) 100%
    );

    color: rgb(255, 255, 255);
  }
`;

export const WhiteBox = styled.div`
  /* width: auto; */
  padding: 2rem;
  height: ${props => (props.height ? props.height : '260px')};

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  background: #fff;

  border-radius: 0.4rem;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);

  /* margin-bottom: 10px; */

  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 428px) {
    width: 100%;
    /* margin-top: 1rem;
    margin-left: 1rem; */
    /*
    &:last-child {
      margin-right: 1rem;
    } */
  }

  .box-title {
    @media (max-width: 428px) {
      font-size: 1.5rem;
    }
  }

  .box-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #999;

    text-align: center;

    @media (max-width: 428px) {
      font-size: 1.2rem;
    }
  }
`;

export const BoxAction = styled(Button)`
  position: absolute !important;
  top: 7px;
  right: 5px;
`;

export const PaybackSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 780px) {
    gap: 25px;
  }

  > img {
    width: 500px;
    padding: 10px;
  }
`;

export const PaybackInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
