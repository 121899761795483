import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import times from 'lodash/times';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import AppliedFilters from '~/components/AppliedFilters';
import ProjectCard from '~/components/Card/Project';
import SummaryCard from '~/components/Summary/Card';
import SummaryContainer from '~/components/Summary/Container';
import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

import ProjectDrawerFilter from './DrawerFilter';
import { Container, Overlay } from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function customDate(dateString) {
  return new Date(dateString.replace(/-/g, '/'));
}

export default function ProjectSearch() {
  const { user } = useContext(AuthContext);

  const history = useHistory();

  const queryString = useQuery();

  const { openModal } = useContext(ModalContext);

  const [showTable, setShowTable] = useState(!isMobile);

  const [projects, setProjects] = useState([]);
  const [summary, setSummary] = useState({});

  const [statusList, setStatusList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const [keyword, setKeyword] = useState('');

  const [showOverlay, setShowOverlay] = useState(false);

  const [filterData, setFilterData] = useState({});
  const [searchFilter, setSearchFilter] = useState(() => {
    const queryAccountId = queryString.get('ACCOUNT_ID');
    const queryGroupId = queryString.get('ACCOUNT_GROUP_ID');
    const queryStateId = queryString.get('UF_ID');
    const queryCityId = queryString.get('CITY_ID');
    const queryStepId = queryString.get('STEP_ID');
    const queryStatusId = queryString.get('STATUS_ID');
    const queryDateStart = queryString.get('DATE_TIME_START');
    const queryDateEnd = queryString.get('DATE_TIME_FINAL');
    const queryDateFinishStart = queryString.get('DATE_FINISH_START');
    const queryDateFinishEnd = queryString.get('DATE_FINISH_FINAL');
    const queryClientId = queryString.get('CLIENT_ID');

    const queryModuleId = queryString.get('module_id');
    const queryInverterId = queryString.get('inverter_id');

    return {
      ...(queryAccountId && { ACCOUNT_ID: queryAccountId }),
      ...(queryGroupId && { ACCOUNT_GROUP_ID: queryGroupId }),
      ...(queryStateId && { UF_ID: queryStateId }),
      ...(queryCityId && { CITY_ID: queryCityId }),
      ...(queryStepId && { STEP_ID: queryStepId }),
      ...(queryStatusId && { STATUS_ID: queryStatusId }),
      ...(queryDateStart && { DATE_TIME_START: queryDateStart }),
      ...(queryDateEnd && { DATE_TIME_FINAL: queryDateEnd }),
      ...(queryDateFinishStart && { DATE_FINISH_START: queryDateFinishStart }),
      ...(queryDateFinishEnd && { DATE_FINISH_FINAL: queryDateFinishEnd }),
      ...(queryClientId && { CLIENT_ID: queryClientId }),
      ...(queryModuleId && { module_id: queryModuleId }),
      ...(queryInverterId && { inverter_id: queryInverterId }),
    };
  });

  useEffect(() => {
    Object.entries(searchFilter).forEach(([key, value]) => {
      if (value) queryString.set(key, value);
    });

    queryString.forEach((_, key) => {
      if (key in searchFilter && !searchFilter[key]) {
        queryString.delete(key);
      }
    });

    if (!searchFilter.DATE_TIME_FINAL) {
      queryString.delete('DATE_TIME_FINAL');
    }

    if (!searchFilter.DATE_FINISH_FINAL) {
      queryString.delete('DATE_FINISH_FINAL');
    }

    history.replace({ search: queryString.toString() });
  }, [searchFilter, history]);

  const transformedFilters = useMemo(() => {
    const filters = [];

    if (filterData.DATE_TIME_START && !filterData.DATE_TIME_FINAL) {
      const dateFmt = customDate(filterData.DATE_TIME_START).toLocaleDateString(
        'pt-BR'
      );

      filters.push({
        key: 'Data de criação',
        data: `A partir de ${dateFmt}`,
      });
    }

    if (!filterData.DATE_TIME_START && filterData.DATE_TIME_FINAL) {
      const dateFmt = customDate(filterData.DATE_TIME_FINAL).toLocaleDateString(
        'pt-BR'
      );

      filters.push({
        key: 'Data de criação',
        data: `Até ${dateFmt}`,
      });
    }

    if (filterData.DATE_TIME_START && filterData.DATE_TIME_FINAL) {
      const dateStartFmt = customDate(
        filterData.DATE_TIME_START
      ).toLocaleDateString('pt-BR');

      const dateFinalFmt = customDate(
        filterData.DATE_TIME_FINAL
      ).toLocaleDateString('pt-BR');

      filters.push({
        key: 'Data de criação',
        data: `De ${dateStartFmt} até ${dateFinalFmt}`,
      });
    }

    if (filterData.DATE_FINISH_START && !filterData.DATE_FINISH_FINAL) {
      const dateFmt = customDate(
        filterData.DATE_FINISH_START
      ).toLocaleDateString('pt-BR');

      filters.push({
        key: 'Data de finalização',
        data: `A partir de ${dateFmt}`,
      });
    }

    if (!filterData.DATE_FINISH_START && filterData.DATE_FINISH_FINAL) {
      const dateFmt = customDate(
        filterData.DATE_FINISH_FINAL
      ).toLocaleDateString('pt-BR');

      filters.push({
        key: 'Data de finalização',
        data: `Até ${dateFmt}`,
      });
    }

    if (filterData.DATE_FINISH_START && filterData.DATE_FINISH_FINAL) {
      const dateStartFmt = customDate(
        filterData.DATE_FINISH_START
      ).toLocaleDateString('pt-BR');

      const dateFinalFmt = customDate(
        filterData.DATE_FINISH_FINAL
      ).toLocaleDateString('pt-BR');

      filters.push({
        key: 'Data de finalização',
        data: `De ${dateStartFmt} até ${dateFinalFmt}`,
      });
    }

    if (filterData.GROUP_NAMES && filterData.GROUP_NAMES.length) {
      filters.push({
        key: filterData.GROUP_NAMES.length > 1 ? 'Grupos' : 'Grupo',
        data: filterData.GROUP_NAMES,
      });
    }

    if (filterData.ACCOUNT_NAMES && filterData.ACCOUNT_NAMES.length) {
      filters.push({
        key: filterData.ACCOUNT_NAMES.length > 1 ? 'Contas' : 'Conta',
        data: filterData.ACCOUNT_NAMES,
      });
    }

    if (filterData.STATE_NAME) {
      filters.push({
        key: 'Estado',
        data: filterData.STATE_NAME,
      });
    }

    if (filterData.CITY_NAME) {
      filters.push({
        key: 'Cidade',
        data: filterData.CITY_NAME,
      });
    }

    if (filterData.STEP_NAMES && filterData.STEP_NAMES.length) {
      filters.push({
        key: filterData.STEP_NAMES.length > 1 ? 'Etapas' : 'Etapa',
        data: filterData.STEP_NAMES,
      });
    }

    if (filterData.STAGE_NAMES && filterData.STAGE_NAMES.length) {
      filters.push({
        key: 'Status',
        data: filterData.STAGE_NAMES,
      });
    }

    if (filterData.CLIENT_NAME) {
      filters.push({
        key: 'Cliente',
        data: filterData.CLIENT_NAME,
      });
    }

    return filters;
  }, [filterData]);

  useEffect(() => {
    if (transformedFilters.length === 0) {
      setIsLoadingFilters(false);
    }
  }, [transformedFilters]);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'CREATED_AT', mode: 'DESC' }],
  });

  const getStep = (key, data) => {
    const step = data.find(item => item.STEP_NAME === key);

    if (!step) {
      return { QTTY: 0, TOTAL_PROJECTS: 0 };
    }

    return step;
  };

  const processFilters = useCallback(filter => {
    const validFilter = {};

    Object.assign(validFilter, filter);

    if (
      !!filter.ACCOUNT_GROUP_ID &&
      typeof filter.ACCOUNT_GROUP_ID === 'string'
    ) {
      Object.assign(validFilter, {
        ACCOUNT_GROUP_ID: filter.ACCOUNT_GROUP_ID.split(',').map(x =>
          Number(x)
        ),
      });
    }

    if (!!filter.ACCOUNT_ID && typeof filter.ACCOUNT_ID === 'string') {
      Object.assign(validFilter, {
        ACCOUNT_ID: filter.ACCOUNT_ID.split(',').map(x => Number(x)),
      });
    }

    if (!!filter.STATUS_ID && typeof filter.STATUS_ID === 'string') {
      Object.assign(validFilter, {
        STATUS_ID: filter.STATUS_ID.split(',').map(x => Number(x)),
      });
    }

    if (!!filter.STEP_ID && typeof filter.STEP_ID === 'string') {
      Object.assign(validFilter, {
        STEP_ID: filter.STEP_ID.split(',').map(x => Number(x)),
      });
    }

    const cleanFilter = Object.fromEntries(
      Object.entries(validFilter).filter(([, v]) => !!v)
    );

    return cleanFilter;
  }, []);

  const searchProjects = useCallback(
    async (filters, pag) => {
      setIsLoading(true);

      try {
        const { data: responseSearch } = await api({
          method: 'post',
          url: '/project/dashboard/search',
          params: {
            pagination: JSON.stringify(pag),
            filter: JSON.stringify({
              ...processFilters(filters),
              GROUP_BY: 'step_id',
            }),
          },
        });

        if (!('pagination' in responseSearch)) {
          throw new Error('Expected pagination to be in responseSearch.');
        }

        if (responseSearch.status === 0) {
          setProjects(responseSearch.data);

          const budget = getStep('BUDGET', responseSearch.step);
          const inProgress = getStep('EXECUTE', responseSearch.step);
          const finalized = getStep('FINISH', responseSearch.step);
          const canceled = getStep('CANCEL', responseSearch.step);

          const newSummary = {
            ...responseSearch.summary,
            QTTY_BUDGET: budget.QTTY,
            TOTAL_PROJECT_BUDGET: budget.TOTAL_PROJECTS,
            QTTY_PROGRESS: inProgress.QTTY,
            TOTAL_PROJECT_PROGRESS: inProgress.TOTAL_PROJECTS,
            QTTY_FINALIZED: finalized.QTTY,
            TOTAL_PROJECT_FINALIZED: finalized.TOTAL_PROJECTS,
            QTTY_CANCELED: canceled.QTTY,
            TOTAL_PROJECT_CANCELED: canceled.TOTAL_PROJECTS,
          };

          setSummary(newSummary);
        } else {
          setProjects([]);
        }

        setPagination(responseSearch.pagination);
      } catch {
        toast.error(
          'Houve um erro ao buscar os projetos. Por favor, atualize os filtros e tente novamente.'
        );

        setProjects([]);
      } finally {
        setIsLoading(false);
      }
    },
    [searchFilter]
  );

  const searchStatus = useCallback(async () => {
    setIsLoadingStatus(true);

    try {
      const { data: responseSearch } = await api({
        method: 'get',
        url: 'stage/select',
        params: {
          filter: JSON.stringify({}),
          orderby: { field: 'POSITION', mode: 'ASC' },
        },
      });

      if (responseSearch.status === 0) {
        setStatusList(responseSearch.data);
      } else {
        setStatusList([]);
      }
    } catch {
      setStatusList([]);
    } finally {
      setIsLoadingStatus(false);
    }
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchProjects(searchFilter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchProjects(searchFilter, pagination);
  }, [searchProjects]);

  useEffect(() => {
    searchStatus();
  }, [searchStatus]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    searchProjects(searchFilter, {
      ...pagination,
      page: activePage,
    });
  }

  function getProjectStep(project) {
    const stepMap = {
      EXECUTE: {
        description: 'Em execução',
        color: 'yellow',
      },
      BUDGET: {
        description: 'Em orçamento',
        color: 'grey',
      },
      FINISH: {
        description: 'Finalizado',
        color: 'green',
      },
      CANCEL: {
        description: 'Cancelado',
        color: 'red',
      },
    };

    const stepLabel = (
      <Label
        size="small"
        color={stepMap[project.STEP_KEY || 'EXECUTE'].color}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          openModal('stepSelect', {
            data: {
              PROJECT_STEP_ID: project.STEP_ID,
              PROJECT_ID: project.ID,
            },
            onConfirm: () =>
              searchProjects(searchFilter, { ...pagination, keyword }),
          })
        }
      >
        {stepMap[project.STEP_KEY || 'EXECUTE'].description}
      </Label>
    );

    const defaultLabel = (
      <Label
        size="small"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          openModal('stepSelect', {
            data: {
              PROJECT_ID: project.ID,
            },
            onConfirm: () =>
              searchProjects(searchFilter, { ...pagination, keyword }),
          })
        }
      >
        Adicionar etapa
      </Label>
    );

    return project.STEP_ID ? stepLabel : defaultLabel;
  }

  function loadProjectStatus(projectId, stages = []) {
    const projectStatus = [];

    stages.forEach(statusObj => {
      const found = statusList.find(el => el.ID === statusObj.STAGE_ID);

      projectStatus.push({ ...found, PROJECT_STAGE_ID: statusObj.ID });
    });

    const mapStatus = projectStatus.map((status, index) => (
      <>
        <Label
          key={status.ID}
          size="small"
          color={status.COLOR}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            openModal('statusSelect', {
              data: {
                PROJECT_STATUS: projectStatus,
                PROJECT_ID: projectId,
              },
              onConfirm: () =>
                searchProjects(searchFilter, { ...pagination, keyword }),
            })
          }
        >
          {status.DESCRIPTION}
        </Label>
        {(index + 1) % 3 === 0 && <br />}
      </>
    ));

    const defaultStatus = (
      <Label
        size="small"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          openModal('statusSelect', {
            data: {
              PROJECT_ID: projectId,
            },
            onConfirm: () =>
              searchProjects(searchFilter, { ...pagination, keyword }),
          })
        }
      >
        Adicionar status
      </Label>
    );

    return projectStatus.length === 0 ? defaultStatus : mapStatus;
  }

  function handleProjectEdit(projectId) {
    history.push(`/project/${projectId}`);
  }

  function getClientNameByType(project) {
    if (project.CLIENT_TYPE !== 'L') {
      return <span>{project.CLIENT_NAME || '-'}</span>;
    }

    let clientCompanyName = project.CLIENT_COMPANY_NAME || '-';
    let clientFantasyName = project.CLIENT_FANTASY_NAME || '-';
    const substrSize = 37;

    if (clientCompanyName.length > substrSize) {
      clientCompanyName = `${clientCompanyName.substring(0, substrSize)}...`;
    }

    if (clientFantasyName.length > substrSize) {
      clientFantasyName = `${clientFantasyName.substring(0, substrSize)}...`;
    }

    return (
      <>
        <span title={project.CLIENT_COMPANY_NAME}>{clientCompanyName}</span>
        <br />
        <span title={project.CLIENT_FANTASY_NAME}>{clientFantasyName}</span>
      </>
    );
  }

  function handleProjectDelete(projectId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um projeto permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: project } = await api({
          method: 'post',
          url: 'project/delete',
          params: {
            project_id: projectId,
          },
        });

        if (project.status === 0) {
          toast.success('Projeto excluído com sucesso!');
          searchProjects(searchFilter, pagination);
        } else {
          toast.error(project.message.text);
        }
      }
    });
  }

  function renderProjectsWithCard() {
    return (
      <Grid centered>
        {projects.length > 0 &&
          !isLoading &&
          !isLoadingStatus &&
          !isLoadingFilters &&
          projects.map(project => (
            <Grid.Column key={project.ID} mobile={16} tablet={8} computer={4}>
              <ProjectCard
                handleEdit={() => handleProjectEdit(project.ID)}
                handleDelete={() => handleProjectDelete(project.ID)}
                project={project}
              />
            </Grid.Column>
          ))}

        {!isLoading &&
          !isLoadingFilters &&
          !isLoadingStatus &&
          projects.length === 0 && (
            <Grid.Column>
              <Message content="Nenhum projeto encontrado!" />
            </Grid.Column>
          )}

        {(isLoading || isLoadingFilters) && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando projetos..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderProjectsWithTable() {
    return (
      <Table
        cols={[
          { field: 'CODE', description: 'Código' },
          { field: 'ACCOUNT_NAME', description: 'Descrição do projeto' },
          {
            field: 'CREATED_AT',
            description: 'Data de criação',
            default: true,
          },
          { field: 'FINISHED_AT', description: 'Data de finalização' },
          { field: 'STEP_DESCRIPTION', description: 'Etapa do projeto' },
          { field: 'STATUS', description: 'Status do projeto' },
          { field: 'CLIENT_NAME', description: 'Cliente' },
          { field: 'CITY_NAME', description: 'Cidade' },
          { field: 'TOTAL_ROYALTIES', description: 'Valor Total' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={project => ({
          CODE: project.CODE,
          ACCOUNT_NAME: () => (
            <>
              <strong>
                {project.ACCOUNT_NAME}{' '}
                {user.TYPE_KEY_ === 'ROOT' && `(${project.ACCOUNT_GROUP_NAME})`}
              </strong>{' '}
              <br />
              {project.NAME}
            </>
          ),
          CREATED_AT: project.DATE_CREATED_AT_FORMAT,
          FINISHED_AT: project.DATE_FINISHED_AT_FORMAT,
          STEP_DESCRIPTION: getProjectStep(project),
          STATUS: () => loadProjectStatus(project.ID, project.STAGES),
          CLIENT_NAME: getClientNameByType(project),
          CITY_NAME: project.CITY_NAME
            ? `${project.CITY_NAME} / ${project.UF_ABBREVIATION}`
            : '',
          TOTAL_ROYALTIES: `R$ ${numberFormat(
            project.TOTAL_ROYALTIES,
            2,
            ',',
            '.'
          )}`,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                color="blue"
                title="Editar projeto"
                onClick={() => history.push(`/project/${project.ID}`)}
              >
                <Icon name="pencil" />
              </Button>

              <Dropdown
                icon="cog"
                floating
                title="Ações"
                button
                className="mini orange icon"
                direction="left"
              >
                <Dropdown.Menu>
                  <Dropdown.Header content="Ações" />
                  <Dropdown.Divider />

                  <Dropdown.Item
                    icon="history"
                    text="Histórico do projeto"
                    title="Histórico do projeto"
                    onClick={() =>
                      openModal('projectHistory', {
                        data: {
                          ID: project.ID,
                        },
                      })
                    }
                  />

                  <Dropdown.Item
                    color="red"
                    icon="trash"
                    text="Remover projeto"
                    title="Remover projeto"
                    onClick={() => handleProjectDelete(project.ID)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </>
          ),
        })}
        data={projects}
        loading={isLoading || isLoadingStatus || isLoadingFilters}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          if (!isEqual(pagination, newPagination)) {
            setPagination(newPagination);

            searchProjects(searchFilter, newPagination);
          }
        }}
      />
    );
  }

  function renderProjectsContainer() {
    return (
      <Container>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading || isLoadingStatus || isLoadingFilters,
                })}
                type="button"
                disabled={isLoading || isLoadingStatus || isLoadingFilters}
                onClick={() => searchProjects(searchFilter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            className="middle aligned"
            textAlign="right"
            mobile={16}
            table={8}
            computer={4}
          >
            <span>Registros por página:</span>
          </Grid.Column>

          <Grid.Column mobile={16} table={8} computer={2}>
            <select
              className="ui dropdown fluid"
              onChange={e => {
                searchProjects(searchFilter, {
                  ...pagination,
                  limit: e.target.value,
                });
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </Grid.Column>

          <Grid.Column mobile={16} table={8} computer={2}>
            <ProjectDrawerFilter
              initialData={searchFilter}
              loading={isLoading || isLoadingStatus || isLoadingFilters}
              onSubmit={appliedFilters => {
                setSearchFilter(appliedFilters);
              }}
              onToggle={() => {
                setShowOverlay(show => !show);
              }}
              onLoadFilters={loadedFilterData => {
                setIsLoadingFilters(false);
                setFilterData(loadedFilterData);
              }}
            />
          </Grid.Column>
        </Grid>

        {transformedFilters.length > 0 && !isLoadingFilters && (
          <Grid style={{ marginTop: -20, marginBottom: 15 }}>
            <Grid.Column mobile={16}>
              <AppliedFilters filters={transformedFilters} />
            </Grid.Column>
          </Grid>
        )}

        {isLoadingFilters && (
          <Grid style={{ marginTop: -20, marginBottom: 15 }}>
            <Grid.Column mobile={16}>
              <AppliedFilters filters={[{ key: 'Carregando', data: '...' }]} />
            </Grid.Column>
          </Grid>
        )}

        {showTable ? renderProjectsWithTable() : renderProjectsWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading || isLoadingStatus || isLoadingFilters}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      {showOverlay && <Overlay />}

      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar projetos </span>
            <div>
              {!isMobile && (
                <button
                  type="button"
                  className="ui button icon"
                  title="Alterar visualização"
                  onClick={() => setShowTable(!showTable)}
                >
                  <i
                    className={classNames('mdi icon', {
                      'mdi-table': showTable,
                      'mdi-cards': !showTable,
                    })}
                  />
                </button>
              )}
              <button
                type="button"
                className="ui button green"
                onClick={() => history.push('/project')}
              >
                Novo projeto
              </button>
            </div>
          </div>

          <SummaryContainer columns={5}>
            {(isLoading || isLoadingStatus || isLoadingFilters) &&
              times(5, i => <Skeleton height={65} key={i} />)}

            {!isLoading && !isLoadingStatus && !isLoadingFilters && (
              <>
                <SummaryCard>
                  <strong>
                    <Icon name="dollar" /> Valor total
                  </strong>
                  <p>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(summary.TOTAL_PROJECT || 0)}{' '}
                    ({numberFormat(summary.QTTY || 0, 0)})
                  </p>
                </SummaryCard>

                <SummaryCard color="grey">
                  <strong>
                    <Icon name="cogs" /> Em orçamento
                  </strong>
                  <p>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(summary.TOTAL_PROJECT_BUDGET || 0)}{' '}
                    ({numberFormat(summary.QTTY_BUDGET || 0, 0)})
                  </p>
                </SummaryCard>

                <SummaryCard color="yellow">
                  <strong>
                    <Icon name="clock" /> Em execução
                  </strong>
                  <p>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(summary.TOTAL_PROJECT_PROGRESS || 0)}{' '}
                    ({numberFormat(summary.QTTY_PROGRESS || 0, 0)})
                  </p>
                </SummaryCard>

                <SummaryCard color="green">
                  <strong>
                    <Icon name="check" /> Finalizado
                  </strong>

                  <p>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(summary.TOTAL_PROJECT_FINALIZED || 0)}{' '}
                    ({numberFormat(summary.QTTY_FINALIZED || 0, 0)})
                  </p>
                </SummaryCard>

                <SummaryCard color="red">
                  <strong>
                    <Icon name="close" /> Cancelado
                  </strong>
                  <p>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(summary.TOTAL_PROJECT_CANCELED || 0)}{' '}
                    ({numberFormat(summary.QTTY_CANCELED || 0, 0)})
                  </p>
                </SummaryCard>
              </>
            )}
          </SummaryContainer>

          <div className="ui attached bottom segment">
            {renderProjectsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
