import React, { useState, useEffect, useCallback, useContext } from 'react';
import useForm from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import queryString from 'query-string';
import swal from 'sweetalert';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

import { Container } from './styles';

export default function Reset() {
  const history = useHistory();
  const location = useLocation();

  const { user } = useContext(AuthContext);
  const { register, handleSubmit, errors, watch } = useForm();

  const [loading, setLoading] = useState(false);

  const query = queryString.parse(location.search);
  const activateWithoutVoucher = query.pass && query.pass === 'default';

  const voucherCheck = useCallback(async () => {
    setLoading(true);

    const { voucher } = query;

    const { data: response } = await api({
      method: 'POST',
      url: 'voucher/check',
      params: {
        voucher,
      },
    });

    if (response.status !== 0) {
      const { title, text, type } = response.message;
      swal(title, text, type).then(() => history.push('/login'));
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (!activateWithoutVoucher) {
      voucherCheck();
    }
  }, [voucherCheck]);

  async function onSubmit(data) {
    setLoading(true);

    const { voucher } = query;

    const { data: response } = await api({
      method: 'POST',
      url: activateWithoutVoucher
        ? 'credential/password/renewdefault'
        : 'credential/password/renew',
      params: {
        ...data,
        voucher: !activateWithoutVoucher ? voucher : null,
        credential_id: user.ID,
      },
    });

    const { title, text, type } = response.message;

    swal(title, text, type).then(() => {
      if (!activateWithoutVoucher) {
        history.push('/login');
      } else {
        history.push('/');
      }
    });

    setLoading(false);
  }

  return (
    <Container>
      <form className="ui form segment" onSubmit={handleSubmit(onSubmit)}>
        <div className="field required">
          <label>Nova senha</label>
          <input
            type="password"
            name="pass"
            placeholder="********"
            ref={register({ required: true })}
          />
          {errors.pass && <span>Nova senha é obrigatória!</span>}
        </div>
        <div className="field required">
          <label>Confirmar senha</label>
          <input
            type="password"
            name="newpass"
            placeholder="********"
            ref={register({
              required: true,
              validate: value => {
                return value === watch('pass');
              },
            })}
          />
          {errors.newpass && <span>As duas senhas deverão ser iguais!</span>}
        </div>

        <button
          className={classNames('ui fluid button yellow', {
            loading,
          })}
          disabled={loading}
          type="submit"
        >
          Confirmar senha
        </button>

        {!activateWithoutVoucher && (
          <p>
            <Link to="/login">Clique aqui para voltar ao login.</Link>
          </p>
        )}
      </form>
    </Container>
  );
}
