import styled from 'styled-components';

export const Container = styled.div`
  /* height: 100%;
  width: 100%;
  margin: 30px auto; */

  .sidebar {
    padding: 10px;

    .drawer-title {
      display: flex;
      justify-content: space-between;

      .close-drawer-btn {
        background: transparent;
        border: none;
      }
    }

    label {
      text-align: left;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;

  animation-name: fadeIn;
  animation-duration: 0.2s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.5;
    }
  }
`;
