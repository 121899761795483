import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalRoyaltyStatusSelect({
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [receipt, setReceipt] = useState(data.RECEIPT || '');

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: '/project/royalties/update',
      params: {
        royalties_id: data.ID,
        royalties: JSON.stringify({
          RECEIPT: receipt,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar o item: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>Editar situação do pagamento</Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Situação</label>
            <select value={receipt} onChange={e => setReceipt(e.target.value)}>
              <option value="T">Recebido</option>
              <option value="F">A receber</option>
            </select>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
