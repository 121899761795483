import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function Search() {
  const { openModal } = useContext(ModalContext);

  const history = useHistory();

  const [materials, setMaterials] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter, setFilter] = useState({
    LIMIT: '10',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchMaterials = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: '/material/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setMaterials(responseSearch.data);
    } else {
      setMaterials([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchMaterials(filter, newPagination);
    }, 1000)
  ).current;

  useEffect(() => {
    searchMaterials(filter, pagination);
  }, [searchMaterials]);

  useEffect(() => {
    searchMaterials(filter, {
      ...pagination,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchMaterials(filter, pagination);
  }

  function handlePaginationChange(e, { activePage }) {
    searchMaterials(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function handleMaterialDelete(materialId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um material permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'material/delete',
          params: {
            material_id: JSON.stringify([materialId]),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Material excluído com sucesso!');
          searchMaterials(filter, pagination);
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function onConfirmModalFilter(data) {
    const newOrderBy = [];

    setFilter({ ...filter, data });

    newOrderBy.push({
      field: data.FIELD,
      mode: data.ORDER,
    });

    searchMaterials(
      { ...filter, data },
      {
        ...pagination,
        ...{
          limit: data.LIMIT,
          orderby: newOrderBy,
        },
      }
    );
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários materiais permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const materialsToDelete = materials
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!materialsToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um material para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'material/delete',
          params: {
            material_id: JSON.stringify(materialsToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Materiais excluídos com sucesso!');
          searchMaterials(filter, pagination);
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () => materials.length && materials.every(m => m.uiChecked === true),
    [materials]
  );

  function handleSelectAll(e, { checked }) {
    setMaterials(oldMaterial =>
      oldMaterial.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(materialId) {
    setMaterials(oldMaterial =>
      oldMaterial.map(item => {
        return item.ID === materialId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function rendermaterialsWithTable() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'DESCRIPTION', description: 'Descrição' },
          { field: 'PRICE', description: 'Valor unitário' },
          { field: 'CREATE_BY_NAME', description: 'Responsável' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={material => ({
          ID: () => (
            <Checkbox
              checked={!!material.uiChecked}
              onChange={() => handleSelect(material.ID)}
            />
          ),
          DESCRIPTION: material.DESCRIPTION,
          PRICE: `R$ ${numberFormat(material.PRICE, 2, ',', '.')}`,
          CREATE_BY_NAME: material.CREATE_BY_NAME,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                title="Histórico material"
                color={material.ENERGY_BILL_QTTY > 0 ? 'green' : 'orange'}
                onClick={() => history.push(`/material/${material.ID}/history`)}
              >
                <Icon name="file alternate" /> {material.ENERGY_BILL_QTTY}
              </Button>

              <Button
                size="mini"
                icon
                color="blue"
                title="Editar material"
                onClick={() => {
                  openModal('materialAdd', {
                    mode: 'edit',
                    data: {
                      ID: material.ID,
                      PRICE: material.PRICE,
                      DESCRIPTION: material.DESCRIPTION,
                    },
                    onConfirm: () => searchMaterials(filter, pagination),
                  });
                }}
              >
                <Icon name="pencil" />
              </Button>

              <Button
                size="mini"
                icon
                color="red"
                title="Remover material"
                onClick={() => handleMaterialDelete(material.ID)}
              >
                <Icon name="trash" />
              </Button>
            </>
          ),
        })}
        data={materials}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchMaterials(filter, { ...newPagination, keyword });
        }}
      />
    );
  }

  function rendermaterialsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!materials.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            <button
              type="button"
              className="ui button green icon"
              title="Novo material"
              onClick={() => {
                openModal('materialAdd', {
                  mode: 'add',
                  onConfirm: () => searchMaterials(filter, pagination),
                });
              }}
            >
              <i className="mdi mdi-plus icon" /> Novo material
            </button>
          </Grid.Column>
        </Grid>

        {rendermaterialsWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar materiais </span>
          </div>

          <div className="ui attached bottom segment">
            {rendermaterialsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
