import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
  Checkbox,
  Tab,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import BillCard from '~/components/Card/Bill';
import api from '~/services/api';

import useProjectSelf from '../../hooks/useProjectSelf';

export default function ProjectBillSelect({ projectId }) {
  const history = useHistory();

  const [project, , updateProject] = useProjectSelf(projectId);

  const [showTable, setShowTable] = useState(!isMobile);

  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter, setFilter] = useState({
    PROJECT_ID: projectId,
    CLIENT_ID: project.CLIENT_ID,
  });

  useEffect(() => {
    setFilter(oldFilter => ({
      ...oldFilter,
      CLIENT_ID: project.CLIENT_ID,
    }));
  }, [project]);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const searchBills = useCallback(
    useRef(
      throttle(async (filters, pag) => {
        setIsLoading(true);

        const { data: response } = await api({
          method: 'post',
          url: 'energy/bill/search',
          params: {
            filter: filters,
            pagination: pag,
          },
        });

        if (response.status === 0) {
          setBills(response.data);
          setPagination(response.pagination);
        } else {
          toast.error(response.message.text);
          setBills([]);
        }

        setIsLoading(false);
      }, 1000)
    ).current,
    []
  );

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchBills(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    if (filter.CLIENT_ID) {
      searchBills(filter, pagination);
    }
  }, [searchBills, filter]);

  const [isSaving, setIsSaving] = useState(false);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    const newPagination = {
      ...pagination,
      page: activePage,
    };

    searchBills(filter, newPagination);
  }

  const allSearchSelected = useMemo(
    () => bills.length && bills.every(b => b.uiChecked === true),
    [bills]
  );

  const [selectedBills, setSelectedBills] = useState([]);

  async function handleSave() {
    setIsSaving(true);

    const selectedToAdd = bills
      .filter(item => item.uiChecked === true)
      .map(item => item.ID);

    if (selectedToAdd.length === 0) {
      swal(
        'Atenção',
        'Nenhuma conta será adicionada ao projeto, pois nenhuma foi selecionada!',
        'warning'
      ).then(() => {
        setIsSaving(false);

        history.push(`/project/${projectId}/bills/search`);
      });

      return;
    }

    const { data: responseAddBill } = await api({
      method: 'post',
      url: 'project/energy/bill/add',
      params: {
        project_id: projectId,
        energy_bill: JSON.stringify(selectedToAdd),
      },
    });

    if (responseAddBill.status === 0) {
      toast.success('Contas adicionadas com sucesso!');
      history.push(`/project/${projectId}/bills/search`);
      await updateProject();
    } else {
      toast.error(responseAddBill.message.text);
    }
  }

  function handleSelectSingleBill(bill) {
    if (!selectedBills.some(item => item.ID === bill.ID)) {
      setSelectedBills([bill, ...selectedBills]);
    }
  }

  function renderBillsWithCard() {
    return (
      <Grid centered>
        {bills.length > 0 &&
          !isLoading &&
          bills.map(bill => (
            <Grid.Column key={bill.ID} mobile={16} tablet={8} computer={4}>
              <BillCard
                type="select"
                handleSelect={b => handleSelectSingleBill(b)}
                bill={bill}
              />
            </Grid.Column>
          ))}

        {!isLoading && bills.length === 0 && (
          <Grid.Column>
            <Message content="Nenhuma conta adicionada!" />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando contas..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderBillsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">
              <Checkbox
                title="Selecionar"
                onChange={(e, { checked }) =>
                  setBills(oldBills =>
                    oldBills.map(item => ({
                      ...item,
                      uiChecked: checked,
                    }))
                  )
                }
                checked={allSearchSelected}
              />
            </th>
            <th className="center aligned">Código</th>
            <th className="center aligned">Descrição</th>
            <th className="center aligned">Valor TUSD</th>
            <th className="center aligned">Valor TE</th>
            <th className="center aligned">Grupo</th>
            <th className="center aligned">
              Taxa de <br /> disponibilidade
            </th>
            <th className="center aligned">HSP</th>
            <th className="center aligned">Tipo de rede</th>
            <th className="center aligned">Consumo médio mensal (KW/h)</th>
            <th className="center aligned">Valor médio mensal (KW/h)</th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {bills.length > 0 &&
            !isLoading &&
            bills.map(bill => {
              return (
                <tr
                  key={bill.ID}
                  className={classNames({
                    active: bill.uiChecked,
                  })}
                >
                  <td className="center aligned">
                    <div className="ui checkbox">
                      <Checkbox
                        checked={bill.uiChecked}
                        onChange={() =>
                          setBills(oldBills =>
                            oldBills.map(item => {
                              return item.ID === bill.ID
                                ? { ...item, uiChecked: !item.uiChecked }
                                : item;
                            })
                          )
                        }
                      />
                    </div>
                  </td>
                  <td className="center aligned">{bill.UNIT_CODE || '-'}</td>
                  <td className="center aligned">
                    {bill.PROPERTY_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.TOTAL_VALUE_TAX_TUSD, 4, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.TOTAL_VALUE_TAX_TE, 4, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {bill.GROUND_GROUP_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.AVAILABILITY_RATE, 0, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.HOURLY_SUNNY_PEAK, 3, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {bill.ENERGY_TYPE
                      ? `Trifásico ${numberFormat(
                          bill.ENERGY_TYPE,
                          0,
                          ',',
                          '.'
                        )}v`
                      : '-'}
                  </td>

                  <td className="center aligned">
                    {numberFormat(bill.KWH_MEAN, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.KWH_TOTAL, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    <Button
                      size="mini"
                      icon
                      color="blue"
                      title="Editar conta"
                      onClick={() =>
                        history.push(
                          `/client/${project.CLIENT_ID}/bill/${bill.ID}/identification?backTo=/project/${projectId}/bills/select&mode=edit`
                        )
                      }
                    >
                      <Icon name="pencil" />
                    </Button>
                  </td>
                </tr>
              );
            })}

          {!isLoading && bills.length === 0 && (
            <tr>
              <td colSpan="14">
                <Message
                  content={`Nenhuma conta encontrada!  ${
                    project.CLIENT_ID
                      ? 'Atualize os filtros e pesquisa novamente.'
                      : 'Vincule um cliente ao projeto para buscar suas contas'
                  }`}
                />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="14">
                <Message color="blue" content="Buscando contas..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderTabSearch() {
    return (
      <Tab.Pane>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={() => searchBills(filter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <button
              type="button"
              disabled={isSaving}
              className={classNames('ui button red icon', {
                loading: isSaving,
              })}
              onClick={() => history.push(`/project/${projectId}/bills/search`)}
            >
              Cancelar
            </button>

            {!isMobile && (
              <button
                type="button"
                className="ui button icon"
                title="Alterar visualização"
                onClick={() => setShowTable(!showTable)}
              >
                <i
                  className={classNames('mdi icon', {
                    'mdi-table': showTable,
                    'mdi-cards': !showTable,
                  })}
                />
              </button>
            )}

            <button
              type="button"
              disabled={isSaving}
              className={classNames('ui button blue icon', {
                loading: isSaving,
              })}
              onClick={() =>
                history.push(
                  `/client/${project.CLIENT_ID}/bill/new?projectId=${projectId}&backTo=/project/${projectId}/bills/search`
                )
              }
            >
              <i className="mdi mdi-plus icon" /> Nova conta
            </button>

            <button
              type="button"
              disabled={isSaving}
              className={classNames('ui button green icon', {
                loading: isSaving,
              })}
              onClick={handleSave}
            >
              Adicionar
            </button>
          </Grid.Column>
        </Grid>

        {showTable ? renderBillsWithTable() : renderBillsWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={bills.loading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </Tab.Pane>
    );
  }

  return (
    <>
      <h4 className="ui horizontal divider">Selecionar contas de energia</h4>

      <Tab
        panes={[
          {
            menuItem: 'Pesquisar contas',
            render: renderTabSearch,
          },
        ]}
      />
    </>
  );
}
