import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import numberFormat from 'locutus/php/strings/number_format';
import { Pagination, Icon, Grid, Button } from 'semantic-ui-react';

import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

function SimulationSearch() {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [simulations, setSimulations] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [summary, setSummary] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [orderByChanged, setOrderByChanged] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [filter, setFilter] = useState({});
  const [error, setError] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const searchSimulations = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/simulation/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setSimulations(responseSearch.data);
      setSummary(responseSearch.summary);
    } else {
      setSimulations([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const getGroupList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupList(response.data);
    }
  }, []);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  useEffect(() => {
    searchSimulations(filter, pagination);
  }, [searchSimulations]);

  useEffect(() => {
    searchSimulations(filter, {
      ...pagination,
    });

    setOrderByChanged(false);
  }, [orderByChanged]);

  useEffect(() => {
    searchSimulations(filter, {
      ...pagination,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function isPastDate(date1, date2) {
    return new Date(date2) < new Date(date1);
  }

  function handlePaginationChange(e, { activePage }) {
    searchSimulations(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleFilterChange(key, value) {
    if (key === 'DATE_TIME_FINAL') {
      setError(isPastDate(filter.DATE_TIME_START, value));
    }

    setFilter(oldFilter => ({
      ...oldFilter,
      [key]: value,
    }));
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function getClientNameByType(simulation) {
    return simulation.CLIENT_TYPE !== 'L' ? (
      <span>{simulation.CLIENT_NAME || '-'}</span>
    ) : (
      <>
        <span>{simulation.CLIENT_COMPANY_NAME || '-'}</span>
        <br />
        <span>({simulation.CLIENT_FANTASY_NAME || '-'})</span>
      </>
    );
  }

  function renderSimulationsWithTable() {
    return (
      <Table
        cols={[
          { field: 'ACCOUNT_GROUP_NAME', description: 'Grupo' },
          { field: 'ACCOUNT_NAME', description: 'Conta' },
          { field: 'CREATED_AT', description: 'Data e hora (criação)' },
          { field: 'CLIENT_NAME', description: 'Cliente' },
          { field: 'PHONE', description: 'Telefone (WhatsApp)' },
          { field: 'BILL_VALUE', description: 'Conta de Luz' },
          { field: 'MODULES', description: 'Qtde. Módulos' },
          { field: 'INVESTIMENT', description: 'Investimento' },
          { field: 'MONTHLY_SAVINGS', description: 'Economia' },
          { field: 'SAVING_25YEARS', description: 'Retorno 25 Anos' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={simulation => ({
          ACCOUNT_GROUP_NAME: simulation.ACCOUNT_GROUP_NAME,
          ACCOUNT_NAME: simulation.ACCOUNT_NAME,
          CREATED_AT: `${simulation.DATE_CREATED_AT_FORMAT} - ${simulation.HOUR_CREATED_AT_FORMAT}`,
          CLIENT_NAME: () => (
            <>
              {getClientNameByType(simulation)} <br />
              <em>{simulation.EMAIL}</em>
            </>
          ),
          PHONE: simulation.PHONE,
          BILL_VALUE: `R$ ${numberFormat(simulation.BILL_VALUE, 2, ',', '.')}`,
          MODULES: `${numberFormat(simulation.MODULES, 0, ',', '.')}`,
          INVESTIMENT: `
            R$ ${numberFormat(simulation.INVESTIMENT, 2, ',', '.')}
          `,
          MONTHLY_SAVINGS: `${numberFormat(
            simulation.MONTHLY_SAVINGS_PERC,
            0,
            ',',
            '.'
          )}%`,
          SAVING_25YEARS: `
            R$ ${numberFormat(simulation.SAVING_25YEARS, 2, ',', '.')}`,
          ACTIONS: () => (
            <Link
              to={{
                pathname: `/simulation/${simulation.ID}`,
                search: '?backTo=/simulations',
              }}
              target="_blank"
            >
              <Button size="mini" icon color="blue" title="Ver detalhes">
                <Icon name="search" />
              </Button>
            </Link>
          ),
        })}
        data={simulations}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchSimulations(filter, newPagination);
        }}
      />
    );
  }

  function renderAccountSimulationsWithTable() {
    return (
      <Table
        cols={[
          { field: 'CREATED_AT', description: 'Data e hora (criação)' },
          { field: 'CLIENT_NAME', description: 'Cliente' },
          { field: 'PHONE', description: 'Telefone (WhatsApp)' },
          { field: 'BILL_VALUE', description: 'Conta de Luz' },
          { field: 'MODULES', description: 'Qtde. Módulos' },
          { field: 'INVESTIMENT', description: 'Investimento' },
          { field: 'MONTHLY_SAVINGS', description: 'Economia' },
          { field: 'SAVING_25YEARS', description: 'Retorno 25 Anos' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={simulation => ({
          CREATED_AT: `${simulation.DATE_CREATED_AT_FORMAT} - ${simulation.HOUR_CREATED_AT_FORMAT}`,
          CLIENT_NAME: () => (
            <>
              {getClientNameByType(simulation)} <br />
              <em>{simulation.EMAIL}</em>
            </>
          ),
          PHONE: simulation.PHONE,
          BILL_VALUE: `R$ ${numberFormat(simulation.BILL_VALUE, 2, ',', '.')}`,
          MODULES: `${numberFormat(simulation.MODULES, 0, ',', '.')}`,
          INVESTIMENT: `
            R$ ${numberFormat(simulation.INVESTIMENT, 2, ',', '.')}
          `,
          MONTHLY_SAVINGS: `${numberFormat(
            simulation.MONTHLY_SAVINGS_PERC,
            0,
            ',',
            '.'
          )}%`,
          SAVING_25YEARS: `
            R$ ${numberFormat(simulation.SAVING_25YEARS, 2, ',', '.')}`,
          ACTIONS: () => (
            <Link
              to={{
                pathname: `/simulation/${simulation.ID}`,
                search: '?backTo=/simulations',
              }}
              target="_blank"
            >
              <Button size="mini" icon color="blue" title="Ver detalhes">
                <Icon name="search" />
              </Button>
            </Link>
          ),
        })}
        data={simulations}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchSimulations(filter, newPagination);
        }}
      />
    );
  }

  function renderSimulationsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={4}>
            <div className="ui small fluid left labeled input">
              <label className="ui label ">Data inicial</label>
              <input
                type="date"
                placeholder="Data e hora inicial"
                onChange={e =>
                  handleFilterChange('DATE_TIME_START', e.target.value)
                }
              />
            </div>
          </Grid.Column>

          <Grid.Column mobile={16} table={8} computer={4}>
            <Grid.Row>
              <div className="ui small fluid left labeled input">
                <label className="ui label">Data final</label>
                <input
                  type="date"
                  min={filter.DATE_TIME_START || undefined}
                  placeholder="Data e hora final"
                  onChange={e =>
                    handleFilterChange('DATE_TIME_FINAL', e.target.value)
                  }
                />
              </div>
            </Grid.Row>
            {error && (
              <span className="field-error">
                A data final precisa ser maior que a inicial
              </span>
            )}
          </Grid.Column>

          {user.TYPE_KEY_ === 'ROOT' && (
            <Grid.Column mobile={16} table={8} computer={3}>
              <div className="ui fluid left labeled input">
                <label className="ui label">Grupo</label>
                <select
                  className="ui dropdown fluid"
                  disabled={groupList.length === 0}
                  readOnly={groupList.length === 1}
                  value={filter.GROUP}
                  onChange={e =>
                    handleFilterChange('ACCOUNT_GROUP_ID', e.target.value)
                  }
                >
                  <option value="*">Todos</option>
                  {groupList.map(type => (
                    <option key={type.ID} value={type.ID}>
                      {type.NAME}
                    </option>
                  ))}
                </select>
              </div>
            </Grid.Column>
          )}

          {user.TYPE_KEY_ !== 'ACCOUNT' && (
            <Grid.Column mobile={16} table={8} computer={3}>
              <div className="ui fluid left labeled input">
                <label className="ui label">Conta</label>
                <input
                  type="text"
                  placeholder="Conta"
                  value={filter.ACCOUNT_NAME}
                  onChange={e =>
                    handleFilterChange('ACCOUNT_NAME', e.target.value)
                  }
                />
              </div>
            </Grid.Column>
          )}

          <Grid.Column mobile={16} table={8} computer={2}>
            <Button
              icon
              title="Filtrar"
              className="ui fluid"
              disabled={error}
              onClick={() => searchSimulations(filter, pagination)}
            >
              <Icon name="check" /> Filtrar
            </Button>
          </Grid.Column>

          <Grid.Row>
            <Grid.Column
              className="middle aligned"
              textAlign="right"
              mobile={16}
              table={8}
              computer={14}
              floated="right"
            >
              <span>Registros por página:</span>
            </Grid.Column>

            <Grid.Column mobile={16} table={8} computer={2} floated="right">
              <select
                className="ui dropdown fluid"
                onChange={handleLimitChange}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {user.TYPE_KEY_ === 'ACCOUNT'
          ? renderAccountSimulationsWithTable()
          : renderSimulationsWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title"> Simulações realizadas </span>
          <div>
            <button
              type="button"
              className="ui button green"
              onClick={() => history.push('/simulation')}
            >
              Nova simulação
            </button>
          </div>
        </div>

        <Grid centered>
          <Grid.Column mobile={16} table={16} computer={16}>
            <div className="ui centered tiny statistics m-b m-t-md">
              <div className="ui statistic">
                <div className="value">
                  {numberFormat(summary.QTTY, 0, ',', '.')}
                </div>
                <div className="label">Número de simulações</div>
              </div>
              <div className="ui statistic">
                <div className="value">
                  R$ {numberFormat(summary.TOTAL_INVESTIMENT, 2, ',', '.')}
                </div>
                <div className="label">Valor total do investimento</div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <div className="ui attached bottom segment">
          {renderSimulationsContainer()}
        </div>
      </div>
    </div>
  );
}

export default SimulationSearch;
