import React from 'react';

import numberFormat from 'locutus/php/strings/number_format';
import { Button, Card, Icon } from 'semantic-ui-react';

export default function ItemCard({
  item = {},
  type,
  handleEdit,
  handleSelect,
  handleDelete,
}) {
  function getButtonsByType() {
    switch (type) {
      case 'KPI':
        return null;
      case 'select':
        return (
          <>
            <Button
              size="mini"
              icon
              color="green"
              title="Selecionar item"
              onClick={handleSelect}
            >
              <Icon name="check" /> Selecionar
            </Button>

            <Button
              size="mini"
              icon
              color="blue"
              title="Editar item"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>
            <Button
              size="mini"
              icon
              color="red"
              title="Remover item"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );

      case 'projectSelected':
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar item"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>
            <Button
              size="mini"
              icon
              color="red"
              title="Remover item"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
      default:
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar item"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>
            <Button
              size="mini"
              icon
              color="red"
              title="Remover item"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
    }
  }
  return (
    <Card fluid className="text-center">
      <Card.Content>
        <Card.Header>
          {item.DESCRIPTION || item.ITEM_DESCRIPTION || ''}
        </Card.Header>
        {item.PROVIDER_DESCRIPTION && (
          <Card.Meta> {item.PROVIDER_DESCRIPTION} </Card.Meta>
        )}
      </Card.Content>

      {type !== 'KPI' && (
        <>
          {item.QUANTITY && (
            <Card.Content extra>
              Quantidade: <b>{numberFormat(item.QUANTITY, 2, ',', '.')}</b>
            </Card.Content>
          )}

          {item.UNIT_VALUE && (
            <Card.Content extra>
              Valor unitário:{' '}
              <b>R$ {numberFormat(item.UNIT_VALUE, 2, ',', '.')}</b>
            </Card.Content>
          )}

          {type === 'select' && (
            <Card.Content extra>
              Valor unitário: <b>R$ {numberFormat(item.PRICE, 2, ',', '.')}</b>
            </Card.Content>
          )}
        </>
      )}

      {item.TOTAL && (
        <Card.Content extra>
          Total: <b>R$ {numberFormat(item.TOTAL, 2, ',', '.')}</b>
        </Card.Content>
      )}

      {type !== 'KPI' && (
        <Card.Content extra>{getButtonsByType()}</Card.Content>
      )}
    </Card>
  );
}
