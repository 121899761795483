import React, { useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/chart/bar';
import numberFormat from 'locutus/php/strings/number_format';
import { Grid } from 'semantic-ui-react';

import api from '~/services/api';

import InvestmentSection from './components/InvestmentSection';

export default function SubPageSimulation({ project, projectId }) {
  const [comparativoData, setComparativoData] = useState([]);

  const [projecaoData, setProjecaoData] = useState([]);

  const [estimativaData, setEstimativaData] = useState([]);

  const getProjectCharts = useCallback(async () => {
    const { data: responseBill } = await api({
      method: 'post',
      url: 'project/charts/energy/bill/compare',
      params: { project_id: projectId },
    });

    if (responseBill.status === 0) {
      const { value, color } = responseBill.data;
      const data = value.map((item, i) => ({
        value: item,
        itemStyle: { color: color[i] },
      }));

      setComparativoData(data);
    }

    const { data: responseProjection } = await api({
      method: 'post',
      url: 'project/charts/projection',
      params: { project_id: projectId },
    });

    if (responseProjection.status === 0) {
      const { value, color } = responseProjection.data;
      const data = value.map((item, i) => ({
        value: item,
        itemStyle: { color: color[i] },
      }));

      setProjecaoData(data);
    }

    const { data: responseProduction } = await api({
      method: 'post',
      url: 'project/charts/monthly/production',
      params: { project_id: projectId },
    });

    if (responseProduction.status === 0) {
      const { value, color } = responseProduction.data;
      const data = value.map((item, i) => ({
        value: item,
        itemStyle: { color: color[i] },
      }));

      setEstimativaData(data);
    }
  }, [projectId]);

  useEffect(() => {
    getProjectCharts();
  }, [getProjectCharts]);

  return (
    <>
      <h4 className="ui horizontal divider">Quantitativos</h4>

      <Grid>
        <Grid.Column mobile={16}>
          <div className="ui attached segment bg-grey">
            <div className="ui small centered six statistics">
              <div className="ui statistic">
                <span className="value">
                  <i className="mdi mdi-crop-square" />
                  {numberFormat(project.MODULE_NUMBER_TOTAL || 0, 0, ',', '.')}
                </span>
                <span className="label">Quantidade de módulos</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  <i className="mdi mdi-flash-circle" />
                  {numberFormat(project.SYSTEM_POWER_TOTAL || 0, 2, ',', '.')}
                </span>
                <span className="label">Potência do sistema (KWP)</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  <i className="mdi mdi-tape-measure" />
                  {numberFormat(project.REQUIRED_AREA_TOTAL || 0, 0, ',', '.')}
                </span>
                <span className="label">Área necessária (m2)</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  <i className="mdi mdi-weight-kilogram" />
                  {numberFormat(
                    project.REQUIRED_WEIGHT_TOTAL || 0,
                    0,
                    ',',
                    '.'
                  )}
                </span>
                <span className="label">Peso total (Kg)</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  <i className="mdi mdi-flash" />
                  {numberFormat(
                    project.ESTIMATED_MONTHLY_ENERGY_TOTAL || 0,
                    0,
                    ',',
                    '.'
                  )}
                </span>
                <span className="label">Energia mensal estimada (KWH/Mês)</span>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <h4 className="ui horizontal divider">Financeiros</h4>

      <Grid>
        <Grid.Column mobile={16}>
          <div className="ui attached segment bg-grey">
            <div className="ui one statistics centered m-b-sm">
              <div className="ui statistic">
                <span className="value">
                  R$ {numberFormat(project.TOTAL_ROYALTIES || 0, 2, ',', '.')}
                </span>
                <span className="label">Valor total do projeto</span>
              </div>
            </div>

            <div className="ui small centered five statistics">
              <div className="ui statistic">
                <span className="value">
                  {project.RETURN_TIME_YEARS_DESC || '-'}
                </span>
                <span className="label">Tempo de retorno do investimento</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  R$ {numberFormat(project.NEW_KWH_VALUE || 0, 2, ',', '.')}
                </span>
                <span className="label">Novo valor do KWh</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  R$ {numberFormat(project.ENERGY_BILL_VALUE || 0, 2, ',', '.')}
                </span>
                <span className="label">Conta de energia antiga</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  R${' '}
                  {numberFormat(
                    project.NEW_ENERGY_BILL_VALUE || 0,
                    2,
                    ',',
                    '.'
                  )}
                </span>
                <span className="label">Nova conta de energia</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  {numberFormat(project.MONTHLY_SAVINGS_PERC || 0, 2, ',', '.')}{' '}
                  %
                </span>
                <span className="label">Economia mensal</span>
              </div>
              <div className="ui statistic">
                <span className="value">
                  R${' '}
                  {numberFormat(project.ENERGY_BILL_SAVINGS || 0, 2, ',', '.')}
                </span>
                <span className="label">
                  Economia da conta de energia em 25 anos
                </span>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <InvestmentSection projectId={projectId} project={project} />

      <Grid columns={2} stackable>
        <Grid.Column>
          <h4 className="ui horizontal divider">
            Comparativo (antes x depois)
          </h4>

          <ReactEchartsCore
            echarts={echarts}
            option={{
              grid: {
                right: '5px',
                left: '5px',
                containLabel: true,
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow', // 'line' | 'shadow'
                },
                formatter([params]) {
                  const { name, value } = params;
                  return `${name}: R$ ${numberFormat(value, 2, ',', '.')}`;
                },
              },
              xAxis: {
                type: 'category',
                data: ['Antes da Energia Solar', 'Depois da Energia Solar'],
                axisLabel: {
                  rotate: isMobile ? 25 : 0,
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  // formatter: 'R$ {value}',
                  formatter(value) {
                    return `R$ ${numberFormat(value, 2, ',', '.')}`;
                  },
                },
              },
              series: [
                {
                  data: comparativoData,
                  type: 'bar',
                  label: {
                    show: !isMobile,
                    formatter({ value }) {
                      return `R$ ${numberFormat(value, 2, ',', '.')}`;
                    },
                    position: 'top',
                  },
                },
              ],
            }}
            notMerge
            lazyUpdate
          />
        </Grid.Column>

        <Grid.Column>
          <h4 className="ui horizontal divider">Projeção para 25 anos</h4>

          {/* <div className="ui attached segment bg-grey"> */}
          <ReactEchartsCore
            echarts={echarts}
            option={{
              grid: {
                right: '0px',
                left: '0px',
                containLabel: true,
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow', // 'line' | 'shadow'
                },
                formatter([params]) {
                  const { name, value } = params;
                  return `${name}: <br> R$ ${numberFormat(value, 2, ',', '.')}`;
                },
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  rotate: isMobile ? 25 : 0,
                },
                data: [
                  'Antes da Energia Solar',
                  'Depois da Energia Solar',
                  'Economia',
                ],
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter(value) {
                    return `R$ ${numberFormat(value, 2, ',', '.')}`;
                  },
                },
              },
              series: [
                {
                  data: projecaoData,
                  type: 'bar',
                  label: {
                    show: !isMobile,
                    formatter({ value }) {
                      return `R$ ${numberFormat(value, 2, ',', '.')}`;
                    },
                    position: 'top',
                  },
                },
              ],
            }}
            notMerge
            lazyUpdate
          />
          {/* </div> */}
        </Grid.Column>
      </Grid>

      <h4 className="ui horizontal divider">Estimativa mensal de produção</h4>

      <Grid>
        <Grid.Column mobile={16}>
          <ReactEchartsCore
            echarts={echarts}
            option={{
              grid: {
                right: '0px',
                left: '0px',
                containLabel: true,
              },
              color: ['#062'],
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow', // 'line' | 'shadow'
                },
                formatter([params]) {
                  const { name, value } = params;
                  return `${name}: <br> ${numberFormat(value, 0, ',', '.')} KW`;
                },
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  rotate: isMobile ? 90 : 0,
                },
                data: [
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ],
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter(value) {
                    return `${numberFormat(value, 0, ',', '.')} KW`;
                  },
                },
              },
              series: [
                {
                  data: estimativaData,
                  type: 'bar',
                  label: {
                    show: !isMobile,
                    formatter({ value }) {
                      return `${numberFormat(value, 0, ',', '.')} KW`;
                    },
                    position: 'top',
                  },
                },
              ],
            }}
            notMerge
            lazyUpdate
          />
        </Grid.Column>
      </Grid>
    </>
  );
}
