import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import InputNumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import { Pagination, Icon, Grid, Button } from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const { openModal } = useContext(ModalContext);

  const history = useHistory();
  const query = useQuery();
  const [backTo] = useState(query.get('backTo'));

  const { groupId } = useParams();

  const [royalties, setRoyalties] = useState([]);

  const [group, setGroup] = useState({});

  const [configs, setConfigs] = useState([]);

  const [canChangePercent, setCanChangePercent] = useState(false);
  const [percent, setPercent] = useState(0);
  const [loadingPercent, setLoadingPercent] = useState(false);
  const [inputPercent, setInputPercent] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchConfigs = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'config/search',
      params: {
        pagination: JSON.stringify({
          page: 1,
          limit: 100,
          orderby: [{ field: 'ID', mode: 'ASC' }],
        }),
        filter: JSON.stringify({}),
      },
    });

    if (responseSearch.status === 0) {
      setConfigs(responseSearch.data);
    } else {
      setConfigs([]);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    searchConfigs();
  }, [searchConfigs]);

  function getConfigID(key) {
    const config = configs.find(c => c.KEY_ === key);
    if (config) return config.ID;

    return null;
  }

  function findKeyIn(data, key) {
    return data.find(c => c.CONFIG_KEY_ === key) || {};
  }

  const getGroupRoyaltiesPercent = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'config/value/search',
      params: {
        pagination: JSON.stringify({
          page: 1,
          limit: 100,
          orderby: [{ field: 'ID', mode: 'ASC' }],
        }),
        filter: JSON.stringify({
          ACCOUNT_GROUP_ID: groupId,
        }),
      },
    });

    if (responseSearch.status === 0) {
      const { VALUE } = findKeyIn(responseSearch.data, 'royalties.group');
      setPercent(VALUE);
    } else {
      setPercent(null);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    getGroupRoyaltiesPercent();
  }, [getGroupRoyaltiesPercent]);

  async function handleSaveConfig(configKey, value) {
    const { data: responseAdd } = await api({
      method: 'post',
      url: '/config/value/save',
      params: {
        value: {
          CONFIG_ID: getConfigID(configKey),
          ACCOUNT_GROUP_ID: groupId,
          VALUE: value,
        },
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);
      getGroupRoyaltiesPercent();
      setCanChangePercent(false);
    } else {
      toast.error(`${responseAdd.message.text}`);
    }
  }

  const searchAccountRoyalties = useCallback(
    async pag => {
      setIsLoading(true);

      const { data: responseSearch } = await api({
        method: 'get',
        url: 'config/value/search',
        params: {
          pagination: JSON.stringify(pag),
          filter: JSON.stringify({
            ALL_FROM_GROUP_ID: groupId,
          }),
        },
      });

      if (responseSearch.status === 0) {
        setRoyalties(responseSearch.data);
      } else {
        setRoyalties([]);
      }

      setPagination(responseSearch.pagination);

      setIsLoading(false);
    },
    [configs]
  );

  function handleDeleteAccountRoyalties(configValueId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir os royalties de uma conta permanentemente. Deseja mesmo realizar essa ação?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'config/value/delete',
          params: {
            config_value_id: [configValueId],
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Royalties excluídos com sucesso!');
          searchAccountRoyalties({ ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const setPaginationKeyword = useRef(
    debounce(
      key => searchAccountRoyalties({ ...pagination, keyword: key }),
      1000
    )
  ).current;

  useEffect(() => {
    if (configs.length > 0) {
      searchAccountRoyalties({ ...pagination, keyword });
    }
  }, [searchAccountRoyalties, configs]);

  useEffect(() => {
    if (limitChanged) {
      searchAccountRoyalties({ ...pagination });
    }

    setLimitChanged(false);
  }, [limitChanged]);

  useEffect(() => {
    if (canChangePercent && inputPercent) {
      inputPercent.focus();
    }
  }, [canChangePercent]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchAccountRoyalties({ ...pagination, keyword });
  }

  function handlePaginationChange(e, { activePage }) {
    searchAccountRoyalties({ ...pagination, page: activePage });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  async function handleSavePercent() {
    setLoadingPercent(true);
    await handleSaveConfig('royalties.group', percent);
    setLoadingPercent(false);
  }

  useEffect(() => {
    (async () => {
      const groupData = await getGroupSelf({ account_group_id: groupId });
      setGroup(groupData);
    })();
  }, [groupId]);

  async function getGroupSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'account/group/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  function renderStatusWithTable() {
    return (
      <Table
        cols={[
          { field: 'ACCOUNT_NAME', description: 'Conta' },
          { field: 'PERCENT', description: 'Royalties (%)' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={accountRoyalties => ({
          ACCOUNT_NAME: accountRoyalties.ACCOUNT_NAME,
          PERCENT: `${numberFormat(accountRoyalties.VALUE, 2, ',', '.')} %`,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                color="red"
                title="Editar royalties"
                onClick={() => {
                  // openModal('royaltiesAdd', {
                  //   mode: 'edit',
                  //   data: {
                  //     PERCENT: accountRoyalties.PERCENT,
                  //     ACCOUNT_ID: accountRoyalties.ACCOUNT_ID,
                  //     ROYALTIES_ID: accountRoyalties.ID,
                  //     ACCOUNT_GROUP_ID: groupId,
                  //   },
                  //   onConfirm: () => {
                  //     // searchAccountRoyalties(filter, { ...pagination, keyword });
                  //   },
                  // });
                  handleDeleteAccountRoyalties(accountRoyalties.ID);
                }}
              >
                <Icon name="trash" />
              </Button>
            </>
          ),
        })}
        data={royalties}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);
          searchAccountRoyalties({ ...newPagination, keyword });
        }}
      />
    );
  }

  function renderRoyaltiesContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui form centered">
              <div className="fields m-b-n text-center">
                <div className="field m">
                  <label>Royalties do grupo (%)</label>

                  <div className="ui action mini input">
                    <InputNumberFormat
                      type="tel"
                      disabled={!canChangePercent}
                      displayType="input"
                      thousandSeparator="."
                      decimalSeparator=","
                      value={percent}
                      suffix=" %"
                      decimalScale={2}
                      onValueChange={v => setPercent(v.floatValue)}
                      onBlur={handleSavePercent}
                      onKeyPress={e => e.key === 'Enter' && handleSavePercent()}
                      getInputRef={el => setInputPercent(el)}
                    />

                    {!canChangePercent && (
                      <button
                        type="button"
                        className="ui icon button yellow"
                        onClick={() => {
                          setCanChangePercent(true);
                        }}
                      >
                        <i className="mdi mdi-pencil icon" />
                      </button>
                    )}

                    {canChangePercent && (
                      <button
                        type="button"
                        disabled={loadingPercent}
                        className={classNames('ui icon button blue', {
                          loading: loadingPercent,
                        })}
                        onClick={handleSavePercent}
                      >
                        <i className="mdi mdi-content-save icon" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <button
              type="button"
              className="ui button green icon"
              title="Novo royaltie personalizado"
              onClick={() => {
                openModal('royaltiesAdd', {
                  mode: 'add',
                  data: {
                    CONFIG_ID: getConfigID('royalties.account'),
                    ACCOUNT_GROUP_ID: groupId,
                  },
                  onConfirm: () => searchAccountRoyalties(pagination),
                });
              }}
            >
              <i className="mdi mdi-plus icon" /> Novo royaltie personalizado
            </button>
          </Grid.Column>
        </Grid>

        {renderStatusWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title">
              Gerenciar royalties do grupo / personalizados por conta
              <br />
              <small>{group.NAME && `Grupo: ${group.NAME}`}</small>
            </span>

            {backTo && (
              <div>
                <button
                  className="ui button red"
                  type="button"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              </div>
            )}
          </div>

          <div className="ui attached bottom segment">
            {renderRoyaltiesContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
