import React, { useState, useEffect, useRef, useCallback } from 'react';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import swal from 'sweetalert';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

function FormRegister({ onSubmit, initialData }) {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: initialData,
  });

  const watchFinancialStatus = watch('FINANCIAL_STATUS');

  const inputNumberRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [isFreeAccount, setIsFreeAccount] = useState(
    initialData.FINANCIAL_STATUS === 'E'
  );

  const [stateSelected, setStateSelected] = useState(initialData.UF_ID);

  const [isFindingCEP, setIsFindingCEP] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [filteredCities, setFilteredCities] = useState(
    cityList.filter(city => city.UF_ID === stateSelected)
  );

  const [groupList, setGroupList] = useState([]);

  const getGroupList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupList(response.data);

      if (initialData.ACCOUNT_GROUP_ID) {
        setValue('ACCOUNT_GROUP_ID', initialData.ACCOUNT_GROUP_ID);
      }
    }
  }, []);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  useEffect(() => {
    let states = [];
    let cities = [];

    setIsLoading(true);

    async function getState() {
      const { data: uf } = await api({
        method: 'get',
        url: 'uf/list',
        params: {
          filter: JSON.stringify({}),
        },
      });

      if (uf.status === 0) {
        states = uf.data;
      } else {
        swal('Ops!', 'Houve um erro ao tentar carregar os estados!', 'error');
      }
    }

    async function getCity() {
      const { data: city } = await api({
        method: 'get',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({ UF_ID: '' }),
        },
      });

      if (city.status === 0) {
        cities = city.data;
      } else {
        swal('Ops!', 'Houve um erro ao tentar carregar as cidades!', 'error');
      }
    }

    async function initStateAndCity() {
      await getState();
      await getCity();

      setIsLoading(false);

      setStateList(states);
      setCityList(cities);

      setFilteredCities(cities);

      setValue('UF_ID', initialData.UF_ID);
      setValue('CITY_ID', initialData.CITY_ID);
    }

    initStateAndCity();
  }, [initialData.UF_ID, initialData.CITY_ID, setValue]);

  useEffect(() => {
    setFilteredCities(cityList.filter(city => city.UF_ID === stateSelected));
  }, [stateSelected, cityList]);

  useEffect(() => {
    if (formState.submitCount > 0 && !formState.isValid) {
      toast.error('Verifique todos os campos obrigatórios.');
    }
  }, [formState.submitCount, formState.isValid]);

  async function handleFindCEP(e) {
    if (e) {
      e.preventDefault();
    }

    const { POSTAL_CODE: cep } = getValues();

    if (!cep) return;

    setIsFindingCEP(true);

    const { data } = await api({
      method: 'get',
      url: 'cep/lookup',
      params: {
        cep,
      },
    });

    setIsFindingCEP(false);

    if (data.status === 0) {
      const { UF, CITY, ADDRESS } = data.data;

      setStateSelected(+UF.ID);
      setValue('UF_ID', +UF.ID);
      setValue('CITY_ID', +CITY.ID);
      setValue('ADDRESS', ADDRESS.VALUE);
      setValue('DISTRICT', ADDRESS.DISTRICT);

      if (inputNumberRef.current) {
        inputNumberRef.current.focus();
      }
    } else {
      const { title, text, type } = data.message;
      swal(title, text, type);
    }
  }

  return (
    <form
      className={classNames('ui form', {
        loading: isLoading,
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h4 className="ui dividing header">Informações básicas</h4>

      <div className="fields">
        <div className="four wide field required">
          <label>Grupo</label>
          <select
            className="ui dropdown"
            name="ACCOUNT_GROUP_ID"
            ref={register}
            readOnly
          >
            {groupList.map(type => (
              <option key={type.ID} value={type.ID}>
                {type.NAME}
              </option>
            ))}
          </select>
          {errors.ACCOUNT_GROUP_ID && <span>Grupo é obrigatório</span>}
        </div>

        <div className="eight wide field required">
          <label>Nome</label>
          <input
            type="text"
            name="NAME"
            readOnly
            ref={register}
            placeholder="Nome completo"
          />
          {errors.NAME && <span>Nome é obrigatório</span>}
        </div>

        <div className="four wide field required">
          <label>Email</label>
          <input
            type="email"
            name="EMAIL"
            readOnly
            ref={register}
            placeholder="exemplo@exemplo.com"
          />
          {errors.EMAIL && <span>Email é obrigatório</span>}
        </div>
      </div>

      <h4 className="ui dividing header">Responsável</h4>

      <div className="field">
        <div className="two fields">
          <div className="field required">
            <label>Nome do responsável</label>
            <input
              type="text"
              name="RESPONSIBLE_NAME"
              placeholder=""
              ref={register}
              readOnly
            />
            {errors.RESPONSIBLE_NAME && (
              <span>Nome do responsável é obrigatório</span>
            )}
          </div>
          <div className="field required">
            <label>E-mail do responsável</label>
            <input
              type="email"
              name="RESPONSIBLE_EMAIL"
              placeholder="exemplo@exemplo.com"
              ref={register}
              readOnly
            />
            {errors.RESPONSIBLE_EMAIL && (
              <span>E-mail do responsável é obrigatório</span>
            )}
          </div>
        </div>
      </div>

      <div className="two fields">
        <div className="field">
          <label>Telefone (fixo) do responsável</label>
          <MaskedInput
            mask="(99) 9999-9999"
            type="tel"
            name="RESPONSIBLE_PHONE"
            placeholder="(00) 0000-0000"
            readOnly
            defaultValue={initialData.RESPONSIBLE_PHONE}
            register={register}
          />
        </div>
        <div className="field">
          <label>Telefone (celular) do responsável</label>
          <MaskedInput
            mask="(99) 9 9999-9999"
            type="tel"
            name="RESPONSIBLE_MOBILE"
            defaultValue={initialData.RESPONSIBLE_MOBILE}
            placeholder="(00) 0 0000-0000"
            readOnly
            register={register}
          />
        </div>
      </div>

      <h4 className="ui dividing header">Localização</h4>

      <div className="field">
        <div className="three fields">
          <div className="field">
            <label>CEP</label>
            <div className="ui action input">
              <InputNumberFormat
                type="tel"
                format="#####-###"
                mask="_"
                defaultValue={initialData.POSTAL_CODE}
                displayType="input"
                onBlur={handleFindCEP}
                onValueChange={v => setValue('POSTAL_CODE', v.value)}
                onKeyPress={e => e.key === 'Enter' && handleFindCEP(e)}
                readOnly
              />
              <input type="hidden" name="POSTAL_CODE" ref={register} />

              <button
                type="button"
                className={classNames('ui button', {
                  loading: isFindingCEP,
                })}
                disabled
                onClick={handleFindCEP}
              >
                Buscar
              </button>
            </div>
          </div>

          <div className="field required">
            <label>Estado</label>
            <select
              className="ui dropdown"
              name="UF_ID"
              readOnly
              ref={register}
              onChange={e => setStateSelected(Number(e.target.value))}
            >
              <option value="">Selecione</option>
              {stateList.map(state => (
                <option key={state.ID} value={state.ID}>
                  {state.NAME}
                </option>
              ))}
              {errors.UF_ID && <span>Estado é obrigatório</span>}
            </select>
          </div>

          <div className="field required">
            <label>Cidade</label>
            <select
              className="ui dropdown"
              name="CITY_ID"
              readOnly
              disabled={filteredCities.length === 0}
              ref={register}
            >
              <option value="">Selecione</option>
              {filteredCities.map(city => (
                <option key={city.ID} value={city.ID}>
                  {city.NAME}
                </option>
              ))}
            </select>
            {errors.CITY_ID && <span>Cidade é obrigatória</span>}
          </div>
        </div>

        <div className="two fields">
          <div className="twelve wide field">
            <label>Endereço</label>
            <input
              type="text"
              name="ADDRESS"
              placeholder="Ex: Rua. Padre João"
              ref={register}
              readOnly
            />
          </div>

          <div className="four wide field">
            <label>Número</label>
            <input
              type="text"
              name="NUMBER"
              placeholder="Ex: 123"
              ref={register}
              readOnly
            />
          </div>

          <div className="field">
            <label>Complemento</label>
            <input
              type="text"
              name="COMPLEMENT"
              placeholder="Ex: Apartamento 10"
              ref={register}
              readOnly
            />
          </div>

          <div className="field">
            <label>Bairro</label>
            <input
              type="text"
              name="DISTRICT"
              placeholder="Ex: Vila Mariana"
              ref={register}
              readOnly
            />
          </div>
        </div>
      </div>

      <h4 className="ui dividing header">Gestão financeira</h4>

      <div className="three fields">
        <div className="ui field">
          <label>Status Financeiro</label>
          <select
            className="ui dropdown"
            name="FINANCIAL_STATUS"
            ref={register({ required: false })}
            onChange={e => {
              if (e.target.value === 'E') {
                setIsFreeAccount(true);
              } else {
                setIsFreeAccount(false);
                setValue('FINANCIAL_OBSERVATION', '');
              }
            }}
          >
            <option value="P">Pagante</option>
            <option value="E">Isento</option>
            <option value="B">Bloqueio financeiro</option>
          </select>
        </div>

        <div className="ui field">
          <label>Projetos simulados pela conta</label>
          <input
            type="number"
            name="QTTY_PROJECT"
            ref={register}
            defaultValue={0}
            readOnly
          />
        </div>
      </div>

      <div className="field required">
        <label>Observação</label>
        <textarea
          name="FINANCIAL_OBSERVATION"
          disabled={!isFreeAccount}
          rows={4}
          style={{ resize: 'none' }}
          ref={register({
            required: false,
            validate: value => {
              if (watchFinancialStatus === 'E') {
                return value.length > 0;
              }

              return true;
            },
          })}
        />

        {errors.FINANCIAL_OBSERVATION && <span>Observação é obrigatória</span>}
      </div>

      <button className="ui fluid button blue" type="submit">
        Confirmar
      </button>
    </form>
  );
}

export default FormRegister;
