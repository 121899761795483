import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import swal from 'sweetalert';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

function FormRegister({ onSubmit, initialData, saving }) {
  const { register, handleSubmit, errors, formState, setValue } = useForm({
    defaultValues: initialData,
  });

  const [loading, setLoading] = useState(true);

  const [stateSelected, setStateSelected] = useState(initialData.UF_ID);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [filteredCities, setFilteredCities] = useState(
    cityList.filter(city => city.UF_ID === stateSelected)
  );

  useEffect(() => {
    let states = [];
    let cities = [];

    setLoading(true);

    async function getState() {
      const { data: uf } = await api({
        method: 'get',
        url: 'uf/list',
        params: {
          filter: JSON.stringify({}),
        },
      });

      if (uf.status === 0) {
        states = uf.data;
      } else {
        swal('Ops!', 'Houve um erro ao tentar carregar os estados!', 'error');
      }
    }

    async function getCity() {
      const { data: city } = await api({
        method: 'get',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: '',
          }),
        },
      });

      if (city.status === 0) {
        cities = city.data;
      } else {
        swal('Ops!', 'Houve um erro ao tentar carregar as cidades!', 'error');
      }
    }

    async function initStateAndCity() {
      await getState();
      await getCity();

      setLoading(false);

      setStateList(states);
      setCityList(cities);

      setFilteredCities(cities);

      setValue('UF_ID', initialData.UF_ID);
      setValue('CITY_ID', initialData.CITY_ID);
    }

    initStateAndCity();
  }, [initialData.UF_ID, initialData.CITY_ID, setValue]);

  useEffect(() => {
    setFilteredCities(cityList.filter(city => city.UF_ID === stateSelected));
  }, [stateSelected, cityList]);

  useEffect(() => {
    if (formState.submitCount > 0 && !formState.isValid) {
      toast.error('Verifique todos os campos obrigatórios.');
    }
  }, [formState.submitCount, formState.isValid]);

  return (
    <form
      className={classNames('ui form', {
        loading,
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="field">
        <div className="two fields">
          <div className="field required">
            <label>Nome completo</label>
            <input
              type="text"
              name="NAME"
              placeholder="Nome completo"
              ref={register({ required: true })}
            />
            {errors.NAME && <span>Nome é obrigatório</span>}
          </div>

          <div className="field required">
            <label>E-mail</label>
            <input
              type="text"
              name="EMAIL"
              placeholder="exemplo@exemplo.com"
              ref={register({ required: true })}
            />
            {errors.EMAIL && <span>E-mail é obrigatório</span>}
          </div>
        </div>
      </div>

      <div className="field">
        <div className="four fields">
          <div className="field required">
            <label>Telefone</label>
            <MaskedInput
              mask="(99) 9999-9999"
              type="tel"
              name="RESPONSIBLE_PHONE"
              placeholder="(00) 0000-0000"
              register={register({ required: true })}
              defaultValue={initialData.RESPONSIBLE_PHONE}
            />
            {errors.RESPONSIBLE_PHONE && <span>Telefone é obrigatório</span>}
          </div>

          <div className="field required">
            <label>Telefone celular</label>
            <MaskedInput
              mask="(99) 9 9999-9999"
              type="tel"
              name="RESPONSIBLE_MOBILE"
              placeholder="(00) 0 0000-0000"
              register={register({ required: true })}
              defaultValue={initialData.RESPONSIBLE_MOBILE}
            />
            {errors.RESPONSIBLE_MOBILE && (
              <span>Telefone celular é obrigatório</span>
            )}
          </div>

          <div className="field required">
            <label>Estado</label>
            <select
              className="ui dropdown"
              name="UF_ID"
              onChange={e => setStateSelected(Number(e.target.value))}
              ref={register({ required: true })}
            >
              <option value="">Selecione</option>
              {stateList.map(state => (
                <option key={state.ID} value={state.ID}>
                  {state.NAME}
                </option>
              ))}
            </select>
            {errors.UF_ID && <span>Estado é obrigatório</span>}
          </div>

          <div className="field required">
            <label>Cidade</label>
            <select
              className="ui dropdown"
              name="CITY_ID"
              ref={register({ required: true })}
              disabled={filteredCities.length === 0}
            >
              <option value="">Selecione</option>
              {filteredCities.map(city => (
                <option key={city.ID} value={city.ID}>
                  {city.NAME}
                </option>
              ))}
            </select>
            {errors.CITY_ID && <span>Cidade é obrigatória</span>}
          </div>
        </div>
      </div>

      <button
        className={classNames('ui fluid button blue', {
          loading: loading || saving,
        })}
        disabled={loading || saving}
        type="submit"
      >
        Salvar
      </button>
    </form>
  );
}

export default FormRegister;
