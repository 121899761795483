import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from '~/contexts/AuthContext';

import { Container, Content, Section, User, Avatar, Name } from './styles';

export default function Sidebar({ isOpen }) {
  const { authenticated, user } = useContext(AuthContext);

  const userName = user && user.NAME;

  function getMenuByLevel() {
    if (user.LEVEL_KEY_ === 'SIMULATE') {
      return (
        <Section>
          <h1> Simulação </h1>
          <Link to="/simulation"> Orçamento rápido </Link>
        </Section>
      );
    }

    return (
      <>
        <Section>
          <h1> Visão Gerencial </h1>
          <Link to="/"> Dashboard gerencial </Link>
        </Section>

        <Section>
          <h1> Cliente </h1>
          <Link to="/client/"> Novo cliente </Link>
          <Link to="/clients"> Gerenciar clientes </Link>
        </Section>
        <Section>
          <h1> Projeto </h1>
          <Link to="/project"> Novo projeto </Link>
          <Link to="/projects"> Gerenciar projetos </Link>

          {['ROOT', 'GROUP'].includes(user.TYPE_KEY_) && (
            <Link to="/configurations"> Configurações </Link>
          )}
        </Section>
        <Section>
          <h1> Ferramentas </h1>
          <Link to="/materials"> Materiais </Link>

          {user.LEVEL_KEY_ === 'ADMINISTRATOR' && (
            <>
              <Link to="/inverters"> Inversores </Link>
              <Link to="/modules"> Módulos </Link>
            </>
          )}

          <Link to="/pricing"> Precificação </Link>

          {user.TYPE_KEY_ === 'ROOT' && <Link to="/brands"> Marcas </Link>}
        </Section>
        <Section>
          <h1> Simulação </h1>
          <Link to="/simulation"> Orçamento rápido </Link>
          <Link to="/simulations"> Simulações realizadas </Link>
        </Section>

        {user.TYPE_KEY_ === 'ROOT' && (
          <Section>
            <h1> Financeiro </h1>
            <Link to="/royalties"> Royalties </Link>
          </Section>
        )}

        {user.LEVEL_KEY_ === 'ADMINISTRATOR' && (
          <Section>
            <h1> Administração </h1>
            <Link to="/accounts"> Controle de acesso </Link>
          </Section>
        )}
      </>
    );
  }

  return (
    <Container isOpen={isOpen}>
      <header>
        {!!authenticated && (
          <Link to="/me">
            {userName && (
              <User>
                <Avatar>
                  <span> {userName[0].toUpperCase()} </span>
                </Avatar>
                <Name>{userName}</Name>
              </User>
            )}
          </Link>
        )}
      </header>
      <Content>
        <Section>
          <h1> Minha conta </h1>
          <Link to="/me"> Minha conta </Link>
        </Section>

        {getMenuByLevel()}
      </Content>
    </Container>
  );
}
