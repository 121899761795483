import React, { memo, useEffect, useState } from 'react';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

import styled from 'styled-components';

const Container = styled.div`
  width: 100% !important;
  height: 470px !important;
  border-radius: 4px;
  overflow: hidden;
`;

const Rect = styled.rect.attrs({
  width: 38,
  height: 38,
  ry: 4,
  rx: 4,
})`
  stroke: #e6e6e6;
  stroke-width: 2px;
  fill: rgb(8, 29, 88);
`;

const Text = styled.text.attrs({})`
  fill: #000;
  font-size: 10px;
  font-weight: bold;
  color: #000;
  transform: ${({ vertical }) =>
    vertical ? 'translate(-6, 25.333333333333332)' : 'translate(19, -6)'};
`;

const G = styled.g`
  transform: translate(30, 50);
`;

function LayoutGrid({ series, parallel, additional }) {
  const [arraySeries, setArraySeries] = useState([]);
  const [arrayParallel, setArrayParallel] = useState([]);
  const [arrayAdditional, setArrayAdditional] = useState([]);

  useEffect(() => {
    const aSeries = [];
    const aParallel = [];
    const aAdditional = [];

    for (let i = 1; i <= Number(parallel); i += 1) {
      aSeries.push(i);
    }

    for (let i = 1; i <= Number(series); i += 1) {
      aParallel.push(i);
    }

    for (let i = 0; i < Number(additional); i += 1) {
      aAdditional.push(i);
    }

    setArraySeries(aSeries);
    setArrayParallel(aParallel);
    setArrayAdditional(aAdditional);
  }, [series, parallel, additional]);

  return (
    <Container>
      <AutoSizer>
        {({ width, height }) =>
          width === 0 || height === 0 ? null : (
            <UncontrolledReactSVGPanZoom width={width} height={height}>
              <svg
                width={
                  additional > series ? additional * 38 + 38 : series * 50 + 56
                }
                height={
                  Number(parallel) <= 1
                    ? Number(parallel) * 40 + 100
                    : Number(parallel) * 50 + 56
                }
              >
                <G>
                  {arraySeries.map(v => (
                    <Text key={v} vertical x={18} y={v * 38 + 12}>
                      {v}
                    </Text>
                  ))}

                  {arrayParallel.map(v => (
                    <Text key={v} vertical x={v * 38 + 12} y={18}>
                      {v}
                    </Text>
                  ))}

                  {arraySeries.map((v, y) =>
                    arrayParallel.map((k, x) => (
                      <Rect key={v + k} x={38 + x * 38} y={y * 38 + 24} />
                    ))
                  )}

                  {arrayAdditional.length > 0 && (
                    <Text
                      vertical
                      x={18}
                      y={arraySeries[arraySeries.length - 1] * 38 + 38 + 12}
                    >
                      {arraySeries.length + 1}
                    </Text>
                  )}

                  {arrayAdditional.map((v, x) => (
                    <Rect
                      key={v}
                      x={38 + x * 38}
                      y={arraySeries.length * 38 + 24}
                    />
                  ))}
                </G>
              </svg>
            </UncontrolledReactSVGPanZoom>
          )
        }
      </AutoSizer>
    </Container>
  );
}

export default memo(LayoutGrid);
