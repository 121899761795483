import styled from 'styled-components';

import { headerHeight } from '~/styles/global';

export const User = styled.a`
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  color: #fff;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
    color: #fff;
  }
`;

export const Avatar = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  border-radius: 50%;
  background: #fbbd08;
  color: #000;
`;

export const Name = styled.span`
  font-weight: bold;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export const Container = styled.div`
  position: relative;

  &.open > .menu {
    display: flex;
  }
`;

export const Menu = styled.div.attrs({
  className: 'menu',
})`
  /* display: ${props => (props.open ? 'flex' : 'none')}; */
  display: none;
  /* justify-content: center; */

  position: absolute;
  top: ${headerHeight}px;

  right: 0;

  width: 100%;
  max-width: 300px;
  height: auto;

  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;

  animation-name: fadeInUp;
  animation-duration: 0.1s;

  & .close {
    font-size: 18px;
    color: #000;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    cursor: pointer;
  }

  & .card {
    margin-top: 0;
  }

`;

export const AvatarMenu = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin: auto;
  margin-bottom: 10px;
`;
