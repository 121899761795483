import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const history = useHistory();
  const query = useQuery();
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);

  const [backTo] = useState(query.get('backTo'));

  const [loading, setLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [summary, setSummary] = useState({});

  const [keyword, setKeyword] = useState('');

  const [filter, setFilter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchAccounts = useCallback(async (filters, pag) => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/account/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setAccounts(responseSearch.data);
      setSummary(responseSearch.summary);
    } else {
      setAccounts([]);
    }

    setPagination(responseSearch.pagination);

    setLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce((key, filterObj) => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchAccounts(filterObj, newPagination);
    }, 1000)
  ).current;

  useEffect(() => {
    searchAccounts(filter, pagination);
  }, [searchAccounts]);

  useEffect(() => {
    searchAccounts(filter, {
      ...pagination,
      keyword,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value, filter);
  }

  function handleSearch() {
    searchAccounts(filter, pagination);
  }

  function handlePaginationChange(e, { activePage }) {
    searchAccounts(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function buildFilter(e, { value }) {
    const STATUS = value.filter(v => ['A', 'B', 'P'].includes(v));

    const FINANCIAL_STATUS = value
      .filter(v => ['FP', 'FE', 'FB'].includes(v))
      .map(v => v.substring(1));

    const SIMULATED = value.filter(v => ['T', 'F'].includes(v));

    const ACCOUNT_SIMULATION = value.filter(v => ['4'].includes(v));

    const filterObj = {
      STATUS,
      FINANCIAL_STATUS,
      SIMULATED,
      ACCOUNT_SIMULATION,
    };

    setFilter(filterObj);
  }

  const allSelected = useMemo(
    () => accounts.length && accounts.every(m => m.uiChecked === true),
    [accounts]
  );

  function handleSelectAll(e, { checked }) {
    setAccounts(oldAccounts =>
      oldAccounts.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(groupId) {
    setAccounts(oldAccounts =>
      oldAccounts.map(item => {
        return item.ID === groupId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function handleDelete(accountId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir uma conta permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'account/delete',
          params: {
            account_id: JSON.stringify([accountId]),
          },
        });

        if (response.status === 0) {
          searchAccounts(filter, pagination);
          toast.success('Conta excluída com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir várias contas permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const accountsToDelete = accounts
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!accountsToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos uma conta para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'account/delete',
          params: {
            account_id: JSON.stringify(accountsToDelete),
          },
        });

        if (response.status === 0) {
          toast.success('Contas excluídas com sucesso!');
          searchAccounts(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleBlock(accountId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a bloquear uma conta. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Bloquear'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'account/block',
          params: {
            account_id: JSON.stringify([accountId]),
            action: 'block',
          },
        });

        if (response.status === 0) {
          searchAccounts(filter, pagination);
          toast.success('Conta bloqueada com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleBlockSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a bloquear várias contas. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Bloquear'],
    }).then(async result => {
      if (result) {
        const accountsToBlock = accounts
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!accountsToBlock.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos uma conta para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'account/block',
          params: {
            account_id: JSON.stringify(accountsToBlock),
            action: 'block',
          },
        });

        if (response.status === 0) {
          toast.success('Contas bloqueadas com sucesso!');
          searchAccounts(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleUnblock(accountId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a desbloquear uma conta. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Desbloquear'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'account/block',
          params: {
            account_id: JSON.stringify([accountId]),
            action: 'unblock',
          },
        });

        if (response.status === 0) {
          searchAccounts(filter, pagination);
          toast.success('Conta desbloqueada com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleUnblockSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a desbloquear várias contas. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Desbloquear'],
    }).then(async result => {
      if (result) {
        const accountsToUnblock = accounts
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!accountsToUnblock.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos uma conta para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'account/block',
          params: {
            account_id: JSON.stringify(accountsToUnblock),
            action: 'unblock',
          },
        });

        if (response.status === 0) {
          toast.success('Contas desbloqueadas com sucesso!');
          searchAccounts(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function renderSituationByStatus(status) {
    if (status === 'A') {
      return (
        <Label size="mini" color="green">
          Ativo
        </Label>
      );
    }
    if (status === 'B') {
      return (
        <Label size="mini" color="red">
          Bloqueado
        </Label>
      );
    }
    if (status === 'P') {
      return (
        <Label size="mini" color="yellow">
          Pendente
        </Label>
      );
    }

    return <Label size="mini">-</Label>;
  }

  function renderFinancialStatus(status) {
    if (status === 'E') {
      return (
        <Label size="mini" color="blue">
          Cortesia
        </Label>
      );
    }

    if (status === 'B') {
      return (
        <Label size="mini" color="red">
          Bloqueio financeiro
        </Label>
      );
    }

    return null;
  }

  function renderAccountStatus(account) {
    return (
      <>
        {renderSituationByStatus(account.STATUS)}{' '}
        {renderFinancialStatus(account.FINANCIAL_STATUS)}
      </>
    );
  }

  function getNameByType(account) {
    return account.TYPE_FORMAT === 'PF' || !account.TYPE ? (
      <span>{account.NAME || '-'}</span>
    ) : (
      <>
        <span>{account.COMPANY_NAME || '-'}</span>
        <br />
        <span>{account.FANTASY_NAME || '-'}</span>
      </>
    );
  }

  function renderWithTable() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'ACCOUNT_GROUP_NAME', description: 'Grupo' },
          { field: 'NAME', description: 'Nome' },
          { field: 'CREATED_AT', description: 'Cadastro' },
          { field: 'CITY_NAME', description: 'Cidade' },
          { field: 'RESPONSIBLE_NAME', description: 'Responsável' },
          { field: 'QTTY_USER', description: 'Qtde. Usuários' },
          { field: 'QTTY_PROJECT', description: 'Projetos simulados' },
          { field: 'STATUS', description: 'Situação' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={account => ({
          ID: () => (
            <Checkbox
              checked={!!account.uiChecked}
              onChange={() => handleSelect(account.ID)}
            />
          ),
          ACCOUNT_GROUP_NAME: account.ACCOUNT_GROUP_NAME,
          NAME: getNameByType(account),
          CREATED_AT: () => (
            <>
              {account.CREATED_AT_DATE_FORMAT} <br />
              {account.CREATED_AT_HOUR_FORMAT}
            </>
          ),
          CITY_NAME: account.CITY_NAME
            ? `${account.CITY_NAME} / ${account.UF_ABBREVIATION}`
            : '-',
          RESPONSIBLE_NAME: account.RESPONSIBLE_NAME,
          STATUS: renderAccountStatus(account),
          QTTY_USER: account.QTTY_USER,
          QTTY_PROJECT: account.QTTY_PROJECT,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                color="black"
                title="Usuários da conta"
                onClick={() =>
                  history.push(`/account/${account.ID}/users?backTo=/accounts`)
                }
              >
                <i className="mdi mdi-account-group icon" />
                {account.ENERGY_BILL_QTTY}
              </Button>

              <Dropdown
                icon="cog"
                floating
                title="Ações"
                button
                className="mini blue icon"
                direction="left"
              >
                <Dropdown.Menu>
                  <Dropdown.Header content="Ações" />
                  <Dropdown.Divider />

                  <Dropdown.Item
                    color="blue"
                    icon="pencil"
                    text="Editar conta"
                    title="Editar conta"
                    onClick={() =>
                      history.push(`/account/${account.ID}?backTo=/accounts`)
                    }
                  />

                  <Dropdown.Item title="Gestão financeira">
                    <Link
                      to={`/account/${account.ID}/finance?backTo=/accounts`}
                      target="_blank"
                      style={{ color: 'rgba(0,0,0,.87)' }}
                    >
                      <Icon name="dollar sign" /> Gestão Financeira
                    </Link>
                  </Dropdown.Item>

                  {account.STATUS === 'P' && (
                    <Dropdown.Item
                      icon="check"
                      text="Ativar conta"
                      title="Ativar conta"
                      onClick={() =>
                        openModal('accountActivation', {
                          data: {
                            ID: account.ID,
                            RESPONSIBLE_NAME: account.RESPONSIBLE_NAME,
                            EMAIL: account.EMAIL,
                          },
                          onConfirm: () => searchAccounts(filter, pagination),
                        })
                      }
                    />
                  )}

                  {account.STATUS === 'A' ? (
                    <Dropdown.Item
                      icon="lock"
                      text="Bloquear conta"
                      title="Bloquear conta"
                      onClick={() => handleBlock(account.ID)}
                    />
                  ) : (
                    <Dropdown.Item
                      icon="lock open"
                      text="Desbloquear conta"
                      title="Desbloquear conta"
                      onClick={() => handleUnblock(account.ID)}
                    />
                  )}

                  <Dropdown.Item
                    color="red"
                    icon="trash"
                    text="Remover conta"
                    title="Remover conta"
                    onClick={() => handleDelete(account.ID)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </>
          ),
        })}
        data={accounts}
        loading={loading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchAccounts(filter, newPagination);
        }}
      />
    );
  }

  function renderContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading,
                })}
                type="button"
                disabled={loading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            className="right aligned"
            mobile={16}
            table={8}
            computer={8}
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!accounts.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu direction="left">
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="lock open"
                  text="Desbloquear selecionados"
                  title="Desbloquear selecionados"
                  onClick={handleUnblockSelected}
                />

                <Dropdown.Item
                  color="red"
                  icon="lock"
                  text="Bloquear selecionados"
                  title="Bloquear selecionados"
                  onClick={handleBlockSelected}
                />

                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  title="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            {user.TYPE_KEY_ === 'ROOT' && (
              <button
                type="button"
                className="ui button blue"
                onClick={() => history.push(`/groups`)}
              >
                Grupos
              </button>
            )}

            {user.TYPE_KEY_ !== 'ACCOUNT' && (
              <button
                type="button"
                className="ui button green"
                onClick={() => history.push(`/account/?backTo=/accounts`)}
              >
                <i className="mdi mdi-account-plus"> </i> Nova conta
              </button>
            )}
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} table={8} computer={5}>
            <strong>Situação</strong>

            <div className="ui fluid action input">
              <Dropdown
                placeholder="Filtrar por situações"
                noResultsMessage="Nenhum resultado encontrado"
                fluid
                multiple
                search
                selection
                onChange={buildFilter}
                options={[
                  { key: 'A', value: 'A', text: 'Ativo' },
                  { key: 'P', value: 'P', text: 'Pendente' },
                  { key: 'B', value: 'B', text: 'Bloqueado' },
                  { key: 'FP', value: 'FP', text: 'Pagantes' },
                  { key: 'FE', value: 'FE', text: 'Isentas' },
                  { key: 'FB', value: 'FB', text: 'Bloqueio Financeiro' },
                  { key: 'T', value: 'T', text: 'Ativas com simulação' },
                  { key: 'F', value: 'F', text: 'Ativas sem simulação' },
                  { key: '4', value: '4', text: 'Contas simulação' },
                ]}
              />

              <Button
                icon
                onClick={() =>
                  searchAccounts(filter, {
                    ...pagination,
                    keyword,
                  })
                }
              >
                <Icon size="small" name="filter" /> Filtrar
              </Button>
            </div>
          </Grid.Column>
        </Grid>

        {renderWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              boundaryRange={2}
              siblingRange={2}
              disabled={loading}
              defaultActivePage={1}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title">
              Controle de acessos - Gestão de contas
            </span>
            <div>
              {backTo && (
                <div>
                  <button
                    className="ui button red"
                    type="button"
                    onClick={() => history.push(backTo)}
                  >
                    Cancelar
                  </button>
                </div>
              )}
            </div>
          </div>

          <Grid centered>
            <Grid.Column mobile={16} table={16} computer={16}>
              <div className="ui centered tiny statistics m-b m-t-md">
                <div className="ui statistic">
                  <div className="value">
                    {numberFormat(summary.QTTY, 0, ',', '.')}
                  </div>
                  <div className="label">Total de contas</div>
                </div>
              </div>
            </Grid.Column>
          </Grid>

          <div className="ui attached bottom segment">{renderContainer()}</div>
        </div>
      </div>
    </>
  );
}
