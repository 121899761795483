import React, { useState, useEffect } from 'react';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalHomologValueAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [minKwp, setMinKwp] = useState(data.MIN_KWP);
  const [maxKwp, setMaxKwp] = useState(data.MAX_KWP);
  const [price, setPrice] = useState(data.PRICE);

  const [inputMinKwp, setInputMinKwp] = useState(null);
  const [inputMaxKwp, setInputMaxKwp] = useState(null);
  const [inputPrice, setInputPrice] = useState(null);

  useEffect(() => {
    if (inputMinKwp) {
      inputMinKwp.focus();
    }
  }, [inputMinKwp]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/homologation/add' : '/homologation/update',
      params: {
        homologation_id: data.ID || null,
        homologation: JSON.stringify({
          MIN_KWP: minKwp,
          MAX_KWP: maxKwp,
          PRICE: price,
          ACCOUNT_GROUP_ID: data.ACCOUNT_GROUP_ID,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(`${responseAdd.message.text}`);
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add'
          ? 'Novo valor de homologação'
          : 'Editar valor de homologação'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>kWp mínimo</label>
            <InputNumberFormat
              type="tel"
              value={minKwp}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              placeholder="0,00"
              onValueChange={v => setMinKwp(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && inputMaxKwp.focus()}
              getInputRef={el => setInputMinKwp(el)}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>kWp máximo</label>
            <InputNumberFormat
              type="tel"
              value={maxKwp}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              placeholder="0,00"
              onValueChange={v => setMaxKwp(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && inputPrice.focus()}
              getInputRef={el => setInputMaxKwp(el)}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Valor (R$)</label>
            <InputNumberFormat
              type="tel"
              value={price || ''}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setPrice(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputPrice(el)}
              disabled={isSaving}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
