import React, { useState, useEffect, useCallback } from 'react';

import {
  Modal,
  Pagination,
  Icon,
  Grid,
  Button,
  Label,
} from 'semantic-ui-react';

import Table from '~/components/Table';
import api from '~/services/api';

export default function ModalProjectHistory({
  onClose,
  handleClose,
  data = {},
}) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'DATEHOUR', mode: 'DESC' }],
  });

  const searchProjects = useCallback(async (filters, pag) => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/project/history/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
        project_id: data.ID,
      },
    });

    if (responseSearch.status === 0) {
      setProjects(responseSearch.data);
    } else {
      setProjects([]);
    }

    setPagination(responseSearch.pagination);
    setLoading(false);
  }, []);

  useEffect(() => {
    searchProjects(filter, pagination);
  }, [searchProjects]);

  function handlePaginationChange(e, { activePage }) {
    searchProjects(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function getStepLabelColor(key) {
    const stepMap = {
      EXECUTE: 'yellow',
      BUDGET: 'grey',
      FINISH: 'green',
      CANCEL: 'red',
    };

    return stepMap[key];
  }

  function renderProjectStatus(project) {
    if (project.STATUS === 'A') {
      return (
        <>
          Adicionado{' '}
          <Label size="mini" color={project.STAGE_COLOR || 'grey'}>
            {project.STAGE_DESCRIPTION || project.STEP_DESCRIPTION}
          </Label>
        </>
      );
    }

    if (project.STATUS === 'D') {
      return (
        <>
          Removido{' '}
          <Label size="mini" color={project.STAGE_COLOR}>
            {project.STAGE_DESCRIPTION}
          </Label>
        </>
      );
    }
    return '-';
  }

  function renderWithTable() {
    const cols = [
      { field: 'DATEHOUR', description: 'Data e hora' },
      { field: 'AUTHOR_NAME', description: 'Realizado por' },
      { field: 'SIMULATED', description: 'Simulado' },
      { field: 'STAGE_DESCRIPTION', description: 'Status' },
      { field: 'STEP_DESCRIPTION', description: 'Etapa' },
    ];

    return projects.length > 0 && !loading ? (
      <Table
        cols={cols}
        mapping={project => ({
          DATEHOUR: project.DATEHOUR_FORMAT,
          AUTHOR_NAME: project.AUTHOR_NAME,
          SIMULATED: project.SIMULATED === 'T' ? 'Sim' : 'Não',
          STAGE_DESCRIPTION: renderProjectStatus(project),
          STEP_DESCRIPTION: () =>
            project.STEP_KEY ? (
              <Label size="mini" color={getStepLabelColor(project.STEP_KEY)}>
                {project.STEP_DESCRIPTION}
              </Label>
            ) : (
              '-'
            ),
        })}
        data={projects}
        loading={loading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchProjects(filter, newPagination);
        }}
      />
    ) : (
      <Table
        cols={cols}
        data={[]}
        loading={loading}
        onUpdateOrderBy={() => {}}
      />
    );
  }

  function renderContainer() {
    return (
      <>
        {renderWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={loading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <Modal size="large" open onClose={onClose}>
      <Modal.Header>Histórico do projeto</Modal.Header>

      <Modal.Content>{renderContainer()}</Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose}>Fechar</Button>
      </Modal.Actions>
    </Modal>
  );
}
