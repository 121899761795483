import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import { Pagination, Icon, Grid, Message } from 'semantic-ui-react';

import api from '~/services/api';

export default function Historic() {
  const history = useHistory();

  const { materialId } = useParams();

  const [materials, setMaterials] = useState([]);

  const [material, setMaterial] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [filter] = useState({
    LIMIT: '8',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
    MATERIAL_ID: materialId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const getMaterial = useCallback(async () => {
    const { data: responseSelf } = await api({
      method: 'get',
      url: '/material/self',
      params: {
        material_id: materialId,
      },
    });

    if (responseSelf.status === 0) {
      setMaterial(responseSelf.data);
    } else {
      setMaterial({});
    }
  });

  const searchMaterials = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: '/material/history/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setMaterials(responseSearch.data);
    } else {
      setMaterials([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    searchMaterials(filter, pagination);
  }, [searchMaterials]);

  useEffect(() => {
    getMaterial();
  }, [getMaterial]);

  function handlePaginationChange(e, { activePage }) {
    searchMaterials(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function renderWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">Data Histórico</th>
            <th className="center aligned">Descrição</th>
            <th className="center aligned">Valor unitário</th>
            <th className="center aligned">Responsável</th>
          </tr>
        </thead>
        <tbody>
          {materials.length > 0 &&
            !isLoading &&
            materials.map(material => {
              return (
                <tr
                  key={material.ID}
                  className={classNames({
                    active: material.uiChecked,
                  })}
                >
                  <td className="center aligned">
                    {material.CREATED_AT_FORMAT || '-'}
                  </td>
                  <td className="center aligned">{material.CONTENT || '-'}</td>
                  <td className="center aligned">
                    R$ {numberFormat(material.PRICE, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {material.CREATE_BY_NAME || '-'}
                  </td>
                </tr>
              );
            })}

          {!isLoading && materials.length === 0 && (
            <tr>
              <td colSpan="6">
                <Message content="Não existe registro de histórico para esse material." />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="6">
                <Message
                  color="blue"
                  content="Buscando histórico do material selecionado..."
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderContainer() {
    return (
      <>
        <Grid columns="equal">
          <Grid.Column width={4}>
            <div className="ui message">
              <div className="header">Descrição do material:</div>
              <div className="content">{material.DESCRIPTION}</div>
              <div className="content" />
            </div>
          </Grid.Column>
        </Grid>

        {renderWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Histórico do material </span>
            <div>
              <button
                type="button"
                className="ui red right floated button"
                title="Voltar lista de materiais"
                onClick={() => history.push(`/materials`)}
              >
                Voltar
              </button>
            </div>
          </div>

          <div className="ui attached bottom segment">{renderContainer()}</div>
        </div>
      </div>
    </>
  );
}
