/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';

import ModalContext from '~/contexts/Modal';

import modalList from './modalList';

export default function ModalRoot() {
  const { modals, closeModal } = useContext(ModalContext);

  return (
    <>
      {modals.map(({ key, props }) => {
        const Component = modalList[key];

        if (!Component) return null;

        return (
          <Component
            key={key}
            open
            {...props}
            centered
            onClose={() => closeModal(key)}
            handleClose={() => closeModal(key)}
          />
        );
      })}
    </>
  );
}
