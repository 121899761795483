import React, { useState, useContext } from 'react';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import AuthContext from '~/contexts/AuthContext';

import { Container } from './styles';

export default function Login() {
  const { handleLogin } = useContext(AuthContext);

  const { register, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    const { username, password } = data;

    setLoading(true);

    await handleLogin(username, password);

    setLoading(false);
  }

  return (
    <Container>
      <form className="ui form segment" onSubmit={handleSubmit(onSubmit)}>
        <div className="field required">
          <label htmlFor=""> Usuário ou e-mail </label>
          <input
            type="text"
            name="username"
            placeholder="exemplo@exemplo.com"
            autoCapitalize="none"
            ref={register({ required: true })}
          />
          {errors.username && <span>Usuário ou e-mail é obrigatório!</span>}
        </div>
        <div className="field required">
          <label> Senha </label>
          <input
            type="password"
            name="password"
            placeholder="********"
            ref={register({ required: true })}
          />
          {errors.password && <span>Senha é obrigatória!</span>}
        </div>
        <button
          className={classNames('ui fluid button yellow', {
            loading,
          })}
          disabled={loading}
          type="submit"
        >
          Entrar
        </button>
        <p>
          <Link to="/forgot"> Esqueceu a senha ? Clique aqui </Link>
        </p>
      </form>

      <div className="ui horizontal divider"> OU </div>

      <Link className="ui fluid button blue" to="/register">
        Criar conta
      </Link>
    </Container>
  );
}
