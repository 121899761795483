import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  h1 {
    font-size: 36px;
  }
  span {
    font-size: 20px;
    color: #333;
  }
`;
