import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const history = useHistory();
  const query = useQuery();
  const [backTo] = useState(query.get('backTo'));

  const [kitReferences, setKitReferences] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    orderby: [{ field: 'REFERENCE', mode: 'DESC' }],
  });

  const searchKitReferences = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: 'importKit/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setKitReferences(responseSearch.data);
    } else {
      setKitReferences([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchKitReferences(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchKitReferences(filter, {
      ...pagination,
      keyword,
    });
  }, [searchKitReferences]);

  useEffect(() => {
    if (limitChanged) {
      searchKitReferences(filter, {
        ...pagination,
      });
    }

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchKitReferences(filter, { ...pagination, keyword });
  }

  function handlePaginationChange(e, { activePage }) {
    searchKitReferences(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function handleRemoveKitReference(kitReferenceId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir uma planilha permanentemente. Deseja mesmo realizar essa ação?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'importKit/delete',
          params: {
            import: JSON.stringify([kitReferenceId]),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Planilha excluída com sucesso!');
          searchKitReferences(filter, { ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  async function handleDownloadKitReference(kitReferenceId) {
    setIsDownloading(true);

    const response = await api({
      url: 'importKit/download',
      method: 'GET',
      responseType: 'blob', // important
      params: {
        import_kit_id: JSON.stringify([kitReferenceId]),
      },
    });

    setIsDownloading(false);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute(
      'download',
      response.headers['content-disposition'].split('filename=')[1] ||
        `Orçamento do projeto.xlsx`
    );
    link.click();
    window.URL.revokeObjectURL(url);
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários planilhas permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const referenceToDelete = kitReferences
          .filter(ref => ref.uiChecked === true)
          .map(ref => ref.ID);

        if (!referenceToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um planilha para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'importKit/delete',
          params: {
            import: JSON.stringify(referenceToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Planilhas excluídas com sucesso!');
          searchKitReferences(filter, { ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () =>
      kitReferences.length && kitReferences.every(m => m.uiChecked === true),
    [kitReferences]
  );

  function handleSelectAll(e, { checked }) {
    setKitReferences(oldReference =>
      oldReference.map(ref => ({
        ...ref,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(file) {
    setKitReferences(oldReference =>
      oldReference.map(ref => {
        return ref.ID === file ? { ...ref, uiChecked: !ref.uiChecked } : ref;
      })
    );
  }

  function getColsByUserType() {
    const columns = [];

    if (user.TYPE_KEY_ === 'ROOT') {
      columns.push({
        field: 'ID',
        description: () => (
          <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
        ),
      });
    }

    columns.push({ field: 'REFERENCE', description: 'Referência' });

    if (user.TYPE_KEY_ === 'ROOT') {
      columns.push({ field: 'GROUP_NAME', description: 'Grupo de negócio' });
    }

    columns.push({ field: 'ACTIONS', description: 'Ações' });

    return columns;
  }

  function renderFilesWithTable() {
    return (
      <Table
        cols={getColsByUserType()}
        mapping={file => ({
          ID: () => (
            <Checkbox
              checked={!!file.uiChecked}
              onChange={() => handleSelect(file.ID)}
            />
          ),
          REFERENCE: (
            <>
              {file.REFERENCE}
              <br />
              {user.TYPE_KEY_ === 'ROOT' && (
                <small className="text-red" title="Data e hora do envio">
                  {file.CREATED_AT_FORMAT}
                </small>
              )}
            </>
          ),
          GROUP_NAME: file.GROUP_NAME,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                color="violet"
                title="Fazer download da planilha"
                loading={isDownloading}
                onClick={() => handleDownloadKitReference(file.ID)}
              >
                <Icon name="download" />
              </Button>

              {user.TYPE_KEY_ === 'ROOT' && (
                <>
                  <Button
                    size="mini"
                    icon
                    color="blue"
                    title="Editar planilha"
                    onClick={() =>
                      openModal('kitReferenceAdd', {
                        mode: 'edit',
                        data: {
                          ID: file.ID,
                          DESCRIPTION: file.DESCRIPTION,
                          REFERENCE: file.REFERENCE,
                          GROUP_ID: file.GROUP_ID,
                        },
                        onConfirm: () =>
                          searchKitReferences(filter, pagination),
                      })
                    }
                  >
                    <Icon name="pencil" />
                  </Button>

                  <Button
                    size="mini"
                    icon
                    color="red"
                    title="Remover planilha"
                    onClick={() => handleRemoveKitReference(file.ID)}
                  >
                    <Icon name="trash" />
                  </Button>
                </>
              )}
            </>
          ),
        })}
        data={kitReferences}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchKitReferences(filter, { ...newPagination, keyword });
        }}
      />
    );
  }

  function renderFilesContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            {user.TYPE_KEY_ === 'ROOT' && (
              <>
                <Dropdown
                  icon="cogs"
                  floating
                  title="Ações rápidas"
                  button
                  className="blue icon"
                  disabled={!kitReferences.some(m => m.uiChecked === true)}
                >
                  <Dropdown.Menu>
                    <Dropdown.Header content="Ações rápidas" />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      color="red"
                      icon="trash"
                      text="Excluir selecionados"
                      onClick={handleDeleteSelected}
                    />
                  </Dropdown.Menu>
                </Dropdown>

                <button
                  type="button"
                  className="ui button green icon"
                  title="Nova referência"
                  onClick={() => {
                    openModal('kitReferenceAdd', {
                      mode: 'add',
                      onConfirm: () => searchKitReferences(filter, pagination),
                    });
                  }}
                >
                  <i className="mdi mdi-plus icon" /> Nova referência
                </button>
              </>
            )}
          </Grid.Column>
        </Grid>

        {renderFilesWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title">
              Gerenciar tabelas de preços - Kits Fotovoltaicos
            </span>

            {backTo && (
              <div>
                <button
                  className="ui button red"
                  type="button"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              </div>
            )}
          </div>

          <div className="ui attached bottom segment">
            {renderFilesContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
