import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import ModalRoot from '~/components/Modal/Root';

import { AuthProvider } from './contexts/AuthContext';
import { ModalProvider } from './contexts/Modal';
import Routes from './routes';
import GlobalStyle from './styles/global';

const App = () => (
  <CookiesProvider>
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <Routes />
          <ModalRoot />
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>

    <ToastContainer
      position={isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_RIGHT}
    />

    <GlobalStyle />
  </CookiesProvider>
);

export default App;
