import React, { useState, useEffect, useRef, useCallback } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Modal, Pagination, Icon, Grid, Button } from 'semantic-ui-react';

import Table from '~/components/Table';
import api from '~/services/api';

export default function ModalAccountSelect({ onClose, handleClose, onSelect }) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('*');

  const [keyword, setKeyword] = useState('');

  const [filter, setFilter] = useState({ ACCOUNT_GROUP_ID: selectedGroup });

  const updateFilterByGroup = useCallback(() => {
    setFilter(oldFilter => ({ ...oldFilter, ACCOUNT_GROUP_ID: selectedGroup }));
  }, [selectedGroup]);

  useEffect(() => {
    updateFilterByGroup();
  }, [updateFilterByGroup]);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [
      { field: 'ACCOUNT_GROUP_NAME', mode: 'ASC' },
      { field: 'NAME', mode: 'ASC' },
    ],
  });

  const searchProviders = useCallback(async (filters, pag) => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/account/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setAccounts(responseSearch.data);
    } else {
      setAccounts([]);
    }

    setPagination(responseSearch.pagination);

    setLoading(false);
  }, []);

  useEffect(() => {
    searchProviders(filter, pagination);
  }, [searchProviders]);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchProviders(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  const getGroupList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupList(response.data);
    }
  }, []);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    searchProviders(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleSelect(accountId) {
    onSelect(accountId);
    onClose();
  }

  function renderWithTable() {
    return (
      <Table
        cols={[
          { field: 'ACCOUNT_GROUP_NAME', description: 'Grupo' },
          { field: 'NAME', description: 'Conta' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={account => ({
          ACCOUNT_GROUP_NAME: account.ACCOUNT_GROUP_NAME || '-',
          NAME: account.NAME || '-',
          ACTIONS: () => (
            <Button
              size="mini"
              icon
              color="green"
              title="Selecionar conta"
              onClick={() => handleSelect(account.ID)}
            >
              <Icon name="check" /> Selecionar
            </Button>
          ),
        })}
        data={accounts}
        loading={loading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchProviders(filter, newPagination);
        }}
      />
    );
  }

  function renderContainer() {
    return (
      <>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="ui fluid left labeled input">
              <label className="ui label">Grupo</label>
              <select
                className="ui dropdown fluid"
                disabled={groupList.length === 0}
                readOnly={groupList.length === 1}
                value={selectedGroup}
                onChange={e => setSelectedGroup(e.target.value)}
              >
                <option value="*">Todos</option>
                {groupList.map(type => (
                  <option key={type.ID} value={type.ID}>
                    {type.NAME}
                  </option>
                ))}
              </select>
            </div>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="ui action fluid labeled input">
              <input
                type="text"
                placeholder="Digite o nome da conta"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading,
                })}
                type="button"
                disabled={loading}
                onClick={() => searchProviders(filter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
        </Grid>

        {renderWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={loading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <Modal size="small" open onClose={onClose}>
      <button
        type="button"
        href="#"
        className="no-style close"
        onClick={handleClose}
      >
        <i className="mdi mdi-close" />
      </button>

      <Modal.Header>Selecionar conta</Modal.Header>

      <Modal.Content>{renderContainer()}</Modal.Content>
    </Modal>
  );
}
