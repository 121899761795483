import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalBrandAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState(data.NAME || '');
  const [error, setError] = useState(false);

  const inputName = useRef(null);

  useEffect(() => {
    if (inputName.current) {
      inputName.current.focus();
    }
  }, [inputName]);

  async function handleConfirm() {
    setIsSaving(true);

    if (!name) {
      setError(true);
      setIsSaving(false);
      return;
    }

    setError(false);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/brand/add' : '/brand/update',
      params: {
        brand_id: data.ID || null,
        brand: JSON.stringify({
          NAME: name,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar a marca: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Nova marca' : 'Editar marca'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Nome</label>
            <input
              type="text"
              ref={inputName}
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={isSaving}
            />
            {error && <span className="text-red">Nome é obrigatório</span>}
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
