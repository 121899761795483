import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from '~/contexts/AuthContext';

export default function CustomRoute({ isPrivate, ...props }) {
  const { authenticated } = useContext(AuthContext);

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
}
