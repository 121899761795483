export default [
  {
    name: 'Status do projeto',
    description: 'Configure os status disponíveis para os projetos.',
    icon: 'tag',
    action: 'page',
    target: '/configurations/status?backTo=/configurations',
    roles: ['ROOT'],
  },
  {
    name: 'Concessionárias de energia',
    description: 'Gerenciamento das concessionárias de energia.',
    icon: 'bolt',
    action: 'page',
    target: '/configurations/providers?backTo=/configurations',
    roles: ['ROOT'],
  },
  {
    name: 'Royalties',
    description:
      'Configure a % de Royalties globais e personalizados da plataforma.',
    icon: 'dollar sign',
    action: 'modal',
    target: 'groupSelect',
    getRedirectUrl(id) {
      return `/configurations/group/${id}/royalties?backTo=/configurations`;
    },
    callback(history) {
      return selectedGroup => {
        history.push(this.getRedirectUrl(selectedGroup));
      };
    },
    roles: ['ROOT', 'GROUP'],
  },
  {
    name: 'Transformadores',
    description: 'Configure os transformadores padrões do sistema.',
    icon: 'exchange',
    action: 'page',
    target: '/configurations/transformers?backTo=/configurations',
    roles: ['ROOT'],
  },
  {
    name: 'Homologação do projeto',
    description: 'Configure preços de homologação de projetos com base no KWP.',
    icon: 'clipboard check',
    action: 'modal',
    target: 'groupSelect',
    getRedirectUrl(id) {
      return `/configurations/group/${id}/homolog-values?backTo=/configurations`;
    },
    callback(history) {
      return selectedGroup => {
        history.push(this.getRedirectUrl(selectedGroup));
      };
    },
    roles: ['ROOT', 'GROUP'],
  },
  {
    name: 'Configurações gerais',
    description: 'Regras de negócio específicas por grupo.',
    icon: 'cogs',
    action: 'modal',
    target: 'groupSelect',
    getRedirectUrl(id) {
      return `/configurations/group/${id}?backTo=/configurations`;
    },
    callback(history) {
      return selectedGroup => {
        history.push(this.getRedirectUrl(selectedGroup));
      };
    },
    roles: ['ROOT'],
  },
];
