import React, { useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button, Label } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalActivationMail({
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const [credentialId] = useState(data.ID || '');
  const [email, setEmail] = useState(data.EMAIL || '');
  const [status] = useState(data.STATUS || '');

  const [canChangeEmail, setCanChangeEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const inputEmail = useRef(null);

  function renderSituationByStatus(accountStatus) {
    if (accountStatus === 'A') {
      return (
        <Label size="huge" color="green">
          Ativo
        </Label>
      );
    }
    if (accountStatus === 'B') {
      return (
        <Label size="huge" color="red">
          Bloqueado
        </Label>
      );
    }
    if (accountStatus === 'P') {
      return (
        <Label size="huge" color="yellow">
          Pendente
        </Label>
      );
    }

    return <Label>-</Label>;
  }

  function handleEditEmail() {
    setCanChangeEmail(true);

    if (inputEmail.current) {
      inputEmail.current.focus();
    }
  }

  function handleCancel() {
    if (onConfirm) onConfirm();

    handleClose();
  }

  const resendActivationEmail = useCallback(async () => {
    const { data: response } = await api({
      method: 'post',
      url: 'credential/resend',
      params: {
        credential_id: credentialId,
      },
    });

    if (response.status === 0) {
      toast.success('Email de confirmação enviado com sucesso.');
      setEmailSent(true);
    } else {
      toast.error(response.message.text);
    }
  }, []);

  const handleUpdateEmail = useCallback(async newEmail => {
    const { data: response } = await api({
      method: 'post',
      url: 'credential/update',
      params: {
        credential_id: credentialId,
        credential: {
          EMAIL: newEmail,
        },
      },
    });

    if (response.status === 0) {
      toast.success('Email de confirmação enviado com sucesso.');
      setEmailSent(true);

      if (onConfirm) onConfirm();

      handleClose();
    } else {
      toast.error(response.message.text);
    }
  }, []);

  return (
    <Modal size="small" open onClose={onClose}>
      <Modal.Header>Ativação de cadastro</Modal.Header>

      <Modal.Content>
        <h1 className="text-center">
          Este usuário está {renderSituationByStatus(status)}
        </h1>
        <div className="ui form">
          <div className="field">
            <label>E-mail</label>
            <div className="ui input">
              <input
                ref={inputEmail}
                value={email}
                onChange={e => setEmail(e.target.value)}
                readOnly={!canChangeEmail}
                type="email"
              />
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          onClick={() =>
            emailSent || canChangeEmail
              ? handleCancel()
              : resendActivationEmail()
          }
        >
          {emailSent || canChangeEmail
            ? 'Fechar'
            : 'Enviar e-mail de confirmação'}
        </Button>
        <Button
          positive
          onClick={() =>
            canChangeEmail ? handleUpdateEmail(email) : handleEditEmail()
          }
        >
          {canChangeEmail ? 'Salvar' : 'Trocar e-mail'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
