import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { subDays, format, differenceInDays } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import numberFormat from 'locutus/php/strings/number_format';
import Brightness7Icon from 'mdi-react/Brightness7Icon';
import CheckAllIcon from 'mdi-react/CheckAllIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import EmoticonSadIcon from 'mdi-react/EmoticonSadIcon';
import GlobeIcon from 'mdi-react/GlobeIcon';
import { Button, Grid, Icon } from 'semantic-ui-react';

import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/chart/bar';

import paybackImg from '~/assets/dashboard.svg';
import AppliedFilters from '~/components/AppliedFilters';
import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

import DrawerFilter from './DrawerFilter';
import {
  Container,
  Overlay,
  DashboardContainer,
  FilterContainer,
  FiltersByDate,
  ButtonGroup,
  DashboardSummaryCard,
  WhiteBox,
  PaybackSection,
  PaybackInfo,
  BoxAction,
} from './styles';

const steps = {
  IN_BUDGET: 'BUDGET',
  IN_PROGRESS: 'EXECUTE',
  FINALIZED: 'FINISH',
  CANCELED: 'CANCEL',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function customDate(dateString = new Date().toDateString('pt-BR')) {
  return new Date(dateString.replace(/-/g, '/'));
}

export default function Dashboard() {
  const { user } = useContext(AuthContext);

  const history = useHistory();
  const queryString = useQuery();

  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const [showDrawer, setShowDrawer] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [data, setData] = useState([]);

  const [chartByDay, setChartByDay] = useState([]);
  const [chartByDayLabel, setChartByDayLabel] = useState([]);

  const [chartByMonth, setChartByMonth] = useState([]);
  const [chartByMonthLabel, setChartByMonthLabel] = useState([]);

  const [payback, setPayback] = useState({});

  const [personalizedFlag, setPersonalizedFlag] = useState('30days');
  const [chartType, setChartType] = useState('value');

  const [filterData, setFilterData] = useState({});
  const [searchFilter, setSearchFilter] = useState(() => {
    const queryDateStart = queryString.get('DATE_TIME_START');
    const queryDateEnd = queryString.get('DATE_TIME_FINAL');
    const queryGroupId = queryString.get('ACCOUNT_GROUP_ID');
    const queryAccountId = queryString.get('ACCOUNT_ID');
    const queryStateId = queryString.get('UF_ID');
    const queryCityId = queryString.get('CITY_ID');

    const dateStart = queryDateStart
      ? customDate(queryDateStart)
      : subDays(customDate(), 30);

    const dateEnd = queryDateEnd ? customDate(queryDateEnd) : customDate();

    return {
      DATE_TIME_START: format(dateStart, 'yyyy-MM-dd'),
      DATE_TIME_FINAL: format(dateEnd, 'yyyy-MM-dd'),
      ...(queryGroupId && { ACCOUNT_GROUP_ID: queryGroupId }),
      ...(queryAccountId && { ACCOUNT_ID: queryAccountId }),
      ...(queryStateId && { UF_ID: queryStateId }),
      ...(queryCityId && { CITY_ID: queryCityId }),
    };
  });

  useEffect(() => {
    Object.entries(searchFilter).forEach(([key, value]) => {
      if (value) queryString.set(key, value);
    });

    queryString.forEach((_, key) => {
      if (key in searchFilter && !searchFilter[key]) {
        queryString.delete(key);
      }
    });

    if (!searchFilter.DATE_TIME_START) {
      queryString.delete('DATE_TIME_START');
    }

    if (!searchFilter.DATE_TIME_FINAL) {
      queryString.delete('DATE_TIME_FINAL');
    }

    history.replace({ search: queryString.toString() });
  }, [searchFilter, history]);

  const setPersonalizedDate = useCallback(
    flag => {
      setPersonalizedFlag(flag);

      if (flag === 'other') return;

      const today = customDate();

      if (flag === '7days') {
        const dateStart = subDays(customDate(), 7);

        setSearchFilter(oldFilter => ({
          ...oldFilter,
          DATE_TIME_START: format(dateStart, 'yyyy-MM-dd'),
          DATE_TIME_FINAL: format(today, 'yyyy-MM-dd'),
        }));
      } else if (flag === '15days') {
        const dateStart = subDays(customDate(), 15);

        setSearchFilter(oldFilter => ({
          ...oldFilter,
          DATE_TIME_START: format(dateStart, 'yyyy-MM-dd'),
          DATE_TIME_FINAL: format(today, 'yyyy-MM-dd'),
        }));
      } else if (flag === '30days') {
        const dateStart = subDays(customDate(), 30);

        setSearchFilter(oldFilter => ({
          ...oldFilter,
          DATE_TIME_START: format(dateStart, 'yyyy-MM-dd'),
          DATE_TIME_FINAL: format(today, 'yyyy-MM-dd'),
        }));
      } else if (flag === 'complete') {
        const { DATE_TIME_FINAL, DATE_TIME_START, ...newFilter } = searchFilter;

        setSearchFilter({
          ...newFilter,
        });
      } else {
        setSearchFilter(oldFilter => ({
          ...oldFilter,
          DATE_TIME_START: format(today, 'yyyy-MM-dd'),
          DATE_TIME_FINAL: format(today, 'yyyy-MM-dd'),
        }));
      }
    },
    [searchFilter]
  );

  const transformedFilters = useMemo(() => {
    const filters = [];

    if (filterData.DATE_TIME_START && !filterData.DATE_TIME_FINAL) {
      const dateFmt = customDate(filterData.DATE_TIME_START).toLocaleDateString(
        'pt-BR'
      );

      filters.push({
        key: 'Data',
        data: `A partir de ${dateFmt}`,
      });
    }

    if (!filterData.DATE_TIME_START && filterData.DATE_TIME_FINAL) {
      const dateFmt = customDate(filterData.DATE_TIME_FINAL).toLocaleDateString(
        'pt-BR'
      );

      filters.push({
        key: 'Data',
        data: `Até ${dateFmt}`,
      });
    }

    if (filterData.DATE_TIME_START && filterData.DATE_TIME_FINAL) {
      const dateStartFmt = customDate(
        filterData.DATE_TIME_START
      ).toLocaleDateString('pt-BR');

      const dateFinalFmt = customDate(
        filterData.DATE_TIME_FINAL
      ).toLocaleDateString('pt-BR');

      filters.push({
        key: 'Data',
        data: `De ${dateStartFmt} até ${dateFinalFmt}`,
      });
    }

    if (filterData.GROUP_NAME) {
      filters.push({
        key: 'Grupo',
        data: filterData.GROUP_NAME,
      });
    }

    if (filterData.ACCOUNT_NAMES && filterData.ACCOUNT_NAMES.length) {
      filters.push({
        key: filterData.ACCOUNT_NAMES.length > 1 ? 'Contas' : 'Conta',
        data: filterData.ACCOUNT_NAMES,
      });
    }

    if (filterData.STATE_NAME) {
      filters.push({
        key: 'Estado',
        data: filterData.STATE_NAME,
      });
    }

    if (filterData.CITY_NAME) {
      filters.push({
        key: 'Cidade',
        data: filterData.CITY_NAME,
      });
    }

    return filters;
  }, [filterData]);

  useEffect(() => {
    if (transformedFilters.length === 0) {
      setLoadingFilters(false);
    }
  });

  const processFilters = useCallback(filter => {
    const validFilter = {};

    Object.assign(validFilter, filter);

    if (!!filter.ACCOUNT_ID && typeof filter.ACCOUNT_ID === 'string') {
      Object.assign(validFilter, {
        ACCOUNT_ID: filter.ACCOUNT_ID.split(',').map(x => Number(x)),
      });
    }

    const cleanFilter = Object.fromEntries(
      Object.entries(validFilter).filter(([, v]) => !!v)
    );

    return cleanFilter;
  }, []);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const { data: responseSearch } = await api({
        method: 'post',
        url: 'project/dashboard/steps/search',
        params: {
          filter: JSON.stringify({
            ...processFilters(searchFilter),
            GROUP_BY: 'step_id',
          }),
        },
      });

      if (!('status' in responseSearch)) {
        throw new Error('Expected status to be in responseSearch.');
      }

      if (responseSearch.status === 0) {
        setData(responseSearch.data);
      } else {
        setData([]);
      }
    } catch (e) {
      toast.error(
        'Houve um erro ao recuperar os dados.  Por favor, atualize os filtros e tente novamente.'
      );

      setData([]);
    } finally {
      setLoading(false);
    }
  }, [searchFilter]);

  const getPayback = useCallback(async () => {
    setLoading(true);
    try {
      const { data: responseSearch } = await api({
        method: 'post',
        url: 'project/dashboard/payback/search',
        params: {
          filter: processFilters(searchFilter),
        },
      });

      if (responseSearch.status === 0) {
        setPayback({
          returnYears: responseSearch.mediaReturnYears,
          energyProduction: responseSearch.mediaEnergyProduction,
          totalProjects: responseSearch.mediaTotalProjects,
        });
      } else {
        setPayback({});
      }
    } catch (e) {
      toast.error('Houve um erro ao recuperar os dados de Payback!');
    } finally {
      setLoading(false);
    }
  }, [searchFilter]);

  const getChartByDay = useCallback(async () => {
    setLoading(true);
    try {
      const { data: responseSearch } = await api({
        method: 'post',
        url: 'project/dashboard/graphics/day/projects',
        params: {
          filter: JSON.stringify({
            ...processFilters(searchFilter),
            GROUP_BY: 'day(project.CREATED_AT)',
          }),
        },
      });

      if (responseSearch.status === 0) {
        const labels = Object.keys(responseSearch.data).map(day =>
          format(customDate(day), 'dd/MM/yyyy')
        );

        const values = Object.values(responseSearch.data).map(value =>
          Number(value)
        );

        setChartByDay(values);
        setChartByDayLabel(labels);
      } else {
        setChartByDay([]);
        setChartByDayLabel([]);
      }
    } catch (e) {
      toast.error('Houve um erro ao recuperar os dados por dia!');
    } finally {
      setLoading(false);
    }
  }, [searchFilter]);

  const getChartByMonth = useCallback(async () => {
    setLoading(true);
    try {
      const { data: responseSearch } = await api({
        method: 'post',
        url: 'project/dashboard/graphics/monthly/projects',
        params: {
          filter: JSON.stringify({
            ...processFilters(searchFilter),
            GROUP_BY: 'month(project.CREATED_AT)',
          }),
        },
      });

      if (responseSearch.status === 0) {
        const labels = Object.keys(responseSearch.data).map(month =>
          format(customDate(month), 'MM/yyyy')
        );

        const values = Object.values(responseSearch.data).map(value =>
          Number(value)
        );

        setChartByMonth(values);
        setChartByMonthLabel(labels);
      } else {
        setChartByMonth([]);
        setChartByMonthLabel([]);
      }
    } catch (e) {
      toast.error('Houve um erro ao recuperar os dados por mês!');
    } finally {
      setLoading(false);
    }
  }, [searchFilter]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getPayback();
  }, [getPayback]);

  useEffect(() => {
    getChartByDay();
  }, [getChartByDay]);

  useEffect(() => {
    getChartByMonth();
  }, [getChartByMonth]);

  const getStep = key => {
    const step = data.find(item => item.STEP_NAME === key);

    if (!step) {
      return { QTTY: 0, TOTAL_PROJECTS: 0 };
    }

    return step;
  };

  const totalProjects = useMemo(() => {
    const finalized = getStep(steps.FINALIZED);
    const inBudget = getStep(steps.IN_BUDGET);
    const inProgress = getStep(steps.IN_PROGRESS);
    const canceled = getStep(steps.CANCELED);

    return {
      QTTY: finalized.QTTY + inBudget.QTTY + inProgress.QTTY + canceled.QTTY,
      VALUE:
        finalized.TOTAL_PROJECTS +
        inBudget.TOTAL_PROJECTS +
        inProgress.TOTAL_PROJECTS +
        canceled.TOTAL_PROJECTS,
    };
  }, [data]);

  const percent = (a, b) => {
    const perc = (a / b) * 100;
    const result = Number.isNaN(perc) ? 0 : perc;
    return numberFormat(result, 2, ',', '.');
  };

  const finalized = getStep(steps.FINALIZED);
  const inBudget = getStep(steps.IN_BUDGET);
  const inProgress = getStep(steps.IN_PROGRESS);
  const canceled = getStep(steps.CANCELED);

  const hasProjects = useMemo(() => {
    return finalized.QTTY + inBudget.QTTY + inProgress.QTTY + canceled.QTTY > 0;
  }, [data]);

  return (
    <Container>
      {showOverlay && <Overlay />}

      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui attached bottom segment">
            <Grid centered>
              <Grid.Row>
                <h2>
                  Olá {user.NAME}, aqui está a visão gerencial dos seus
                  projetos!
                  <Icon name="sun" color="yellow" />
                </h2>
              </Grid.Row>
            </Grid>
          </div>

          <DashboardContainer>
            <h2 className="ui header">Gestão de projetos</h2>

            <FilterContainer>
              <FiltersByDate>
                <strong>PERÍODO</strong>

                <ButtonGroup>
                  <Button
                    color={personalizedFlag === 'today' ? 'yellow' : 'blue'}
                    size="medium"
                    active={personalizedFlag === 'today'}
                    onClick={() => setPersonalizedDate('today')}
                  >
                    Hoje
                  </Button>

                  <Button
                    color={personalizedFlag === '7days' ? 'yellow' : 'blue'}
                    size="medium"
                    active={personalizedFlag === '7days'}
                    onClick={() => setPersonalizedDate('7days')}
                  >
                    7 Dias
                  </Button>

                  <Button
                    color={personalizedFlag === '15days' ? 'yellow' : 'blue'}
                    size="medium"
                    active={personalizedFlag === '15days'}
                    onClick={() => setPersonalizedDate('15days')}
                  >
                    15 Dias
                  </Button>

                  <Button
                    color={personalizedFlag === '30days' ? 'yellow' : 'blue'}
                    size="medium"
                    active={personalizedFlag === '30days'}
                    onClick={() => setPersonalizedDate('30days')}
                  >
                    30 Dias
                  </Button>

                  <Button
                    color={personalizedFlag === 'complete' ? 'yellow' : 'blue'}
                    size="medium"
                    active={personalizedFlag === 'complete'}
                    onClick={() => setPersonalizedDate('complete')}
                  >
                    Completo
                  </Button>

                  <Button
                    color={personalizedFlag === 'other' ? 'yellow' : 'blue'}
                    size="medium"
                    active={personalizedFlag === 'other'}
                    onClick={() => {
                      setPersonalizedDate('other');
                      setShowDrawer(open => !open);
                      setShowOverlay(show => !show);
                    }}
                  >
                    Personalizado
                  </Button>
                </ButtonGroup>
              </FiltersByDate>

              <DrawerFilter
                open={showDrawer}
                initialData={searchFilter}
                loading={loading || loadingFilters}
                onSubmit={appliedFilters => {
                  const { DATE_TIME_FINAL, DATE_TIME_START } = appliedFilters;

                  const today = customDate();
                  const dateStart = customDate(DATE_TIME_START);
                  const dateFinal = customDate(DATE_TIME_FINAL);

                  const todayStr = today.toDateString();
                  const dateFinalStr = dateFinal.toDateString();

                  const daysDiff = differenceInDays(dateFinal, dateStart);

                  if (daysDiff === 0 && todayStr === dateFinalStr) {
                    setPersonalizedFlag('today');
                  } else if (daysDiff === 7 && todayStr === dateFinalStr) {
                    setPersonalizedFlag('7days');
                  } else if (daysDiff === 15 && todayStr === dateFinalStr) {
                    setPersonalizedFlag('15days');
                  } else if (daysDiff === 30 && todayStr === dateFinalStr) {
                    setPersonalizedFlag('30days');
                  } else {
                    setPersonalizedFlag('other');
                  }

                  setSearchFilter(appliedFilters);
                }}
                onToggle={() => {
                  setShowOverlay(show => !show);
                  setShowDrawer(open => !open);
                }}
                onLoadFilters={loadedFilterData => {
                  setLoadingFilters(false);
                  setFilterData(loadedFilterData);
                }}
              />
            </FilterContainer>

            {transformedFilters.length > 0 && !loadingFilters && (
              <Grid>
                <Grid.Column mobile={16}>
                  <AppliedFilters filters={transformedFilters} />
                </Grid.Column>
              </Grid>
            )}

            {loadingFilters && (
              <Grid>
                <Grid.Column mobile={16}>
                  <AppliedFilters
                    filters={[{ key: 'Carregando', data: '...' }]}
                  />
                </Grid.Column>
              </Grid>
            )}

            <Grid centered>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <DashboardSummaryCard
                  color="blue"
                  className="party horizontal-center"
                >
                  <div className="icon-wrapper">
                    <GlobeIcon className="icon" size={48} color="#FFFFFF" />
                  </div>

                  <div className="ui small inverted statistic">
                    <div className="value">
                      R$ {numberFormat(totalProjects.VALUE, 2, ',', '.')}
                    </div>
                    <div className="label">{totalProjects.QTTY} projetos</div>
                  </div>
                </DashboardSummaryCard>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <WhiteBox>
                  <h1 className="box-title">
                    STATUS DOS PROJETOS{' '}
                    {hasProjects
                      ? `(${chartType === 'value' ? 'R$' : 'QTDE.'})`
                      : ''}
                  </h1>

                  {hasProjects && (
                    <BoxAction
                      icon
                      size="small"
                      title={
                        chartType === 'value'
                          ? 'Ver por quantidade'
                          : 'Ver por valor do projeto'
                      }
                      onClick={() => {
                        if (chartType === 'value') {
                          setChartType('qtty');
                        } else {
                          setChartType('value');
                        }
                      }}
                    >
                      <Icon name="exchange" />
                    </BoxAction>
                  )}

                  {!hasProjects ? (
                    <h3 className="box-message">
                      Nenhum projeto encontrado! Atualize os filtros para
                      pesquisar novamente.
                    </h3>
                  ) : (
                    <ReactECharts
                      echarts={echarts}
                      option={{
                        tooltip: {
                          trigger: 'item',
                          formatter: params => {
                            return `${params.seriesName} <br/>${params.name}: ${
                              chartType === 'value'
                                ? `R$${numberFormat(
                                    params.value || 0,
                                    2,
                                    ',',
                                    '.'
                                  )}`
                                : params.value
                            } (${params.percent}%)`;
                          },
                        },
                        color: [
                          'rgb(33, 186, 69)',
                          'rgb(251, 190, 8)',
                          'rgb(219, 40, 40)',
                          'rgb(118, 118, 118)',
                        ],
                        series: [
                          {
                            name: 'Status do projeto',
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            data: [
                              {
                                value:
                                  chartType === 'value'
                                    ? finalized.TOTAL_PROJECTS
                                    : finalized.QTTY,
                                name: 'Finalizado',
                              },
                              {
                                value:
                                  chartType === 'value'
                                    ? inProgress.TOTAL_PROJECTS
                                    : inProgress.QTTY,
                                name: 'Em execução',
                              },
                              {
                                value:
                                  chartType === 'value'
                                    ? canceled.TOTAL_PROJECTS
                                    : canceled.QTTY,
                                name: 'Cancelado',
                              },
                              {
                                value:
                                  chartType === 'value'
                                    ? inBudget.TOTAL_PROJECTS
                                    : inBudget.QTTY,
                                name: 'Em orçamento',
                              },
                            ],
                            itemStyle: {
                              emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                              },
                            },
                          },
                        ],
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                        marginTop: '-50px',
                      }}
                      notMerge
                      lazyUpdate
                      showLoading={loading || loadingFilters}
                      loadingOption={{
                        text: 'Carregando...',
                        color: 'rgb(33, 133, 208)',
                        textColor: '#000000',
                        maskColor: '#FFFFFF',
                        zlevel: 0,
                      }}
                    />
                  )}
                </WhiteBox>
              </Grid.Column>
            </Grid>

            <Grid centered>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <DashboardSummaryCard
                  color="red"
                  className="red horizontal-center"
                >
                  <div className="icon-wrapper">
                    <EmoticonSadIcon
                      className="icon"
                      size={48}
                      color="#FFFFFF"
                    />
                  </div>

                  <div className="ui tiny inverted statistic">
                    <div className="value">
                      R$ {numberFormat(canceled.TOTAL_PROJECTS, 2, ',', '.')} (
                      {percent(canceled.TOTAL_PROJECTS, totalProjects.VALUE)}
                      %)
                    </div>
                    <div className="label">
                      {canceled.QTTY} projetos cancelados (
                      {percent(canceled.QTTY, totalProjects.QTTY)}%)
                    </div>
                  </div>
                </DashboardSummaryCard>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={4}>
                <DashboardSummaryCard
                  color="grey"
                  className="grey horizontal-center"
                >
                  <div className="icon-wrapper">
                    <CurrencyUsdIcon
                      className="icon"
                      size={48}
                      color="#FFFFFF"
                    />
                  </div>

                  <div className="ui tiny inverted statistic">
                    <div className="value">
                      R$ {numberFormat(inBudget.TOTAL_PROJECTS, 2, ',', '.')} (
                      {percent(inBudget.TOTAL_PROJECTS, totalProjects.VALUE)}
                      %)
                    </div>
                    <div className="label">
                      {inBudget.QTTY} projetos em orçamento (
                      {percent(inBudget.QTTY, totalProjects.QTTY)}%)
                    </div>
                  </div>
                </DashboardSummaryCard>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={4}>
                <DashboardSummaryCard
                  color="yellow"
                  className="yellow horizontal-center"
                >
                  <div className="icon-wrapper">
                    <Brightness7Icon
                      className="icon"
                      size={48}
                      color="#FFFFFF"
                    />
                  </div>

                  <div className="ui tiny inverted statistic">
                    <div className="value">
                      R$ {numberFormat(inProgress.TOTAL_PROJECTS, 2, ',', '.')}{' '}
                      ({percent(inProgress.TOTAL_PROJECTS, totalProjects.VALUE)}
                      %)
                    </div>
                    <div className="label">
                      {inProgress.QTTY} projetos em execução (
                      {percent(inProgress.QTTY, totalProjects.QTTY)}%)
                    </div>
                  </div>
                </DashboardSummaryCard>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={4}>
                <DashboardSummaryCard
                  color="green"
                  className="green horizontal-center"
                >
                  <div className="icon-wrapper">
                    <CheckAllIcon className="icon" size={48} color="#FFFFFF" />
                  </div>

                  <div className="ui tiny inverted statistic">
                    <div className="value">
                      R$ {numberFormat(finalized.TOTAL_PROJECTS, 2, ',', '.')} (
                      {percent(finalized.TOTAL_PROJECTS, totalProjects.VALUE)}
                      %)
                    </div>
                    <div className="label">
                      {finalized.QTTY} projetos finalizados (
                      {percent(finalized.QTTY, totalProjects.QTTY)}
                      %)
                    </div>
                  </div>
                </DashboardSummaryCard>
              </Grid.Column>
            </Grid>

            <h2 className="ui header">
              <Icon name="chart line" size="tiny" /> Valor de projetos
              realizados (por dia)
            </h2>

            <Grid centered>
              <Grid.Column mobile={16}>
                <WhiteBox>
                  {chartByDay.length === 0 ? (
                    <h3 className="box-message">
                      Nenhum projeto encontrado! Atualize os filtros para
                      pesquisar novamente.
                    </h3>
                  ) : (
                    <ReactECharts
                      echarts={echarts}
                      option={{
                        title: {
                          text: 'POR DIA (R$)',
                          left: 'center',
                          align: 'right',
                        },
                        grid: {
                          top: '30px',
                          right: '5px',
                          bottom: '5px',
                          left: '5px',
                          containLabel: true,
                        },
                        tooltip: {
                          trigger: 'axis',
                          axisPointer: {
                            type: 'line', // 'line' | 'shadow'
                            lineStyle: {
                              color: '#707070',
                              type: 'dashed',
                            },
                          },
                          formatter([params]) {
                            const { name, value } = params;
                            return `Dia ${name}: <br> R$ ${numberFormat(
                              value,
                              2,
                              ',',
                              '.'
                            )}`;
                          },
                        },
                        xAxis: {
                          type: 'category',
                          axisLabel: {
                            rotate: isMobile ? 90 : 0,
                          },
                          data: chartByDayLabel,
                        },
                        yAxis: {
                          type: 'value',
                          axisLabel: {
                            formatter(value) {
                              return `R$ ${numberFormat(value, 2, ',', '.')}`;
                            },
                          },
                        },
                        series: [
                          {
                            data: chartByDay,
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                              color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                  {
                                    offset: 0,
                                    color: 'rgb(33, 133, 208)',
                                  },
                                  {
                                    offset: 1,
                                    color: 'rgb(110, 193, 255)',
                                  },
                                ]
                              ),
                            },
                            label: {
                              formatter({ value }) {
                                return `R$ ${numberFormat(value, 2, ',', '.')}`;
                              },
                              position: 'top',
                            },
                          },
                        ],

                        color: ['rgb(33, 133, 208)'],
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      notMerge
                      lazyUpdate
                      showLoading={loading || loadingFilters}
                      loadingOption={{
                        text: 'Carregando...',
                        color: 'rgb(33, 133, 208)',
                        textColor: '#000000',
                        maskColor: '#FFFFFF',
                        zlevel: 0,
                      }}
                    />
                  )}
                </WhiteBox>
              </Grid.Column>
            </Grid>

            <h2 className="ui header">
              <Icon name="chart bar" /> Valor de projetos realizados (por mês)
            </h2>

            <Grid centered>
              <Grid.Column mobile={16}>
                <WhiteBox>
                  {chartByMonth.length === 0 ? (
                    <h3 className="box-message">
                      Nenhum projeto encontrado! Atualize os filtros para
                      pesquisar novamente.
                    </h3>
                  ) : (
                    <ReactECharts
                      echarts={echarts}
                      option={{
                        title: {
                          text: 'POR MÊS (R$)',
                          left: 'center',
                          align: 'right',
                        },
                        grid: {
                          top: '30px',
                          right: '5px',
                          bottom: '5px',
                          left: '5px',
                          containLabel: true,
                        },
                        tooltip: {
                          trigger: 'item',
                          formatter(params) {
                            const { name, value } = params;
                            return `Mês ${name}: <br> Total R$ ${numberFormat(
                              value,
                              2,
                              ',',
                              '.'
                            )}`;
                          },
                        },
                        xAxis: {
                          type: 'category',
                          axisLabel: {
                            rotate: isMobile ? 90 : 0,
                          },
                          data: chartByMonthLabel,
                        },
                        yAxis: {
                          type: 'value',
                          axisLabel: {
                            formatter(value) {
                              return `R$ ${numberFormat(value, 2, ',', '.')}`;
                            },
                          },
                        },
                        series: [
                          {
                            data: chartByMonth,
                            type: 'bar',
                            roundCap: true,
                            color: ['rgb(63, 63, 99)'],
                            label: {
                              formatter({ value }) {
                                return `R$ ${numberFormat(value, 2, ',', '.')}`;
                              },
                              position: 'top',
                            },
                            showBackground: true,
                            backgroundStyle: {
                              color: 'rgba(180, 180, 180, 0.2)',
                            },
                          },
                        ],
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      notMerge
                      lazyUpdate
                      showLoading={loading || loadingFilters}
                      loadingOption={{
                        text: 'Carregando...',
                        color: 'rgb(33, 133, 208)',
                        textColor: '#000000',
                        maskColor: '#FFFFFF',
                        zlevel: 0,
                      }}
                    />
                  )}
                </WhiteBox>
              </Grid.Column>
            </Grid>

            <h2 className="ui header">
              <Icon name="money bill alternate" />
              Retorno do investimento (Payback)
            </h2>

            <Grid relaxed centered>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <PaybackSection>
                  <img className="ui image" src={paybackImg} alt="Payback" />
                </PaybackSection>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <PaybackSection>
                  <PaybackInfo>
                    <div className="ui small statistic">
                      <div className="label">
                        O seu tempo médio de retorno é de:
                      </div>
                      <div className="value">
                        {payback.returnYears || 'Não definido'}
                      </div>
                    </div>
                  </PaybackInfo>

                  <PaybackInfo>
                    <div className="ui small statistic">
                      <div className="label">
                        Os projetos estão com produção média de:
                      </div>
                      <div className="value">
                        {numberFormat(
                          payback.energyProduction || 0,
                          2,
                          ',',
                          '.'
                        )}{' '}
                        KWP
                      </div>
                    </div>
                  </PaybackInfo>

                  <PaybackInfo>
                    <div className="ui small statistic">
                      <div className="label">A média dos projetos em R$ é:</div>
                      <div className="value">
                        R${' '}
                        {numberFormat(payback.totalProjects || 0, 2, ',', '.')}
                      </div>
                    </div>
                  </PaybackInfo>
                </PaybackSection>
              </Grid.Column>
            </Grid>
          </DashboardContainer>
        </div>
      </div>
    </Container>
  );
}
