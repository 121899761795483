import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import InputNumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

export default function ModalModuleAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [brands, setBrands] = useState([]);
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [brandId, setBrandId] = useState(data.BRAND_ID || null);
  const [model, setModel] = useState(data.MODEL || '');
  const [power, setPower] = useState(data.POWER);
  const [currentClosed, setCurrentClosed] = useState(data.CURRENT_CLOSED);
  const [currentOpen, setCurrentOpen] = useState(data.CURRENT_OPEN);
  const [dimension, setDimension] = useState(data.DIMENSION || '');
  const [weight, setWeight] = useState(data.WEIGHT);
  const [circuitVoltageClosed, setCircuitVoltageClosed] = useState(
    data.CIRCUIT_VOLTAGE_CLOSED
  );
  const [circuitVoltageOpen, setCircuitVoltageOpen] = useState(
    data.CIRCUIT_VOLTAGE_OPEN
  );

  const inputModel = useRef(null);
  const inputDimension = useRef(null);
  const [inputPower, setInputPower] = useState(null);
  const [inputCurrentClosed, setInputCurrentClosed] = useState(null);
  const [inputCurrentOpen, setInputCurrentOpen] = useState(null);
  const [inputWeight, setInputWeight] = useState(null);
  const [inputCircuitVoltageClosed, setInputCircuitVoltageClosed] = useState(
    null
  );
  const [inputCircuitVoltageOpen, setInputCircuitVoltageOpen] = useState(null);

  const searchBrands = useCallback(async () => {
    const { data: responseBrands } = await api({
      method: 'post',
      url: 'brand/select',
    });

    if (responseBrands.status === 0) {
      if (user.TYPE_KEY_ === 'ROOT') {
        setBrands(responseBrands.data);
      } else if (user.TYPE_KEY_ === 'GROUP') {
        const filteredBrands = responseBrands.brands.filter(
          brand => brand.ACCOUNT_RESPONSIBLE_ID !== user.ID
        );

        setBrands(filteredBrands);
      }

      if (!brandId) {
        setBrandId(responseBrands.data[0].ID);
      }
    } else {
      setBrands([]);
    }
  }, []);

  useEffect(() => {
    searchBrands();
  }, [searchBrands]);

  const [isSaving, setIsSaving] = useState(false);

  async function handleConfirm(e) {
    e.preventDefault();

    setIsSaving(true);

    const { data: responseSave } = await api({
      method: 'post',
      url: mode === 'add' ? '/module/add' : '/module/update',
      params: {
        module_id: data.ID || null,
        module: JSON.stringify({
          BRAND_ID: brandId,
          MODEL: model,
          POWER: power,
          CIRCUIT_VOLTAGE_CLOSED: circuitVoltageClosed,
          CIRCUIT_VOLTAGE_OPEN: circuitVoltageOpen,
          CURRENT_CLOSED: currentClosed,
          CURRENT_OPEN: currentOpen,
          DIMENSION: dimension,
          WEIGHT: weight,
        }),
      },
    });

    if (responseSave.status === 0) {
      toast.success(responseSave.message.text);

      if (onConfirm) onConfirm(responseSave.data);

      handleClose();
    } else {
      toast.error(responseSave.message.text);
    }

    setIsSaving(false);
  }

  function handleGoToBrands() {
    handleClose();
    history.push('brands/?backTo=/modules');
  }

  return (
    <Modal size="large" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo Módulo' : 'Editar Módulo'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field">
            <div className="three fields">
              <div className="ui fluid four wide field required">
                <label>Marca</label>
                <div className="ui action right labeled input">
                  <select
                    className="ui dropdown"
                    name="BRAND_ID"
                    value={brandId}
                    onChange={e => setBrandId(e.target.value)}
                    disabled={isSaving}
                  >
                    {brands &&
                      brands.map(brand => (
                        <option
                          key={brand.ID}
                          value={brand.ID}
                          selected={brand.ID === brandId}
                        >
                          {brand.NAME}
                        </option>
                      ))}
                  </select>

                  {user.TYPE_KEY_ !== 'GROUP' && (
                    <button
                      type="button"
                      title="Gerenciar marcas"
                      className="ui label icon button green m-r-sm"
                      onClick={handleGoToBrands}
                    >
                      <i className="mdi mdi-cogs icon" />
                    </button>
                  )}
                </div>
              </div>

              <div className="six wide field required">
                <label>Modelo</label>
                <input
                  type="text"
                  name="MODEL"
                  className="ui action input"
                  placeholder="CS6U 330P"
                  ref={inputModel}
                  value={model}
                  onChange={e => setModel(e.target.value)}
                  onKeyPress={e => e.key === 'Enter' && inputPower.focus()}
                />
              </div>

              <div className="six wide field required">
                <label>Potência (W)</label>
                <InputNumberFormat
                  type="tel"
                  value={power || ''}
                  displayType="input"
                  placeholder="330"
                  onValueChange={v => setPower(v.floatValue)}
                  onKeyPress={e =>
                    e.key === 'Enter' && inputCircuitVoltageOpen.focus()
                  }
                  getInputRef={el => setInputPower(el)}
                  disabled={isSaving}
                />
              </div>
            </div>

            <div className="seven fields">
              <div className="ui fluid text-center">
                <b>Tensão Circuito (VCC)</b>
                <div className="three fields">
                  <div className="ui fluid ten wide field required">
                    <label className="ui fluid text-center">Aberto</label>
                    <InputNumberFormat
                      type="tel"
                      value={circuitVoltageOpen || ''}
                      displayType="input"
                      className="ui action input text-center"
                      placeholder="37,20"
                      fixedDecimalScale
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={v => setCircuitVoltageOpen(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputCircuitVoltageClosed.focus()
                      }
                      getInputRef={el => setInputCircuitVoltageOpen(el)}
                      disabled={isSaving}
                    />
                  </div>

                  <div className="ui fluid ten wide field required">
                    <label className="ui fluid text-center">Fechado</label>
                    <InputNumberFormat
                      type="tel"
                      value={circuitVoltageClosed || ''}
                      displayType="input"
                      className="ui action input text-center"
                      placeholder="37,20"
                      fixedDecimalScale
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={v => setCircuitVoltageClosed(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputCurrentOpen.focus()
                      }
                      getInputRef={el => setInputCircuitVoltageClosed(el)}
                      disabled={isSaving}
                    />
                  </div>
                </div>
              </div>

              <div className="ui fluid text-center m-l-sm">
                <b>Corrente (A)</b>
                <div className="three fields">
                  <div className="ui fluid ten wide field required">
                    <label className="ui fluid text-center">Aberto</label>
                    <InputNumberFormat
                      type="tel"
                      value={currentOpen || ''}
                      displayType="input"
                      className="ui action input text-center"
                      placeholder="9,45"
                      fixedDecimalScale
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={v => setCurrentOpen(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputCurrentClosed.focus()
                      }
                      getInputRef={el => setInputCurrentOpen(el)}
                      disabled={isSaving}
                    />
                  </div>

                  <div className="ui fluid ten wide field required">
                    <label className="ui fluid text-center">Fechado</label>
                    <InputNumberFormat
                      type="tel"
                      value={currentClosed || ''}
                      displayType="input"
                      className="ui action input text-center"
                      placeholder="9,45"
                      fixedDecimalScale
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={v => setCurrentClosed(v.floatValue)}
                      onKeyPress={e =>
                        e.key === 'Enter' && inputDimension.current.focus()
                      }
                      getInputRef={el => setInputCurrentClosed(el)}
                      disabled={isSaving}
                    />
                  </div>
                </div>
              </div>

              <div className="six wide field required m-t-md">
                <label>Dimensão</label>
                <input
                  type="tel"
                  className="ui action input"
                  placeholder="1960 X 992 X 35"
                  ref={inputDimension}
                  value={dimension}
                  onChange={e => setDimension(e.target.value)}
                  onKeyPress={e => e.key === 'Enter' && inputWeight.focus()}
                />
              </div>

              <div className="six wide field required m-t-md">
                <label>Peso</label>
                <InputNumberFormat
                  type="tel"
                  value={weight || ''}
                  displayType="input"
                  className="ui action input"
                  placeholder="22,40"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  onValueChange={v => setWeight(v.floatValue)}
                  onKeyPress={e => e.key === 'Enter' && handleConfirm()}
                  getInputRef={el => setInputWeight(el)}
                  disabled={isSaving}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
