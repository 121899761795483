import React, { useState } from 'react';

import classNames from 'classnames';
import { Grid } from 'semantic-ui-react';
import swal from 'sweetalert';

import api from '~/services/api';

export default function SubPageDocuments({ projectId }) {
  const [loading, setLoading] = useState(false);

  async function downloadFile(format) {
    setLoading(true);

    const response = await api({
      url: 'project/docs/budget',
      method: 'GET',
      responseType: 'blob', // important
      params: {
        project_id: projectId,
        output: format || 'pdf',
      },
    });

    setLoading(false);

    if (response.data && response.data.status === 302) {
      swal('Atenção', response.data.message, 'error');

      return;
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    // link.target = '_blank';
    link.setAttribute(
      'download',
      response.headers['content-disposition'].split('filename=')[1] ||
        `Orçamento do projeto.${format || 'pdf'}`
    );
    link.click();
    window.URL.revokeObjectURL(url);
  }

  function handleDownloadDOCX() {
    downloadFile('docx');
  }

  function handleDownloadPDF() {
    downloadFile('pdf');
  }

  return (
    <>
      <h4 className="ui horizontal divider">Documentos</h4>

      <Grid centered>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <h4 className="ui top attached inverted bg-blue header">
            Orçamento do projeto (cliente)
          </h4>
          <div className="ui attached segment bg-grey text-center">
            <h4>Escolha o formato para download</h4>
            <button
              type="button"
              className={classNames('ui button blue icon', {
                loading,
              })}
              disabled={loading}
              onClick={handleDownloadDOCX}
            >
              <i className="mdi mdi-file-word icon" />
              DOCX (Office)
            </button>
            <button
              type="button"
              className={classNames('ui button red icon', {
                loading,
              })}
              disabled={loading}
              onClick={handleDownloadPDF}
            >
              <i className="mdi mdi-file-pdf icon" />
              PDF
            </button>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
}
