/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalMaterialAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState(data.DESCRIPTION || '');
  const [unitValue, setUnitValue] = useState(data.PRICE || 0);

  const inputDescription = useRef(null);
  const [inputQuantity, setInputQuantity] = useState(null);

  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.focus();
    }
  }, [inputDescription]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/material/add' : '/material/update',
      params: {
        material_id: data.ID || null,
        material: JSON.stringify({
          DESCRIPTION: description,
          PRICE: unitValue,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar o material: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo material' : 'Editar material'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Descrição</label>
            <input
              type="text"
              ref={inputDescription}
              value={description}
              onChange={e => setDescription(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && inputQuantity.focus()}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Valor unitário (R$)</label>
            <InputNumberFormat
              type="tel"
              value={unitValue || ''}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setUnitValue(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputQuantity(el)}
              disabled={isSaving}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
