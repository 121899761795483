import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import api from '~/services/api';

import { Container } from './styles';

export default function Activate() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isActivate, setIsActivate] = useState(false);
  const [isActiveWithoutPass, setIsActiveWithoutPass] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const query = queryString.parse(location.search);

  useEffect(() => {
    validate();

    async function validate() {
      setIsLoading(true);

      const { data: response } = await api({
        method: 'POST',
        url:
          query.activate === 'account'
            ? 'account/activate'
            : 'credential/activate',
        params: {
          token: query.token,
        },
      });

      if (response.status === 0) {
        setIsLoading(false);
        setIsActivate(true);
      } else {
        const { data: check } = await api({
          method: 'POST',
          url: 'voucher/check',
          params: {
            token: query.token,
          },
        });

        if (check.status === 0) {
          setIsLoading(false);
          setIsActiveWithoutPass(true);
        } else {
          setIsLoading(false);
          setIsComplete(true);
        }
      }
    }
  }, [query.token]);

  return (
    <Container>
      {isActivate && (
        <div className="ui message green">
          <div className="header">Parabéns!</div>
          Cadastro ativado com sucesso.{' '}
          <Link to={`/reset?voucher=${query.voucher}`}>Clique aqui</Link> para
          criar uma senha e acessar o sistema.
        </div>
      )}

      {isActiveWithoutPass && (
        <div className="ui message yellow">
          <div className="header">Quase lá!</div>
          Você já ativou seu cadastro, porém, não configurou nenhuma senha!
          <Link to={`/reset?voucher=${query.voucher}`}>Clique aqui</Link> para
          criar uma senha e acessar o sistema.
        </div>
      )}

      {isComplete && (
        <div className="ui message red">
          <div className="header">Atenção!</div>
          <p>Você já ativou o cadastro e também configurou uma senha.</p>
          <p>
            <Link to="/login">Clique aqui</Link> para acessar o sistema.
          </p>
          <p>
            Caso não lembre a senha <Link to="/forgot">clique aqui</Link> para
            recuperar.
          </p>
        </div>
      )}

      {isLoading && (
        <div className="ui attached segment">
          <div className="ui icon message">
            <div className="content">
              <div className="header">Só um momento...</div>
              <p>Estamos validando seu cadastro.</p>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}
