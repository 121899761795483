import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import ClientCardOnline from '~/components/Card/Client';
import Table from '~/components/Table';
import api from '~/services/api';

// import FormFilter from './components/Filter';

export default function Search() {
  const history = useHistory();

  const [showTable, setShowTable] = useState(!isMobile);

  const [clients, setClients] = useState([]);
  const [summary, setSummary] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter, setFilter] = useState({
    LIMIT: '10',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchClients = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/client/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setClients(responseSearch.data);
      setSummary(responseSearch.summary);
    } else {
      setClients([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchClients(filter, newPagination);
    }, 1000)
  ).current;

  // const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    searchClients(filter, pagination);
  }, [searchClients]);

  useEffect(() => {
    searchClients(filter, {
      ...pagination,
      keyword,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchClients(filter, pagination);
  }

  function handlePaginationChange(e, { activePage }) {
    searchClients(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleClientDelete(clientId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um cliente permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'client/delete',
          params: {
            client_id: clientId,
          },
        });

        if (responseDelete.status === 0) {
          searchClients(filter, pagination);
          toast.success('Material excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setFilter({
      ...filter,
      LIMIT: e.target.value,
    });

    setLimitChanged(true);
  }

  // function onConfirmFilter(data) {
  //   const newOrderBy = [];

  //   setFilter({ ...filter, data });

  //   newOrderBy.push({
  //     field: data.FIELD,
  //     mode: data.ORDER,
  //   });

  //   searchClients(
  //     { ...filter, data },
  //     {
  //       ...pagination,
  //       ...{
  //         limit: data.LIMIT,
  //         orderby: newOrderBy,
  //       },
  //     }
  //   );
  // }

  function renderClientsWithCard() {
    return (
      <Grid centered>
        {clients.length > 0 &&
          !isLoading &&
          clients.map(client => (
            <Grid.Column key={client.ID} mobile={16} tablet={8} computer={4}>
              <ClientCardOnline
                handleEdit={() =>
                  history.push(`/client/${client.ID}?backTo=/clients`)
                }
                handleDelete={() => handleClientDelete(client.ID)}
                handleBill={() => history.push(`/client/${client.ID}/bills`)}
                client={client}
              />
            </Grid.Column>
          ))}

        {!isLoading && clients.length === 0 && (
          <Grid.Column>
            <Message content="Nenhum cliente encontrado! Atualize os filtros e pesquisa novamente." />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando clientes..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function getNameByType(client) {
    return client.TYPE_FORMAT === 'PF' ? (
      <span>{client.NAME || '-'}</span>
    ) : (
      <>
        <span>{client.COMPANY_NAME || '-'}</span>
        <br />
        <span>{client.FANTASY_NAME || '-'}</span>
      </>
    );
  }

  function renderClientsWithTable() {
    return (
      clients && (
        <Table
          cols={[
            {
              field: 'NAME',
              description: 'Nome do cliente',
              default: true,
            },
            { field: 'EMAIL', description: 'E-mail' },
            { field: 'CITY_NAME', description: 'Cidade' },
            {
              field: 'PHONE',
              description: 'Responsável',
            },
            {
              field: 'DATE_CREATE_FORMAT',
              description: 'Data de Cadastro',
            },
            { field: 'ACTIONS', description: 'Ações' },
          ]}
          mapping={client => ({
            NAME: getNameByType(client),
            EMAIL: client.EMAIL,
            CITY_NAME: client.CITY_NAME,
            PHONE: `${client.CONTACT || '-'}  ${' '}
            ${
              client.PHONE && client.CELL_PHONE
                ? `${client.PHONE} / ${client.CELL_PHONE}`
                : client.PHONE
            }${' '}`,
            DATE_CREATE_FORMAT: client.DATE_CREATE_FORMAT,
            ACTIONS: () => (
              <>
                <Button
                  size="mini"
                  icon
                  color={client.ENERGY_BILL_QTTY > 0 ? 'green' : 'orange'}
                  title="Contas do cliente"
                  onClick={() => history.push(`/client/${client.ID}/bills`)}
                >
                  <Icon name="file alternate" /> {client.ENERGY_BILL_QTTY}
                </Button>

                <Dropdown
                  icon="cog"
                  floating
                  title="Ações"
                  button
                  className="mini blue icon"
                  direction="left"
                >
                  <Dropdown.Menu>
                    <Dropdown.Header content="Ações" />
                    <Dropdown.Divider />

                    <Dropdown.Item
                      color="blue"
                      icon="pencil"
                      text="Editar cliente"
                      title="Editar cliente"
                      onClick={() =>
                        history.push(`/client/${client.ID}?backTo=/clients`)
                      }
                    />

                    <Dropdown.Item
                      color="red"
                      icon="trash"
                      text="Remover cliente"
                      title="Remover cliente"
                      onClick={() => handleClientDelete(client.ID)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ),
          })}
          data={clients}
          loading={isLoading}
          onUpdateOrderBy={orderby => {
            const newPagination = {
              ...pagination,
              orderby,
            };

            setPagination(newPagination);

            searchClients(filter, newPagination);
          }}
        />
      )
    );
  }

  function renderClientsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              {/* <button
                className="ui button label blue"
                type="button"
                disabled={isLoading}
                onClick={() => setShowFilter(!showFilter)}
              >
                <i className="mdi mdi-filter" />
                Filtros
              </button> */}

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>

            {keyword.length !== 0 && keyword.length < 3 && (
              <span>Digite pelo menos 3 caracteres</span>
            )}
          </Grid.Column>

          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </Grid.Column>

          {/* {showFilter && (
            <Grid.Column mobile={16} table={16} computer={16}>
              <FormFilter handleSubmit={onConfirmFilter} initialData={filter} />
            </Grid.Column>
          )} */}
        </Grid>

        {showTable ? renderClientsWithTable() : renderClientsWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              // boundaryRange={2}
              // siblingRange={2}
              disabled={isLoading}
              // defaultActivePage={1}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar clientes </span>
            <div>
              {!isMobile && (
                <button
                  type="button"
                  className="ui button icon"
                  title="Alterar visualização"
                  onClick={() => setShowTable(!showTable)}
                >
                  <i
                    className={classNames('mdi icon', {
                      'mdi-table': showTable,
                      'mdi-cards': !showTable,
                    })}
                  />
                </button>
              )}

              <button
                type="button"
                className="ui button green"
                onClick={() => history.push(`/client/`)}
              >
                <i className="mdi mdi-account-plus"> </i> Adicionar cliente
              </button>
            </div>
          </div>

          <Grid centered>
            <Grid.Column mobile={16} table={16} computer={16}>
              <div className="ui centered tiny statistics m-b m-t-md">
                <div className="ui statistic">
                  <div className="value">
                    {numberFormat(summary.QTTY, 0, ',', '.')}
                  </div>
                  <div className="label">Número de clientes</div>
                </div>
              </div>
            </Grid.Column>
          </Grid>

          <div className="ui attached bottom segment">
            {renderClientsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
