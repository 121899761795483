import styled from 'styled-components';

export default styled.div`
  display: inline-grid;
  grid-template-columns: repeat(${p => p.columns || 1}, 1fr);
  grid-template-rows: repeat(${p => p.rows || 1}, 1fr);
  grid-gap: 1rem;

  width: 98.5%;
  margin: 1rem 10px;

  border-radius: 0.6rem;

  > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  @media (max-width: 428px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;
