/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Modal, Pagination, Icon, Grid, Message } from 'semantic-ui-react';
import swal from 'sweetalert';

import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

import MaterialCard from './Card';

export default function ModalMaterial({
  open,
  onClose,
  handleClose,
  onSelect,
}) {
  const { openModal, closeModal } = useContext(ModalContext);

  const [materials, setMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'DESCRIPTION', mode: 'ASC' }],
  });

  const searchMaterials = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/material/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setMaterials(responseSearch.data);
      setSearched(true);
    } else {
      setMaterials([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchMaterials(filter, newPagination);
    }, 1000)
  ).current;

  useEffect(() => {
    searchMaterials(filter, pagination);
  }, [searchMaterials]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    const newPagination = {
      ...pagination,
      page: activePage,
    };

    searchMaterials(filter, newPagination);
  }

  function onCardSelect(material) {
    openModal('materialAdd', {
      initialMaterial: material,
      onAdd: data => onSelect(data),
    });
    handleClose();
  }

  function handleMaterialDelete(materialId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um material permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'material/delete',
          params: {
            material_id: materialId,
          },
        });

        if (responseDelete.status === 0) {
          searchMaterials(filter, pagination);
          toast.success('Material excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  return (
    <>
      <Modal size="fullscreen" open={open} onClose={onClose}>
        <a href="#" className="close" onClick={handleClose}>
          <i className="mdi mdi-close" />
        </a>

        <Modal.Header>Selecionar materiais</Modal.Header>

        <Modal.Content>
          <Grid centered>
            <Grid.Column mobile={16} table={8} computer={8}>
              <div className="ui action fluid input">
                <input
                  type="text"
                  placeholder="Digite o nome do material"
                  value={keyword}
                  onChange={handleKeywordChange}
                />
                <button
                  className={classNames('ui button label', {
                    loading: isLoading,
                  })}
                  type="button"
                  disabled={isLoading}
                  onClick={() => searchMaterials(filter, pagination)}
                >
                  Pesquisar
                </button>
              </div>
            </Grid.Column>
          </Grid>

          <Grid columns={1} centered>
            {materials.length > 0 &&
              !isLoading &&
              materials.map(material => (
                <Grid.Column
                  key={material.ID}
                  mobile={16}
                  tablet={8}
                  computer={4}
                >
                  <MaterialCard
                    material={material}
                    type="select"
                    handleSelect={onCardSelect}
                    handleDelete={handleMaterialDelete}
                  />
                </Grid.Column>
              ))}

            {!isLoading && materials.length === 0 && (
              <Grid.Column>
                <Message content="Nenhum material encontrado! Atualize os filtros e pesquise novamente." />
              </Grid.Column>
            )}

            {isLoading && (
              <Grid.Column mobile={16}>
                <Message color="blue" content="Buscando materiais..." />
              </Grid.Column>
            )}

            {!isLoading &&
              searched &&
              materials.length === 0 &&
              keyword.length > 0 && (
                <Grid.Column>
                  <button
                    className="ui fluid button green icon"
                    type="button"
                    onClick={() => {
                      closeModal('material');
                      openModal('materialAdd', {
                        initialDescription: keyword,
                        onAdd: data => onSelect(data),
                      });
                    }}
                  >
                    <i className="mdi mdi-plus icon" />{' '}
                    {`Criar um material com o nome "${keyword}"?`}
                  </button>
                </Grid.Column>
              )}
          </Grid>

          <Grid centered>
            <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
              <Pagination
                disabled={isLoading}
                activePage={pagination.page}
                onPageChange={handlePaginationChange}
                totalPages={pagination.total_page}
                ellipsisItem={false}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  );
}
