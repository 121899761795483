import React, { useMemo, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import { Grid } from 'semantic-ui-react';
import swal from 'sweetalert';

import 'datejs';

import api from '~/services/api';

export default function SubPageHistory({
  store,
  updateSelf,
  projectId,
  usedBy = [],
  backTo,
  mode,
}) {
  const history = useHistory();

  const { self, billId } = store;

  const [hasChange, setHasChange] = useState(false);

  const historyValues = useMemo(
    () =>
      Array.from(self.ENERGY_BILL_HISTORY || {}).reduce((acc, item) => {
        return {
          ...acc,
          [item.MONTH]: item.KWH,
        };
      }, {}),
    [self]
  );

  const lastTwelveMonths = useMemo(() => {
    const months = [];

    const ptMonths = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    for (let i = 0; i < 12; i += 1) {
      const date = Date.today();

      // const month = date.getMonth();
      const actualYear = date.getFullYear();

      months.push({
        // key: `${month < 10 ? `0${month}` : month}`,
        key: i + 1,
        year: actualYear,
        // days: date.getDaysInMonth(),
        name: `${ptMonths[i]}`,
      });
    }

    return months;
  }, []);

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    defaultValues: historyValues,
  });

  async function handleFormSubmit(data) {
    setLoading(true);

    const historySerialized = [];
    const shouldAskConfirmation = mode === 'edit' && usedBy.length > 0;

    Object.keys(data).forEach(month => {
      historySerialized.push({
        MONTH: month < 10 ? `0${month}` : month,
        KWH: data[month] || 0,
      });
    });

    let confirmation;
    if (shouldAskConfirmation) {
      confirmation = await swal({
        title: 'Atencão',
        dangerMode: true,
        text:
          'Essa conta pode estar vinculada em outros projetos!\n' +
          'Confirmar a alteração acarretará na na mudança dos resultados desses projeto.\n\n' +
          'Deseja continuar?',
        icon: 'warning',
        buttons: ['Não', 'Sim'],
      });
    }

    if (confirmation || !shouldAskConfirmation) {
      const { data: response } = await api({
        method: 'post',
        url: '/energy/bill/history/save',
        params: {
          energy_bill_id: billId,
          energy_bill_history: JSON.stringify(historySerialized),
        },
      });

      if (response.status === 0) {
        updateSelf();
        toast.success('Histórico salvo com sucesso!');
      } else {
        toast.error(response.message.text);
      }

      if (projectId) {
        const { data: responseProject } = await api({
          method: 'post',
          url: '/project/energy/bill/add',
          params: {
            project_id: projectId,
            energy_bill: JSON.stringify([billId]),
          },
        });

        if (responseProject.status === 0) {
          toast.success(responseProject.message.text);
        } else {
          toast.error(responseProject.message.text);
        }
      }

      if (backTo) {
        history.push(backTo);
      }
    }

    setLoading(false);
  }

  const debouncedSubmit = useRef(debounce(data => handleFormSubmit(data), 300))
    .current;

  function handleChangeInput() {
    const data = getValues();
    debouncedSubmit(data);
  }

  return (
    <form
      className={classNames('ui form')}
      onSubmit={handleSubmit(handleChangeInput)}
    >
      <h4 className="ui horizontal divider">Histórico da conta</h4>

      <div className="ui centered tiny statistics m-b">
        <div className="ui yellow statistic">
          <div
            className={classNames('value', {
              'text-line': hasChange,
            })}
          >
            {numberFormat(self.KWH_TOTAL, 0, ',', '.')}
          </div>
          <div className="label">Total Consumo Ano (kWh)</div>
        </div>
        <div className="ui yellow statistic">
          <div
            className={classNames('value', {
              'text-line': hasChange,
            })}
          >
            {numberFormat(self.KWH_MEAN, 0, ',', '.')}
          </div>
          <div className="label">Média de Consumo Mês (kWh)</div>
        </div>
      </div>

      {hasChange && (
        <div className="ui message yellow">
          Clique em salvar para atualizar os totais e média de consumo.
        </div>
      )}

      <div className="field">
        <div className="ui centered grid">
          {lastTwelveMonths.map(item => (
            <Grid.Column mobile={8} computer={4} className="" key={item.name}>
              <label>
                <b>{item.name} (KWH)</b>
              </label>
              <input
                name={item.key}
                type="number"
                placeholder="0"
                onChange={() => setHasChange(true)}
                disabled={loading}
                ref={e => {
                  register(e, { min: 0, required: true });
                }}
              />
            </Grid.Column>
          ))}
        </div>
      </div>

      <div className="field">
        <button
          type="submit"
          disabled={!hasChange}
          className={classNames('ui button fluid blue', {
            loading,
          })}
        >
          Salvar
        </button>
      </div>
    </form>
  );
}
