import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { Dropdown } from 'semantic-ui-react';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

function FormRegister({ onSubmit, initialData }) {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    getValues,
  } = useForm({
    defaultValues: initialData,
  });

  const inputNumberFef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  const [personSelected, setPersonSeleted] = useState(initialData.TYPE);
  const [maskPersonDocument, setMaskPersonDocument] = useState();

  const [stateSelected, setStateSelected] = useState(initialData.UF_ID);
  const [citySelected, setCitySelected] = useState(initialData.CITY_ID);

  const [isFindingCEP, setIsFindingCEP] = useState(false);

  const [groupList, setGroupList] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const filteredCities = useMemo(() => {
    if (stateSelected) {
      return cityList.map(city => ({
        key: city.ID,
        value: city.ID,
        text: city.NAME,
      }));
    }

    return [];
  }, [cityList, stateSelected]);

  const loadStatesAndCities = useCallback(async () => {
    setIsLoading(true);

    const states = await getState();
    const cities = await getCity();

    const statesMap = states.map(state => ({
      key: state.ID,
      value: state.ID,
      text: state.NAME,
    }));

    setStateList(statesMap);
    setCityList(cities);

    setIsLoading(false);
  }, []);

  const getGroupList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupList(response.data);

      if (initialData.ACCOUNT_GROUP_ID) {
        setValue('ACCOUNT_GROUP_ID', initialData.ACCOUNT_GROUP_ID);
      }
    }
  }, []);

  async function getState() {
    const { data: uf } = await api({
      method: 'post',
      url: 'uf/list',
      params: {
        filter: JSON.stringify({}),
      },
    });

    if (uf.status === 0) {
      return uf.data;
    }

    return false;
  }

  async function getCity() {
    if (stateSelected) {
      const { data: city } = await api({
        method: 'post',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: stateSelected,
          }),
        },
      });

      if (city.status === 0) {
        return city.data;
      }
    }

    return [];
  }

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  useEffect(() => {
    loadStatesAndCities();
  }, [loadStatesAndCities]);

  useEffect(() => {
    if (groupList.length && initialData.ACCOUNT_GROUP_ID) {
      setValue('ACCOUNT_GROUP_ID', initialData.ACCOUNT_GROUP_ID);
    }
  }, [groupList]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [stateSelected]);

  useEffect(() => {
    if (personSelected === 'P') {
      setMaskPersonDocument('999.999.999-99');
    } else {
      setMaskPersonDocument('99.999.999/9999-99');
    }
  }, [personSelected]);

  useEffect(() => {
    if (formState.submitCount > 0 && !formState.isValid) {
      toast.error('Verifique todos os campos obrigatórios.');
    }
  }, [formState.submitCount, formState.isValid]);

  function handleChangePerson(event) {
    setPersonSeleted(event.target.value);
  }

  function renderPersonPJFields() {
    return (
      <div className="field">
        <div className="two fields">
          <div
            className={classNames('ui field required', {
              error: errors.COMPANY_NAME,
            })}
          >
            <label>Razão social</label>
            <input
              type="text"
              name="COMPANY_NAME"
              placeholder="Razão social"
              ref={register({ required: true })}
            />
            {errors.COMPANY_NAME && <span>Razão social é obrigatória</span>}
          </div>

          <div
            className={classNames('ui field required', {
              error: errors.FANTASY_NAME,
            })}
          >
            <label>Nome fantasia</label>
            <input
              type="text"
              name="FANTASY_NAME"
              placeholder="Nome fantasia"
              ref={register({ required: true })}
            />
            {errors.FANTASY_NAME && <span>Nome fantasia é obrigatório</span>}
          </div>
        </div>

        <div className="two fields">
          <div className="field">
            <label>Inscrição estadual</label>
            <input
              type="text"
              name="STATE_REGISTRATION"
              placeholder="Inscrição estadual"
              ref={register}
            />
          </div>

          <div className="field">
            <label>Inscrição municipal</label>
            <input
              type="text"
              name="TOWN_REGISTRATION"
              placeholder="Inscrição municipal"
              ref={register}
            />
          </div>
        </div>

        <div className="field">
          <label>Proprietário</label>
          <input
            type="text"
            name="HOLDER"
            placeholder="Proprietário"
            ref={register}
          />
        </div>
      </div>
    );
  }

  function renderPersonPFFields() {
    return (
      <div className="field">
        <div className="two fields">
          <div className="field">
            <label>RG</label>
            <input type="text" name="RG" placeholder="RG" ref={register} />
          </div>

          <div className="field">
            <label>Emissor RG</label>
            <input
              type="text"
              name="EMISSOR_RG"
              placeholder="Orgão Emissor do RG"
              ref={register}
            />
          </div>
        </div>

        <div className="field required">
          <label>Nome completo</label>
          <input
            type="text"
            name="NAME"
            placeholder="Nome completo"
            ref={register({ required: true })}
          />
          {errors.NAME && <span>Nome completo é obrigatório</span>}
        </div>
      </div>
    );
  }

  async function handleFindCEP() {
    const { POSTAL_CODE: cep } = getValues();

    if (!cep) return;

    setIsFindingCEP(true);

    const { data } = await api({
      method: 'get',
      url: 'cep/lookup',
      params: {
        cep,
      },
    });

    setIsFindingCEP(false);

    if (data.status === 0) {
      const { UF, CITY, ADDRESS } = data.data;

      setStateSelected(+UF.ID);
      setValue('UF_ID', +UF.ID);

      setCitySelected(+CITY.ID);
      setValue('CITY_ID', +CITY.ID);

      setValue('ADDRESS', ADDRESS.VALUE);
      setValue('DISTRICT', ADDRESS.DISTRICT);

      if (inputNumberFef.current) {
        inputNumberFef.current.focus();
      }
    } else {
      toast.error(data.message.text);
    }
  }

  function updateCity(cityId) {
    setValue('CITY_ID', cityId);
    setCitySelected(cityId);
  }

  function updateState(stateId) {
    setValue('UF_ID', stateId);
    setStateSelected(stateId);
  }

  function prepareSubmit(data) {
    const payload = data;

    if (personSelected === 'P') {
      Object.assign(payload, { CPF: data.PERSON_DOCUMENT });
    } else {
      Object.assign(payload, { CNPJ: data.PERSON_DOCUMENT });
    }

    delete payload.PERSON_DOCUMENT;
    onSubmit({ ...payload });
  }

  return (
    <form
      className={classNames('ui form', {
        loading: isLoading,
      })}
      onSubmit={handleSubmit(prepareSubmit)}
    >
      <h4 className="ui dividing header">Informações básicas</h4>

      <div className="fields">
        <div className="four wide field required">
          <label>Grupo</label>
          <select
            className="ui dropdown"
            name="ACCOUNT_GROUP_ID"
            ref={register({ required: true })}
            disabled={groupList.length === 0}
            readOnly={groupList.length === 1}
          >
            {groupList.map(type => (
              <option key={type.ID} value={type.ID}>
                {type.NAME}
              </option>
            ))}
          </select>
          {errors.ACCOUNT_GROUP_ID && <span>Grupo é obrigatório</span>}
        </div>

        <div className="two wide field required">
          <label>Tipo pessoa</label>

          <select
            className={classNames('ui dropdown', {
              error: errors.PERSON_DOCUMENT,
            })}
            name="TYPE"
            onChange={handleChangePerson}
            ref={register({ required: true })}
          >
            <option value="L">Pessoa jurídica</option>
            <option value="P">Pessoa física</option>
          </select>
        </div>

        <div className="four wide field">
          <label>CNPJ/CPF</label>
          <MaskedInput
            mask={maskPersonDocument}
            type="tel"
            name="PERSON_DOCUMENT"
            placeholder={`Ex: ${maskPersonDocument}`}
            register={register({ required: false })}
          />
          {errors.PERSON_DOCUMENT && <span>CNPJ/CPF é obrigatório</span>}
        </div>

        <div className="six wide field required">
          <label>Email</label>
          <input
            type="email"
            name="EMAIL"
            placeholder="exemplo@exemplo.com"
            ref={register({ required: true })}
          />
          {errors.EMAIL && <span>Email é obrigatório</span>}
        </div>
      </div>

      {personSelected === 'P' ? renderPersonPFFields() : renderPersonPJFields()}

      <h4 className="ui dividing header">Responsável</h4>

      <div className="field">
        <div className="two fields">
          <div className="field required">
            <label>Nome do responsável</label>
            <input
              type="text"
              name="RESPONSIBLE_NAME"
              placeholder=""
              ref={register({ required: true })}
            />
            {errors.RESPONSIBLE_NAME && (
              <span>Nome do responsável é obrigatório</span>
            )}
          </div>
          <div className="field required">
            <label>E-mail do responsável</label>
            <input
              type="email"
              name="RESPONSIBLE_EMAIL"
              placeholder="exemplo@exemplo.com"
              ref={register({ required: true })}
            />
            {errors.RESPONSIBLE_EMAIL && (
              <span>E-mail do responsável é obrigatório</span>
            )}
          </div>
        </div>
      </div>

      <div className="two fields">
        <div className="field">
          <label>Telefone (fixo) do responsável</label>
          <MaskedInput
            mask="(99) 9999-9999"
            type="tel"
            name="RESPONSIBLE_PHONE"
            placeholder="(00) 0000-0000"
            defaultValue={initialData.RESPONSIBLE_PHONE}
            register={register}
          />
        </div>
        <div className="field">
          <label>Telefone (celular) do responsável</label>
          <MaskedInput
            mask="(99) 9 9999-9999"
            type="tel"
            name="RESPONSIBLE_MOBILE"
            defaultValue={initialData.RESPONSIBLE_MOBILE}
            placeholder="(00) 0 0000-0000"
            register={register}
          />
        </div>
      </div>

      <h4 className="ui dividing header">Localização</h4>

      <div className="field">
        <div className="three fields">
          <div className="field">
            <label>CEP</label>
            <div className="ui action input">
              <InputNumberFormat
                type="tel"
                format="#####-###"
                mask="_"
                defaultValue={initialData.POSTAL_CODE}
                displayType="input"
                onBlur={handleFindCEP}
                onValueChange={v => setValue('POSTAL_CODE', v.value)}
                onKeyPress={e => e.key === 'Enter' && handleFindCEP(e)}
              />
              <input type="hidden" name="POSTAL_CODE" ref={register} />

              <button
                type="button"
                className={classNames('ui button', {
                  loading: isFindingCEP,
                })}
                disabled={isFindingCEP}
                onClick={handleFindCEP}
              >
                Buscar
              </button>
            </div>
          </div>

          <div className="field required">
            <label>Estado</label>
            <Dropdown
              fluid
              search
              selection
              options={stateList}
              value={stateSelected}
              placeholder="Seleciona um estado."
              noResultsMessage="Nenhum estado encontrado."
              onChange={(e, data) => updateState(data.value)}
            />
            <input
              type="hidden"
              name="UF_ID"
              ref={register({ required: true })}
            />{' '}
            {errors.UF_ID && <span>Estado é obrigatório</span>}
          </div>

          <div className="field required">
            <label>Cidade</label>
            <Dropdown
              fluid
              search
              selection
              options={filteredCities}
              value={citySelected}
              placeholder="Seleciona uma cidade."
              noResultsMessage="Nenhuma cidade encontrada."
              onChange={(e, data) => {
                updateCity(data.value);
              }}
            />
            <input
              type="hidden"
              name="CITY_ID"
              ref={register({ required: true })}
            />
            {errors.CITY_ID && <span>Cidade é obrigatória</span>}
          </div>
        </div>

        <div className="two fields">
          <div className="twelve wide field">
            <label>Endereço</label>
            <input
              type="text"
              name="ADDRESS"
              placeholder="Ex: Rua. Padre João"
              ref={register}
            />
          </div>

          <div className="four wide field">
            <label>Número</label>
            <input
              type="text"
              name="NUMBER"
              placeholder="Ex: 123"
              ref={e => {
                inputNumberFef.current = e;
                register(e);
              }}
            />
          </div>

          <div className="field">
            <label>Complemento</label>
            <input
              type="text"
              name="COMPLEMENT"
              placeholder="Ex: Apartamento 10"
              ref={register}
            />
          </div>

          <div className="field">
            <label>Bairro</label>
            <input
              type="text"
              name="DISTRICT"
              placeholder="Ex: Vila Mariana"
              ref={register}
            />
          </div>
        </div>
      </div>

      <button className="ui fluid button blue" type="submit">
        Confirmar
      </button>
    </form>
  );
}

export default FormRegister;
