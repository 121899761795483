import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalStatusAdd({
  onClose,
  handleClose,
  // mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [filter] = useState({});
  const [step, setStep] = useState(data.PROJECT_STEP_ID || '');
  const [stepList, setStepList] = useState([]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: '/project/step/update',
      params: {
        step_id: step,
        project_id: data.PROJECT_ID,
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar a etapa: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  const searchSteps = useCallback(async () => {
    const { data: responseSteps } = await api({
      method: 'post',
      url: 'step/select',
      params: {
        filter,
        orderby: { field: 'KEY_STEP', mode: 'ASC' },
      },
    });

    if (responseSteps.status === 0) {
      const stepsMap = responseSteps.data.map(s => {
        let description = '';

        switch (s.KEY_STEP) {
          case 'BUDGET':
            description = 'Em orçamento';
            break;
          case 'EXECUTE':
            description = 'Em execução';
            break;
          case 'FINISH':
            description = 'Finalizado';
            break;
          case 'CANCEL':
            description = 'Cancelado';
            break;
          default:
            description = 'Etapa desconhecida';
        }

        return {
          key: s.ID,
          text: description,
          value: s.ID,
        };
      });

      setStepList(stepsMap);
    } else {
      setStepList([]);
    }
  }, []);

  useEffect(() => {
    searchSteps();
  }, [searchSteps]);

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>Etapa do projeto</Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field">
            <label>Etapa do projecto</label>
            <Dropdown
              fluid
              selection
              value={step}
              options={stepList}
              onChange={(e, d) => setStep(d.value)}
              placeholder="Selecione uma etapa"
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
