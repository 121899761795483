import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Pagination, Icon, Grid, Button } from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function BrandGroupSearch() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [backTo] = useState(query.get('backTo'));

  const { groupId } = useParams();

  const { openModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const [group, setGroup] = useState({});
  const [brandGroups, setBrandGroups] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({
    ACCOUNT_GROUP_ID: groupId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ACCOUNT_GROUP_ID', mode: 'DESC' }],
  });

  const searchBrandGroups = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'brand/account/group/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setBrandGroups(responseSearch.data);
    } else {
      setBrandGroups([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      const groupData = await getGroupSelf({ account_group_id: groupId });
      setGroup(groupData);
    })();
  }, [groupId]);

  async function getGroupSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'account/group/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchBrandGroups(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchBrandGroups(filter, pagination);
  }, [searchBrandGroups]);
  useEffect(() => {
    searchBrandGroups(filter, {
      ...pagination,
      keyword,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    searchBrandGroups(filter, {
      ...pagination,
      page: activePage,
    });
  }

  async function handleBrandGroupDelete(brandGroup) {
    swal({
      dangerMode: true,
      title: 'Atenção',
      text:
        'Você está prestes a excluir uma marca deste grupo. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: brandGroupResponse } = await api({
          method: 'post',
          url: 'brand/delete',
          params: {
            brand_id: brandGroup.BRAND_ID,
            account_group_id: brandGroup.ACCOUNT_GROUP_ID,
          },
        });

        if (brandGroupResponse.status === 0) {
          toast.success('Marca excluída com sucesso!');
          searchBrandGroups(filter, pagination);
        } else {
          toast.error(brandGroupResponse.message.text);
        }
      }
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  async function handleSaveBrand(brandId) {
    const { data: responseAdd } = await api({
      method: 'post',
      url: '/brand/account/group/add',
      params: {
        account_group_id: groupId,
        brand_id: brandId,
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);
    } else {
      toast.error(`${responseAdd.message.text}`);
    }
  }

  function renderBrandGroupsWithTable() {
    return (
      brandGroups && (
        <Table
          cols={[
            { field: 'BRAND_NAME', description: 'Marca' },
            { field: 'CREATED_AT', description: 'Data da permissão' },
            { field: 'ACTIONS', description: 'Ações' },
          ]}
          mapping={brand => ({
            BRAND_NAME: brand.BRAND_NAME,
            CREATED_AT: new Date(brand.CREATED_AT).toLocaleString(),
            ACTIONS: () => (
              <Button
                size="mini"
                icon
                color="red"
                title="Remover marca"
                onClick={() => handleBrandGroupDelete(brand)}
              >
                <Icon name="trash" />
              </Button>
            ),
          })}
          data={brandGroups}
          loading={isLoading}
          onUpdateOrderBy={orderby => {
            const newPagination = {
              ...pagination,
              orderby,
            };

            searchBrandGroups(filter, { ...newPagination, keyword });
          }}
        />
      )
    );
  }

  function renderBrandGroupsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={() => searchBrandGroups(filter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            className="middle aligned"
            textAlign="right"
            mobile={16}
            table={8}
            computer={6}
          >
            <span>Registros por página:</span>
          </Grid.Column>

          <Grid.Column mobile={16} table={8} computer={2} floated="right">
            <select className="ui dropdown fluid" onChange={handleLimitChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </Grid.Column>
        </Grid>

        {renderBrandGroupsWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar marcas do grupo </span>
            <div>
              {user.TYPE_KEY_ === 'ROOT' && (
                <button
                  type="button"
                  className="ui button green"
                  onClick={() => {
                    openModal('brandSelect', {
                      mode: 'add',
                      data: {
                        ACCOUNT_GROUP_ID: groupId,
                      },
                      onConfirm: async selectedBrand => {
                        await handleSaveBrand(selectedBrand);
                        searchBrandGroups(filter, pagination);
                      },
                    });
                  }}
                >
                  <i className="mdi mdi-plus icon" /> Nova marca
                </button>
              )}
              {backTo && (
                <button
                  type="button"
                  className="ui button primary"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              )}
            </div>
          </div>

          <div className="ui attached bottom segment">
            <div className="ui message">
              <div className="header">Informações do grupo:</div>
              <div className="content">{group.NAME}</div>
            </div>

            {renderBrandGroupsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
