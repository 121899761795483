import styled from 'styled-components';

export const Container = styled.div`
  .ui.message {
    text-align: center;
  }
`;

export const Logo = styled.img`
  width: 800px;
  margin-top: 50px;
`;
