import React, { createContext, useState } from 'react';

const ModalContext = createContext({});

export function ModalProvider({ children }) {
  const [list, setList] = useState([]);

  function openModal(key, props) {
    setList(oldList => [
      ...oldList,
      {
        key,
        props,
      },
    ]);
  }

  function closeModal(key) {
    setList(oldList => oldList.filter(modal => modal.key !== key));
  }

  return (
    <ModalContext.Provider
      value={{
        modals: list,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
