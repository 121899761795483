import React, { useState, useEffect, useCallback } from 'react';

import { Modal, Button, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalCitySelect({ onClose, handleClose, onConfirm }) {
  const [isLoading, setIsLoading] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedState, setSelectedState] = useState(0);

  async function getState() {
    const { data: uf } = await api({
      method: 'post',
      url: 'uf/list',
      params: {
        filter: JSON.stringify({}),
      },
    });

    if (uf.status === 0) {
      const states = uf.data.map(state => ({
        key: state.ID,
        value: state.ID,
        text: state.NAME,
      }));

      return states;
    }

    return [];
  }

  async function getCity() {
    if (selectedState) {
      const { data: city } = await api({
        method: 'post',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: selectedState,
          }),
        },
      });

      if (city.status === 0) {
        const cities = city.data.map(state => ({
          key: state.ID,
          value: state.ID,
          text: state.NAME,
        }));

        return cities;
      }
    }

    return [];
  }

  const loadStatesAndCities = useCallback(async () => {
    setIsLoading(true);

    const states = await getState();
    const cities = await getCity();

    setStateList(states);
    setCityList(cities);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadStatesAndCities();
  }, [loadStatesAndCities]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [selectedState]);

  async function handleConfirm() {
    onConfirm(selectedCity);

    handleClose();
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>Selecionar cidade</Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Estado</label>

            <Dropdown
              fluid
              search
              selection
              options={stateList}
              value={selectedState}
              placeholder="Seleciona um estado."
              noResultsMessage="Nenhum estado encontrado."
              onChange={(e, data) => setSelectedState(data.value)}
              disabled={isLoading}
            />
          </div>

          <div className="field required">
            <label>Cidade</label>

            <Dropdown
              fluid
              search
              selection
              options={cityList}
              value={selectedCity}
              placeholder="Seleciona uma cidade."
              noResultsMessage="Nenhuma cidade encontrada."
              onChange={(e, data) => setSelectedCity(data.value)}
              disabled={isLoading}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
