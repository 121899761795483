import React from 'react';

import { Button, Card, Icon, Label } from 'semantic-ui-react';

export default function BillCard({
  bill,
  type,
  handleSelect,
  handleEdit,
  handleDelete,
}) {
  function getButtonsByType() {
    switch (type) {
      case 'select':
        return (
          <Button
            size="mini"
            icon
            color="green"
            title="Selecionar"
            onClick={() => handleSelect(bill)}
          >
            <Icon name="check" /> Selecionar
          </Button>
        );
      case 'project':
        return (
          <Button
            size="mini"
            icon
            color="red"
            title="Remover conta"
            onClick={handleDelete}
          >
            <Icon name="trash" />
          </Button>
        );
      default:
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar conta"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>

            <Button
              size="mini"
              icon
              color="red"
              title="Remover conta"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
    }
  }
  return (
    <Card fluid className="text-center">
      <Card.Content extra>
        <Label size="small" title="Pessoa física">
          {bill.ENERGY_PROVIDER_DESCRIPTION}
        </Label>
      </Card.Content>
      <Card.Content>
        <Card.Header>{bill.PROPERTY_DESCRIPTION}</Card.Header>
        <Card.Meta> Código: {bill.UNIT_CODE} </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <b>TUSD:</b> <span>{bill.TOTAL_VALUE_TAX_TUSD}</span> <b>TE:</b>{' '}
        <span>{bill.TOTAL_VALUE_TAX_TE}</span>
      </Card.Content>
      <Card.Content extra>
        Tipo Entrada: {bill.ENTRY_PATTERN_DESCRIPTION}
      </Card.Content>
      <Card.Content extra>Grupo: {bill.GROUND_GROUP_DESCRIPTION}</Card.Content>

      <Card.Content extra>
        <b>Taxa Disponibilidade: </b> <span>{bill.AVAILABILITY_RATE}</span>
      </Card.Content>

      <Card.Content extra>{getButtonsByType()}</Card.Content>
    </Card>
  );
}
