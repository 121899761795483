import { useEffect, useCallback, useState } from 'react';

import api from '~/services/api';

export default function useProjectSelf(projectId) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getProjectSelf = useCallback(async () => {
    setLoading(true);

    const { data: response } = await api({
      method: 'post',
      url: 'project/self',
      params: {
        project_id: projectId,
      },
    });

    if (response.status === 0) {
      setData(response.data);
    }

    setLoading(false);
  }, [projectId]);

  useEffect(() => {
    getProjectSelf();
  }, [getProjectSelf]);

  return [data, loading, getProjectSelf];
}
