/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Modal,
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

import ProviderCard from './Card';

export default function ModalProviderSelect({
  onClose,
  handleClose,
  onSelect,
}) {
  const { openModal } = useContext(ModalContext);

  const [showTable, setShowTable] = useState(!isMobile);

  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        setPagination({
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  const searchProviders = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/provider/search',
      params: {
        pagination: JSON.stringify(pagination),
        filter: JSON.stringify(filter),
      },
    });

    if (responseSearch.status === 0) {
      setProviders(responseSearch.data);
      setSearched(true);
    } else {
      setProviders([]);
    }

    setIsLoading(false);
  }, [filter, pagination]);

  useEffect(() => {
    searchProviders();
  }, [searchProviders]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    setPagination({
      ...pagination,
      page: activePage,
    });
  }

  function onCardSelect(provider) {
    onSelect(provider);
    onClose();
  }

  function handleDeleteProvider(providerId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um fornecedor permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'provider/delete',
          params: {
            provider_id: providerId,
          },
        });

        if (responseDelete.status === 0) {
          searchProviders(filter, pagination);
          toast.success('Fornecedor excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  async function handleClickAddProvider() {
    openModal('providerAdd', {
      mode: 'add',
      data: {
        DESCRIPTION: keyword,
      },
      onConfirm: () => searchProviders(filter, pagination),
    });
  }

  function renderWithCard() {
    return (
      <Grid columns={1} centered>
        {providers.length > 0 &&
          !isLoading &&
          providers.map(provider => (
            <Grid.Column key={provider.ID} mobile={16} tablet={8} computer={8}>
              <ProviderCard
                provider={provider}
                type="select"
                handleSelect={onCardSelect}
                handleEdit={() => {
                  openModal('providerAdd', {
                    mode: 'edit',
                    data: {
                      ID: provider.ID,
                      DESCRIPTION: provider.DESCRIPTION,
                    },
                    onConfirm: () => searchProviders(filter, pagination),
                  });
                }}
                handleDelete={() => handleDeleteProvider(provider.ID)}
              />
            </Grid.Column>
          ))}

        {!isLoading && providers.length === 0 && (
          <Grid.Column>
            <Message content="Nenhum fornecedor encontrado! Atualize os filtros e pesquise novamente." />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando fornecedores..." />
          </Grid.Column>
        )}

        {!isLoading &&
          searched &&
          providers.length === 0 &&
          keyword.length > 0 && (
            <Grid.Column>
              <button
                className="ui fluid button green icon"
                type="button"
                onClick={handleClickAddProvider}
              >
                <i className="mdi mdi-plus icon" />{' '}
                {`Criar um fornecedor com o nome "${keyword}"?`}
              </button>
            </Grid.Column>
          )}
      </Grid>
    );
  }

  function renderWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">Fornecedor</th>
            <th className="center aligned" width="30%">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {providers.length > 0 &&
            !isLoading &&
            providers.map(provider => (
              <tr key={provider.ID}>
                <td className="center aligned">
                  {provider.DESCRIPTION || '-'}
                </td>

                <td className="center aligned">
                  <Button
                    size="mini"
                    icon
                    color="green"
                    title="Selecionar fornecedor"
                    onClick={() => onCardSelect(provider)}
                  >
                    <Icon name="check" /> Selecionar
                  </Button>

                  <Button
                    size="mini"
                    icon
                    color="blue"
                    title="Editar fornecedor"
                    onClick={() => {
                      openModal('providerAdd', {
                        mode: 'edit',
                        data: {
                          ID: provider.ID,
                          DESCRIPTION: provider.DESCRIPTION,
                        },
                        onConfirm: () => searchProviders(filter, pagination),
                      });
                    }}
                  >
                    <Icon name="pencil" />
                  </Button>

                  <Button
                    size="mini"
                    icon
                    color="red"
                    title="Excluir fornecedor"
                    onClick={() => handleDeleteProvider(provider.ID)}
                  >
                    <Icon name="trash" />
                  </Button>
                </td>
              </tr>
            ))}

          {!isLoading && providers.length === 0 && (
            <tr>
              <td colSpan="6">
                <Message content="Nenhum fornecedor encontrado! Atualize os filtros e pesquisa novamente." />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="6">
                <Message color="blue" content="Buscando fornecedores..." />
              </td>
            </tr>
          )}

          {!isLoading &&
            searched &&
            providers.length === 0 &&
            keyword.length > 0 && (
              <tr>
                <td colSpan="6">
                  <button
                    className="ui fluid button green icon"
                    type="button"
                    onClick={handleClickAddProvider}
                  >
                    <i className="mdi mdi-plus icon" />{' '}
                    {`Criar um fornecedor com o nome "${keyword}"?`}
                  </button>
                </td>
              </tr>
            )}
        </tbody>
      </table>
    );
  }

  function renderContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Digite o nome do fornecedor"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={() => searchProviders()}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column mobile={8} className="right aligned">
            {!isMobile && (
              <>
                <button
                  type="button"
                  className="ui button icon"
                  title="Alterar visualização"
                  onClick={() => setShowTable(!showTable)}
                >
                  <i
                    className={classNames('mdi icon', {
                      'mdi-table': showTable,
                      'mdi-cards': !showTable,
                    })}
                  />
                </button>

                <button
                  type="button"
                  className="ui button green icon"
                  title="Novo fornecedor"
                  onClick={() => {
                    openModal('providerAdd', {
                      mode: 'add',
                      onConfirm: () => searchProviders(filter, pagination),
                    });
                  }}
                >
                  <i className="mdi mdi-plus icon" /> Novo fornecedor
                </button>
              </>
            )}
          </Grid.Column>
        </Grid>

        {showTable ? renderWithTable() : renderWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              // boundaryRange={2}
              // siblingRange={2}
              disabled={isLoading}
              // defaultActivePage={1}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <Modal size="small" open onClose={onClose}>
      <a href="#" className="close" onClick={handleClose}>
        <i className="mdi mdi-close" />
      </a>

      <Modal.Header>Selecionar fornecedor</Modal.Header>

      <Modal.Content>{renderContainer()}</Modal.Content>
    </Modal>
  );
}
