import React, { useEffect, useState, useCallback, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import { Grid, Dropdown } from 'semantic-ui-react';

import LayoutGrid from '~/components/LayoutGrid';
import api from '~/services/api';

import useProjectSelf from '../hooks/useProjectSelf';

export default function SubPageSimulation({ projectId, validateProject }) {
  const history = useHistory();

  const [project, loadingProject, updateProject] = useProjectSelf(projectId);

  const { register, setValue, watch, getValues } = useForm({
    mode: 'onChange',
    defaultValues: project,
  });

  const watchRequiredFields = watch([
    'PHOTOVOLTAIC_YIELD',
    'HOURLY_SUNNY_PEAK',
    'MODULE_BRAND',
    'MODULE_MODEL',
    'MIN_TEMPERATURE',
    'INVERTER_QUANTITY',
    'ROOF_ID',
  ]);

  useEffect(() => {
    if (loadingProject) {
      if (project.PHOTOVOLTAIC_YIELD) {
        setValue('PHOTOVOLTAIC_YIELD', project.PHOTOVOLTAIC_YIELD);
      } else {
        setValue('PHOTOVOLTAIC_YIELD', 80);
      }
      setValue('HOURLY_SUNNY_PEAK', project.HOURLY_SUNNY_PEAK);
      setValue('MIN_TEMPERATURE', project.MIN_TEMPERATURE);
      setValue('TYPE_ENERGY', project.TYPE_ENERGY);
    }
  }, [loadingProject, project]);

  const [series, setSeries] = useState(0);
  const [seriesToSave, setSeriesToSave] = useState(0);
  const [parallel, setParallel] = useState(0);
  const [additional, setAdditional] = useState(0);
  const [manualAdditional, setManualAdditional] = useState(0);

  // const [warnings, setWarnings] = useState({});

  const [inverterSelf, setInverter] = useState({});
  const [moduleSelf, setModule] = useState({});

  const [moduleBrands, setModuleBrands] = useState([]);
  const [moduleModels, setModuleModels] = useState([]);
  const [isFindingModuleBrands, setIsFindingModuleBrands] = useState(false);

  const [inverterBrands, setInverterBrands] = useState([]);
  const [inverterModels, setInverterModels] = useState([]);
  const [isFindingInverterBrands, setIsFindingInverterBrands] = useState(false);

  const [selectedInverterBrand, setSelectedInverterBrand] = useState();
  const [selectedModuleBrand, setSelectedModuleBrand] = useState();

  const [selectedModuleModel, setSelectedModuleModel] = useState();
  const [selectedInverterModel, setSelectedInverterModel] = useState();

  const [roofs, setRoofs] = useState([]);

  const [barsProfileSize, setBarsProfileSize] = useState(
    project.BARS_PROFILE_SIZE
  );

  const [inputMinTemperature /* , setInputMinTemperature */] = useState(null);
  const inputHSP = useRef(null);
  const [, setInputPhotovoltaicYield] = useState(null);

  useEffect(() => {
    if (roofs.length) {
      setValue('ROOF_ID', project.ROOF_ID);
    }
  }, [loadingProject, project, roofs]);

  const updateLayoutSelf = useCallback(async () => {
    const data = await getLayoutSelf();

    if (data) {
      setSeries(data.SERIES);
      setSeriesToSave(data.SERIES);
      setParallel(data.PARALLEL);
      setAdditional(data.ADDITIONAL);
      setManualAdditional(data.MODULE_FIT);
    }
  }, []);

  // const updateWarnings = useCallback(async () => {
  //   const data = await getWarnings();

  //   if (data) {
  //     setWarnings(data);
  //   }
  // }, []);

  useEffect(() => {
    updateLayoutSelf();
  }, [updateLayoutSelf]);

  // useEffect(() => {
  //   updateWarnings();
  // }, [updateWarnings]);

  useEffect(() => {
    (async () => {
      const module = await getModuleSelf();

      if (!module) {
        return;
      }

      const moduleModel = await getModel({ BRAND_ID: module.BRAND_ID });

      const orderedModels = filterAvailableModels(moduleModel);

      setModule(module);
      setModuleModels(orderedModels);
      setSelectedModuleBrand(module.BRAND_ID);
      setSelectedModuleModel(module.MODULE_ID);
      setValue('MODULE_MODEL', module.MODULE_ID);
      setValue('MODULE_BRAND', module.BRAND_ID);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const inverter = await getInverterSelf();

      if (!inverter) {
        return;
      }

      const inverterModel = await getInverter({ BRAND_ID: inverter.BRAND_ID });

      const orderedModels = filterAvailableModels(inverterModel);

      setInverter(inverter);
      setInverterModels(orderedModels);
      setSelectedInverterBrand(inverter.BRAND_ID);
      setSelectedInverterModel(inverter.INVERTER_ID);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsFindingModuleBrands(true);

      const mb = await getBrand({ HAS_INVERTERS: 'F', HAS_MODULES: 'T' });

      const defaultBrand = mb.filter(brand => brand.IS_DEFAULT === 'T');

      let brands = [];

      if (defaultBrand.length > 0) {
        brands = [
          ...defaultBrand,
          ...mb.filter(brand => brand.IS_DEFAULT === 'F'),
        ];
      } else {
        brands = [...mb];
      }

      if (defaultBrand.length > 0 && !selectedModuleBrand) {
        const [brand] = defaultBrand;

        const models = await getModel({ BRAND_ID: brand.ID });

        const orderedModels = filterAvailableModels(models);

        setModuleModels(orderedModels);
        setSelectedModuleBrand(brand.ID);
        setValue('MODULE_BRAND', brand.ID);
      }

      setModuleBrands(brands);
      setIsFindingModuleBrands(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsFindingInverterBrands(true);

      const ib = await getBrand({ HAS_INVERTERS: 'T', HAS_MODULES: 'F' });

      const defaultBrand = ib.filter(brand => brand.IS_DEFAULT === 'T');

      let brands = [];

      if (defaultBrand.length > 0) {
        brands = [
          ...defaultBrand,
          ...ib.filter(brand => brand.IS_DEFAULT === 'F'),
        ];
      } else {
        brands = [...ib];
      }

      if (defaultBrand.length > 0 && !selectedInverterBrand) {
        const [brand] = defaultBrand;

        const models = await getInverter({ BRAND_ID: brand.ID });

        const orderedModels = filterAvailableModels(models);

        setInverterModels(orderedModels);
        setSelectedInverterBrand(brand.ID);
        setValue('INVERTER_BRAND', brand.ID);
      }

      setInverterBrands(brands);
      setIsFindingInverterBrands(false);
    })();
  }, []);

  useEffect(() => {
    if (selectedModuleModel) {
      (async () => {
        const module = await getModuleSelf();
        setModule(module);
      })();
    }
  }, [selectedModuleModel]);

  useEffect(() => {
    if (selectedInverterModel) {
      (async () => {
        const inverter = await getInverterSelf();
        setInverter(inverter);
      })();
    }
  }, [selectedInverterModel]);

  useEffect(() => {
    if (roofs.length === 0) {
      (async () => {
        const roofTypes = await getRoofTypes();
        setRoofs(roofTypes);
      })();
    }
  }, [selectedInverterModel]);

  function filterAvailableModels(models) {
    const availableModels = models.filter(model => model.AVAILABILITY === 'A');
    const restModels = models.filter(model => model.AVAILABILITY === 'U');

    return [...availableModels, ...restModels];
  }

  async function getBrand(filter) {
    const { data: brand } = await api({
      method: 'post',
      url: 'brand/select',
      params: {
        filter,
      },
    });

    if (brand.status === 0) {
      return brand.data;
    }

    return false;
  }

  async function getModel(filter) {
    const { data: model } = await api({
      method: 'post',
      url: 'module/select',
      params: {
        filter,
        orderby: { field: 'POWER', mode: 'ASC' },
      },
    });

    if (model.status === 0) {
      return model.data;
    }

    return false;
  }

  async function getInverter(filter) {
    const { data: inverter } = await api({
      method: 'post',
      url: 'inverter/select',
      params: {
        filter,
        orderby: { field: 'OUTPUT_POWER', mode: 'ASC' },
      },
    });

    if (inverter.status === 0) {
      return inverter.data;
    }

    return false;
  }

  async function getInverterSelf() {
    const { data: response } = await api({
      method: 'post',
      url: 'project/inverter/self',
      params: {
        project_id: projectId,
      },
    });

    if (response.status === 0) {
      return response.data;
    }

    return false;
  }

  async function getModuleSelf() {
    const { data: response } = await api({
      method: 'post',
      url: 'project/module/self',
      params: {
        project_id: projectId,
      },
    });

    if (response.status === 0) {
      return response.data;
    }

    return false;
  }

  async function getLayoutSelf() {
    const { data: self } = await api({
      method: 'post',
      url: 'project/layout/self',
      params: {
        project_id: projectId,
      },
    });

    if (self.status === 0) {
      return self.data;
    }

    return false;
  }

  async function getRoofTypes() {
    const { data: response } = await api({
      method: 'post',
      url: 'roof/select',
      params: {
        orderby: { field: 'DESCRIPTION', mode: 'ASC' },
      },
    });

    if (response.status === 0) {
      return response.data;
    }

    return [];
  }

  async function postLayoutFit() {
    const { data: response } = await api({
      method: 'post',
      url: 'project/layout/fit',
      params: {
        project_id: projectId,
        project: { SERIES: seriesToSave, MODULE_FIT: manualAdditional },
      },
    });

    return response;
  }

  async function postProjectSimulate(data) {
    const { data: response } = await api({
      method: 'post',
      url: 'project/simulate',
      params: {
        project_id: projectId,
        project: data,
      },
    });

    return {
      success: response.status === 0,
      message: response.message.text,
    };
  }

  async function postModuleAdd(moduleId) {
    const { data: model } = await api({
      method: 'post',
      url: 'project/module/add',
      params: {
        project_id: projectId,
        module_id: moduleId,
      },
    });

    return model.status === 0;
  }

  async function postInverterAdd(inverterId) {
    const { data: model } = await api({
      method: 'post',
      url: 'project/inverter/add',
      params: {
        project_id: projectId,
        inverter_id: inverterId,
      },
    });

    return model.status === 0;
  }

  async function postInverterQuantity(inverterQuantity) {
    const { data: model } = await api({
      method: 'post',
      url: 'project/inverter/quantity',
      params: {
        project_id: projectId,
        quantity: inverterQuantity,
      },
    });

    return model.status === 0;
  }

  async function postBarsSize(size) {
    const { data: model } = await api({
      method: 'post',
      url: 'project/bars/size',
      params: {
        project_id: projectId,
        size,
      },
    });

    return model.status === 0;
  }

  async function postPhotovoltaicYield(perc) {
    const { data: photovoltaicYield } = await api({
      method: 'post',
      url: 'project/configYield',
      params: {
        project_id: projectId,
        project: {
          PHOTOVOLTAIC_YIELD: perc,
        },
      },
    });

    return photovoltaicYield.status === 0;
  }

  async function postRoofType(typeId) {
    const { data: photovoltaicYield } = await api({
      method: 'post',
      url: 'project/configRoof',
      params: {
        project_id: projectId,
        project: {
          ROOF_ID: typeId,
        },
      },
    });

    return photovoltaicYield.status === 0;
  }

  async function handleChangeModuleBrand(brandId) {
    const models = await getModel({ BRAND_ID: brandId });

    const orderedModels = filterAvailableModels(models);

    setModuleModels(orderedModels);

    setSelectedModuleBrand(brandId);
    setValue('MODULE_BRAND', brandId);
    setValue('MODULE_MODEL', '');
  }

  async function handleChangeInverterBrand(brandId) {
    const models = await getInverter({ BRAND_ID: brandId });

    const orderedModels = filterAvailableModels(models);

    setInverterModels(orderedModels);
    setSelectedInverterBrand(brandId);
    setValue('INVERTER_BRAND', brandId);
    setValue('INVERTER_MODEL', '');
  }

  async function handleChangeModuleModel(modelId) {
    const response = await postModuleAdd(modelId);

    if (response) {
      toast.success('Modelo do módulo alterado com sucesso!');
      setSelectedModuleModel(modelId);
      setValue('MODULE_MODEL', modelId);

      if (inputMinTemperature) {
        inputMinTemperature.focus();
      }

      updateProject();
      validateProject();
    } else {
      toast.error('Problema ao alterar o modelo do módulo.');
    }
  }

  async function handleChangeInverterModel(inverterId) {
    const response = await postInverterAdd(inverterId);

    if (response) {
      toast.success('Modelo do inversor alterado com sucesso!');
      setSelectedInverterModel(inverterId);
      setValue('INVERTER_MODEL', inverterId);

      updateProject();
      validateProject();
    } else {
      toast.error('Problema ao alterar o modelo do inversor.');
    }
  }

  async function handleChangeBarsSize() {
    if (!barsProfileSize) return;

    const response = await postBarsSize(barsProfileSize);

    if (response) {
      toast.success('Metragem da barra alterada com sucesso!');
    } else {
      toast.error('Problema ao alterar a metragem da barra.');
    }
  }

  async function handleChangeInverterQuantity(value) {
    const inverterQuantity = value;

    const response = await postInverterQuantity(inverterQuantity);

    if (response) {
      toast.success('Quantidade de inversores alterada com sucesso!');
      updateProject();
      validateProject();
    } else {
      toast.error('Problema ao alterar a quantidade de inversores.');
    }
  }

  async function handleChangePhotovoltaicYield(value) {
    const percentage = value;

    const response = await postPhotovoltaicYield(percentage);

    if (response) {
      toast.success('Rendimento fotovoltaico alterado com sucesso!');
      updateProject();
      validateProject();
    } else {
      toast.error('Problema ao alterar o rendimento fotovoltaico.');
    }
  }

  async function handleChangeRoofType(value) {
    const type = value;

    const response = await postRoofType(type);

    if (response) {
      toast.success('Tipo de telhado alterado com sucesso!');
      updateProject();
      validateProject();
    } else {
      toast.error('Problema ao alterar o tipo de telhado.');
    }
  }

  async function handleChangeSeries(e) {
    setSeriesToSave(e.target.value);
  }

  const handleChangeInverterQuantityDebounced = useRef(
    debounce(value => handleChangeInverterQuantity(value), 300)
  ).current;

  const handleChangePhotovoltaicYieldDebounced = useRef(
    debounce(value => handleChangePhotovoltaicYield(value), 300)
  ).current;

  async function handleClickUpdateLayout() {
    const responseUpdateLayout = await postLayoutFit();

    if (responseUpdateLayout.status === 0) {
      toast.success('Layout ajustado com sucesso!');
    } else {
      toast.error(
        `Houve um problema ao ajustar o layout: ${responseUpdateLayout.message.text}`
      );
    }

    updateProject();
    updateLayoutSelf();
    // updateWarnings();
  }

  // function handleClickUpdateMin() {
  //   setManualAdditional(manualAdditional - 1);
  // }

  // function handleClickUpdateMax() {
  //   setManualAdditional(manualAdditional + 1);
  // }

  async function handleClickSimulate() {
    try {
      if (
        watchRequiredFields.PHOTOVOLTAIC_YIELD &&
        watchRequiredFields.HOURLY_SUNNY_PEAK &&
        watchRequiredFields.MODULE_BRAND &&
        watchRequiredFields.MODULE_MODEL &&
        watchRequiredFields.MIN_TEMPERATURE &&
        watchRequiredFields.INVERTER_QUANTITY &&
        watchRequiredFields.ROOF_ID
      ) {
        // const {
        //   HOURLY_SUNNY_PEAK,
        //   PHOTOVOLTAIC_YIELD,
        //   MIN_TEMPERATURE,
        // } = getValues();

        const { TYPE_ENERGY, ROOF_ID } = getValues();

        const responseSimulate = await postProjectSimulate({
          ...watchRequiredFields,
          TYPE_ENERGY,
          ROOF_ID,
        });

        if (responseSimulate.success) {
          toast.success('Simulação salva com sucesso!');
          updateProject();
          updateLayoutSelf();
          validateProject();
          // updateWarnings();
        } else {
          toast.error(responseSimulate.message);
        }
      } else {
        toast.error(
          `Verifique os campos obrigatórios para realizar a simulação.`
        );
      }
    } catch {
      toast.error(
        `Houve um erro ao tentar salvar a simulação. Verifique os dados do projeto e tente novamente.`
      );
    }
  }

  function renderSimulationResult() {
    return (
      <>
        <Grid>
          <Grid.Column mobile={16}>
            <h4 className="ui top attached inverted bg-yellow header">
              Resultado da simulação
            </h4>
            <div className="ui attached segment bg-grey">
              <div className="ui small space-between six statistics">
                <div className="ui statistic">
                  <span className="value">
                    <i className="mdi mdi-crop-square" />
                    {numberFormat(
                      project.MODULE_NUMBER_TOTAL || 0,
                      0,
                      ',',
                      '.'
                    )}
                  </span>
                  <span className="label">Quantidade de módulos</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    <i className="mdi mdi-flash-circle" />
                    {numberFormat(project.SYSTEM_POWER_TOTAL || 0, 2, ',', '.')}
                  </span>
                  <span className="label">Potência do sistema (KWP)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    <i className="mdi mdi-tape-measure" />
                    {numberFormat(
                      project.REQUIRED_AREA_TOTAL || 0,
                      0,
                      ',',
                      '.'
                    )}
                  </span>
                  <span className="label">Área necessária (m2)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    <i className="mdi mdi-weight-kilogram" />
                    {numberFormat(
                      project.REQUIRED_WEIGHT_TOTAL || 0,
                      0,
                      ',',
                      '.'
                    )}
                  </span>
                  <span className="label">Peso total (Kg)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    <i className="mdi mdi-flash" />
                    {numberFormat(
                      project.ESTIMATED_MONTHLY_ENERGY_TOTAL || 0,
                      0,
                      ',',
                      '.'
                    )}
                  </span>
                  <span className="label">
                    Energia mensal estimada (KWH/Mês)
                  </span>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <h4 className="ui top attached inverted bg-olive header">
              Configuração do layout
            </h4>
            <div
              className="ui attached segment bg-grey"
              style={{ height: isMobile ? 'auto' : 210 }}
            >
              <div className="ui form">
                <div className="two fields">
                  <div className="field">
                    <label>Séries</label>
                    <input
                      type="number"
                      value={seriesToSave}
                      onChange={handleChangeSeries}
                      onKeyPress={e =>
                        e.key === 'Enter' && handleClickUpdateLayout()
                      }
                    />
                  </div>

                  <div className="field">
                    <div className="ui small centered one statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(parallel, 0)}
                        </span>
                        <span className="label">Paralelos</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ui small centered two statistics">
                <div className="ui statistic">
                  <span className="value">{numberFormat(additional, 0)}</span>
                  <span className="label">Adicionais</span>
                </div>
              </div>

              {/*
              <div className="ui small centered two statistics m-t">
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(manualAdditional, 0)}
                  </span>
                  <span className="label">Ajuste manual</span>
                </div>
              </div>
              <div className="ui two buttons m-t m-b">
                <button
                  type="button"
                  className="ui button blue icon"
                  onClick={handleClickUpdateMax}
                >
                  <i className="mdi mdi-plus icon mdi-18px" />
                </button>
                <button
                  type="button"
                  className="ui button red icon"
                  onClick={handleClickUpdateMin}
                >
                  <i className="mdi mdi-minus icon mdi-18px" />
                </button>
              </div> */}

              <button
                type="button"
                className="ui button green fluid m-t-sm"
                onClick={handleClickUpdateLayout}
              >
                Atualizar
              </button>
            </div>
            <h4 className="ui top attached inverted bg-olive header">
              Rede de entrada
            </h4>
            <div
              className="ui attached segment bg-grey"
              style={{ height: isMobile ? 'auto' : 220 }}
            >
              <div className="ui fluid container text-center m-b-xs">
                <small>
                  Se a rede de entrada for menor que os dados abaixo, trocar
                  padrão!
                </small>
              </div>

              <div className="ui form">
                <div className="two fields">
                  <div className="fluid field">
                    <div className="ui right aligned container m-t-sm">
                      <strong>Disjuntor</strong>
                    </div>
                  </div>

                  <div className="field">
                    <div className="ui mini centered one statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(project.CIRCUIT_BREAKER || 0, 0)}
                        </span>
                        <span className="label">Amperes</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="two fields">
                  <div className="fluid field">
                    <div className="ui right aligned container m-t-sm">
                      <strong>Condutor</strong>
                    </div>
                  </div>

                  <div className="field">
                    <div className="ui mini centered one statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(project.CONDUCTOR || 0, 2, ',', '.')}
                        </span>
                        <span className="label">Milímetros</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="two fields">
                  <div className="fluid field">
                    <div className="ui right aligned container m-t-sm">
                      <strong>Potência de entrada</strong>
                    </div>
                  </div>

                  <div className="field">
                    <div className="ui mini centered one statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(project.ENTRY_POWER || 0, 2, ',', '.')}
                        </span>
                        <span className="label">Kilowatts</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={12} computer={12}>
            <h4 className="ui top attached inverted bg-olive header">
              Posicionamento dos módulos
            </h4>
            <div
              className="ui attached segment bg-grey"
              style={{ height: isMobile ? 'auto' : 500 }}
            >
              <LayoutGrid
                series={series}
                parallel={parallel}
                additional={additional}
              />
            </div>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16}>
            <h4 className="ui top attached inverted bg-teal header">
              Potência do sistema
            </h4>
            <div className="ui attached segment bg-grey">
              <div className="ui mini centered three statistics">
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.SYSTEM_POWER_TOTAL || 0, 2, ',', '.')}
                  </span>
                  <span className="label">Potência (KWP)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.SERIES_POWER || 0, 0, ',', '.')}
                  </span>
                  <span className="label">Potência da série (W)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.SERIES_VOLTAGE || 0, 2, ',', '.')}
                  </span>
                  <span className="label">Tensão da série (V)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(
                      project.PROJECT_MODULE.CURRENT_CLOSED || 0,
                      2,
                      ',',
                      '.'
                    )}
                  </span>
                  <span className="label">Corrente da série (A)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.MAX_INVERTER || 0, 2, ',', '.')}
                  </span>
                  <span className="label">Inversor máximo (KW)</span>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <h4 className="ui top attached inverted header">
              Materiais elétricos
            </h4>
            <div
              className="ui attached segment bg-grey"
              style={{ height: isMobile ? 'auto' : 158 }}
            >
              <div className="ui mini centered three statistics">
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.BLACK_WIRE_6MM || 0, 0)}
                  </span>
                  <span className="label">Fio Preto 6mm 1000kv (M)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.RED_WIRE || 0, 0)}
                  </span>
                  <span className="label">Fio Vermelho (M)</span>
                </div>
                <div className="ui statistic">
                  <span className="value">
                    {numberFormat(project.CONNECTOR_MC4 || 0, 0)}
                  </span>
                  <span className="label">
                    Conector MC4{' '}
                    {project.CONNECTOR_MC4 > 1 ? '(Pares)' : '(Par)'}
                  </span>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <h4 className="ui top attached inverted header">
              Estrutura do módulo
            </h4>

            <div className="ui attached segment bg-grey">
              <div className="ui form">
                <div className="two fields">
                  <div className="field">
                    <div className="ui mini centered two statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(project.MIDDLE_FIXER || 0, 0)}
                        </span>
                        <span className="label">Intermediários</span>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="ui mini centered two statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(project.FINAL_FIXER || 0, 0)}
                        </span>
                        <span className="label">Final</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="three fields">
                  <div className="field">
                    <InputNumberFormat
                      type="tel"
                      defaultValue={project.BARS_PROFILE_SIZE || null}
                      displayType="input"
                      fixedDecimalScale
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix=" metros"
                      decimalScale={2}
                      onValueChange={v => {
                        setBarsProfileSize(v.floatValue);
                      }}
                      onBlur={() => {
                        handleChangeBarsSize();
                      }}
                    />
                    <label>Barra perfil metragem</label>
                  </div>

                  <div className="field">
                    <div className="ui mini centered two statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(project.BARS_PROFILE_USED || 0, 0)}
                        </span>
                        <span className="label">Qtde de barras perfil</span>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="ui mini centered two statistics">
                      <div className="ui statistic">
                        <span className="value">
                          {numberFormat(
                            project.BARS_PROFILE_AMENDMENT_U || 0,
                            0
                          )}
                        </span>
                        <span className="label">Emendas U</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <button
              type="button"
              className={classNames('ui button blue', {
                'left floated': !isMobile,
                fluid: isMobile,
              })}
              onClick={() => history.push('bills')}
            >
              Voltar
            </button>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <button
              type="button"
              className={classNames('ui button green', {
                'right floated': !isMobile,
                fluid: isMobile,
              })}
              onClick={() => history.push('material')}
            >
              Avançar
            </button>
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <div>
      <h4 className="ui horizontal divider">Simulação</h4>

      {/* {Object.keys(warnings).length > 0 && (
        <h4>Atenção! Existem algumas restrições no projeto:</h4>
      )}

      {Object.keys(warnings).length > 0 &&
        Object.entries(warnings).map(([key, value]) => (
          <Message
            key={key}
            size="mini"
            color="yellow"
            icon="warning sign"
            header={key}
            content={value}
          />
        ))} */}

      <div className="ui attached segment bg-grey m-b-lg">
        <div className="ui form">
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h4 className="ui top attached inverted bg-blue header">
                Selecione o módulo
              </h4>
              <div
                className={classNames('ui attached segment', {
                  loading: isFindingModuleBrands,
                })}
                style={{ height: isMobile ? 'auto' : 253 }}
              >
                <div className="two fields">
                  <div className="field">
                    <label>Marca</label>
                    <Dropdown
                      fluid
                      search
                      selection
                      options={moduleBrands.map(brand => ({
                        key: brand.ID,
                        value: brand.ID,
                        text: `${brand.NAME}`,
                      }))}
                      value={selectedModuleBrand}
                      disabled={moduleBrands.length === 0}
                      placeholder="Selecione uma marca."
                      noResultsMessage="Nenhuma marca encontrada."
                      onChange={(e, data) =>
                        handleChangeModuleBrand(data.value)
                      }
                    />

                    <input
                      type="hidden"
                      name="MODULE_BRAND"
                      ref={register({ required: true })}
                    />

                    {!watchRequiredFields.MODULE_BRAND && (
                      <span>Marca é obrigatória para a simulação</span>
                    )}
                  </div>
                  <div className="field">
                    <label>Modelo (Potência)</label>

                    <Dropdown
                      fluid
                      search
                      selection
                      options={moduleModels.map(moduleObj => ({
                        key: moduleObj.ID,
                        value: moduleObj.ID,
                        text: `${moduleObj.MODEL} (${moduleObj.POWER})`,
                        disabled: moduleObj.AVAILABILITY === 'U',
                      }))}
                      value={selectedModuleModel}
                      disabled={
                        moduleModels.length === 0 || !selectedModuleBrand
                      }
                      placeholder="Selecione um modelo."
                      noResultsMessage="Nenhum modelo encontrado."
                      onChange={(e, data) =>
                        handleChangeModuleModel(data.value)
                      }
                    />

                    <input
                      type="hidden"
                      name="MODULE_MODEL"
                      ref={register({ required: true })}
                    />

                    {!watchRequiredFields.MODULE_MODEL && (
                      <span>Modelo é obrigatório para a simulação</span>
                    )}
                  </div>
                </div>

                <div className="ui mini centered three statistics">
                  <div className="ui statistic">
                    <span className="value">
                      {numberFormat(moduleSelf.POWER || 0, 0, ',', '.')}
                    </span>
                    <span className="label">Potência placa (W)</span>
                  </div>
                  <div className="ui statistic">
                    <span className="value">
                      {numberFormat(
                        moduleSelf.CIRCUIT_VOLTAGE_OPEN || 0,
                        2,
                        ',',
                        '.'
                      )}
                    </span>
                    <span className="label">Tensão operação (V)</span>
                  </div>
                  <div className="ui statistic">
                    <span className="value">
                      {numberFormat(
                        moduleSelf.CURRENT_CLOSED || 0,
                        2,
                        ',',
                        '.'
                      )}
                    </span>
                    <span className="label">Corrente operação (A)</span>
                  </div>
                  <div className="ui statistic">
                    <span className="value">
                      {numberFormat(moduleSelf.WEIGHT || 0, 2, ',', '.')}
                    </span>
                    <span className="label">Peso (Kg)</span>
                  </div>
                  <div className="ui statistic">
                    <span className="value">
                      {moduleSelf.DIMENSION || 'Não definido'}
                    </span>
                    <span className="label">Dimensão (mm)</span>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h4 className="ui top attached inverted bg-teal header">
                Selecione o inversor
              </h4>
              <div
                className={classNames('ui attached segment', {
                  loading: isFindingInverterBrands,
                })}
              >
                <div className="ui form">
                  {project.SIMULATION_VALID !== 'T' && (
                    <div className="field error text-center">
                      <label>
                        Configuração do inversor inválida. Verifique o modelo e
                        quantidade.
                      </label>
                    </div>
                  )}

                  <div className="fields">
                    <div className="seven wide field">
                      <label>Marca</label>
                      <Dropdown
                        fluid
                        search
                        selection
                        options={inverterBrands.map(brand => ({
                          key: brand.ID,
                          value: brand.ID,
                          text: `${brand.NAME}`,
                        }))}
                        value={selectedInverterBrand}
                        disabled={inverterBrands.length === 0}
                        placeholder="Selecione uma marca."
                        noResultsMessage="Nenhuma marca encontrada."
                        onChange={(e, data) =>
                          handleChangeInverterBrand(data.value)
                        }
                      />

                      <input
                        type="hidden"
                        name="INVERTER_BRAND"
                        ref={register({ required: true })}
                      />
                    </div>
                    <div className="seven wide field">
                      <label>Modelo (Potência)</label>
                      <Dropdown
                        fluid
                        search
                        selection
                        options={inverterModels.map(inverterObj => ({
                          key: inverterObj.ID,
                          value: inverterObj.ID,
                          text: `${inverterObj.MODEL} (${numberFormat(
                            inverterObj.OUTPUT_POWER || 0,
                            2,
                            ',',
                            '.'
                          )})`,
                          disabled: inverterObj.AVAILABILITY === 'U',
                        }))}
                        value={selectedInverterModel}
                        disabled={
                          inverterModels.length === 0 || !selectedInverterBrand
                        }
                        placeholder="Selecione um modelo."
                        noResultsMessage="Nenhum modelo encontrado."
                        onChange={(e, data) =>
                          handleChangeInverterModel(data.value)
                        }
                      />

                      <input
                        type="hidden"
                        name="INVERTER_MODEL"
                        ref={register({ required: true })}
                      />
                    </div>
                    <div className="four wide field">
                      <label>Qtde. Inversores</label>
                      <input
                        type="number"
                        name="INVERTER_QUANTITY"
                        defaultValue={project.INVERTER_QUANTITY}
                        onBlur={e => {
                          if (e.target.value) {
                            handleChangeInverterQuantityDebounced(
                              e.target.value
                            );
                          }
                        }}
                        ref={register({ required: true })}
                      />

                      {!watchRequiredFields.INVERTER_QUANTITY && (
                        <span>
                          Qtde. de Inversores é obrigatório para a simulação
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="ui mini centered three statistics">
                  <div
                    className={classNames('ui statistic', {
                      red: project.MAX_POWER_FLG !== 'T',
                    })}
                  >
                    <span className="value">
                      {numberFormat(
                        inverterSelf.MAXIMUM_POWER || 0,
                        2,
                        ',',
                        '.'
                      )}
                    </span>
                    <span className="label">Potência máxima (KWP)</span>
                  </div>
                  <div className="ui statistic">
                    <span className="value">
                      {numberFormat(inverterSelf.MAXIMUM_VOLTAGE || 0, 0)}
                    </span>
                    <span className="label">Tensão máxima CC (V)</span>
                  </div>
                  <div
                    className={classNames('ui statistic', {
                      red: project.MAX_CURRENT_FLG !== 'T',
                    })}
                  >
                    <span className="value">
                      {numberFormat(
                        inverterSelf.MAXIMUM_ELECTRIC_CURRENT || 0,
                        2,
                        ',',
                        '.'
                      )}
                    </span>
                    <span className="label">Corrente máxima (A)</span>
                  </div>
                  <div
                    className={classNames('ui statistic', {
                      red: project.OUTPUT_POWER_FLG !== 'T',
                    })}
                  >
                    <span className="value">
                      {numberFormat(
                        inverterSelf.OUTPUT_POWER || 0,
                        2,
                        ',',
                        '.'
                      )}
                    </span>
                    <span className="label">Potência saída CA</span>
                  </div>
                  <div
                    className={classNames('ui statistic', {
                      red: inverterSelf.INVERTER_AC_RATE_VOLTAGE === 380,
                    })}
                  >
                    <span className="value">
                      {inverterSelf.INVERTER_AC_RATE_VOLTAGE || 'Não definido'}
                    </span>
                    <span className="label">Tensão Nominal AC (V)</span>
                  </div>

                  <div className="ui statistic">
                    <span className="value">
                      {inverterSelf.CURRENT_BY_MPPT || 'Não definido'}
                    </span>
                    <span className="label">Corrente por MPPT</span>
                  </div>

                  <div className="ui statistic">
                    <span className="value">
                      {inverterSelf.TENSION_PHASE || 'Não definido'}
                    </span>
                    <span className="label">Fases</span>
                  </div>

                  <div className="ui statistic">
                    <span className="value">
                      {numberFormat(inverterSelf.MPP_TRACKER_QTTY || 0, 0)} |{' '}
                      {numberFormat(inverterSelf.MPP_TRACKER_STRINGS || 0, 0)} |{' '}
                      {numberFormat(inverterSelf.MPP_TRACKER_PLUS || 0, 0)}
                    </span>
                    <span className="label">Qtde. de MPP Tracker</span>
                  </div>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column mobile={16}>
              <h4 className="ui top attached header inverted bg-orange">
                Configuração da simulação
              </h4>
              <div className="ui attached segment">
                <div className="ui form m-t">
                  <div className="four fields">
                    <div className="field">
                      <div className="ui small centered three statistics">
                        <div className="ui statistic">
                          <span className="value">
                            {numberFormat(
                              project.TOTAL_AVAILABILITY_RATE,
                              0,
                              ',',
                              '.'
                            )}
                          </span>
                          <span className="label">
                            Taxa de disponibilidade (KW/H)
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <label>Rendimento fotovoltaico</label>

                      {!loadingProject && (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          name="PHOTOVOLTAIC_YIELD"
                          value={watchRequiredFields.PHOTOVOLTAIC_YIELD}
                          suffix=" %"
                          decimalScale={0}
                          onValueChange={v => {
                            setValue('PHOTOVOLTAIC_YIELD', v.floatValue);
                          }}
                          onBlur={() => {
                            if (watchRequiredFields.PHOTOVOLTAIC_YIELD) {
                              handleChangePhotovoltaicYieldDebounced(
                                watchRequiredFields.PHOTOVOLTAIC_YIELD
                              );
                            }
                          }}
                          getInputRef={el => setInputPhotovoltaicYield(el)}
                          onKeyPress={e =>
                            e.key === 'Enter' && handleClickSimulate()
                          }
                        />
                      )}

                      <input
                        type={loadingProject ? 'number' : 'hidden'}
                        name="PHOTOVOLTAIC_YIELD"
                        value={watchRequiredFields.PHOTOVOLTAIC_YIELD}
                        ref={register({ required: true })}
                      />

                      {!watchRequiredFields.PHOTOVOLTAIC_YIELD && (
                        <span>
                          Rendimento fotovoltaico é obrigatório para a simulação
                        </span>
                      )}
                    </div>

                    <div className="field">
                      <label>HSP (horas de sol a pico)</label>

                      <input
                        type="number"
                        name="HOURLY_SUNNY_PEAK"
                        readOnly
                        defaultValue={project.HOURLY_SUNNY_PEAK}
                        ref={e => {
                          inputHSP.current = e;
                          register(e, { required: true });
                        }}
                      />

                      {!watchRequiredFields.HOURLY_SUNNY_PEAK && (
                        <span>HSP é obrigatório para a simulação</span>
                      )}
                      {/* </div>
                    <div className="field">
                      <label>Rendimento fotovoltaico</label>
                      {!loadingProject && (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          name="PHOTOVOLTAIC_YIELD"
                          value={watchRequiredFields.PHOTOVOLTAIC_YIELD}
                          suffix=" %"
                          decimalScale={0}
                          onValueChange={v =>
                            setValue('PHOTOVOLTAIC_YIELD', v.floatValue)
                          }
                          getInputRef={el => setInputPhotovoltaicYield(el)}
                          onKeyPress={e =>
                            e.key === 'Enter' && handleClickSimulate()
                          }
                        />
                      )}
                      <input
                        type={loadingProject ? 'number' : 'hidden'}
                        name="PHOTOVOLTAIC_YIELD"
                        value={watchRequiredFields.PHOTOVOLTAIC_YIELD}
                        ref={register({ required: true })}
                      />
                      {!watchRequiredFields.PHOTOVOLTAIC_YIELD && (
                        <span>
                          Rendimento fotovoltaico é obrigatório para a simulação
                        </span>
                      )} */}
                    </div>

                    {/* <div className="field">
                      <label>Temperatura mínima</label>
                      {!loadingProject && (
                        <InputNumberFormat
                          type="tel"
                          displayType="input"
                          thousandSeparator="."
                          decimalSeparator=","
                          name="MIN_TEMPERATURE"
                          value={watchRequiredFields.MIN_TEMPERATURE}
                          suffix=" ˚C"
                          decimalScale={0}
                          onValueChange={v =>
                            setValue('MIN_TEMPERATURE', v.floatValue)
                          }
                          getInputRef={el => setInputMinTemperature(el)}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              if (inputHSP.current) {
                                inputHSP.current.focus();
                              }
                            }
                          }}
                        />
                      )}
                      <input
                        type={loadingProject ? 'number' : 'hidden'}
                        name="MIN_TEMPERATURE"
                        value={watchRequiredFields.MIN_TEMPERATURE}
                        ref={register({ required: true })}
                      />
                      {!watchRequiredFields.MIN_TEMPERATURE && (
                        <span>
                          Temperatura mínima é obrigatória para a simulação
                        </span>
                      )}
                      </div> */}

                    <input
                      type="hidden"
                      name="MIN_TEMPERATURE"
                      value={0}
                      ref={register({ required: true })}
                    />

                    <div className="field">
                      <label>Tipo de telhado</label>

                      <select
                        name="ROOF_ID"
                        ref={register({ required: true })}
                        onChange={e => handleChangeRoofType(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        {roofs.map(roof => (
                          <option key={roof.ID} value={roof.ID}>
                            {roof.DESCRIPTION}
                          </option>
                        ))}
                      </select>

                      {!watchRequiredFields.ROOF_ID && (
                        <span>
                          Tipo de telhado é obrigatório para a simulação
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>

            {project.SIMULATED === 'F' && (
              <Grid.Column mobile={16}>
                <h4 className="ui top attached inverted bg-yellow header">
                  Atualize a simulação!
                </h4>
                <div className="ui attached segment text-center">
                  <div className="ui statistic">
                    <span className="value">
                      <i className="mdi mdi-alert-circle-outline" />
                    </span>
                    <span className="label">
                      Alguns valores do seu projeto foram alterados, simule
                      novamente para atualizar os resultados.
                    </span>
                  </div>
                </div>
              </Grid.Column>
            )}

            <Grid.Column className="text-center" mobile={16}>
              <button
                type="button"
                className="ui button green"
                onClick={handleClickSimulate}
                style={{ width: '50%' }}
              >
                Simular
              </button>
            </Grid.Column>
          </Grid>
        </div>
      </div>

      {/* {project.SIMULATED === 'F' && renderSimulateAgainContainer()} */}
      {project.SIMULATED === 'T' && renderSimulationResult()}
    </div>
  );
}
