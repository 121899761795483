import styled from 'styled-components';

import { headerHeight, sidebarWidth } from '../styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  margin-top: ${headerHeight}px;
  /* height: calc(100% - ${headerHeight}px); */
  height: 100%;
  z-index: 1;
  /* overflow-y: auto; */
  left: ${({ sidebarIsOpen }) => (sidebarIsOpen ? `${sidebarWidth}` : '0')}px;
  right: 0;
  transition: all 0.2s ease-in-out 0s;
`;

export const ApplicationLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  div {
    background: #000;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      font-size: 32px;
    }
  }

  img {
    height: 40px;
    margin: 20px;
  }
`;
