import React, { useEffect, useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import useForm from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';

import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function SubPageIdentification({
  project,
  projectId,
  updateStore,
}) {
  const { openModal } = useContext(ModalContext);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
    setValue,
    getValues,
    triggerValidation,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...project,
      CLIENT_NAME:
        project.CLIENT_TYPE === 'L'
          ? `${project.CLIENT_COMPANY_NAME} (${project.CLIENT_FANTASY_NAME})`
          : project.CLIENT_NAME,
    },
  });

  const watchClientId = watch('CLIENT_ID');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formState.submitCount > 0 && !formState.isValid) {
      toast.error('Verifique todos os campos obrigatórios.');
    }
  }, [formState.submitCount, formState.isValid]);

  async function handleFormSubmit(data) {
    setIsLoading(true);

    const { data: response } = await api({
      method: 'post',
      url: 'project/information',
      params: {
        project_id: projectId,
        project: data,
      },
    });

    if (response.status === 0) {
      toast.success('Informações salva com sucesso!');

      updateStore(data);

      return true;
    }

    toast.error(response.message.text);

    setIsLoading(false);

    return false;
  }

  function handleClientSelect() {
    openModal('clientSelect', {
      onSelect: client => {
        setValue('CLIENT_ID', client.ID);

        if (client.TYPE_FORMAT === 'PF') {
          setValue('CLIENT_NAME', client.NAME);
        } else {
          setValue('CLIENT_NAME', client.COMPANY_NAME);
        }
      },
    });
  }

  function handleClearClientSelect() {
    setValue('CLIENT_ID', '');
    setValue('CLIENT_NAME', '');
  }

  function handleViewClientSelect() {
    const { CLIENT_ID: clientId } = getValues();

    openModal('clientAdd', {
      mode: 'view',
      clientId,
    });
  }

  async function submitAndNavigate(navigateTo, validate = false) {
    const data = getValues();

    if (validate) {
      if (!(await triggerValidation())) {
        return;
      }
    }

    const isSave = await handleFormSubmit(data);

    if (isSave) {
      updateStore();
      history.push(navigateTo);
    }
  }

  return (
    <form
      className={classNames('ui form')}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <h4 className="ui horizontal divider">Identificação</h4>

      <div className="fields">
        <div className="four wide field required">
          <label>Código</label>

          <input type="text" name="CODE" ref={register} disabled />
        </div>
        <div
          className={classNames('six wide field required', {
            error: errors.NAME,
          })}
        >
          <label>Descrição</label>

          <input type="text" name="NAME" ref={register({ required: true })} />

          {errors.NAME && <span>Descrição do projeto é obrigatória</span>}
        </div>

        <div
          className={classNames('six wide field required', {
            error: errors.CLIENT_ID,
          })}
        >
          <label>Cliente</label>

          <div className="ui action input">
            <input
              type="text"
              name="CLIENT_NAME"
              readOnly
              placeholder="Cadastre ou selecione um cliente nos botões ao lado"
              ref={register}
            />

            <input
              type="hidden"
              name="CLIENT_ID"
              ref={register({ required: true })}
            />

            {watchClientId ? (
              <>
                <button
                  type="button"
                  className="ui button icon blue"
                  onClick={handleViewClientSelect}
                >
                  <i className="mdi mdi-eye" />
                </button>
                <button
                  type="button"
                  className="ui button icon red"
                  onClick={handleClearClientSelect}
                >
                  <i className="mdi mdi-delete" />
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="ui button green icon"
                  title="Novo cliente"
                  onClick={
                    () =>
                      submitAndNavigate(
                        `/client/new?projectId=${projectId}&backTo=/project/${projectId}/identification`
                      )
                    // history.push(
                    //   `/client/new?projectId=${projectId}&backTo=/project/${projectId}/identification`
                    // )
                  }
                >
                  <i className="mdi mdi-account-plus" />
                </button>
                <button
                  type="button"
                  className="ui button icon"
                  title="Selecionar cliente"
                  onClick={handleClientSelect}
                >
                  <i className="mdi mdi-account-search" />
                </button>
              </>
            )}
          </div>
          {errors.CLIENT_ID && <span>Cliente é obrigatório</span>}
        </div>
      </div>

      <div className="field">
        <button
          type="button"
          className={classNames('ui button green', {
            loading: isLoading,
            'right floated': !isMobile,
            fluid: isMobile,
          })}
          disabled={isLoading}
          onClick={() =>
            submitAndNavigate(`/project/${projectId}/bills/search`, true)
          }
        >
          Salvar informações
        </button>
      </div>

      <div className="field" />
    </form>
  );
}
