import React, { useContext, useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import swal from 'sweetalert';

import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

import { Container, User, Avatar, AvatarMenu, Name, Menu } from './styles';

export default function UserMenu() {
  const history = useHistory();

  const { user, account, setManageAccount, logout } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);

  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const userName = user && user.NAME;
  const userEmail = user && user.EMAIL;

  const accountName = account && account.NAME;
  const accountGroupName = account && account.GROUP_NAME;

  useEffect(() => {
    history.listen(() => {
      setOpen(false);
    });
  }, [history]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        open &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, open]);

  function handleClickUser() {
    setOpen(!open);
  }

  function handlePasswordRecovery() {
    const email = user && user.EMAIL;

    if (!email) {
      swal(
        'Atenção',
        'Para recuperar a senha é necessário um endereço de e-mail.',
        'warning'
      );
    }

    swal({
      title: 'Atencão',
      text: `Um email de recuperação de senha será enviado para o seguinte endereço: ${email}!`,
      icon: 'info',
      buttons: ['Cancelar', 'Confirmar'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'credential/password/resend',
          params: {
            login: email,
          },
        });

        const { title, text, type } = response.message;

        swal(title, text, type);

        if (response.status === 0) {
          logout();
        }
      }
    });
  }

  function handleSelectAccount() {
    openModal('accountSelect', {
      onSelect: selectedId => {
        setManageAccount(selectedId);
      },
    });
  }

  return (
    <Container ref={containerRef} className={classNames({ open })}>
      {userName && (
        <User onClick={handleClickUser}>
          <Avatar>
            <span> {userName[0].toUpperCase()} </span>
          </Avatar>

          {!isMobile && <Name>{userName}</Name>}

          <i className="mdi mdi-menu-down" />
        </User>
      )}

      <Menu>
        <button
          type="button"
          className="no-style close"
          onClick={handleClickUser}
        >
          <i className="mdi mdi-close" />
        </button>

        <div className="ui card fluid text-center">
          {userName && (
            <div className="center aligned content">
              <AvatarMenu>
                <span> {userName[0].toUpperCase()} </span>
              </AvatarMenu>
              <div className="center aligned header">{userName}</div>
              {userEmail && (
                <div className="center aligned description">{userEmail}</div>
              )}
            </div>
          )}
          {user.ACCOUNT_FINANCIAL_STATUS !== 'B' && (
            <>
              <div className="center aligned extra content">
                <div className="header">Conta</div>
                <div className="description">
                  {accountName || '-'} <b> · {accountGroupName || '-'}</b>
                </div>
                <button
                  type="button"
                  className="ui mini button green m-t-sm"
                  onClick={handleSelectAccount}
                >
                  Alterar
                </button>
              </div>

              <Link to="/me" className="center aligned extra content">
                <div className="description">Minha conta</div>
              </Link>

              <button
                type="button"
                onClick={handlePasswordRecovery}
                className="center aligned extra content"
              >
                <div className="description">Alterar senha</div>
              </button>
            </>
          )}

          <button
            type="button"
            className="center aligned extra content"
            onClick={() => logout()}
          >
            <div className="description text-red">Sair do sistema</div>
          </button>
        </div>
      </Menu>
    </Container>
  );
}
