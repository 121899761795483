import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useForm from 'react-hook-form';

import classNames from 'classnames';
import { Dropdown } from 'semantic-ui-react';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

export default function FormRegister({ onSubmit, isLoading }) {
  const { register, handleSubmit, errors, setValue } = useForm();

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [stateSelected, setStateSelected] = useState();
  const [citySelected, setCitySelected] = useState();

  const filteredCities = useMemo(() => {
    if (stateSelected) {
      return cityList.map(city => ({
        key: city.ID,
        value: city.ID,
        text: city.NAME,
      }));
    }

    return [];
  }, [cityList, stateSelected]);

  const loadStatesAndCities = useCallback(async () => {
    const states = await getState();
    const cities = await getCity();

    const statesMap = states.map(state => ({
      key: state.ID,
      value: state.ID,
      text: state.NAME,
    }));

    setStateList(statesMap);
    setCityList(cities);
  }, []);

  useEffect(() => {
    loadStatesAndCities();
  }, [loadStatesAndCities]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [stateSelected]);

  async function getState() {
    const { data: uf } = await api({
      method: 'get',
      url: 'uf/list',
      params: {
        filter: JSON.stringify({}),
      },
    });

    if (uf.status === 0) {
      return uf.data;
    }

    return [];
  }

  async function getCity() {
    if (stateSelected) {
      const { data: city } = await api({
        method: 'get',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({ UF_ID: stateSelected }),
        },
      });

      if (city.status === 0) {
        return city.data;
      }
    }
    return [];
  }

  function updateCity(cityId) {
    setValue('CITY_ID', cityId);
    setCitySelected(cityId);
  }

  function updateState(stateId) {
    setValue('UF_ID', stateId);
    setStateSelected(stateId);
  }

  return (
    <form className="ui segment form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="ui dividing header">Informações</h3>

      <div className="field">
        <div className="two fields">
          <div className="field required">
            <label>Nome completo</label>
            <input
              type="text"
              name="NAME"
              placeholder="Nome completo"
              ref={register({ required: true })}
            />
            {errors.NAME && <span>Nome é obrigatório</span>}
          </div>

          <div className="field required">
            <label>E-mail</label>
            <input
              type="text"
              name="EMAIL"
              placeholder="exemplo@exemplo.com"
              ref={register({ required: true })}
            />
            {errors.EMAIL && <span>E-mail é obrigatório</span>}
          </div>
        </div>
      </div>

      <div className="field">
        <div className="four fields">
          <div className="field required">
            <label>Telefone</label>
            <MaskedInput
              mask="(99) 9999-9999"
              type="tel"
              name="PHONE"
              placeholder="(00) 0000-0000"
              register={register({ required: true })}
            />
            {errors.PHONE && <span>Telefone é obrigatório</span>}
          </div>

          <div className="field required">
            <label>Telefone celular</label>
            <MaskedInput
              mask="(99) 9 9999-9999"
              type="tel"
              name="MOBILE"
              placeholder="(00) 0 0000-0000"
              register={register({ required: true })}
            />
            {errors.MOBILE && <span>Telefone celular é obrigatório</span>}
          </div>

          <div
            className={classNames('field required', {
              error: errors.UF_ID,
            })}
          >
            <label>Estado</label>

            <Dropdown
              fluid
              search
              selection
              options={stateList}
              value={stateSelected}
              placeholder="Seleciona um estado."
              noResultsMessage="Nenhum estado encontrado."
              onChange={(e, data) => updateState(data.value)}
            />
            <input
              type="hidden"
              name="UF_ID"
              ref={register({ required: true })}
            />
            {errors.UF_ID && <span>Estado é obrigatório</span>}
          </div>

          <div
            className={classNames('field required', {
              error: errors.CITY_ID,
            })}
          >
            <label>Cidade</label>

            <Dropdown
              fluid
              search
              selection
              options={filteredCities}
              value={citySelected}
              disabled={filteredCities.length === 0}
              placeholder="Seleciona uma cidade."
              noResultsMessage="Nenhuma cidade encontrada."
              onChange={(e, data) => {
                updateCity(data.value);
              }}
            />
            <input
              type="hidden"
              name="CITY_ID"
              ref={register({ required: true })}
            />
            {errors.CITY_ID && <span>Cidade é obrigatória</span>}
          </div>
        </div>
      </div>

      <button
        className={classNames('ui fluid button yellow', {
          loading: isLoading,
        })}
        disabled={isLoading}
        type="submit"
      >
        Confirmar
      </button>
    </form>
  );
}
