import React from 'react';

import numberFormat from 'locutus/php/strings/number_format';
import { Button, Card, Icon } from 'semantic-ui-react';

export default function MaterialCard({
  material = {},
  type,
  handleEdit,
  handleSelect,
  handleDelete,
}) {
  function getButtonsByType() {
    switch (type) {
      case 'projectSelected':
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar material"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>
            <Button
              size="mini"
              icon
              color="red"
              title="Remover material"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
      case 'select':
        return (
          <>
            <Button
              size="mini"
              icon
              color="green"
              title="Selecionar material"
              onClick={handleSelect}
            >
              <Icon name="check" /> Selecionar
            </Button>

            <Button
              size="mini"
              icon
              color="blue"
              title="Editar material"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>
            <Button
              size="mini"
              icon
              color="red"
              title="Remover material"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );

      default:
        return (
          <>
            <Button
              size="mini"
              icon
              color="blue"
              title="Editar material"
              onClick={handleEdit}
            >
              <Icon name="pencil" />
            </Button>

            <Button
              size="mini"
              icon
              color="red"
              title="Remover material"
              onClick={handleDelete}
            >
              <Icon name="trash" />
            </Button>
          </>
        );
    }
  }
  return (
    <Card fluid className="text-center">
      <Card.Content>
        <Card.Header>
          {material.DESCRIPTION || material.MATERIAL_DESCRIPTION || ''}
        </Card.Header>
        <Card.Meta> {material.PROVIDER_DESCRIPTION} </Card.Meta>
      </Card.Content>

      {type !== 'select' && (
        <>
          {material.QUANTITY && (
            <Card.Content extra>
              Quantidade: <b>{numberFormat(material.QUANTITY, 2, ',', '.')}</b>
            </Card.Content>
          )}

          {material.UNIT_VALUE && (
            <Card.Content extra>
              Valor unitário:{' '}
              <b>R$ {numberFormat(material.UNIT_VALUE, 2, ',', '.')}</b>
            </Card.Content>
          )}

          {material.TOTAL && (
            <Card.Content extra>
              Total: <b>R$ {numberFormat(material.TOTAL, 2, ',', '.')}</b>
            </Card.Content>
          )}
        </>
      )}

      {type === 'select' && (
        <Card.Content extra>
          Valor unitário: <b>R$ {numberFormat(material.PRICE, 2, ',', '.')}</b>
        </Card.Content>
      )}

      <Card.Content extra>{getButtonsByType()}</Card.Content>
    </Card>
  );
}
