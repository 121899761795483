import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { Card, Icon } from 'semantic-ui-react';
import uniqid from 'uniqid';

import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';

import cards from './cards';

export default function ProjectConfig() {
  const history = useHistory();
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const handleClick = card => {
    if (card.action === 'modal' && user.TYPE_KEY_ === 'ROOT') {
      openModal(card.target, { onConfirm: card.callback(history) });
    } else if (card.action === 'modal' && user.TYPE_KEY_ === 'GROUP') {
      history.push(card.getRedirectUrl(user.ACCOUNT_GROUP_ID));
    } else {
      history.push(card.target);
    }
  };

  function renderConfigContainer() {
    return (
      <Card.Group stackable itemsPerRow={isMobile ? 1 : 4}>
        {cards.map(card => {
          return card.roles.includes(user.TYPE_KEY_) ? (
            <Card key={uniqid()} onClick={() => handleClick(card)}>
              <Card.Content>
                <Card.Header>
                  <Icon name={card.icon} /> {card.name}
                </Card.Header>
              </Card.Content>
              <Card.Content description={card.description} />
            </Card>
          ) : null;
        })}
      </Card.Group>
    );
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title"> Gerenciar configurações de projeto </span>
        </div>

        <div className="ui attached bottom segment">
          {renderConfigContainer()}
        </div>
      </div>
    </div>
  );
}
