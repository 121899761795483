import axios from 'axios';
import { merge } from 'lodash/object';

export const API_URL = process.env.REACT_APP_API_URL;
export const SYSTEM_SERVICES_KEY = 'fotovoltservices';
export const SESSION_NAME = 'FOTOVOLT_SESSION';
export const COOKIES_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

api.interceptors.request.use(config => {
  const managedAccountId = JSON.parse(
    localStorage.getItem('@webvolt:managed_account')
  );

  return merge({}, config, {
    params: {
      type: SYSTEM_SERVICES_KEY,
      managed_account_id: managedAccountId,
    },
  });
});

export default api;
