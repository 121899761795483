import React, { useState, useEffect, useCallback, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/chart/bar';
import numberFormat from 'locutus/php/strings/number_format';
import { Grid, Button, Message, Icon } from 'semantic-ui-react';
import swal from 'sweetalert';

import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function InvestmentSection({ projectId, project }) {
  const { openModal } = useContext(ModalContext);

  const [investments, setInvestments] = useState([]);

  const [investmentsChartPerc, setInvestmentsChartPerc] = useState({});
  const [investmentsChartTotal, setInvestmentsChartTotal] = useState({});

  const getData = useCallback(async () => {
    const { data: response } = await api({
      method: 'post',
      url: 'project/investment/search',
      params: {
        filter: JSON.stringify({ PROJECT_ID: projectId }),
        pagination: JSON.stringify({
          page: 1,
          limit: 1000,
          orderby: [{ field: 'ID', mode: 'ASC' }],
          keyword: '',
        }),
      },
    });

    if (response.status === 0) {
      setInvestments(response.data);
    }
  }, [projectId]);

  const getProjectCharts = useCallback(async () => {
    const { data: responsePerc } = await api({
      method: 'post',
      url: 'project/charts/investment/compare',
      params: {
        project_id: projectId,
        x_value: 'perc',
        order_field: 'ID',
        order_mode: 'ASC',
      },
    });

    if (responsePerc.status === 0) {
      const { value, color, label } = responsePerc.data;
      const data = value.map((item, i) => ({
        value: item,
        itemStyle: { color: color[i] },
      }));

      setInvestmentsChartPerc({ value: data, color, label });
    }

    const { data: responseTotal } = await api({
      method: 'post',
      url: 'project/charts/investment/compare',
      params: {
        project_id: projectId,
        x_value: 'total',
        order_field: 'ID',
        order_mode: 'ASC',
      },
    });

    if (responseTotal.status === 0) {
      const { value, color, label } = responseTotal.data;
      const data = value.map((item, i) => ({
        value: item,
        itemStyle: { color: color[i] },
      }));

      setInvestmentsChartTotal({ value: data, color, label });
    }
  }, [projectId]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getProjectCharts();
  }, [getProjectCharts]);

  function handleRemove(investmentId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um investimento do projeto permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'project/investment/delete',
          params: {
            project_investment_id: investmentId,
          },
        });

        if (response.status === 0) {
          toast.success('Investimento excluído com sucesso!');

          getData();
          getProjectCharts();
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  return (
    <>
      <h4 className="ui horizontal divider">Comparativo de investimentos</h4>

      <Grid columns={1} stackable className="text-center">
        <Grid.Column>
          <Button
            icon
            color="green"
            title="Adicionar investimento"
            onClick={() =>
              openModal('investmentAdd', {
                data: {
                  PROJECT_ID: projectId,
                  VALUE: project.TOTAL_ITEM,
                },
                onConfirm: () => {
                  getData();
                  getProjectCharts();
                },
              })
            }
          >
            <Icon name="plus" /> Adicionar investimento
          </Button>
        </Grid.Column>
        <Grid.Column>
          <table className="ui compact striped celled table">
            <thead>
              <tr>
                <th className="center aligned">Descrição</th>
                <th className="center aligned">Valor</th>
                <th className="center aligned">Rendimento (% a.m)</th>
                <th className="center aligned">Rendimento Mensal</th>
                <th className="center aligned">Ações</th>
              </tr>
            </thead>
            <tbody>
              {investments.length > 0 &&
                investments.map(investment => {
                  return (
                    <tr
                      key={investment.ID}
                      className={classNames({
                        active:
                          investment.INVESTMENT_COMMON_EDITABLE === 'F' &&
                          investment.INVESTMENT_COMMON_EXCLUDABLE === 'F',
                      })}
                    >
                      <td className="center aligned">
                        {investment.DESCRIPTION || '-'}
                      </td>
                      <td className="center aligned">
                        R$ {numberFormat(investment.VALUE, 2, ',', '.')}
                      </td>
                      <td className="center aligned">
                        {numberFormat(investment.PERC, 2, ',', '.')} %
                      </td>
                      <td className="center aligned">
                        R$ {numberFormat(investment.TOTAL, 2, ',', '.')}
                      </td>

                      <td className="center aligned">
                        {investment.INVESTMENT_COMMON_EDITABLE === 'T' && (
                          <Button
                            size="mini"
                            icon
                            color="blue"
                            title="Editar"
                            onClick={() =>
                              openModal('investmentAdd', {
                                mode: 'edit',
                                data: investment,
                                onConfirm: () => {
                                  getData();
                                  getProjectCharts();
                                },
                              })
                            }
                          >
                            <Icon name="pencil" />
                          </Button>
                        )}

                        {investment.INVESTMENT_COMMON_EXCLUDABLE === 'T' && (
                          <Button
                            size="mini"
                            icon
                            color="red"
                            title="Remover"
                            onClick={() => handleRemove(investment.ID)}
                          >
                            <Icon name="trash" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}

              {investments.length === 0 && (
                <tr>
                  <td colSpan="9">
                    <Message content="Nenhum investimento adicionado!" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Grid.Column>
      </Grid>

      <Grid columns={2} stackable>
        <Grid.Column>
          <h4 className="ui horizontal divider">
            Comparativo de investimentos (%)
          </h4>

          {/* <div className="ui attached segment bg-grey"> */}
          <ReactEchartsCore
            echarts={echarts}
            option={{
              grid: {
                right: '5px',
                left: '5px',
                containLabel: true,
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow', // 'line' | 'shadow'
                },
                formatter([params]) {
                  const { name, value } = params;
                  return `${name}: ${numberFormat(value, 2, ',', '.')}%`;
                },
              },
              xAxis: {
                type: 'category',
                data: investmentsChartPerc.label,
                axisLabel: {
                  rotate: isMobile ? 25 : 0,
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  // formatter: 'R$ {value}',
                  formatter(value) {
                    return `${numberFormat(value, 2, ',', '.')}%`;
                  },
                },
              },
              series: [
                {
                  data: investmentsChartPerc.value,
                  type: 'bar',
                  label: {
                    show: !isMobile,
                    formatter({ value }) {
                      return `${numberFormat(value, 2, ',', '.')}%`;
                    },
                    position: 'top',
                  },
                },
              ],
            }}
            // style={{
            //   height: '400px',
            // }}
            notMerge
            lazyUpdate
          />
          {/* </div> */}
        </Grid.Column>
        <Grid.Column>
          <h4 className="ui horizontal divider">
            Comparativo de investimentos (R$)
          </h4>
          {/* <div className="ui attached segment bg-grey"> */}
          <ReactEchartsCore
            echarts={echarts}
            option={{
              grid: {
                right: '5px',
                left: '5px',
                containLabel: true,
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow', // 'line' | 'shadow'
                },
                formatter([params]) {
                  const { name, value } = params;
                  return `${name}: R$ ${numberFormat(value, 2, ',', '.')}`;
                },
              },
              xAxis: {
                type: 'category',
                data: investmentsChartTotal.label,
                axisLabel: {
                  rotate: isMobile ? 25 : 0,
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  // formatter: 'R$ {value}',
                  formatter(value) {
                    return `R$ ${numberFormat(value, 2, ',', '.')}`;
                  },
                },
              },
              series: [
                {
                  data: investmentsChartTotal.value,
                  type: 'bar',
                  label: {
                    show: !isMobile,
                    formatter({ value }) {
                      return `R$ ${numberFormat(value, 2, ',', '.')}`;
                    },
                    position: 'top',
                  },
                },
              ],
            }}
            notMerge
            lazyUpdate
          />
          {/* </div> */}
        </Grid.Column>
      </Grid>
    </>
  );
}
