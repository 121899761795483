import React, { useState, useEffect, useCallback } from 'react';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Modal, Button, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalRoyaltyAdd({
  onClose,
  handleClose,
  onConfirm,
  mode = 'add',
  data = {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [accounts, setAccounts] = useState([]);

  const [value, setValue] = useState(data.PERCENT || 0);
  const [account, setAccount] = useState(data.ACCOUNT_ID || null);

  const [inputQuantity, setInputQuantity] = useState(null);

  const getAccounts = useCallback(async () => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: 'account/select',
      params: {
        filter: JSON.stringify({
          account_group_id: data.ACCOUNT_GROUP_ID,
        }),
      },
    });

    const accountsLoaded = responseSearch.data;

    if (responseSearch.status === 0) {
      setAccounts(accountsLoaded);
    } else {
      setAccounts([]);
    }

    setIsLoading(false);
  }, []);

  async function handleConfirm() {
    setIsLoading(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: '/config/value/save',
      params: {
        value: {
          CONFIG_ID: data.CONFIG_ID,
          ACCOUNT_ID: account,
          VALUE: value,
        },
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);
      if (onConfirm) onConfirm(responseAdd.data);
      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar o item: ${responseAdd.message.text}`
      );
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (inputQuantity) {
      inputQuantity.focus();
    }
  }, [inputQuantity]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Adicionar royalties' : 'Editar royalties'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Conta</label>
            <Dropdown
              fluid
              search
              selection
              disabled={mode === 'edit'}
              options={accounts.map(acc => ({
                id: acc.ID,
                value: acc.ID,
                text: acc.NAME,
              }))}
              value={account}
              placeholder="Seleciona uma conta."
              noResultsMessage="Nenhuma conta encontrada."
              onChange={(e, d) => setAccount(d.value)}
            />
          </div>

          <div className="field required">
            <label>Valor (%)</label>
            <InputNumberFormat
              type="tel"
              value={value}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              suffix="%"
              decimalScale={2}
              onValueChange={v => setValue(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputQuantity(el)}
              disabled={isLoading}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
