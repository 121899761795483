/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { Modal, Message } from 'semantic-ui-react';

import api from '~/services/api';

import Form from './components/Form';

export default function ModalClient({
  open,
  handleClose,
  onClose,
  initialData = {},
  clientId,
  mode = 'view',
}) {
  const [client, setClient] = useState(normalizeData(initialData));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === 'view') {
      getClientData();
    }

    async function getClientData() {
      setLoading(true);
      const { data: clientData } = await api({
        method: 'post',
        url: 'client/self',
        params: {
          client_id: clientId,
        },
      });

      if (clientData.status === 0) {
        setClient(normalizeData(clientData.data));
      }
      setLoading(false);
    }
  }, [mode, clientId]);

  function normalizeData(data) {
    return {
      ID: data.ID || '',
      NAME: data.NAME || '',
      PERSON: data.PERSON || data.TYPE_FORMAT || 'PF',
      PERSON_DOCUMENT: data.PERSON_DOCUMENT || data.CNPJ || data.CPF,
      STATE_REGISTRATION: data.STATE_REGISTRATION,
      TOWN_REGISTRATION: data.TOWN_REGISTRATION,
      HOLDER: data.HOLDER,
      FANTASY_NAME: data.FANTASY_NAME,
      COMPANY_NAME: data.COMPANY_NAME,
      PHONE: data.PHONE,
      CELL_PHONE: data.CELL_PHONE,
      EMAIL: data.EMAIL,
      POSTAL_CODE: data.POSTAL_CODE,
      ADDRESS: data.ADDRESS,
      NUMBER: data.NUMBER,
      COMPLEMENT: data.COMPLEMENT,
      DISTRICT: data.DISTRICT,
      UF_ID: Number(data.UF_ID) || '*',
      CITY_ID: Number(data.CITY_ID) || '*',
      CONTACT: data.CONTACT,
      OBSERVATION: data.OBSERVATION,
    };
  }

  function getModalTitle() {
    switch (mode) {
      default:
        return 'Visualizar cliente';
    }
  }

  return (
    <Modal size="fullscreen" open={open} onClose={onClose}>
      <a href="#" className="close" onClick={handleClose}>
        <i className="mdi mdi-close" />
      </a>

      <Modal.Header>{getModalTitle()}</Modal.Header>

      <Modal.Content>
        {loading ? (
          <Message color="blue" content="Carregando informações do cliente" />
        ) : (
          <Form initialData={client} isReadOnly />
        )}
      </Modal.Content>
    </Modal>
  );
}
