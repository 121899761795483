import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import AuthContext from '~/contexts/AuthContext';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function BrandSearch() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [backTo] = useState(query.get('backTo'));

  const { openModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const [brands, setBrands] = useState([]);
  const [groupAccounts, setGroupAccounts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const searchBrands = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: 'brand/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setBrands(responseSearch.data);
    } else {
      setBrands([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const getGroupAccounts = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'credential/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupAccounts(
        response.data.map(account => ({
          key: account.ID,
          text: account.NAME,
          value: account.ID,
        }))
      );
    }
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchBrands(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchBrands(filter, pagination);
  }, [searchBrands]);

  useEffect(() => {
    getGroupAccounts();
  }, [getGroupAccounts]);

  useEffect(() => {
    searchBrands(filter, {
      ...pagination,
      keyword,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    searchBrands(filter, {
      ...pagination,
      page: activePage,
    });
  }

  async function handleBrandAccountChange(brandId, accountId) {
    setIsLoading(true);

    if (!accountId) {
      setIsLoading(false);

      return;
    }

    const { data: responseUpdate } = await api({
      method: 'post',
      url: '/brand/update',
      params: {
        brand_id: brandId,
        brand: JSON.stringify({
          ACCOUNT_RESPONSIBLE_ID: accountId,
        }),
      },
    });

    if (responseUpdate.status === 0) {
      toast.success(responseUpdate.message.text);

      searchBrands(filter, pagination);
    } else {
      toast.error(
        `Erro ao tentar salvar a marca: ${responseUpdate.message.text}`
      );
    }

    setIsLoading(false);
  }

  async function verifyBrandItems(brandId) {
    const { data: itemsExists } = await api({
      method: 'post',
      url: 'brand/verification',
      params: {
        brand_id: brandId,
      },
    });

    const hasItems = itemsExists.status !== 0;
    const hasModules = itemsExists.module || false;
    const hasInverters = itemsExists.inverter || false;
    const { message } = itemsExists;

    return {
      hasItems,
      hasModules,
      hasInverters,
      message,
    };
  }

  async function handleBrandDelete({ ID: brandId, NAME: brandName }) {
    const {
      hasItems,
      hasModules,
      hasInverters,
      message,
    } = await verifyBrandItems(brandId);

    console.table(hasItems, hasModules, hasInverters, message);

    if (hasItems) {
      openModal('modalBrandItems', {
        data: {
          message: message.text,
          hasModules,
          hasInverters,
          brandName,
        },
      });
    } else {
      swal({
        dangerMode: true,
        title: 'Atenção',
        text:
          'Você está prestes a excluir uma marca permanentemente. Deseja mesmo realizar essa acão?',
        icon: 'warning',
        buttons: ['Cancelar', 'Excluir'],
      }).then(async result => {
        if (result) {
          const { data: brand } = await api({
            method: 'post',
            url: 'brand/delete',
            params: {
              brand_id: brandId,
            },
          });

          if (brand.status === 0) {
            toast.success('Marca excluída com sucesso!');
            searchBrands(filter, pagination);
          } else {
            toast.error(brand.message.text);
          }
        }
      });
    }
  }

  async function handleChangeDefaultBrand(brandId) {
    const { data: brand } = await api({
      method: 'post',
      url: 'brand/default',
      params: {
        brand_id: brandId,
      },
    });

    if (brand.status === 0) {
      toast.success(brand.message.text);
      searchBrands(filter, pagination);
    } else {
      toast.error(brand.message.text);
    }
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function renderBrandsWithTable() {
    return (
      brands && (
        <Table
          cols={[
            { field: 'ID', description: 'Código', default: true },
            { field: 'NAME', description: 'Marca' },
            { field: 'CREATED_AT', description: 'Data de Cadastro' },
            {
              field: 'ACCOUNT_NAME',
              description: 'Responsável',
            },
          ]}
          mapping={brand => ({
            ID: brand.ID,
            NAME: brand.NAME,
            CREATED_AT: brand.DATE_CREATED_AT_FORMAT,
            ACCOUNT_NAME: brand.ACCOUNT_NAME,
          })}
          data={brands}
          loading={isLoading}
          onUpdateOrderBy={orderby => {
            const newPagination = {
              ...pagination,
              orderby,
            };

            searchBrands(filter, { ...newPagination, keyword });
          }}
        />
      )
    );
  }

  function renderBrandsWithActions() {
    return (
      brands && (
        <Table
          cols={[
            { field: 'IS_DEFAULT', description: 'Padrão' },
            { field: 'ID', description: 'Código', default: true },
            { field: 'NAME', description: 'Marca' },
            { field: 'CREATED_AT', description: 'Data de Cadastro' },
            {
              field: 'ACCOUNT_NAME',
              description: 'Responsável',
            },
            {
              field: 'ACCOUNT_RESPONSIBLE',
              description: 'Conta Responsável',
            },
            { field: 'ACTIONS', description: 'Ações' },
          ]}
          mapping={brand => ({
            IS_DEFAULT: (
              <Checkbox
                checked={brand.IS_DEFAULT === 'T'}
                onChange={() => {
                  handleChangeDefaultBrand(brand.ID);
                }}
              />
            ),
            ID: brand.ID,
            NAME: brand.NAME,
            CREATED_AT: brand.DATE_CREATED_AT_FORMAT,
            ACCOUNT_NAME: brand.ACCOUNT_NAME,
            ACCOUNT_RESPONSIBLE: () => (
              <Dropdown
                disabled={
                  groupAccounts.length === 0 || user.ID !== brand.ACCOUNT_ID
                }
                readOnly={groupAccounts.length === 1}
                value={brand.ACCOUNT_RESPONSIBLE_ID}
                text={brand.ACCOUNT_RESPONSIBLE}
                fluid
                search
                selection
                options={groupAccounts}
                placeholder="Seleciona uma conta."
                noResultsMessage="Nenhuma conta encontrada."
                onChange={(e, data) =>
                  handleBrandAccountChange(brand.ID, data.value)
                }
              />
            ),
            ACTIONS: () => (
              <>
                <Button
                  size="mini"
                  icon
                  color="blue"
                  title="Editar marca"
                  onClick={() => {
                    openModal('brandAdd', {
                      mode: 'edit',
                      data: {
                        ID: brand.ID,
                        NAME: brand.NAME,
                      },
                      onConfirm: () => searchBrands(filter, pagination),
                    });
                  }}
                >
                  <Icon name="pencil" />
                </Button>

                <Button
                  size="mini"
                  icon
                  color="red"
                  title="Remover marca"
                  onClick={() => handleBrandDelete(brand)}
                >
                  <Icon name="trash" />
                </Button>

                {user.TYPE_KEY_ === `ROOT` && (
                  <Button
                    size="mini"
                    icon
                    color="facebook"
                    title="Permissões de grupo"
                    onClick={() =>
                      history.push(`/brand/${brand.ID}/groups?backTo=/brands`)
                    }
                  >
                    <Icon name="lock" />
                  </Button>
                )}
              </>
            ),
          })}
          data={brands}
          loading={isLoading}
          onUpdateOrderBy={orderby => {
            const newPagination = {
              ...pagination,
              orderby,
            };

            searchBrands(filter, { ...newPagination, keyword });
          }}
        />
      )
    );
  }

  function renderBrandsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={() => searchBrands(filter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            className="middle aligned"
            textAlign="right"
            mobile={16}
            table={8}
            computer={6}
          >
            <span>Registros por página:</span>
          </Grid.Column>

          <Grid.Column mobile={16} table={8} computer={2} floated="right">
            <select className="ui dropdown fluid" onChange={handleLimitChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </Grid.Column>
        </Grid>

        {user.TYPE_KEY_ === 'ROOT'
          ? renderBrandsWithActions()
          : renderBrandsWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar marcas </span>
            <div>
              {user.TYPE_KEY_ === 'ROOT' && (
                <button
                  type="button"
                  className="ui button green"
                  onClick={() => {
                    openModal('brandAdd', {
                      mode: 'add',
                      onConfirm: () => searchBrands(filter, pagination),
                    });
                  }}
                >
                  <i className="mdi mdi-plus icon" /> Nova marca
                </button>
              )}
              {backTo && (
                <button
                  type="button"
                  className="ui button primary"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              )}
            </div>
          </div>

          <div className="ui attached bottom segment">
            {renderBrandsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
