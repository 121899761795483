import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
  Link,
} from 'react-router-dom';

import classNames from 'classnames';
import { Grid } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

import SubPageBillsSearch from './SubPage/Bills/Search';
import SubPageBillsSelect from './SubPage/Bills/Select';
import SubPageDocuments from './SubPage/Documents';
import SubPageIdentification from './SubPage/Identification';
import SubPageItemSearch from './SubPage/Item/Search';
import SubPageItemSelect from './SubPage/Item/Select';
import SubPageMaterialSearch from './SubPage/Material/Search';
import SubPageMaterialSelect from './SubPage/Material/Select';
import SubPageResults from './SubPage/Results';
import SubPageSimulation from './SubPage/Simulation';

function isNumber(x) {
  return !Number.isNaN(Number(x));
}

function ProjectAdd() {
  const history = useHistory();
  const { projectId } = useParams();
  const { path, url } = useRouteMatch();

  const { account } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState('');

  const {
    location: { pathname },
  } = history;

  const [editMode, setEditMode] = useState(false);

  const [self, setSelf] = useState({});
  const [isUpdatingSelf, setIsUpdatingSelf] = useState(false);

  const [validate, setValidate] = useState({});
  const [shouldSimulateAgain, setShouldSimulateAgain] = useState(false);

  const [showMenu, setShowMenu] = useState(!isMobile);

  const validateProject = useCallback(async () => {
    const response = await getProjectValidate();

    if (response) {
      const {
        information,
        project_energy_bill: bills,
        simulate: simulation,
        material,
        item,
      } = response.data;

      setValidate({
        identification: information.validate,
        bills: bills.validate,
        simulation: simulation.validate,
        material: material.validate,
        item: item.validate,
        results:
          !!information.validate &&
          !!bills.validate &&
          !!simulation.validate &&
          !!material.validate &&
          !!item.validate,
        documents:
          !!information.validate &&
          !!bills.validate &&
          !!simulation.validate &&
          !!material.validate &&
          !!item.validate,
      });

      if ('SIMULATED' in simulation.fields) {
        const isNotValidSimulation = simulation.fields.SIMULATED.type === 'F';
        setShouldSimulateAgain(isNotValidSimulation);
      } else {
        setShouldSimulateAgain(false);
      }
    }
  }, [projectId]);

  const updateSelf = useCallback(async () => {
    if (projectId && isNumber(projectId)) {
      setIsUpdatingSelf(true);

      const responseSelf = await getProjectSelf();

      if (responseSelf) {
        setSelf({
          ...responseSelf,
          CLIENT_NAME:
            responseSelf.CLIENT_TYPE === 'L'
              ? `${responseSelf.CLIENT_COMPANY_NAME} (${responseSelf.CLIENT_FANTASY_NAME})`
              : responseSelf.CLIENT_NAME,
        });
      } else {
        setSelf({});
      }

      await validateProject();
      setIsUpdatingSelf(false);
    }
  }, [projectId]);

  const getProjectValidatedId = useCallback(async () => {
    const id = await getProjectId();

    if (id) {
      await updateSelf();
    }
  }, []);

  useEffect(() => {
    getProjectValidatedId();
  }, [getProjectValidatedId]);

  useEffect(() => {
    setActiveTab(pathname.replace(url, ''));
    if (projectId && !isUpdatingSelf) {
      updateSelf();
    }
  }, [pathname, updateSelf]);

  async function getProjectId() {
    const { data: responseProjectId } = await api({
      method: 'post',
      url: 'project/add',
      params: { project_id: projectId },
    });

    if (responseProjectId.status === 0) {
      if (!responseProjectId.data.validated) {
        history.replace(`/project/${responseProjectId.data.ID}`);

        return responseProjectId.data.ID;
      }

      setEditMode(true);

      return false;
    }

    return false;
  }

  async function getProjectSelf() {
    if (projectId && isNumber(projectId)) {
      const { data: responseSelf } = await api({
        method: 'post',
        url: 'project/self',
        params: { project_id: projectId },
      });

      if (responseSelf.status === 0) {
        return responseSelf.data;
      }

      return false;
    }

    return false;
  }

  async function getProjectValidate() {
    if (projectId && isNumber(projectId)) {
      const { data: response } = await api({
        method: 'post',
        url: 'project/validate',
        params: {
          project_id: projectId,
        },
      });

      if (response.status === 0) {
        return response;
      }

      return false;
    }

    return false;
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">
            {editMode ? 'Editar projeto' : 'Novo projeto'} <br />
            {!editMode && (
              <span className="subtitle">
                <strong>Conta</strong>: {account.NAME} · {account.GROUP_NAME}
              </span>
            )}
          </span>
        </div>

        <div className={classNames('ui attached bottom segment')}>
          <div className="ui form m-b">
            <div className="fields">
              <div className="field">
                <label>Descrição do projeto</label>
                <i className="m-t-n-xs">{self.NAME || 'Não definida'}</i>
              </div>
              <div className="field">
                <label>Cliente</label>
                <i className="m-t-n-xs">{self.CLIENT_NAME || 'Não definido'}</i>
              </div>
            </div>
          </div>

          <Grid centered>
            {isMobile && (
              <Grid.Column mobile={16}>
                <button
                  type="button"
                  className="ui fluid button"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  {showMenu ? 'Fechar etapas' : 'Ver etapas'}
                </button>
              </Grid.Column>
            )}

            {showMenu && (
              <Grid.Column mobile={16}>
                <Link
                  to={`${url}/identification`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive:
                      validate.identification &&
                      activeTab.startsWith('/identification'),
                    green:
                      validate.identification &&
                      !activeTab.startsWith('/identification'),
                    active: activeTab.startsWith('/identification'),
                  })}
                >
                  <i
                    className={classNames('mdi icon status', {
                      'mdi-check': validate.identification,
                      'mdi-close': !validate.identification,
                    })}
                  />{' '}
                  Identificação
                </Link>
                <Link
                  to={`${url}/bills`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive: validate.bills && activeTab.startsWith('/bills'),
                    green: validate.bills && !activeTab.startsWith('/bills'),
                    active: activeTab.startsWith('/bills'),
                  })}
                >
                  <i
                    className={classNames('mdi icon status', {
                      'mdi-check': validate.bills,
                      'mdi-close': !validate.bills,
                    })}
                  />
                  Contas
                </Link>
                <Link
                  to={`${url}/simulation`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive:
                      validate.simulation &&
                      activeTab.startsWith('/simulation'),
                    green:
                      validate.simulation &&
                      !activeTab.startsWith('/simulation'),
                    red: shouldSimulateAgain && !validate.simulation,
                    active: activeTab.startsWith('/simulation'),
                  })}
                >
                  <i
                    className={classNames('mdi icon status', {
                      'mdi-check': validate.simulation,
                      'mdi-close': !shouldSimulateAgain && !validate.simulation,
                      'mdi-alert-circle-outline':
                        shouldSimulateAgain && !validate.simulation,
                    })}
                  />
                  Simulação
                </Link>

                <Link
                  to={`${url}/material`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive:
                      validate.material && activeTab.startsWith('/material'),
                    green:
                      validate.material && !activeTab.startsWith('/material'),
                    active: activeTab.startsWith('/material'),
                    disabled: shouldSimulateAgain,
                  })}
                >
                  <i
                    className={classNames('mdi icon status', {
                      'mdi-check': validate.material,
                      'mdi-close': !validate.material,
                    })}
                  />
                  Produtos
                </Link>

                <Link
                  to={`${url}/item`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive: validate.item && activeTab.startsWith('/item'),
                    green: validate.item && !activeTab.startsWith('/item'),
                    active: activeTab.startsWith('/item'),
                    disabled: shouldSimulateAgain,
                  })}
                >
                  <i
                    className={classNames('mdi icon status', {
                      'mdi-check': validate.item,
                      'mdi-close': !validate.item,
                    })}
                  />
                  Orçamento
                </Link>

                <Link
                  to={`${url}/results`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive: validate.results && activeTab.startsWith('/results'),
                    green:
                      validate.results && !activeTab.startsWith('/results'),
                    active: activeTab.startsWith('/results'),
                    disabled: shouldSimulateAgain,
                  })}
                >
                  <i className="mdi icon status mdi-chart-line" />
                  Resultados
                </Link>

                <Link
                  to={`${url}/documents`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive:
                      validate.documents && activeTab.startsWith('/documents'),
                    green:
                      validate.documents && !activeTab.startsWith('/documents'),
                    active: activeTab.startsWith('/documents'),
                    disabled: shouldSimulateAgain,
                  })}
                >
                  <i className="mdi icon status mdi-file-document" />
                  Documentos
                </Link>
              </Grid.Column>
            )}
          </Grid>

          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/identification`} />
            </Route>
            <Route
              exact
              path={`${path}/identification`}
              component={routeProps => (
                <>
                  <SubPageIdentification
                    {...routeProps}
                    project={self}
                    projectId={projectId}
                    updateStore={updateSelf}
                  />
                </>
              )}
            />

            <Route exact path={`${path}/bills`}>
              <Redirect to={`${url}/bills/search`} />
            </Route>
            <Route
              exact
              path={`${path}/bills/search`}
              render={routeProps => (
                <SubPageBillsSearch
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                  validateProject={validateProject}
                />
              )}
            />
            <Route
              exact
              path={`${path}/bills/select`}
              render={routeProps => (
                <SubPageBillsSelect
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  clientId={self.CLIENT_ID}
                  updateStore={updateSelf}
                />
              )}
            />
            <Route
              exact
              path={`${path}/simulation`}
              render={routeProps => (
                <SubPageSimulation
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                  validateProject={validateProject}
                />
              )}
            />
            <Route exact path={`${path}/material`}>
              <Redirect to={`${url}/material/search`} />
            </Route>
            <Route
              exact
              path={`${path}/material/search`}
              render={routeProps => (
                <SubPageMaterialSearch
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                />
              )}
            />
            <Route
              exact
              path={`${path}/material/select`}
              render={routeProps => (
                <SubPageMaterialSelect
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                />
              )}
            />
            <Route exact path={`${path}/item`}>
              <Redirect to={`${url}/item/search`} />
            </Route>
            <Route
              exact
              path={`${path}/item/search`}
              render={routeProps => (
                <SubPageItemSearch
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                />
              )}
            />
            <Route
              exact
              path={`${path}/item/select`}
              render={routeProps => (
                <SubPageItemSelect
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                />
              )}
            />
            <Route
              exact
              path={`${path}/results`}
              render={routeProps => (
                <SubPageResults
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                />
              )}
            />
            <Route
              exact
              path={`${path}/documents`}
              render={routeProps => (
                <SubPageDocuments
                  {...routeProps}
                  project={self}
                  projectId={projectId}
                  updateStore={updateSelf}
                />
              )}
            />
            {/* <Route path={`${path}/*`}>
              <Redirect to={`${url}/identification`} />
            </Route> */}
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default memo(ProjectAdd);
