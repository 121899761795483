import React from 'react';

import { Container } from './styles';

export default function PageContainer({ width, children, ...restProps }) {
  return (
    <Container width={width} {...restProps}>
      {children}
    </Container>
  );
}
