import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  margin-top: 7px;
  margin-bottom: -15px;
`;

export const Title = styled.strong`
  margin-left: 5px;
`;
