import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function Search() {
  const { openModal } = useContext(ModalContext);

  const [items, setItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({
    LIMIT: '10',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchItems = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: 'item/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setItems(responseSearch.data);
    } else {
      setItems([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchItems(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchItems(filter, pagination);
  }, [searchItems]);

  useEffect(() => {
    searchItems(filter, {
      ...pagination,
    });

    setLimitChanged(false);
  }, [limitChanged]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchItems(filter, pagination);
  }

  function handlePaginationChange(e, { activePage }) {
    searchItems(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleLimitChange(e) {
    setPagination({
      ...pagination,
      limit: e.target.value,
    });

    setLimitChanged(true);
  }

  function handleItemDelete(itemId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um item permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'item/delete',
          params: {
            item_id: JSON.stringify([itemId]),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Item excluído com sucesso!');
          searchItems(filter, pagination);
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários itens permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const itemsToDelete = items
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!itemsToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um item para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'item/delete',
          params: {
            item_id: JSON.stringify(itemsToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Itens excluídos com sucesso!');
          searchItems(filter, pagination);
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () => items.length && items.every(m => m.uiChecked === true),
    [items]
  );

  function handleSelectAll(e, { checked }) {
    setItems(oldItems =>
      oldItems.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(itemId) {
    setItems(oldItems =>
      oldItems.map(item => {
        return item.ID === itemId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function renderItemsWithTable() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'DESCRIPTION', description: 'Descrição' },
          { field: 'PRICE', description: 'Preço' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={item => ({
          ID: () => (
            <Checkbox
              checked={!!item.uiChecked}
              onChange={() => handleSelect(item.ID)}
            />
          ),
          DESCRIPTION: item.DESCRIPTION,
          PRICE: item.KEY_
            ? '-'
            : `R$ ${numberFormat(item.PRICE, 2, ',', '.')}`,
          ACTIONS: () => (
            <>
              {!item.KEY_ && (
                <>
                  <Button
                    size="mini"
                    icon
                    color="blue"
                    title="Editar item"
                    onClick={() => {
                      openModal('itemAdd', {
                        mode: 'edit',
                        data: {
                          ID: item.ID,
                          DESCRIPTION: item.DESCRIPTION,
                          PRICE: item.PRICE,
                        },
                        onConfirm: () => searchItems(filter, pagination),
                      });
                    }}
                  >
                    <Icon name="pencil" />
                  </Button>
                  <Button
                    size="mini"
                    icon
                    color="red"
                    title="Remover item"
                    onClick={() => handleItemDelete(item.ID)}
                  >
                    <Icon name="trash" />
                  </Button>
                </>
              )}
            </>
          ),
        })}
        data={items}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchItems(filter, newPagination);
        }}
      />
    );
  }

  function renderItemsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!items.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            <button
              type="button"
              className="ui button green icon"
              title="Novo item"
              onClick={() => {
                openModal('itemAdd', {
                  mode: 'add',
                  onConfirm: () => searchItems(filter, pagination),
                });
              }}
            >
              <i className="mdi mdi-plus icon" /> Novo item
            </button>
          </Grid.Column>
        </Grid>

        {renderItemsWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Gerenciar itens </span>
          </div>

          <div className="ui attached bottom segment">
            {renderItemsContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
