import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { merge } from 'lodash/object';
import { Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function SubPageIdentification({ store, updateStore }) {
  const { self, clientId, billId } = store;

  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const projectId = query.get('projectId');

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    triggerValidation,
  } = useForm({
    defaultValues: self,
  });

  const [providerList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState();

  const [patternList, setPatternList] = useState([]);
  const [selectedPattern, setSelectedPattern] = useState();

  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();

  const [selectedEnergyType, setSelectedEnergyType] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getPattern();
      setPatternList(data);

      if (self.ENTRY_PATTERN_ID) {
        setSelectedPattern(self.ENTRY_PATTERN_ID);
        setValue('ENTRY_PATTERN_ID', self.ENTRY_PATTERN_ID);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getGroup();
      setGroupList(data);

      if (self.GROUND_GROUP_ID) {
        setSelectedGroup(self.GROUND_GROUP_ID);
        setValue('GROUND_GROUP_ID', self.GROUND_GROUP_ID);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getProvider();
      setProviderList(data);

      if (self.ENERGY_PROVIDER_ID) {
        setSelectedProvider(self.ENERGY_PROVIDER_ID);
        setValue('ENERGY_PROVIDER_ID', self.ENERGY_PROVIDER_ID);
      }
    })();
  }, []);

  useEffect(() => {
    if (self.ENERGY_TYPE) {
      setSelectedEnergyType(self.ENERGY_TYPE);
      setValue('ENERGY_TYPE', self.ENERGY_TYPE);
    }
  }, []);

  async function getPattern() {
    const { data: pattern } = await api({
      method: 'get',
      url: 'entry/pattern/select',
    });

    if (pattern.status === 0) {
      return pattern.data;
    }

    return false;
  }

  async function getGroup() {
    const { data: group } = await api({
      method: 'get',
      url: 'ground/group/select',
    });

    if (group.status === 0) {
      return group.data;
    }

    return false;
  }

  async function getProvider() {
    const { data: provider } = await api({
      method: 'get',
      url: 'energy/provider/select',
    });

    if (provider.status === 0) {
      return provider.data;
    }

    return false;
  }

  async function handleFormSubmit(data) {
    setIsLoading(true);
    const { data: response } = await api({
      method: 'post',
      url: '/energy/bill/information',
      params: {
        energy_bill_id: billId,
        energy_bill: {
          ...data,
          TOTAL_VALUE_TAX_TE:
            data.TOTAL_VALUE_TAX_TE !== '' ? data.TOTAL_VALUE_TAX_TE : null,
          ENERGY_TYPE: data.ENERGY_TYPE !== '' ? data.ENERGY_TYPE : null,
        },
        filter: JSON.stringify({ ...(projectId && { PROJECT_ID: projectId }) }),
      },
    });

    if (response.status === 0) {
      toast.success('Informações salvas com sucesso!');

      updateStore(merge(store, { self: merge(store.self, data) }));

      return true;
    }
    toast.error(response.message.text);

    setIsLoading(false);
    return false;
  }

  async function submitAndNavigate(navigateTo) {
    const data = getValues();

    let path = navigateTo;
    if (projectId) {
      path += `?projectId=${projectId}`;
    }

    if (await triggerValidation()) {
      const isSave = await handleFormSubmit({ CLIENT_ID: clientId, ...data });

      if (isSave) {
        history.push(path);
      }
    }
  }

  return (
    <form
      className={classNames('ui form')}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <h4 className="ui horizontal divider">Identificação</h4>

      <div className="field">
        <div className="fields">
          <div
            className={classNames('six wide field required', {
              error: errors.ENERGY_PROVIDER_ID,
            })}
          >
            <label>Companhia de Energia</label>

            <Dropdown
              fluid
              search
              selection
              options={providerList.map(provider => ({
                key: provider.ID,
                value: provider.ID,
                text: `${provider.DESCRIPTION}`,
              }))}
              value={selectedProvider}
              disabled={providerList.length === 0}
              placeholder="Selecione uma companhia."
              noResultsMessage="Nenhuma companhia encontrado."
              onChange={(e, data) => {
                setSelectedProvider(data.value);
                setValue('ENERGY_PROVIDER_ID', data.value);
              }}
            />

            <input
              type="hidden"
              name="ENERGY_PROVIDER_ID"
              ref={register({ required: true })}
            />

            {errors.ENERGY_PROVIDER_ID && (
              <span>Companhia de Energia é obrigatória </span>
            )}
          </div>

          <div
            className={classNames('four wide field required', {
              error: errors.UNIT_CODE,
            })}
          >
            <label>Código da unidade</label>

            <input
              type="text"
              name="UNIT_CODE"
              ref={register({ required: true })}
            />
            {errors.UNIT_CODE && (
              <span>O código da unidade deve ser um número</span>
            )}
          </div>

          <div
            className={classNames('three wide field required', {
              error: errors.TOTAL_VALUE_TAX_TUSD,
            })}
          >
            <label>Valor Total das Tarifas - TUSD</label>

            <input
              type="hidden"
              name="TOTAL_VALUE_TAX_TUSD"
              ref={register({
                required: true,
              })}
            />

            <InputNumberFormat
              type="tel"
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={8}
              onValueChange={v =>
                setValue('TOTAL_VALUE_TAX_TUSD', v.floatValue)
              }
              defaultValue={self.TOTAL_VALUE_TAX_TUSD}
            />
            {errors.TOTAL_VALUE_TAX_TUSD && (
              <span>
                Valor Total das Tarifas - TUSD é obrigatório e deve ser um
                número válido
              </span>
            )}
          </div>

          <div className="three wide field">
            <label>Valor Total das Tarifas - TE</label>

            <input
              type="hidden"
              name="TOTAL_VALUE_TAX_TE"
              ref={register({
                required: false,
              })}
            />
            <InputNumberFormat
              type="tel"
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={8}
              onValueChange={v => setValue('TOTAL_VALUE_TAX_TE', v.floatValue)}
              defaultValue={self.TOTAL_VALUE_TAX_TE}
            />
            {errors.TOTAL_VALUE_TAX_TE && (
              <span>
                Valor Total das Tarifas - TE é obrigatório e deve ser um número
                válido
              </span>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames('field required', {
          error: errors.PROPERTY_DESCRIPTION,
        })}
      >
        <label>Descrição do imóvel</label>

        <input
          type="text"
          name="PROPERTY_DESCRIPTION"
          ref={register({ required: true })}
        />
        {errors.PROPERTY_DESCRIPTION && (
          <span>A descrição do imóvel é obrigatória</span>
        )}
      </div>

      <div className="field">
        <div className="fields">
          <div
            className={classNames('six wide field required', {
              error: errors.ENTRY_PATTERN_ID,
            })}
          >
            <label>Tipo de Entrada</label>

            <Dropdown
              fluid
              search
              selection
              options={patternList.map(pattern => ({
                key: pattern.ID,
                value: pattern.ID,
                text: `${pattern.DESCRIPTION}`,
              }))}
              value={selectedPattern}
              disabled={patternList.length === 0}
              placeholder="Selecione um tipo."
              noResultsMessage="Nenhum tipo encontrado."
              onChange={(e, data) => {
                setSelectedPattern(data.value);
                setValue('ENTRY_PATTERN_ID', data.value);
              }}
            />

            <input
              type="hidden"
              name="ENTRY_PATTERN_ID"
              ref={register({ required: true })}
            />

            {errors.ENTRY_PATTERN_ID && (
              <span>Tipo de entrada é obrigatória</span>
            )}
          </div>
          <div
            className={classNames('six wide field required', {
              error: errors.GROUND_GROUP_ID,
            })}
          >
            <label>Grupo</label>

            <Dropdown
              fluid
              search
              selection
              options={groupList.map(group => ({
                key: group.ID,
                value: group.ID,
                text: `${group.DESCRIPTION}`,
              }))}
              value={selectedGroup}
              disabled={groupList.length === 0}
              placeholder="Selecione um grupo."
              noResultsMessage="Nenhum grupo encontrado."
              onChange={(e, data) => {
                setSelectedGroup(data.value);
                setValue('GROUND_GROUP_ID', data.value);
              }}
            />

            <input
              type="hidden"
              name="GROUND_GROUP_ID"
              ref={register({ required: true })}
            />

            {errors.GROUND_GROUP_ID && <span>Grupo é obrigatório</span>}
          </div>

          <div
            className={classNames('four wide field', {
              error: errors.ENERGY_TYPE,
            })}
          >
            <label>Tipo de rede</label>

            <Dropdown
              fluid
              search
              selection
              options={[220, 380].map(type => ({
                key: type,
                value: type,
                text: `Trifásico ${type}v`,
              }))}
              value={selectedEnergyType}
              placeholder="Selecione um tipo de rede."
              noResultsMessage="Nenhum tipo de rede encontrado."
              onChange={(e, data) => {
                setSelectedEnergyType(data.value);
                setValue('ENERGY_TYPE', data.value);
              }}
            />

            <input
              type="hidden"
              name="ENERGY_TYPE"
              ref={register({ required: false })}
            />

            {errors.ENERGY_TYPE && <span>Tipo de rede é obrigatório</span>}
          </div>
        </div>
      </div>

      <div className="field">
        <button
          type="button"
          className={classNames('ui button green', {
            loading: isLoading,
            'right floated': !isMobile,
            fluid: isMobile,
          })}
          disabled={isLoading}
          onClick={() => submitAndNavigate('address')}
        >
          Avançar
        </button>
      </div>
      <div className="field" />
    </form>
  );
}
