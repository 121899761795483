import React from 'react';

import { Container } from './styles';

export default function Erro() {
  return (
    <Container>
      <h1>Oops!</h1>
      <span>
        A página que você está tentando acessar encontra-se indisponível.
      </span>
    </Container>
  );
}
