import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${({ width }) => width || '100%'};
  /* margin: 10px auto; */
  /* padding: 5px 10px; */
  /* margin-bottom: 50px; */
`;
