import React, { useContext } from 'react';

import classNames from 'classnames';
import { Grid, Message } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';

import { Container, Logo } from './styles';

export default function Index() {
  const { user } = useContext(AuthContext);
  const accountWithFinancialBlock = user.ACCOUNT_FINANCIAL_STATUS === 'B';

  function renderContainer() {
    return (
      <Grid centered>
        <Grid.Row>
          <h1>Seja bem-vindo!</h1>
        </Grid.Row>

        <Grid.Row>
          <Logo
            src={`${process.env.PUBLIC_URL}/assets/solo_solar_logo.png`}
            alt="WebVolt"
          />
        </Grid.Row>

        {accountWithFinancialBlock && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://suportefranqueado.com.br/solosolar/crm"
          >
            <button type="button" className="ui large green button m-b-md">
              Regularizar acesso
            </button>
          </a>
        )}
      </Grid>
    );
  }

  return (
    <Container>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div
            className={classNames('ui top attached header', {
              module: !accountWithFinancialBlock,
            })}
          >
            {accountWithFinancialBlock ? (
              <Message color="yellow" size="medium">
                Conta temporariamente indisponível, para regularização do
                acesso,{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://suportefranqueado.com.br/solosolar/crm"
                >
                  clique aqui
                </a>
                .
              </Message>
            ) : (
              <span className="title"> Pagina Inicial </span>
            )}
          </div>

          <div className="ui attached bottom segment">{renderContainer()}</div>
        </div>
      </div>
    </Container>
  );
}
