import React, { createContext } from 'react';

import useAuth from './hooks/useAuth';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const {
    authenticated,
    user,
    setUser,
    setToken,
    logout,
    loading,
    handleLogin,
    getUser,
    account,
    setManageAccount,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        setUser,
        setToken,
        logout,
        loading,
        handleLogin,
        getUser,
        account,
        setManageAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
