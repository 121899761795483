import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import InputNumberFormat from 'react-number-format';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
  Checkbox,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import BillCard from '~/components/Card/Bill';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function ProjectBillSearch({
  project,
  projectId,
  validateProject,
}) {
  const history = useHistory();

  const { openModal } = useContext(ModalContext);

  const [showTable, setShowTable] = useState(!isMobile);

  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [summary, setSummary] = useState({});

  const [keyword, setKeyword] = useState('');
  const [incrementalThreshold, setIncrementalThreshold] = useState(0);

  const [canChangeThreshold, setCanChangeThreshold] = useState(false);
  const [inputThreshold, setInputThreshold] = useState(null);

  const [filter] = useState({
    PROJECT_ID: projectId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  useEffect(() => {
    if (canChangeThreshold && inputThreshold) {
      inputThreshold.focus();
    }
  }, [canChangeThreshold]);

  const searchBills = useCallback(
    useRef(
      throttle(async (filters, pag) => {
        setIsLoading(true);

        const { data: response } = await api({
          method: 'post',
          url: 'project/energy/bill/search',
          params: {
            project_id: projectId,
            filter: filters,
            pagination: pag,
          },
        });

        if (response.status === 0) {
          setBills(response.data);
          setSummary(response.summary);
          setPagination(response.pagination);

          const { INCREMENTAL_LIMIT } = response.summary;

          setIncrementalThreshold(INCREMENTAL_LIMIT);
        } else {
          toast.error(response.message.text);
          setBills([]);
        }

        setIsLoading(false);

        return response;
      }, 1000)
    ).current,
    []
  );

  const updateProject = useCallback(async (threshold, billIds) => {
    setIsLoading(true);

    const { data: response } = await api({
      method: 'post',
      url: 'project/energy/bill/calculation',
      params: {
        project_id: projectId,
        project_energy_bill_id: JSON.stringify(billIds),
        calc: threshold || 0,
      },
    });

    if (response.status === 0) {
      toast.success(response.message.text);
      validateProject();
    } else {
      toast.error(response.message.text);
    }

    setIsLoading(false);
    setCanChangeThreshold(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchBills(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  const updateKWHValues = useRef(
    debounce(async (calc, billIds) => {
      await updateProject(calc, billIds);

      await searchBills(filter, pagination);
    }, 1000)
  ).current;

  useEffect(() => {
    searchBills(filter, pagination);
  }, [searchBills]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    const newPagination = {
      ...pagination,
      page: activePage,
    };

    searchBills(filter, newPagination);
  }

  async function handleKWHValueChange(
    value = incrementalThreshold,
    ids = bills.map(b => b.ID)
  ) {
    updateKWHValues(value, ids);
  }

  function handleBillDelete(billId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir uma conta do projeto permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'project/energy/bill/delete',
          params: {
            project_energy_bill_id: JSON.stringify([billId]),
          },
        });

        if (responseDelete.status === 0) {
          searchBills(filter, pagination);
          toast.success('Conta excluída com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  async function handleChangeDefaultBill({ projectBillId, billId }) {
    const { data: bill } = await api({
      method: 'post',
      url: 'project/energy/bill/default',
      params: {
        energy_bill_id: projectBillId,
        project_id: projectId,
      },
    });

    if (bill.status === 0) {
      toast.success(bill.message.text);
      searchBills(filter, pagination);
    } else {
      openModal('projectEnergyBillValidate', {
        onConfirm: () =>
          history.push(
            `/client/${project.CLIENT_ID}/bill/${billId}/identification?projectId=${projectId}&backTo=/project/${projectId}/bills/search&mode=edit`
          ),
      });
    }
  }

  async function validateAndPush(path) {
    const { data: response } = await api({
      method: 'post',
      url: 'project/energy/bill/validateDefault',
      params: {
        project_id: projectId,
      },
    });

    if (response.status !== 0) {
      toast.error(response.message.text);
      return;
    }

    history.push(path);
  }

  function renderBillsWithCard() {
    return (
      <Grid centered>
        {bills.length > 0 &&
          !isLoading &&
          bills.map(bill => (
            <Grid.Column key={bill.ID} mobile={16} tablet={8} computer={4}>
              <BillCard
                type="project"
                handleDelete={() => handleBillDelete(bill.ID)}
                bill={bill}
              />
            </Grid.Column>
          ))}

        {!isLoading && bills.length === 0 && (
          <Grid.Column>
            <Message content="Nenhuma conta adicionada!" />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando contas..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderBillsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">Local de geração</th>
            <th className="center aligned">Código</th>
            <th className="center aligned">Descrição</th>
            <th className="center aligned">Valor TUSD</th>
            <th className="center aligned">Valor TE</th>
            <th className="center aligned">Tipo de entrada</th>
            <th className="center aligned">Grupo</th>
            <th className="center aligned">
              Taxa de <br /> disponibilidade
            </th>
            <th className="center aligned">HSP</th>
            <th className="center aligned">Tipo de rede</th>
            <th className="center aligned">
              Consumo <br /> médio mensal (KW/h)
            </th>
            <th className="center aligned">
              Consumo <br /> total anual (KW/h)
            </th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {bills.length > 0 &&
            !isLoading &&
            bills.map(bill => {
              return (
                <tr key={bill.ID}>
                  <td className="center aligned">
                    <div className="ui checkbox">
                      <Checkbox
                        checked={bill.IS_DEFAULT === 'T'}
                        onChange={() => {
                          handleChangeDefaultBill({
                            projectBillId: bill.ID,
                            billId: bill.ENERGY_BILL_ID,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <td className="center aligned">{bill.UNIT_CODE || '-'}</td>
                  <td className="center aligned">
                    {bill.PROPERTY_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.TOTAL_VALUE_TAX_TUSD, 4, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.TOTAL_VALUE_TAX_TE, 4, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {bill.ENTRY_PATTERN_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {bill.GROUND_GROUP_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.AVAILABILITY_RATE, 0, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {numberFormat(bill.HOURLY_SUNNY_PEAK, 3, ',', '.')}
                  </td>
                  <td className="center aligned">
                    {bill.ENERGY_TYPE
                      ? `Trifásico ${numberFormat(
                          bill.ENERGY_TYPE,
                          0,
                          ',',
                          '.'
                        )}v`
                      : '-'}
                  </td>

                  <td className="center aligned">
                    <span
                      className={classNames({
                        'text-line':
                          !!bill.NEW_KWH_MEAN &&
                          bill.KWH_MEAN !== bill.NEW_KWH_MEAN,
                      })}
                    >
                      {numberFormat(bill.KWH_MEAN, 2, ',', '.')}
                    </span>{' '}
                    <br />
                    {!!summary.INCREMENTAL_LIMIT &&
                      summary.INCREMENTAL_LIMIT !== 0 && (
                        <b>
                          {numberFormat(bill.NEW_KWH_MEAN, 2, ',', '.')}{' '}
                          <span className="text-red">
                            (+
                            {numberFormat(
                              summary.INCREMENTAL_LIMIT,
                              2,
                              ',',
                              '.'
                            )}
                            %)
                          </span>
                        </b>
                      )}
                  </td>
                  <td className="center aligned">
                    <span
                      className={classNames({
                        'text-line':
                          !!bill.NEW_KWH_MEAN &&
                          bill.KWH_MEAN !== bill.NEW_KWH_MEAN,
                      })}
                    >
                      {numberFormat(bill.KWH_TOTAL, 2, ',', '.')}
                    </span>{' '}
                    <br />
                    {!!summary.INCREMENTAL_LIMIT &&
                      summary.INCREMENTAL_LIMIT !== 0 && (
                        <b>
                          {numberFormat(bill.NEW_KWH_TOTAL, 2, ',', '.')}{' '}
                          <span className="text-red">
                            (+
                            {numberFormat(
                              summary.INCREMENTAL_LIMIT,
                              2,
                              ',',
                              '.'
                            )}
                            %)
                          </span>
                        </b>
                      )}
                  </td>

                  <td className="center aligned">
                    <Button
                      size="mini"
                      icon
                      color="blue"
                      title="Editar conta"
                      onClick={() =>
                        history.push(
                          `/client/${project.CLIENT_ID}/bill/${bill.ENERGY_BILL_ID}/identification?projectId=${projectId}&backTo=/project/${projectId}/bills/search&mode=edit`
                        )
                      }
                    >
                      <Icon name="pencil" />
                    </Button>

                    <Button
                      size="mini"
                      icon
                      color="red"
                      title="Remover conta"
                      onClick={() => handleBillDelete(bill.ID)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}

          {!isLoading && bills.length === 0 && (
            <tr>
              <td colSpan="14">
                <Message content="Nenhuma conta encontrada! Atualize os filtros e pesquisa novamente." />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="14">
                <Message color="blue" content="Buscando contas..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <h4 className="ui horizontal divider">Contas de energia</h4>

      <h4
        className={classNames('text-center', {
          'text-red': !summary.PROPERTY_DESCRIPTION,
        })}
      >
        {summary.PROPERTY_DESCRIPTION
          ? `Local de geração de energia: ${summary.PROPERTY_DESCRIPTION} (Trifásico ${summary.ENERGY_TYPE}v)`
          : 'Local de geração de energia não configurado'}
      </h4>

      <Grid centered>
        <Grid.Column mobile={16} table={16} computer={16}>
          <div className="ui form centered">
            <div className="fields m-b-n text-center">
              <div className="field m">
                <label>Total de contas</label>
                <i className="m-t-n-xs">{numberFormat(summary.QTTY || 0, 0)}</i>
              </div>
              <div className="field m">
                <label>Total de consumo - Ano kw/h</label>
                <i
                  className={classNames('m-t-n-xs', {
                    'text-line':
                      !!summary.NEW_KWH_TOTAL &&
                      summary.KWH_TOTAL !== summary.NEW_KWH_TOTAL,
                  })}
                >
                  {numberFormat(summary.KWH_TOTAL || 0, 2, ',', '.')}
                </i>
                <br />
                {summary.NEW_KWH_TOTAL !== 0 &&
                  summary.KWH_TOTAL !== summary.NEW_KWH_TOTAL && (
                    <b>
                      {numberFormat(summary.NEW_KWH_TOTAL, 2, ',', '.')}{' '}
                      {summary.INCREMENTAL_LIMIT !== 0 && (
                        <span className="text-red">
                          (+
                          {numberFormat(summary.INCREMENTAL_LIMIT, 2, ',', '.')}
                          %)
                        </span>
                      )}
                    </b>
                  )}
              </div>
              <div className="field m">
                <label>Média de consumo - Mensal kw/h</label>
                <i
                  className={classNames('m-t-n-xs', {
                    'text-line':
                      !!summary.NEW_KWH_MEAN_TOTAL &&
                      summary.KWH_MEAN_TOTAL !== summary.NEW_KWH_MEAN_TOTAL,
                  })}
                >
                  {numberFormat(summary.KWH_MEAN_TOTAL || 0, 2, ',', '.')}
                </i>
                <br />
                {summary.NEW_KWH_MEAN_TOTAL !== 0 &&
                  summary.KWH_MEAN_TOTAL !== summary.NEW_KWH_MEAN_TOTAL && (
                    <b>
                      {numberFormat(summary.NEW_KWH_MEAN_TOTAL, 2, ',', '.')}{' '}
                      {summary.INCREMENTAL_LIMIT !== 0 && (
                        <span className="text-red">
                          (+
                          {numberFormat(summary.INCREMENTAL_LIMIT, 2, ',', '.')}
                          %)
                        </span>
                      )}
                    </b>
                  )}
              </div>
              <div className="field m">
                <label>Valor médio das tarifas - TUSD/TE</label>
                <i className="m-t-n-xs">
                  {numberFormat(summary.TAX_MEAN || 0, 2, ',', '.')}
                </i>
              </div>
              <div className="field m">
                <label>Taxa de disponibilidade (KWh)</label>
                <i className="m-t-n-xs">
                  {numberFormat(
                    summary.TOTAL_AVAILABILITY_RATE || 0,
                    2,
                    ',',
                    '.'
                  )}
                </i>
              </div>

              <div className="field m">
                <label>Limite incremental - %</label>
                <div className="ui action input">
                  <InputNumberFormat
                    type="tel"
                    disabled={!canChangeThreshold}
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    value={incrementalThreshold || ''}
                    suffix="%"
                    placeholder="0%"
                    decimalScale={2}
                    allowNegative={false}
                    onValueChange={v => setIncrementalThreshold(v.floatValue)}
                    onBlur={() => {
                      handleKWHValueChange();
                    }}
                    onKeyPress={e =>
                      e.key === 'Enter' && handleKWHValueChange()
                    }
                    getInputRef={el => setInputThreshold(el)}
                  />

                  {!canChangeThreshold && (
                    <button
                      type="button"
                      className="ui icon button yellow"
                      onClick={() => {
                        setCanChangeThreshold(true);
                      }}
                    >
                      <i className="mdi mdi-pencil icon" />
                    </button>
                  )}

                  {canChangeThreshold && (
                    <button
                      type="button"
                      disabled={isLoading}
                      className={classNames('ui icon button blue', {
                        loading: isLoading,
                      })}
                    >
                      <i className="mdi mdi-content-save icon" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <Grid centered>
        <Grid.Column mobile={16} table={8} computer={8}>
          <div className="ui action fluid input">
            <input
              type="text"
              placeholder="Pesquisar por"
              value={keyword}
              onChange={handleKeywordChange}
            />
            <button
              className={classNames('ui button label', {
                loading: isLoading,
              })}
              type="button"
              disabled={isLoading}
              onClick={() => searchBills(filter, { ...pagination, keyword })}
            >
              Pesquisar
            </button>
          </div>
        </Grid.Column>

        <Grid.Column
          mobile={16}
          table={8}
          computer={8}
          className="right aligned"
        >
          {!isMobile && (
            <button
              type="button"
              className="ui button icon"
              title="Alterar visualização"
              onClick={() => setShowTable(!showTable)}
            >
              <i
                className={classNames('mdi icon', {
                  'mdi-table': showTable,
                  'mdi-cards': !showTable,
                })}
              />
            </button>
          )}

          <Link to="select" className="ui button green icon">
            <i className="mdi mdi-magnify icon" /> Pesquisar contas
          </Link>
        </Grid.Column>
      </Grid>

      {showTable ? renderBillsWithTable() : renderBillsWithCard()}

      <Grid centered>
        <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
          <Pagination
            disabled={bills.loading}
            activePage={pagination.page}
            onPageChange={handlePaginationChange}
            totalPages={pagination.total_page}
            ellipsisItem={false}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{
              content: <Icon name="angle right" />,
              icon: true,
            }}
          />
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <button
            type="button"
            className={classNames('ui button blue', {
              laoding: isLoading,
              'left floated': !isMobile,
              fluid: isMobile,
            })}
            disabled={isLoading}
            onClick={() => history.push('../identification')}
          >
            Voltar
          </button>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={8} computer={8}>
          <button
            type="button"
            className={classNames('ui button green', {
              laoding: isLoading,
              'right floated': !isMobile,
              fluid: isMobile,
            })}
            disabled={isLoading}
            onClick={() => validateAndPush('../simulation')}
          >
            Avançar
          </button>
        </Grid.Column>
      </Grid>
    </>
  );
}
