import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import swal from 'sweetalert';

import MaskedInput from '~/components/MaskedInput';
import api from '~/services/api';

function FormRegister({ onSubmit, initialData }) {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    getValues,
  } = useForm({
    defaultValues: initialData,
  });

  const inputNumberFef = useRef();

  const [typeList, setTypeList] = useState([]);
  const [levelList, setLevelList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [stateSelected, setStateSelected] = useState(initialData.UF_ID);
  const [citySelected, setCitySelected] = useState(initialData.CITY_ID);

  const [isFindingCEP, setIsFindingCEP] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const filteredCities = useMemo(() => {
    if (stateSelected) {
      return cityList.map(city => ({
        key: city.ID,
        value: city.ID,
        text: city.NAME,
      }));
    }

    return [];
  }, [cityList, stateSelected]);

  async function getState() {
    const { data: uf } = await api({
      method: 'get',
      url: 'uf/list',
      params: {
        filter: JSON.stringify({}),
      },
    });

    if (uf.status === 0) {
      return uf.data;
    }

    return [];
  }

  async function getCity() {
    if (stateSelected) {
      const { data: city } = await api({
        method: 'get',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: stateSelected,
          }),
        },
      });

      if (city.status === 0) {
        return city.data;
      }
    }
    return [];
  }

  const loadStatesAndCities = useCallback(async () => {
    setIsLoading(true);

    const states = await getState();
    const cities = await getCity();

    const statesMap = states.map(state => ({
      key: state.ID,
      value: state.ID,
      text: state.NAME,
    }));

    setStateList(statesMap);
    setCityList(cities);

    setValue('UF_ID', initialData.UF_ID);
    setValue('CITY_ID', initialData.CITY_ID);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadStatesAndCities();
  }, [loadStatesAndCities]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [stateSelected]);

  useEffect(() => {
    if (formState.submitCount > 0 && !formState.isValid) {
      toast.error('Verifique todos os campos obrigatórios.');
    }
  }, [formState.submitCount, formState.isValid]);

  async function handleFindCEP(e) {
    if (e) {
      e.preventDefault();
    }

    const { POSTAL_CODE: cep } = getValues();

    if (!cep) return;

    setIsFindingCEP(true);

    const { data } = await api({
      method: 'get',
      url: 'cep/lookup',
      params: {
        cep,
      },
    });

    setIsFindingCEP(false);

    if (data.status === 0) {
      const { UF, CITY, ADDRESS } = data.data;

      setStateSelected(+UF.ID);
      setCitySelected(+CITY.ID);
      setValue('UF_ID', +UF.ID);
      setValue('CITY_ID', +CITY.ID);
      setValue('ADDRESS', ADDRESS.VALUE);
      setValue('DISTRICT', ADDRESS.DISTRICT);

      if (inputNumberFef.current) {
        inputNumberFef.current.focus();
      }
    } else {
      const { title, text, type } = data.message;
      swal(title, text, type);
    }
  }

  const getLevelList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'level/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setLevelList(response.data);

      if (initialData.LEVEL_ID) setValue('LEVEL_ID', initialData.LEVEL_ID);
    }
  }, []);

  const getTypeList = useCallback(async () => {
    const { data: response } = await api({
      method: 'get',
      url: 'type/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setTypeList(response.data);

      if (initialData.TYPE_ID) setValue('TYPE_ID', initialData.TYPE_ID);
    }
  }, []);

  useEffect(() => {
    getLevelList();
    getTypeList();
  }, [getLevelList, getTypeList]);

  function updateCity(cityId) {
    setValue('CITY_ID', cityId);
    setCitySelected(cityId);
  }

  function updateState(stateId) {
    setValue('UF_ID', stateId);
    setStateSelected(stateId);
  }

  return (
    <form
      className={classNames('ui form', {
        loading: isLoading,
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h4 className="ui dividing header">Informações básicas</h4>

      <div className="fields">
        <div className="field">
          <label>Grupo</label>
          <i>{initialData.ACCOUNT_GROUP_NAME}</i>
        </div>
        <div className="field">
          <label>Conta</label>
          <i>{initialData.ACCOUNT_NAME}</i>
        </div>
      </div>

      <h4 className="ui dividing header">Configuração de acesso</h4>

      <div className="three fields">
        <div className="field required">
          <label>Nome</label>
          <input
            type="text"
            name="NAME"
            placeholder="Nome completo"
            ref={register({ required: true })}
          />
          {errors.NAME && <span>Nome é obrigatório</span>}
        </div>

        {/* {user.LEVEL_KEY_ !== 'ACCOUNT' && ( */}
        <div className="field">
          <label>Tipo</label>
          <select
            className="ui dropdown"
            name="TYPE_ID"
            ref={register}
            disabled={typeList.length === 0}
            readOnly={typeList.length === 1}
          >
            {typeList.map(type => (
              <option key={type.ID} value={type.ID}>
                {type.DESCRIPTION}
              </option>
            ))}
          </select>
        </div>
        {/* )} */}

        <div className="field required">
          <label>Level</label>
          <select
            className="ui dropdown"
            name="LEVEL_ID"
            ref={register({ required: true })}
            disabled={levelList.length === 0}
            readOnly={levelList.length === 1}
          >
            {levelList.map(level => (
              <option key={level.ID} value={level.ID}>
                {level.DESCRIPTION}
              </option>
            ))}
          </select>
          {errors.LEVEL_ID && <span>Level é obrigatório</span>}
        </div>
      </div>

      <div className="three fields">
        <div className="field required">
          <label>Email</label>
          <input
            type="email"
            name="EMAIL"
            ref={register({ required: true })}
            placeholder="exemplo@exemplo.com"
          />
          {errors.EMAIL && <span>E-mail é obrigatório</span>}
        </div>
        <div className="field">
          <label>Telefone (fixo) </label>
          <MaskedInput
            mask="(99) 9999-9999"
            type="tel"
            name="RESPONSIBLE_PHONE"
            placeholder="(00) 0000-0000"
            defaultValue={initialData.RESPONSIBLE_PHONE}
            register={register}
          />
        </div>
        <div className="field">
          <label>Telefone (celular)</label>
          <MaskedInput
            mask="(99) 9 9999-9999"
            type="tel"
            name="RESPONSIBLE_MOBILE"
            defaultValue={initialData.RESPONSIBLE_MOBILE}
            placeholder="(00) 0 0000-0000"
            register={register}
          />
        </div>
      </div>

      <h4 className="ui dividing header">Localização</h4>

      <div className="field">
        <div className="three fields">
          <div className="field">
            <label>CEP</label>
            <div className="ui action input">
              <InputNumberFormat
                type="tel"
                format="#####-###"
                mask="_"
                defaultValue={initialData.POSTAL_CODE}
                displayType="input"
                onBlur={handleFindCEP}
                onValueChange={v => setValue('POSTAL_CODE', v.value)}
                onKeyPress={e => e.key === 'Enter' && handleFindCEP(e)}
              />
              <input type="hidden" name="POSTAL_CODE" ref={register} />

              <button
                type="button"
                className={classNames('ui button', {
                  loading: isFindingCEP,
                })}
                disabled={isFindingCEP}
                onClick={handleFindCEP}
              >
                Buscar
              </button>
            </div>
          </div>

          <div className="field required">
            <label>Estado</label>
            <Dropdown
              fluid
              search
              selection
              options={stateList}
              value={stateSelected}
              placeholder="Seleciona um estado."
              noResultsMessage="Nenhum estado encontrado."
              onChange={(e, data) => updateState(data.value)}
            />

            <input
              type="hidden"
              name="UF_ID"
              ref={register({ required: true })}
            />

            {errors.UF_ID && <span>Estado é obrigatório</span>}
          </div>

          <div className="field required">
            <label>Cidade</label>
            <Dropdown
              fluid
              search
              selection
              options={filteredCities}
              value={citySelected}
              placeholder="Seleciona uma cidade."
              noResultsMessage="Nenhuma cidade encontrada."
              onChange={(e, data) => updateCity(data.value)}
            />
            <input
              type="hidden"
              name="CITY_ID"
              ref={register({ required: true })}
            />

            {errors.UF_ID && <span>Cidade é obrigatória</span>}
          </div>
        </div>

        <div className="two fields">
          <div className="twelve wide field">
            <label>Endereço</label>
            <input
              type="text"
              name="ADDRESS"
              placeholder="Ex: Rua. Padre João"
              ref={register}
            />
          </div>

          <div className="four wide field">
            <label>Número</label>
            <input
              type="text"
              name="NUMBER"
              placeholder="Ex: 123"
              ref={e => {
                inputNumberFef.current = e;
                register(e);
              }}
            />
          </div>

          <div className="field">
            <label>Complemento</label>
            <input
              type="text"
              name="COMPLEMENT"
              placeholder="Ex: Apartamento 10"
              ref={register}
            />
          </div>

          <div className="field">
            <label>Bairro</label>
            <input
              type="text"
              name="DISTRICT"
              placeholder="Ex: Vila Mariana"
              ref={register}
            />
          </div>
        </div>
      </div>

      <button className="ui fluid button blue" type="submit">
        Confirmar
      </button>
    </form>
  );
}

export default FormRegister;
