import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalStatusAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState(data.DESCRIPTION || '');
  const [position, setPosition] = useState(data.POSITION || 1);
  const [color, setColor] = useState(data.COLOR || '');
  const [action, setAction] = useState(data.ACTION || '');

  const inputDescription = useRef(null);
  const inputColor = useRef(null);

  const colors = [
    {
      key: 'red',
      text: 'Vermelho',
      value: 'red',
      label: { color: 'red', empty: true, circular: true },
    },
    {
      key: 'orange',
      text: 'Laranja',
      value: 'orange',
      label: { color: 'orange', empty: true, circular: true },
    },
    {
      key: 'yellow',
      text: 'Amarelo',
      value: 'yellow',
      label: { color: 'yellow', empty: true, circular: true },
    },
    {
      key: 'blue',
      text: 'Azul',
      value: 'blue',
      label: { color: 'blue', empty: true, circular: true },
    },
    {
      key: 'black',
      text: 'Preto',
      value: 'black',
      label: { color: 'black', empty: true, circular: true },
    },
    {
      key: 'purple',
      text: 'Roxo',
      value: 'purple',
      label: { color: 'purple', empty: true, circular: true },
    },
    {
      key: 'olive',
      text: 'Oliva',
      value: 'olive',
      label: { color: 'olive', empty: true, circular: true },
    },
    {
      key: 'green',
      text: 'Verde',
      value: 'green',
      label: { color: 'green', empty: true, circular: true },
    },
    {
      key: 'teal',
      text: 'Verde-Azulado',
      value: 'teal',
      label: { color: 'teal', empty: true, circular: true },
    },
    {
      key: 'violet',
      text: 'Violeta',
      value: 'violet',
      label: { color: 'violet', empty: true, circular: true },
    },
    {
      key: 'pink',
      text: 'Rosa',
      value: 'pink',
      label: { color: 'pink', empty: true, circular: true },
    },
    {
      key: 'brown',
      text: 'Marrom',
      value: 'brown',
      label: { color: 'brown', empty: true, circular: true },
    },
    {
      key: 'grey',
      text: 'Cinza',
      value: 'grey',
      label: { color: 'grey', empty: true, circular: true },
    },
  ];

  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.focus();
    }
  }, [inputDescription]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/stage/add' : '/stage/update',
      params: {
        stage_id: data.ID || null,
        stage: JSON.stringify({
          DESCRIPTION: description,
          POSITION: position,
          COLOR: color,
          ACTION: action,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar o item: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="tiny" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo status' : 'Editar status'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Descrição</label>
            <input
              type="text"
              ref={inputDescription}
              value={description}
              onChange={e => setDescription(e.target.value)}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Posição</label>
            <input
              type="number"
              value={position}
              onChange={e => setPosition(e.target.value)}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Cor</label>
            <Dropdown
              fluid
              selection
              value={color}
              options={colors}
              onChange={(e, d) => setColor(d.value)}
              placeholder="Seleciona uma cor"
              ref={inputColor}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Dropdown
          button
          className="icon"
          floating
          labeled
          icon="cogs"
          placeholder="Ação do status"
          options={[
            { key: 'N', text: 'Nenhuma' },
            { key: 'I', value: 'I', text: 'Projeto em andamento' },
            { key: 'F', value: 'F', text: 'Projeto finalizado' },
            { key: 'C', value: 'C', text: 'Projeto cancelado' },
          ]}
          value={action}
          onChange={(e, d) => setAction(d.value)}
        />

        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
