import React, { useEffect, useState, useContext } from 'react';
import { withRouter, Switch } from 'react-router-dom';

import Header from '~/components/Header';
import PageContainer from '~/components/PageContainer';
import Sidebar from '~/components/Sidebar';
// Apllication Authentication Context
import AuthContext from '~/contexts/AuthContext';
// Application pages
import PageAccessControlAccountAdd from '~/pages/AccessControl/Account/Add';
import PageAccessControlAccountFinanceAdd from '~/pages/AccessControl/Account/Finance/Add';
import PageAccessControlAccountSearch from '~/pages/AccessControl/Account/Search';
import PageAccessControlGroupAdd from '~/pages/AccessControl/Group/Add';
import PageGroupBrandSearch from '~/pages/AccessControl/Group/Brand/Search';
import PageAccessControlGroupSearch from '~/pages/AccessControl/Group/Search';
import PageAccessControlUserAdd from '~/pages/AccessControl/User/Add';
import PageAccessControlUserSearch from '~/pages/AccessControl/User/Search';
import PageBrandGroupSearch from '~/pages/Brand/Group/Search';
import PageBrandSearch from '~/pages/Brand/Search';
import PageClientAdd from '~/pages/Client/Add';
import PageClientBillAdd from '~/pages/Client/Bill/Add';
import PageClientBillSearch from '~/pages/Client/Bill/Search';
import PageClientSearch from '~/pages/Client/Search';
import PageProjectConfig from '~/pages/Configuration';
import PageEnergyProviderSearch from '~/pages/Configuration/EnergyProvider/Search';
import PageGroupConfig from '~/pages/Configuration/Group';
import PageProjectHomologSearch from '~/pages/Configuration/HomologValues/Search';
import PageKitReferenceSearch from '~/pages/Configuration/KitReference/Search';
import PageRoyaltiesConfigSearch from '~/pages/Configuration/Royalties/Search';
import PageStatusSearch from '~/pages/Configuration/Status/Search';
import PageTranformerSearch from '~/pages/Configuration/Transformer/Search';
import PageDashboard from '~/pages/Dashboard';
import PageErro from '~/pages/Erro';
import PageForgot from '~/pages/Forgot';
import PageIndex from '~/pages/Index';
import PageInverterSearch from '~/pages/Inverter/Search';
import PageItemSearch from '~/pages/Item/Search';
import PageLogin from '~/pages/Login';
import PageMaterialHistory from '~/pages/Material/History';
import PageMaterialSearch from '~/pages/Material/Search';
import PageMe from '~/pages/Me';
import PageModuleSearch from '~/pages/Module/Search';
import PageProjectAdd from '~/pages/Project/Add';
import PageProjectSearch from '~/pages/Project/Search';
import PageRegister from '~/pages/Register';
import PageActivate from '~/pages/Register/Activate';
import PageReset from '~/pages/Reset';
import PageRoyaltiesSearch from '~/pages/Royalties/Search';
import PageSimulation from '~/pages/Simulation';
import PageSimulationSearch from '~/pages/Simulation/Search';

import CustomRoute from './CustomRoute';
import { Container, ApplicationLoading } from './styles';

function Routes({ history }) {
  const { user, loading, authenticated } = useContext(AuthContext);

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const { location } = history;

  const { pathname } = location;

  useEffect(() => {
    history.listen(handleLocationChange);
    window.scrollTo(0, 0);
  }, [history, pathname]);

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart, false);
      document.removeEventListener('touchmove', handleTouchMove, false);
    };
  }, []);

  let xDown = null;
  let yDown = null;

  function handleTouchStart(evt) {
    const firstTouch = evt.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /* most significant */
      if (xDiff > 0) {
        /* left swipe */
        console.log('swiped left');
        setSidebarIsOpen(false);
      } else {
        /* right swipe */
        console.log('swiped right');
        setSidebarIsOpen(true);
      }
    } else if (yDiff > 0) {
      /* up swipe */
      console.log('swiped up');
    } else {
      /* down swipe */
      console.log('swiped down');
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  function handleLocationChange() {
    setSidebarIsOpen(false);
  }

  function toggleSidebar() {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  function getRoutesByLevel() {
    if (user && user.ACCOUNT_FINANCIAL_STATUS === 'B') {
      return (
        <CustomRoute isPrivate exact path="/">
          <PageIndex />
        </CustomRoute>
      );
    }

    if (user && user.LEVEL_KEY_ === 'SIMULATE') {
      return (
        <>
          <CustomRoute isPrivate exact path="/">
            <PageIndex />
          </CustomRoute>

          <CustomRoute isPrivate exact path="/simulation">
            <PageSimulation />
          </CustomRoute>
        </>
      );
    }

    return (
      <>
        <CustomRoute isPrivate exact path="/clients">
          <PageClientSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/materials">
          <PageMaterialSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/material/:materialId/history">
          <PageMaterialHistory />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/inverters">
          <PageInverterSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/modules">
          <PageModuleSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/pricing">
          <PageItemSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/brands">
          <PageBrandSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/client/:clientId?">
          <PageClientAdd />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/client/:clientId/bills">
          <PageClientBillSearch />
        </CustomRoute>

        <CustomRoute isPrivate path="/client/:clientId/bill/:billId?">
          <PageClientBillAdd />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/simulation/:simulationId?">
          <PageSimulation />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/simulations">
          <PageSimulationSearch />
        </CustomRoute>

        <CustomRoute isPrivate exact path="/projects">
          <PageProjectSearch />
        </CustomRoute>

        <CustomRoute isPrivate path="/project/:projectId?">
          <PageProjectAdd />
        </CustomRoute>

        {['ROOT', 'GROUP'].includes(user.TYPE_KEY_) && (
          <>
            <CustomRoute isPrivate exact path="/configurations">
              <PageProjectConfig />
            </CustomRoute>

            <CustomRoute
              isPrivate
              exact
              path="/configurations/group/:groupId/royalties"
            >
              <PageRoyaltiesConfigSearch />
            </CustomRoute>

            <CustomRoute
              isPrivate
              exact
              path="/configurations/group/:groupId/homolog-values"
            >
              <PageProjectHomologSearch />
            </CustomRoute>
          </>
        )}

        {user.TYPE_KEY_ === 'ROOT' && (
          <>
            <CustomRoute isPrivate exact path="/brand/:brandId/groups">
              <PageBrandGroupSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/brands/group/:groupId">
              <PageGroupBrandSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/configurations/group/:groupId">
              <PageGroupConfig />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/configurations/status">
              <PageStatusSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/configurations/providers">
              <PageEnergyProviderSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/configurations/transformers">
              <PageTranformerSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/configurations/kit-references">
              <PageKitReferenceSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/royalties">
              <PageRoyaltiesSearch />
            </CustomRoute>
          </>
        )}

        {user.LEVEL_KEY_ === 'ADMINISTRATOR' && (
          <>
            <CustomRoute isPrivate exact path="/accounts">
              <PageAccessControlAccountSearch />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/account/:accountId?">
              <PageAccessControlAccountAdd />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/account/:accountId/finance">
              <PageAccessControlAccountFinanceAdd />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/account/:accountId/users">
              <PageAccessControlUserSearch />
            </CustomRoute>

            <CustomRoute isPrivate path="/account/:accountId/user/:userId?">
              <PageAccessControlUserAdd />
            </CustomRoute>

            <CustomRoute isPrivate path="/groups">
              <PageAccessControlGroupSearch />
            </CustomRoute>

            <CustomRoute isPrivate path="/group/:groupId?">
              <PageAccessControlGroupAdd />
            </CustomRoute>
          </>
        )}
      </>
    );
  }

  if (loading) {
    return (
      <ApplicationLoading>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/logo.png`}
            alt="WebVolt"
          />
          <i className="mdi mdi-loading mdi-spin" />
        </div>
      </ApplicationLoading>
    );
  }

  return (
    <>
      {authenticated && <Sidebar isOpen={sidebarIsOpen} />}
      <Container sidebarIsOpen={sidebarIsOpen}>
        <Header sidebarIsOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />

        <PageContainer>
          <Switch>
            <CustomRoute isPrivate exact path="/">
              <PageDashboard />
            </CustomRoute>
            <CustomRoute exact path="/login">
              <PageLogin />
            </CustomRoute>
            <CustomRoute exact path="/forgot">
              <PageForgot />
            </CustomRoute>
            <CustomRoute exact path="/activate">
              <PageActivate />
            </CustomRoute>
            <CustomRoute exact path="/reset">
              <PageReset />
            </CustomRoute>
            <CustomRoute exact path="/register">
              <PageRegister />
            </CustomRoute>

            <CustomRoute isPrivate path="/me">
              <PageMe />
            </CustomRoute>

            {getRoutesByLevel()}

            <CustomRoute path="*">
              <PageErro />
            </CustomRoute>
          </Switch>
        </PageContainer>
      </Container>
    </>
  );
}

export default withRouter(Routes);
