import React, { useState, useEffect, useCallback, useContext } from 'react';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import { Pagination, Icon, Grid, Button, Label } from 'semantic-ui-react';

import Table from '~/components/Table';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function RoyaltiesSearch() {
  const { openModal } = useContext(ModalContext);

  const [royaltiess, setRoyalties] = useState([]);
  const [summary, setSummary] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'DESC' }],
  });

  const searchRoyalties = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/project/royalties/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setRoyalties(responseSearch.data);
      setSummary(responseSearch.summary);
    } else {
      setRoyalties([]);
    }

    setPagination(responseSearch.pagination);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    searchRoyalties(filter, pagination);
  }, [searchRoyalties]);

  function handlePaginationChange(e, { activePage }) {
    searchRoyalties(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function handleFilterChange(key, value) {
    if (!key && !value) {
      setFilter({});

      searchRoyalties({}, pagination);
      return;
    }

    if (key !== 'RECEIPT') {
      const oldFilter = { ...filter };

      if (Object.keys(oldFilter).includes('RECEIPT')) {
        delete oldFilter.RECEIPT;
      }

      setFilter({
        ...oldFilter,
        [key]: value,
      });
    } else {
      const newFilter = {
        [key]: value,
      };

      setFilter(newFilter);

      searchRoyalties(newFilter, pagination);
    }
  }

  function isFilteredBy(key, value) {
    return Object.keys(filter).includes(key) && filter.RECEIPT === value;
  }

  function renderRoyaltiesWithTable() {
    return (
      <Table
        cols={[
          { field: 'PROJECT_CODE', description: 'Código do projeto' },
          { field: 'ACCOUNT_NAME', description: 'Conta / Grupo' },
          { field: 'PROJECT_NAME', description: 'Descrição do projeto' },
          { field: 'PROJECT_TOTAL_ITEM', description: 'Valor do projeto' },
          {
            field: 'CREATED_AT_FORMAT',
            description: 'Data e hora (finalização do projeto)',
          },
          { field: 'ROYALTIES_VALUE', description: 'Valor dos  Royalties' },
          {
            field: 'DATEHOUR_RECEIPT_FORMAT',
            description: 'Data e hora (recebimento)',
          },
          { field: 'RECEIPT', description: 'Status do recebimento' },
        ]}
        mapping={royalties => ({
          PROJECT_CODE: royalties.PROJECT_CODE,
          ACCOUNT_NAME: `${royalties.ACCOUNT_NAME} (${royalties.ACCOUNT_GROUP_NAME})`,
          PROJECT_NAME: royalties.PROJECT_NAME,
          PROJECT_TOTAL_ITEM: `R$ ${numberFormat(
            royalties.PROJECT_TOTAL_ITEM,
            2,
            ',',
            '.'
          )}`,
          CREATED_AT_FORMAT: royalties.CREATED_AT_FORMAT,
          ROYALTIES_VALUE: `R$ ${numberFormat(
            royalties.ROYALTIES_VALUE,
            2,
            ',',
            '.'
          )} (${royalties.ROYALTIES_PERCENT}%)`,
          DATEHOUR_RECEIPT_FORMAT: royalties.DATEHOUR_RECEIPT_FORMAT,
          RECEIPT: () => (
            <Label
              size="small"
              color={royalties.RECEIPT === 'T' ? 'green' : 'red'}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                openModal('royaltyStatusSelect', {
                  data: {
                    ID: royalties.ID,
                    RECEIPT: royalties.RECEIPT,
                  },
                  onConfirm: () => searchRoyalties(filter, pagination),
                })
              }
            >
              {royalties.RECEIPT === 'T' ? 'RECEBIDO' : 'A RECEBER'}
            </Label>
          ),
        })}
        data={royaltiess}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchRoyalties(filter, newPagination);
        }}
      />
    );
  }

  function renderRoyaltiesContainer() {
    return (
      <>
        <div className="ui form">
          <div className="ui five fields">
            <div className="field">
              <label>Conta</label>
              <input
                type="text"
                placeholder="Conta"
                value={filter.ACCOUNT_NAME || ''}
                onChange={e =>
                  handleFilterChange('ACCOUNT_NAME', e.target.value)
                }
              />
            </div>

            <div className="field">
              <label>Início do projeto - data inicial</label>
              <input
                type="date"
                value={filter.DATE_TIME_START || ''}
                onChange={e =>
                  handleFilterChange('DATE_TIME_START', e.target.value)
                }
              />
            </div>

            <div className="field">
              <label>Início do projeto - data final</label>
              <input
                type="date"
                value={filter.DATE_TIME_FINAL || ''}
                min={filter.DATE_TIME_START || undefined}
                placeholder="Data e hora final"
                onChange={e =>
                  handleFilterChange('DATE_TIME_FINAL', e.target.value)
                }
              />
            </div>

            <div className="field">
              <label>Finalização do projeto - data inicial</label>
              <input
                type="date"
                value={filter.DATE_TIME_START_FINALIZED || ''}
                placeholder="Data e hora inicial"
                onChange={e =>
                  handleFilterChange(
                    'DATE_TIME_START_FINALIZED',
                    e.target.value
                  )
                }
              />
            </div>

            <div className="field">
              <label>Finalização do projeto - data final</label>
              <input
                type="date"
                value={filter.DATE_TIME_FINAL_FINALIZED || ''}
                min={filter.DATE_TIME_START_FINALIZED || undefined}
                placeholder="Data e hora final"
                onChange={e =>
                  handleFilterChange(
                    'DATE_TIME_FINAL_FINALIZED',
                    e.target.value
                  )
                }
              />
            </div>
          </div>
          <Grid.Row>
            <Button
              icon
              title="Filtrar"
              className="ui fluid"
              onClick={() => searchRoyalties(filter, pagination)}
            >
              <Icon name="check" /> Filtrar
            </Button>
          </Grid.Row>
        </div>

        {renderRoyaltiesWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title"> Gerenciar royalties </span>
        </div>

        <Grid centered>
          <Grid.Column mobile={16} table={16} computer={16}>
            <div className="ui centered tiny statistics m-b m-t-md">
              <div
                className="ui statistic"
                role="button"
                tabIndex="0"
                style={{ cursor: 'pointer' }}
                onClick={() => handleFilterChange()}
                onKeyPress={() => handleFilterChange()}
              >
                <div className="value">
                  {numberFormat(summary.QTTY, 0, ',', '.')}
                </div>
                <div className="label">Projetos finalizados</div>
              </div>

              <div
                className="ui statistic"
                role="button"
                tabIndex="0"
                style={{ cursor: 'pointer' }}
                onClick={() => handleFilterChange()}
                onKeyPress={() => handleFilterChange()}
              >
                <div className="value">
                  R$ {numberFormat(summary.SUM_TOTAL_ROYALTIES, 2, ',', '.')}
                </div>
                <div className="label">Royalties</div>
              </div>

              <div
                className={classNames('ui statistic', {
                  blue: isFilteredBy('RECEIPT', 'F'),
                })}
                role="button"
                tabIndex="0"
                style={{
                  cursor: isFilteredBy('RECEIPT', 'F') ? 'default' : 'pointer',
                }}
                onClick={() => {
                  if (!isFilteredBy('RECEIPT', 'F')) {
                    handleFilterChange('RECEIPT', 'F');
                  }
                }}
                onKeyPress={() => {
                  if (!isFilteredBy('RECEIPT', 'F')) {
                    handleFilterChange('RECEIPT', 'F');
                  }
                }}
              >
                <div className="value">
                  R${' '}
                  {numberFormat(summary.ROYALTIES_WITHOUT_RECEIPT, 2, ',', '.')}
                </div>
                <div className="label">A receber</div>
              </div>

              <div
                className={classNames('ui statistic', {
                  blue: isFilteredBy('RECEIPT', 'T'),
                })}
                role="button"
                tabIndex="0"
                style={{
                  cursor: isFilteredBy('RECEIPT', 'T') ? 'default' : 'pointer',
                }}
                onClick={() => {
                  if (!isFilteredBy('RECEIPT', 'T')) {
                    handleFilterChange('RECEIPT', 'T');
                  }
                }}
                onKeyPress={() => {
                  if (!isFilteredBy('RECEIPT', 'T')) {
                    handleFilterChange('RECEIPT', 'T');
                  }
                }}
              >
                <div className="value">
                  R$ {numberFormat(summary.ROYALTIES_RECEIPT, 2, ',', '.')}
                </div>
                <div className="label">Recebido</div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <div className="ui attached bottom segment">
          {renderRoyaltiesContainer()}
        </div>
      </div>
    </div>
  );
}

export default RoyaltiesSearch;
