import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import MaterialCard from '~/components/Card/Material';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function ProjectMaterialSearch({ projectId, updateStore }) {
  const { openModal } = useContext(ModalContext);
  const history = useHistory();

  const [showTable, setShowTable] = useState(!isMobile);

  const [materials, setMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [summary, setSummary] = useState({});

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({
    PROJECT_ID: projectId,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchMaterials = useCallback(
    useRef(
      throttle(async (filters, pag) => {
        setIsLoading(true);

        const { data: response } = await api({
          method: 'post',
          url: 'project/material/search',
          params: {
            project_id: projectId,
            filter: filters,
            pagination: pag,
          },
        });

        if (response.status === 0) {
          setMaterials(response.data);
          setSummary(response.summary);
          setPagination(response.pagination);
        } else {
          toast.error(response.message.text);
          setMaterials([]);
        }

        setIsLoading(false);
      }, 1000)
    ).current,
    []
  );

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchMaterials(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchMaterials(filter, pagination);
  }, [searchMaterials]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPagination({
      ...pagination,
      keyword: e.target.value,
    });

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    const newPagination = {
      ...pagination,
      page: activePage,
    };

    searchMaterials(filter, newPagination);
  }

  function handleMaterialDelete(materialId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um material do projeto permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'project/material/delete',
          params: {
            project_material_id: JSON.stringify([materialId]),
          },
        });

        if (responseDelete.status === 0) {
          updateStore();

          searchMaterials(filter, pagination);
          toast.success('Material excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir varios materiais do projeto permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const materialsToDelete = materials
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!materialsToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um material para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'project/material/delete',
          params: {
            project_material_id: JSON.stringify(materialsToDelete),
          },
        });

        if (responseDelete.status === 0) {
          updateStore();
          toast.success('Materiais excluídos com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () => materials.length && materials.every(b => b.uiChecked === true),
    [materials]
  );

  function handleSelectAll(e, { checked }) {
    setMaterials(oldMaterials =>
      oldMaterials.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(materialId) {
    setMaterials(oldMaterials =>
      oldMaterials.map(item => {
        return item.ID === materialId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  async function handleUpdateMaterial(providerId, data) {
    const { data: responseUpdate } = await api({
      method: 'post',
      url: 'project/material/update',
      params: {
        provider_id: providerId,
        project_material: JSON.stringify(data),
      },
    });

    if (responseUpdate.status === 0) {
      toast.success(responseUpdate.message.text);
      searchMaterials(filter, { ...pagination, keyword });
    } else {
      toast.error(responseUpdate.message.text);
    }
  }

  function renderMaterialsWithCard() {
    return (
      <Grid centered>
        {materials.length > 0 &&
          !isLoading &&
          materials.map(material => (
            <Grid.Column key={material.ID} mobile={16} tablet={8} computer={4}>
              <MaterialCard
                type="project"
                handleDelete={() => handleMaterialDelete(material.ID)}
                handleEdit={() => {
                  openModal('projectMaterialSelect', {
                    data: material,
                    onConfirm: data =>
                      handleUpdateMaterial(material.PROVIDER_ID, [
                        {
                          ID: material.ID,
                          ...data,
                        },
                      ]),
                  });
                }}
                material={material}
              />
            </Grid.Column>
          ))}

        {!isLoading && materials.length === 0 && (
          <Grid.Column>
            <Message content="Nenhum material adicionado!" />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando materiais..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderMaterialsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">
              <Checkbox
                title="Selecionar"
                onChange={handleSelectAll}
                checked={allSelected}
              />
            </th>
            <th className="center aligned">Descrição</th>
            <th className="center aligned">Fornecedor</th>
            <th className="center aligned">Quantidade</th>
            <th className="center aligned">Valor unitário</th>
            <th className="center aligned">Total</th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {materials.length > 0 &&
            !isLoading &&
            materials.map(material => {
              return (
                <tr
                  key={material.ID}
                  className={classNames({
                    active: material.uiChecked,
                  })}
                >
                  <td className="center aligned">
                    <div className="ui checkbox">
                      <Checkbox
                        checked={material.uiChecked}
                        onChange={() => handleSelect(material.ID)}
                      />
                    </div>
                  </td>
                  <td className="center aligned">
                    {material.DESCRIPTION ||
                      material.MATERIAL_DESCRIPTION ||
                      '-'}
                  </td>
                  <td className="center aligned">
                    {material.PROVIDER_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    {numberFormat(material.QUANTITY, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    R$ {numberFormat(material.UNIT_VALUE, 2, ',', '.')}
                  </td>
                  <td className="center aligned">
                    R$ {numberFormat(material.TOTAL, 2, ',', '.')}
                  </td>

                  <td className="center aligned">
                    <Button
                      size="mini"
                      icon
                      color="blue"
                      title="Editar material"
                      onClick={() => {
                        openModal('projectMaterialSelect', {
                          data: material,
                          onConfirm: data =>
                            handleUpdateMaterial(material.PROVIDER_ID, [
                              {
                                ID: material.ID,
                                ...data,
                              },
                            ]),
                        });
                      }}
                    >
                      <Icon name="pencil" />
                    </Button>

                    <Button
                      size="mini"
                      icon
                      color="red"
                      title="Remover material"
                      onClick={() => handleMaterialDelete(material.ID)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}

          {!isLoading && materials.length === 0 && (
            <tr>
              <td colSpan="10">
                <Message content="Nenhum material encontrado!" />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="10">
                <Message color="blue" content="Buscando materiais..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <h4 className="ui horizontal divider">Produtos</h4>

      <Grid centered>
        <Grid.Column mobile={16} table={16} computer={16}>
          <div className="ui form centered">
            <div className="fields m-b-n text-center">
              <div className="field m">
                <label>Quantidade</label>
                <i className="m-t-n-xs">{numberFormat(summary.QTTY || 0, 0)}</i>
              </div>
              <div className="field m">
                <label>Valor total</label>
                <i className="m-t-n-xs">
                  R$ {numberFormat(summary.TOTAL || 0, 2, ',', '.')}
                </i>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <Grid centered>
        <Grid.Column mobile={16} table={8} computer={8}>
          <div className="ui action fluid input">
            <input
              type="text"
              placeholder="Pesquisar por"
              value={keyword}
              onChange={handleKeywordChange}
            />
            <button
              className={classNames('ui button label', {
                loading: isLoading,
              })}
              type="button"
              disabled={isLoading}
              onClick={() =>
                searchMaterials(filter, { ...pagination, keyword })
              }
            >
              Pesquisar
            </button>
          </div>
        </Grid.Column>

        <Grid.Column
          mobile={16}
          table={8}
          computer={8}
          className="right aligned"
        >
          {!isMobile && (
            <button
              type="button"
              className="ui button icon"
              title="Alterar visualização"
              onClick={() => setShowTable(!showTable)}
            >
              <i
                className={classNames('mdi icon', {
                  'mdi-table': showTable,
                  'mdi-cards': !showTable,
                })}
              />
            </button>
          )}

          <Dropdown
            icon="cogs"
            floating
            title="Ações rápidas"
            button
            className="blue icon"
            disabled={!materials.some(b => b.uiChecked === true)}
          >
            <Dropdown.Menu>
              <Dropdown.Header content="Ações rápidas" />
              <Dropdown.Divider />

              <Dropdown.Item
                icon="check"
                text="Selecionar fornecedor"
                onClick={() => {
                  openModal('provider', {
                    onSelect: provider => {
                      const selected = materials.filter(
                        m => m.uiChecked === true
                      );

                      handleUpdateMaterial(provider.ID, selected);
                    },
                  });
                }}
              />

              <Dropdown.Item
                color="red"
                icon="trash"
                text="Excluir selecionados"
                onClick={handleDeleteSelected}
              />
            </Dropdown.Menu>
          </Dropdown>

          <Link to="select" className="ui button green icon">
            <i className="mdi mdi-plus icon" /> Adicionar materiais ao projeto
          </Link>
        </Grid.Column>
      </Grid>

      {showTable ? renderMaterialsWithTable() : renderMaterialsWithCard()}

      <Grid centered>
        <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
          <Pagination
            disabled={materials.loading}
            activePage={pagination.page}
            onPageChange={handlePaginationChange}
            totalPages={pagination.total_page}
            ellipsisItem={false}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{
              content: <Icon name="angle right" />,
              icon: true,
            }}
          />
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <button
            type="button"
            className={classNames('ui button blue', {
              laoding: isLoading,
              'left floated': !isMobile,
              fluid: isMobile,
            })}
            disabled={isLoading}
            onClick={() => history.push('../simulation')}
          >
            Voltar
          </button>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={8} computer={8}>
          <button
            type="button"
            className={classNames('ui button green', {
              laoding: isLoading,
              'right floated': !isMobile,
              fluid: isMobile,
            })}
            disabled={isLoading}
            onClick={() => history.push('../item')}
          >
            Avançar
          </button>
        </Grid.Column>
      </Grid>
    </>
  );
}
