import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import MenuIcon from 'mdi-react/MenuIcon';
import MenuCloseIcon from 'mdi-react/WindowCloseIcon';

import AuthContext from '~/contexts/AuthContext';

import { Container, Page, Logo, Site } from './styles';
import UserMenu from './UserMenu';

export default function Header({ toggleSidebar, sidebarIsOpen }) {
  const { authenticated, user } = useContext(AuthContext);

  return (
    <Container sidebarIsOpen={sidebarIsOpen}>
      {!!authenticated && user.ACCOUNT_FINANCIAL_STATUS !== 'B' && (
        <Page sidebarIsOpen={sidebarIsOpen}>
          <button type="button" onClick={toggleSidebar}>
            {!sidebarIsOpen ? (
              <MenuIcon size={29} color="#FFF" />
            ) : (
              <MenuCloseIcon size={29} color="#FFF" />
            )}
          </button>
        </Page>
      )}

      <Site>
        <Link to="/">
          <Logo
            src={`${process.env.PUBLIC_URL}/assets/logo.png`}
            alt="Webvolt"
          />
        </Link>
      </Site>

      {!!authenticated && <UserMenu />}
    </Container>
  );
}
