import React, { useState, useEffect, useMemo, useCallback } from 'react';
import InputNumberFormat from 'react-number-format';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import {
  Modal,
  Button,
  Dropdown,
  Pagination,
  Icon,
  Grid,
} from 'semantic-ui-react';

import Table from '~/components/Table';
import api from '~/services/api';

export default function ModalProjectItemSelect({
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const [item] = useState(data || {});

  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const isHomolog = useMemo(() => item.ITEM_KEY_ === 'homologation', [item]);
  const isLabor = useMemo(() => item.ITEM_KEY_ === 'labor', [item]);

  const [quantity, setQuantity] = useState(data.QUANTITY || 1);
  const [unitValue, setUnitValue] = useState(
    data.PRICE || data.UNIT_VALUE || data.TOTAL || 0
  );

  const [inputUnitValue, setInputUnitValue] = useState(null);
  const [inputQuantity, setInputQuantity] = useState(null);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchValues = useCallback(async pag => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: '/homologation/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify({}),
      },
    });

    if (responseSearch.status === 0) {
      setValues(responseSearch.data);
    } else {
      setValues([]);
    }

    setPagination(responseSearch.pagination);

    setLoading(false);
  }, []);

  useEffect(() => {
    if (isLabor || isHomolog) {
      if (inputUnitValue) {
        inputUnitValue.focus();
      }
    } else if (inputQuantity) {
      inputQuantity.focus();
    }
  }, [inputQuantity, inputUnitValue]);

  function handleConfirm() {
    onConfirm({
      UNIT_VALUE: unitValue,
      QUANTITY: quantity,
    });

    handleClose();
  }

  function renderHomologValuesTable() {
    return (
      <div className="m-t-md">
        <h4 className="ui dividing header">Tabela de preços</h4>
        <Table
          cols={[
            { field: 'MIN_KWP', description: 'KWP mínimo' },
            { field: 'MAX_KWP', description: 'KWP máximo' },
            { field: 'PRICE', description: 'Preço' },
          ]}
          mapping={value => ({
            MIN_KWP: `${numberFormat(value.MIN_KWP, 2, ',', '.')} kWp`,
            MAX_KWP: `${numberFormat(value.MAX_KWP, 2, ',', '.')} kWp`,
            PRICE: `R$ ${numberFormat(value.PRICE, 2, ',', '.')}`,
          })}
          data={values}
          loading={loading}
          onUpdateOrderBy={orderby => {
            const newPagination = {
              ...pagination,
              orderby,
            };

            setPagination(newPagination);
            searchValues(newPagination);
          }}
        />

        <Grid centered>
          <Grid.Column textAlign="center">
            <Pagination
              disabled={loading}
              activePage={pagination.page}
              onPageChange={(e, { activePage }) => {
                searchValues({
                  ...pagination,
                  page: activePage,
                });
              }}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  return (
    <Modal size={isHomolog ? 'tiny' : 'mini'} open onClose={onClose}>
      <Modal.Header>
        {isHomolog
          ? 'Informe o preço da homologação'
          : 'Informe a quantidade e valor unitário'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form m-b">
          <div className="fields">
            <div className="field">
              <label>
                Descrição do item {isHomolog && '(Potência do projeto)'}
              </label>
              <i className="m-t-n-xs">
                {item.DESCRIPTION || item.ITEM_DESCRIPTION || 'Não definida'}{' '}
                {isHomolog &&
                  `(${numberFormat(item.PROJECT_KWP || 0, 2, ',', '.')} KWP)`}
              </i>
            </div>
          </div>
        </div>

        <div className="ui form">
          {!isHomolog && (
            <div className="field required">
              <label>Quantidade</label>
              <InputNumberFormat
                type="tel"
                value={quantity || null}
                className="text-right"
                displayType="input"
                disabled={isLabor}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                onValueChange={v => setQuantity(v.floatValue)}
                onKeyPress={e =>
                  e.key === 'Enter' && inputUnitValue && inputUnitValue.focus()
                }
                getInputRef={el => setInputQuantity(el)}
              />
            </div>
          )}

          <div className="field required">
            <label>{isHomolog ? 'Preço (R$)' : 'Valor unitário (R$)'}</label>
            <InputNumberFormat
              type="tel"
              value={unitValue || null}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setUnitValue(v.floatValue)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputUnitValue(el)}
            />

            {isHomolog && (
              <span> Preço sugerido de acordo com o KwP do projeto</span>
            )}
          </div>
        </div>

        {values.length > 0 && renderHomologValuesTable()}
      </Modal.Content>

      <Modal.Actions>
        {isHomolog && (
          <Dropdown
            icon="cog"
            title="Ações"
            className="icon button"
            direction="left"
            loading={loading}
          >
            <Dropdown.Menu>
              <Dropdown.Header content="Ações" />
              <Dropdown.Divider />

              <Dropdown.Item
                icon="dollar sign"
                text="Preço padrão"
                title="Resetar valor para preço padrão"
                className={classNames({
                  disabled: unitValue === item.DEFAULT_PRICE,
                })}
                onClick={() => setUnitValue(item.DEFAULT_PRICE)}
              />

              <Dropdown.Item
                icon="table"
                text="Tabela de preços"
                title="Ver a tabela de preços"
                onClick={() => searchValues(pagination)}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Button onClick={handleClose} loading={loading}>
          Cancelar
        </Button>

        <Button positive onClick={handleConfirm} loading={loading}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
