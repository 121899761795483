import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import useForm from 'react-hook-form';

import classNames from 'classnames';
import { isEqual } from 'lodash';
import MenuCloseIcon from 'mdi-react/WindowCloseIcon';
import { Sidebar, Menu, Button, Icon, Dropdown } from 'semantic-ui-react';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

const clean = obj => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v));
};

const DashboardDrawerFilter = ({
  open = false,
  initialData = {},
  loading,
  onToggle,
  onSubmit,
  onLoadFilters,
}) => {
  const { user } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(() => open);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const { register, handleSubmit, getValues, watch, setValue } = useForm({
    defaultValues: clean(initialData),
  });

  const watchDateTime = watch(['DATE_TIME_START', 'DATE_TIME_FINAL']);

  const [groupList, setGroupList] = useState([]);
  const [groupSelected, setGroupSelected] = useState(
    initialData.ACCOUNT_GROUP_ID || ''
  );

  const [accountList, setAccounts] = useState([]);
  const [accountSelected, setAccountSelected] = useState(
    initialData.ACCOUNT_ID
      ? initialData.ACCOUNT_ID.split(',').map(x => Number(x))
      : []
  );

  const [stateList, setStateList] = useState([]);
  const [stateSelected, setStateSelected] = useState(initialData.UF_ID || '');

  const [cityList, setCityList] = useState([]);
  const [citySelected, setCitySelected] = useState(initialData.CITY_ID || '');

  async function getState() {
    if (accountSelected.length) {
      setIsLoadingFilters(true);

      const { data: uf } = await api({
        method: 'post',
        url: 'uf/list',
        params: { filter: JSON.stringify({ ACCOUNT_ID: accountSelected }) },
      });

      if (uf.status === 0) {
        return uf.data;
      }
    }

    setIsLoadingFilters(false);

    return [];
  }

  async function getCity() {
    if (stateSelected && accountSelected.length) {
      setIsLoadingFilters(true);

      const { data: city } = await api({
        method: 'post',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({
            UF_ID: stateSelected,
            ACCOUNT_ID: accountSelected,
          }),
        },
      });

      if (city.status === 0) {
        return city.data;
      }
    }

    setIsLoadingFilters(false);

    return [];
  }

  useEffect(() => {
    (async () => {
      const states = await getState();

      setStateList(states);
    })();
  }, [accountSelected]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [stateSelected, accountSelected]);

  const getGroups = useCallback(async () => {
    setIsLoadingFilters(true);

    const { data: response } = await api({
      method: 'get',
      url: 'account/group/select',
      params: {
        filters: JSON.stringify({}),
      },
    });

    if (response.status === 0) {
      setGroupList(response.data);
    } else {
      setGroupList([]);
    }

    setIsLoadingFilters(false);
  }, []);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const getAccounts = useCallback(async () => {
    if (user.TYPE_KEY_ !== 'GROUP' && !groupSelected) return;

    setIsLoadingFilters(true);

    const { data: response } = await api({
      method: 'get',
      url: 'account/select',
      params: {
        filter: {
          account_group_id: groupSelected || user.ACCOUNT_GROUP_ID,
        },
      },
    });

    if (response.status === 0) {
      setAccounts(response.data);
    } else {
      setAccounts([]);
    }

    setIsLoadingFilters(false);
  }, [groupSelected]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const filterData = useMemo(() => {
    const filters = {};

    if (watchDateTime.DATE_TIME_START) {
      Object.assign(filters, {
        DATE_TIME_START: watchDateTime.DATE_TIME_START,
      });
    }

    if (watchDateTime.DATE_TIME_FINAL) {
      Object.assign(filters, {
        DATE_TIME_FINAL: watchDateTime.DATE_TIME_FINAL,
      });
    }

    if (groupSelected && groupList.length > 0) {
      const group = groupList.find(g => g.ID === Number(groupSelected));
      if (group) {
        Object.assign(filters, { GROUP_NAME: group.NAME });
      }
    }

    if (accountSelected.length > 0 && accountList.length > 0) {
      const accounts = accountList.filter(a => accountSelected.includes(a.ID));

      if (accounts) {
        Object.assign(filters, { ACCOUNT_NAMES: accounts.map(ac => ac.NAME) });
      }
    }

    if (stateSelected && stateList.length > 0) {
      const state = stateList.find(s => s.ID === Number(stateSelected));

      if (state) {
        Object.assign(filters, { STATE_NAME: state.NAME });
      }
    }

    if (citySelected && cityList.length > 0) {
      const city = cityList.find(s => s.ID === Number(citySelected));

      if (city) {
        Object.assign(filters, { CITY_NAME: city.NAME });
      }
    }

    return filters;
  }, [
    initialData,
    watchDateTime.DATE_TIME_START,
    watchDateTime.DATE_TIME_FINAL,
    groupSelected,
    groupList,
    accountSelected,
    accountList,
    stateSelected,
    stateList,
    citySelected,
    cityList,
  ]);

  const totalFiltered = useMemo(() => {
    const vals = getValues();
    let total = 0;

    if (vals.DATE_TIME_START) {
      total += 1;
    }

    if (vals.DATE_TIME_FINAL) {
      total += 1;
    }

    if (groupSelected) {
      total += 1;
    }

    if (accountSelected.length) {
      total += 1;
    }

    if (stateSelected) {
      total += 1;
    }

    if (citySelected) {
      total += 1;
    }

    return total;
  }, [getValues()]);

  useEffect(() => {
    if (initialData.DATE_TIME_START) {
      setValue('DATE_TIME_START', initialData.DATE_TIME_START);
    } else {
      setValue('DATE_TIME_START', '');
    }

    if (initialData.DATE_TIME_FINAL) {
      setValue('DATE_TIME_FINAL', initialData.DATE_TIME_FINAL);
    } else {
      setValue('DATE_TIME_FINAL', '');
    }
  }, [initialData]);

  useEffect(() => {
    const values = getValues();

    if (!isLoadingFilters && isEqual(clean(values), clean(initialData))) {
      onLoadFilters(filterData);
    }
  }, [filterData, isLoadingFilters, getValues()]);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  const prepareSubmit = data => {
    onLoadFilters(filterData);
    onSubmit({ ...data });
  };

  return (
    <>
      <Button
        size="medium"
        icon
        color="blue"
        title="Filtros"
        className="btn-filter"
        loading={loading}
        disabled={loading}
        onClick={() => {
          onToggle();
          setIsOpen(true);
        }}
      >
        <Icon name="filter" /> Filtros ({totalFiltered})
      </Button>

      <Sidebar
        animation="overlay"
        icon="labeled"
        as={Menu}
        onHide={() => {
          onToggle();
          setIsOpen(false);
        }}
        vertical
        visible={isOpen}
        direction="right"
        width="wide"
      >
        <form
          className={classNames('ui form', {
            loading,
          })}
          onSubmit={handleSubmit(prepareSubmit)}
          style={{ height: '100%' }}
        >
          <div className="drawer-title">
            <h4>FILTROS</h4>

            <button
              type="button"
              className="close-drawer-btn"
              onClick={() => setIsOpen(false)}
            >
              <MenuCloseIcon size={20} color="#000" />
            </button>
          </div>
          <div className="fluid field">
            <label>Período inicial</label>
            <input type="date" name="DATE_TIME_START" ref={register} />
          </div>

          <div className="fluid field">
            <label>Período final</label>
            <input type="date" name="DATE_TIME_FINAL" ref={register} />
          </div>

          {user.TYPE_KEY_ === 'ROOT' && (
            <div className="fluid field">
              <label>Grupo</label>

              <select
                className="ui dropdown"
                value={groupSelected}
                disabled={groupList.length === 0}
                onChange={e => {
                  setValue('ACCOUNT_GROUP_ID', e.target.value);
                  setGroupSelected(e.target.value);
                }}
              >
                <option value="">Selecione</option>
                {groupList.map(group => (
                  <option key={group.ID} value={group.ID}>
                    {group.NAME}
                  </option>
                ))}
              </select>

              <input type="hidden" name="ACCOUNT_GROUP_ID" ref={register} />
            </div>
          )}

          {user.TYPE_KEY_ !== 'ACCOUNT' && (
            <div className="fluid field">
              <label>Conta</label>

              <Dropdown
                fluid
                search
                selection
                multiple
                clearable
                options={accountList.map(account => ({
                  key: account.ID,
                  value: account.ID,
                  text: account.NAME,
                }))}
                value={accountSelected}
                disabled={accountList.length === 0}
                placeholder="Selecione uma conta."
                noResultsMessage="Nenhuma conta encontrada."
                onChange={(e, data) => {
                  setValue('ACCOUNT_ID', data.value);
                  setAccountSelected(data.value);
                }}
              />

              <input type="hidden" name="ACCOUNT_ID" ref={register} />
            </div>
          )}

          {user.TYPE_KEY_ !== 'ACCOUNT' && (
            <>
              <div className="fluid field">
                <label>Estado</label>

                <select
                  className="ui dropdown"
                  value={stateSelected}
                  disabled={stateList.length === 0}
                  onChange={e => {
                    setValue('UF_ID', e.target.value);
                    setStateSelected(e.target.value);
                  }}
                >
                  <option value="">Selecione</option>
                  {stateList.map(state => (
                    <option key={state.ID} value={state.ID}>
                      {state.NAME}
                    </option>
                  ))}
                </select>

                <input type="hidden" name="UF_ID" ref={register} />
              </div>

              <div className="fluid field">
                <label>Cidade</label>

                <select
                  className="ui dropdown"
                  value={citySelected}
                  disabled={cityList.length === 0}
                  onChange={e => {
                    setValue('CITY_ID', e.target.value);
                    setCitySelected(e.target.value);
                  }}
                >
                  <option value="">Selecione</option>
                  {cityList.map(city => (
                    <option key={city.ID} value={city.ID}>
                      {city.NAME}
                    </option>
                  ))}
                </select>

                <input type="hidden" name="CITY_ID" ref={register} />
              </div>
            </>
          )}

          <Button
            fluid
            size="medium"
            color="blue"
            type="submit"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Aplicar
          </Button>
        </form>
      </Sidebar>
    </>
  );
};

export default DashboardDrawerFilter;
