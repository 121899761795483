import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import Table from '~/components/Table';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Search() {
  const { openModal } = useContext(ModalContext);

  const history = useHistory();
  const query = useQuery();
  const [backTo] = useState(query.get('backTo'));

  const [group, setGroup] = useState({});
  const [homologValues, setHomologValues] = useState([]);

  const { groupId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [limitChanged, setLimitChanged] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchHomologValues = useCallback(async pag => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'get',
      url: 'homologation/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify({
          ACCOUNT_GROUP_ID: groupId,
        }),
      },
    });

    if (responseSearch.status === 0) {
      setHomologValues(responseSearch.data);
    } else {
      setHomologValues([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => searchHomologValues({ ...pagination, keyword: key }), 1000)
  ).current;

  useEffect(() => {
    if (limitChanged) {
      searchHomologValues({ ...pagination });
    }

    setLimitChanged(false);
  }, [limitChanged]);

  useEffect(() => {
    searchHomologValues({ ...pagination, keyword });
  }, [searchHomologValues]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchHomologValues({ ...pagination, keyword });
  }

  function handlePaginationChange(e, { activePage }) {
    searchHomologValues({ ...pagination, page: activePage });
  }

  function handleLimitChange(e) {
    setPagination({ ...pagination, limit: e.target.value });

    setLimitChanged(true);
  }

  function handleHomologValueDelete(homologValue) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um valor de homologação permanentemente. Deseja mesmo realizar essa ação?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'homologation/delete',
          params: {
            homologation_id: JSON.stringify([homologValue]),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Valor de homologação excluído com sucesso!');
          searchHomologValues({ ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários valores de homologação permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const statusToDelete = homologValues
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!statusToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um valor de homologação para realizar essa ação!`
          );
          return;
        }

        const { data: responseDelete } = await api({
          method: 'post',
          url: 'homologation/delete',
          params: {
            homologation_id: JSON.stringify(statusToDelete),
          },
        });

        if (responseDelete.status === 0) {
          toast.success('Valores de homologação excluídos com sucesso!');
          searchHomologValues({ ...pagination, keyword });
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  const allSelected = useMemo(
    () =>
      homologValues.length && homologValues.every(m => m.uiChecked === true),
    [homologValues]
  );

  function handleSelectAll(e, { checked }) {
    setHomologValues(oldStatus =>
      oldStatus.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(homologValue) {
    setHomologValues(oldStatus =>
      oldStatus.map(item => {
        return item.ID === homologValue
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  useEffect(() => {
    (async () => {
      const groupData = await getGroupSelf({ account_group_id: groupId });
      setGroup(groupData);
    })();
  }, [groupId]);

  async function getGroupSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'account/group/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  function renderStatusWithTable() {
    return (
      <Table
        cols={[
          {
            field: 'ID',
            description: () => (
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            ),
          },
          { field: 'MIN_KWP', description: 'KWP mínimo' },
          { field: 'MAX_KWP', description: 'KWP máximo' },
          { field: 'PRICE', description: 'Preço' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={value => ({
          ID: () => (
            <Checkbox
              checked={!!value.uiChecked}
              onChange={() => handleSelect(value.ID)}
            />
          ),
          MIN_KWP: `${numberFormat(value.MIN_KWP, 2, ',', '.')} kWp`,
          MAX_KWP: `${numberFormat(value.MAX_KWP, 2, ',', '.')} kWp`,
          PRICE: `R$ ${numberFormat(value.PRICE, 2, ',', '.')}`,
          ACTIONS: () => (
            <>
              <Button
                size="mini"
                icon
                color="blue"
                title="Edita valor de homologação"
                onClick={() => {
                  openModal('homologValueAdd', {
                    mode: 'edit',
                    data: {
                      ID: value.ID,
                      MIN_KWP: value.MIN_KWP,
                      MAX_KWP: value.MAX_KWP,
                      PRICE: value.PRICE,
                      ACCOUNT_GROUP_ID: groupId,
                    },
                    onConfirm: () =>
                      searchHomologValues({ ...pagination, keyword }),
                  });
                }}
              >
                <Icon name="pencil" />
              </Button>

              <Button
                size="mini"
                icon
                color="red"
                title="Remove valor de homologação"
                onClick={() => handleHomologValueDelete(value.ID)}
              >
                <Icon name="trash" />
              </Button>
            </>
          ),
        })}
        data={homologValues}
        loading={isLoading}
        onUpdateOrderBy={orderby => {
          const newPagination = {
            ...pagination,
            orderby,
          };

          setPagination(newPagination);

          searchHomologValues({ ...newPagination, keyword });
        }}
      />
    );
  }

  function renderHomologValuesContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <span>Registros por página</span>
            <select
              className="ui dropdown m-r-sm m-l-sm"
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!homologValues.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />
                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>

            <button
              type="button"
              className="ui button green icon"
              title="Novo valor"
              onClick={() => {
                openModal('homologValueAdd', {
                  mode: 'add',
                  data: {
                    ACCOUNT_GROUP_ID: groupId,
                  },
                  onConfirm: () => searchHomologValues(pagination),
                });
              }}
            >
              <i className="mdi mdi-plus icon" /> Novo valor
            </button>
          </Grid.Column>
        </Grid>

        {renderStatusWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title">
              Gerenciar valores de homologação
              <br />
              <small>{group.NAME && `Grupo: ${group.NAME}`}</small>
            </span>

            {backTo && (
              <div>
                <button
                  className="ui button red"
                  type="button"
                  onClick={() => history.push(backTo)}
                >
                  Voltar
                </button>
              </div>
            )}
          </div>

          <div className="ui attached bottom segment">
            {renderHomologValuesContainer()}
          </div>
        </div>
      </div>
    </>
  );
}
