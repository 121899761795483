import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Link,
} from 'react-router-dom';

import classNames from 'classnames';
import { merge } from 'lodash/object';
import { Grid } from 'semantic-ui-react';

import api from '~/services/api';

import SubPageAddress from './SubPage/Address';
import SubPageHistory from './SubPage/History';
import SubPageIdentification from './SubPage/Identification';

function ProjectAdd() {
  const history = useHistory();
  const { clientId, billId } = useParams();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [activeTab, setActiveTab] = useState();

  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    setActiveTab(pathname.replace(url, ''));
  }, [pathname]);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    clientId,
    billId,
    self: {},
  });

  const [backTo] = useState(query.get('backTo'));
  const [mode] = useState(query.get('mode'));
  const [projectId] = useState(query.get('projectId'));

  const [client, setClient] = useState({});
  const [self, setSelf] = useState({});
  const [usedBy, setUsedBy] = useState();
  const [validate, setValidate] = useState({});
  const [showMenu, setShowMenu] = useState(!isMobile);

  const [triggerSelfCount, setTriggerSelfCount] = useState(0);

  useEffect(() => {
    let validatedBillId = billId;

    async function getBillId(params) {
      const { data: project } = await api({
        method: 'post',
        url: 'energy/bill/add',
        params,
      });

      if (project.status === 0) {
        if (!project.data.validated) {
          validatedBillId = project.data.ID;

          history.replace(
            `/client/${clientId}/bill/${validatedBillId}${location.search}`
          );
        }
      } else {
        await getBillId();
      }
    }

    async function init() {
      setIsLoading(true);

      await getBillId({ energy_bill_id: billId });

      setFormData(
        merge(formData, {
          billId: validatedBillId,
          editMode: billId === validatedBillId,
        })
      );

      setIsLoading(false);
    }

    init();
  }, [history, billId]);

  useEffect(() => {
    (async () => {
      const clientData = await getClientSelf({ client_id: clientId });
      setClient({
        ...clientData,
        NAME:
          clientData.TYPE === 'L'
            ? `${clientData.COMPANY_NAME} (${clientData.FANTASY_NAME})`
            : clientData.NAME,
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const billData = await getBillSelf({ energy_bill_id: formData.billId });
      setFormData(oldState => merge(oldState, { self: billData }));
      setSelf(billData);
    })();

    (async () => {
      if (formData.billId && formData.billId !== 'new') {
        const {
          information,
          address,
          energy_bill_history: vHistory,
        } = await getBillValidate({
          energy_bill_id: formData.billId,
        });

        setValidate({
          identification: information.validate,
          address: address.validate,
          history: vHistory && vHistory.validate,
        });
      }
    })();
  }, [formData.billId, triggerSelfCount]);

  useEffect(() => {
    (async () => {
      const billUsed = await searchProjects();
      setUsedBy(billUsed);
    })();
  }, []);

  function updateStore(data) {
    setFormData({ ...formData, self: { ...formData.self, ...data } });
  }

  async function searchProjects() {
    const { data: response } = await api({
      method: 'post',
      url: 'project/energy/bill/select',
      params: {
        bill_id: billId,
      },
    });

    if (response.status === 0) {
      return response.data;
    }

    return false;
  }

  async function getBillSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'energy/bill/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  async function getClientSelf(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'client/self',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  async function getBillValidate(params) {
    const { data: response } = await api({
      method: 'post',
      url: 'energy/bill/validate',
      params,
    });

    if (response.status === 0) {
      return response.data;
    }
    return false;
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          {isLoading && <span className="title">Carregando</span>}

          {!isLoading && (
            <span className="title">
              {formData.editMode ? 'Editar conta' : 'Nova conta'}
            </span>
          )}

          <div>
            {backTo && (
              <button
                className={classNames('ui button', {
                  red: !projectId,
                  blue: projectId,
                })}
                type="button"
                onClick={() => history.push(backTo)}
              >
                {projectId ? 'Voltar ao projeto' : 'Cancelar'}
              </button>
            )}
          </div>
        </div>

        <div
          className={classNames('ui attached bottom segment', {
            loading: isLoading,
          })}
        >
          <div className="ui form m-b">
            <div className="fields">
              <div className="field">
                <label>Cliente</label>
                <i className="m-t-n-xs">{client.NAME || 'Não definido'}</i>
              </div>
              <div className="field">
                <label>Descrição do imóvel</label>
                <i className="m-t-n-xs">
                  {self.PROPERTY_DESCRIPTION || 'Não definida'}
                </i>
              </div>
            </div>
          </div>

          <Grid centered>
            {isMobile && (
              <Grid.Column mobile={16}>
                <button
                  type="button"
                  className="ui fluid button"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  {showMenu ? 'Fechar etapas' : 'Ver etapas'}
                </button>
              </Grid.Column>
            )}

            {showMenu && (
              <Grid.Column mobile={16}>
                <Link
                  to={`identification${location.search}`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive:
                      validate.identification &&
                      activeTab === '/identification',
                    green:
                      validate.identification &&
                      activeTab !== '/identification',
                    active: activeTab === '/identification',
                  })}
                >
                  <i
                    className={classNames('mdi icon', {
                      'mdi-check': validate.identification,
                      'mdi-close': !validate.identification,
                    })}
                  />
                  Identificação
                </Link>
                <Link
                  to={`address${location.search}`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive: validate.address && activeTab === '/address',
                    green: validate.address && activeTab !== '/address',
                    active: activeTab === '/address',
                  })}
                >
                  <i
                    className={classNames('mdi icon', {
                      'mdi-check': validate.address,
                      'mdi-close': !validate.address,
                    })}
                  />
                  Endereço
                </Link>
                <Link
                  to={`history${location.search}`}
                  className={classNames('ui icon left labeled button', {
                    fluid: isMobile,
                    'm-t-xs': isMobile,
                    olive: validate.history && activeTab === '/history',
                    green: validate.history && activeTab !== '/history',
                    active: activeTab === '/history',
                  })}
                >
                  <i
                    className={classNames('mdi icon', {
                      'mdi-check': validate.history,
                      'mdi-close': !validate.history,
                    })}
                  />
                  Histórico
                </Link>
              </Grid.Column>
            )}
          </Grid>

          <Switch>
            <Route exact path={path}>
              <Redirect
                to={{
                  pathname: `${url}/identification`,
                  search: location.search,
                }}
              />
            </Route>

            <Route
              exact
              path={`${path}/identification`}
              component={routeProps => (
                <SubPageIdentification
                  {...routeProps}
                  store={formData}
                  updateStore={() => setTriggerSelfCount(triggerSelfCount + 1)}
                />
              )}
            />

            <Route
              exact
              path={`${path}/address`}
              component={routeProps => (
                <SubPageAddress
                  {...routeProps}
                  store={formData}
                  updateStore={() => setTriggerSelfCount(triggerSelfCount + 1)}
                />
              )}
            />

            <Route
              exact
              path={`${path}/history`}
              component={routeProps => (
                <SubPageHistory
                  {...routeProps}
                  store={formData}
                  updateSelf={() => setTriggerSelfCount(triggerSelfCount + 1)}
                  updateStore={data => updateStore(data)}
                  projectId={projectId}
                  usedBy={usedBy}
                  backTo={backTo}
                  mode={mode}
                />
              )}
            />

            <Route path={`${path}/*`}>
              <Redirect to={`${url}/identification`} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default ProjectAdd;
