import React, { useState, useEffect, useCallback } from 'react';

import { Modal, Button, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalGroupSelect({ onClose, handleClose, onConfirm }) {
  const [isLoading, setIsLoading] = useState(false);

  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);

  async function getGroups() {
    const { data: groupResponse } = await api({
      method: 'post',
      url: 'account/group/select',
      params: {
        filter: JSON.stringify({}),
      },
    });

    if (groupResponse.status === 0) {
      const groups = groupResponse.data.map(group => ({
        key: group.ID,
        value: group.ID,
        text: group.NAME,
      }));

      return groups;
    }

    return [];
  }

  const loadGroups = useCallback(async () => {
    setIsLoading(true);

    const groups = await getGroups();
    setGroupList(groups);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  async function handleConfirm() {
    if (onConfirm) onConfirm(selectedGroup);
    handleClose();
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>Selecionar grupo</Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Grupo</label>

            <Dropdown
              fluid
              search
              selection
              options={groupList}
              value={selectedGroup}
              placeholder="Selecione um grupo."
              noResultsMessage="Nenhum grupo encontrado."
              onChange={(e, selected) => setSelectedGroup(selected.value)}
              disabled={isLoading}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          Selecionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
