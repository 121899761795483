import { useEffect, useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import swal from 'sweetalert';

import api, { SESSION_NAME } from '~/services/api';

export default function useAuth() {
  const history = useHistory();

  const [, setCookie, removeCookie] = useCookies();

  const [loading, setLoading] = useState(true);

  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  const [account, setAccount] = useState({});

  const validateSession = useCallback(async () => {
    const loadedToken = localStorage.getItem('@webvolt:token');

    if (!loadedToken) {
      removeCookie(SESSION_NAME);

      setUser({});

      setLoading(false);
      setAuthenticated(false);

      localStorage.removeItem('@webvolt:managed_account');

      return;
    }

    setCookie(SESSION_NAME, loadedToken);

    const managedAccountId = JSON.parse(
      localStorage.getItem('@webvolt:managed_account')
    );

    const userInfo = await getCredential();

    if (userInfo.status !== 0) {
      localStorage.removeItem('@webvolt:token');

      removeCookie(SESSION_NAME);

      setUser({});

      setLoading(false);
      setAuthenticated(false);

      return;
    }

    const loggedUser = userInfo.data;

    setUser(loggedUser);
    setAuthenticated(true);

    localStorage.setItem('@webvolt:token', loadedToken);

    if (managedAccountId) {
      const accountInfo = await getAccount(managedAccountId);

      if (accountInfo.status === 0) {
        const selectedAccount = accountInfo.data;

        setAccount({
          GROUP_ID: selectedAccount.ACCOUNT_GROUP_ID,
          GROUP_NAME: selectedAccount.ACCOUNT_GROUP_NAME,
          ID: selectedAccount.ID,
          NAME: selectedAccount.NAME,
        });
      } else {
        localStorage.removeItem('@webvolt:managed_account');
      }
    } else {
      setAccount({
        GROUP_ID: loggedUser.ACCOUNT_GROUP_ID,
        GROUP_NAME: loggedUser.ACCOUNT_GROUP_NAME,
        ID: loggedUser.ACCOUNT_ID,
        NAME: loggedUser.ACCOUNT_NAME,
      });
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    validateSession();
  }, [validateSession]);

  const handleLogin = useCallback(async (username, password) => {
    const { data: validate } = await api({
      method: 'POST',
      url: 'login/validate',
      params: { user: username, password },
    });

    const { title, text, type } = validate.message;

    if (validate.status !== 0) {
      swal(title, text, type);

      return;
    }

    const { token } = validate;

    const { data: auth } = await api({
      method: 'POST',
      url: 'auth/login',
      params: {
        token,
      },
    });

    if (auth.status !== 0) {
      swal(title, text, type);
      return;
    }

    setToken(auth.data.happsession);

    const userInfo = await getCredential();
    let redirect = '/';

    if (userInfo.status === 0) {
      const loggedUser = userInfo.data;

      setUser(loggedUser);

      setAccount({
        GROUP_ID: loggedUser.ACCOUNT_GROUP_ID,
        GROUP_NAME: loggedUser.ACCOUNT_GROUP_NAME,
        ID: loggedUser.ACCOUNT_ID,
        NAME: loggedUser.ACCOUNT_NAME,
      });

      if (loggedUser.PASS_DEFAULT === 'T') {
        redirect = '/reset?pass=default';
      }
    }

    setAuthenticated(true);

    history.push(redirect);
  }, []);

  function setToken(tk) {
    setCookie(SESSION_NAME, tk);

    localStorage.setItem('@webvolt:token', tk);
  }

  async function logout() {
    setLoading(true);

    await api({
      method: 'POST',
      url: 'auth/logout',
    });

    localStorage.removeItem('@webvolt:token');
    localStorage.removeItem('@webvolt:managed_account');

    removeCookie(SESSION_NAME);

    setUser({});

    setLoading(false);
    setAuthenticated(false);

    history.push('/login');
  }

  async function getCredential() {
    const { data: response } = await api({
      method: 'POST',
      url: 'auth/self',
    });

    return response;
  }

  async function getUser() {
    const userInfo = await getCredential();

    if (userInfo.status === 0) {
      setUser(userInfo.data);
    }
  }

  async function setManageAccount(accountId) {
    setLoading(true);

    localStorage.removeItem('@webvolt:managed_account');

    const accountInfo = await getAccount(accountId);

    if (accountInfo.status === 0) {
      const selectedAccount = accountInfo.data;

      setAccount({
        GROUP_ID: selectedAccount.ACCOUNT_GROUP_ID,
        GROUP_NAME: selectedAccount.ACCOUNT_GROUP_NAME,
        ID: selectedAccount.ID,
        NAME: selectedAccount.NAME,
      });

      localStorage.setItem(
        '@webvolt:managed_account',
        JSON.stringify(selectedAccount.ID)
      );
    }

    setLoading(false);
  }

  async function getAccount(accountId) {
    const { data: response } = await api({
      method: 'POST',
      url: 'account/self',
      params: {
        account_id: accountId,
      },
    });

    return response;
  }

  return {
    authenticated,
    setUser,
    user,
    setToken,
    logout,
    loading,
    handleLogin,
    getUser,
    account,
    setManageAccount,
  };
}
