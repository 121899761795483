import styled from 'styled-components';

const colors = {
  yellow: 'rgb(251, 190, 8)',
  red: 'rgb(219, 40, 40)',
  green: 'rgb(33, 186, 69)',
  grey: 'rgb(118, 118, 118)',
  blue: 'rgb(33, 133, 208)',
};

export default styled.div`
  width: auto;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: #fff;

  border-radius: 0.6rem;

  box-shadow: 0 1px 3px 0 rgba(212, 212, 213, 0.8),
    0 0 0 1px rgba(212, 212, 213, 0.8);

  /* margin-right: 1rem; */

  color: ${p => (p.color ? colors[p.color] : 'unset')};

  .title {
    display: flex;

    flex-direction: row;

    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 15px;
    line-height: 22px;

    .icon {
      /* width: 22px; */
      height: 22px;

      margin-right: 6px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 428px) {
    width: 100%;
    /* margin-top: 1rem;
    margin-left: 1rem; */
    /*
    &:last-child {
      margin-right: 1rem;
    } */
  }
`;
