import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import numberFormat from 'locutus/php/strings/number_format';
import { Modal, Button, Dropdown, Icon, Label } from 'semantic-ui-react';

import Table from '~/components/Table';
import api from '~/services/api';

export default function ModalStatusAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [filter] = useState({});
  const [status, setStatus] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [projectStatus, setProjectStatus] = useState(data.PROJECT_STATUS);

  const [deletedAny, setDeletedAny] = useState(false);

  async function handleConfirm() {
    if (deletedAny && !status) {
      if (onConfirm) onConfirm();

      handleClose();

      return;
    }

    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/project/stage/add' : '/project/stage/update',
      params: {
        stage_id: status,
        project_id: data.PROJECT_ID,
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar o status: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  const searchStatus = useCallback(async () => {
    const { data: responseStages } = await api({
      method: 'post',
      url: 'stage/select',
      params: {
        filter,
        orderby: { field: 'POSITION', mode: 'ASC' },
      },
    });

    if (responseStages.status === 0) {
      const statusMap = responseStages.data.map(stage => ({
        key: stage.ID,
        text: stage.DESCRIPTION,
        value: stage.ID,
        label: { color: stage.COLOR, empty: true, circular: true },
      }));

      setStatusList(statusMap);
    } else {
      setStatusList([]);
    }
  }, []);

  async function handleStatusDelete(projectStageId) {
    const { data: project } = await api({
      method: 'post',
      url: 'project/stage/delete',
      params: {
        project_stage_id: JSON.stringify(projectStageId),
      },
    });

    if (project.status === 0) {
      toast.success('Status excluído com sucesso!');
      setProjectStatus(oldStatus =>
        oldStatus.filter(
          statusObj => statusObj.PROJECT_STAGE_ID !== projectStageId
        )
      );

      setDeletedAny(true);
    } else {
      toast.error(project.message.text);
    }
  }

  useEffect(() => {
    searchStatus();
  }, [searchStatus]);

  function renderWithTable() {
    return (
      <Table
        cols={[
          { field: 'ID', description: 'Código' },
          { field: 'DESCRIPTION', description: 'Descrição' },
          { field: 'POSITION', description: 'Posição' },
          { field: 'ACTIONS', description: 'Ações' },
        ]}
        mapping={statusObj => ({
          ID: statusObj.ID,
          DESCRIPTION: () => (
            <Label size="small" color={statusObj.COLOR}>
              {statusObj.DESCRIPTION}
            </Label>
          ),
          POSITION: numberFormat(statusObj.POSITION, 0, ',', '.'),
          ACTIONS: () => (
            <Button
              size="mini"
              icon
              color="red"
              title="Excluir status"
              onClick={() => handleStatusDelete(statusObj.PROJECT_STAGE_ID)}
            >
              <Icon name="trash" />
            </Button>
          ),
        })}
        data={projectStatus}
        loading={isSaving}
        onUpdateOrderBy={() => {}}
      />
    );
  }

  return (
    <Modal size="small" open onClose={onClose}>
      <Modal.Header>Status do projeto</Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field">
            <label>Adicione um status ao projeto</label>
            <Dropdown
              fluid
              selection
              value={status}
              options={statusList}
              onChange={(e, d) => setStatus(d.value)}
              placeholder="Selecione um status"
            />
          </div>
        </div>

        {data.PROJECT_STATUS && renderWithTable()}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
