import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Checkbox,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

// import FormFilter from './components/Filter';

export default function Search() {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [groups, setGroups] = useState([]);

  const [keyword, setKeyword] = useState('');

  // const [showFilter, setShowFilter] = useState(false);

  const [filter] = useState({
    LIMIT: '8',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchGroups = useCallback(async (filters, pag) => {
    setLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/account/group/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setGroups(responseSearch.data);
      setPagination(responseSearch.pagination);
    } else {
      setGroups([]);
    }

    setLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchGroups(filter, newPagination);
    }, 1000)
  ).current;

  useEffect(() => {
    searchGroups(filter, pagination);
  }, [searchGroups]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    searchGroups(filter, {
      ...pagination,
      page: activePage,
    });
  }

  const allSelected = useMemo(
    () => groups.length && groups.every(m => m.uiChecked === true),
    [groups]
  );

  function handleSelectAll(e, { checked }) {
    setGroups(oldGroups =>
      oldGroups.map(item => ({
        ...item,
        uiChecked: checked,
      }))
    );
  }

  function handleSelect(groupId) {
    setGroups(oldGroups =>
      oldGroups.map(item => {
        return item.ID === groupId
          ? { ...item, uiChecked: !item.uiChecked }
          : item;
      })
    );
  }

  function handleDelete(groupId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um grupo permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'account/group/delete',
          params: {
            account_group_id: JSON.stringify([groupId]),
          },
        });

        if (response.status === 0) {
          searchGroups(filter, pagination);
          toast.success('Grupo excluído com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleDeleteSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir vários grupos permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const groupsToDelete = groups
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!groupsToDelete.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um grupo para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'account/group/delete',
          params: {
            account_group_id: JSON.stringify(groupsToDelete),
          },
        });

        if (response.status === 0) {
          toast.success('Grupos excluídos com sucesso!');
          searchGroups(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleBlock(groupId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a bloquear um grupo. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Bloquear'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'account/group/block',
          params: {
            account_group_id: JSON.stringify([groupId]),
            action: 'block',
          },
        });

        if (response.status === 0) {
          searchGroups(filter, pagination);
          toast.success('Grupo bloqueado com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleBlockSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a bloquear vários grupos. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Bloquear'],
    }).then(async result => {
      if (result) {
        const groupsToBlock = groups
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!groupsToBlock.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um grupo para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'account/group/block',
          params: {
            account_group_id: JSON.stringify(groupsToBlock),
            action: 'block',
          },
        });

        if (response.status === 0) {
          toast.success('Grupos bloqueados com sucesso!');
          searchGroups(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleUnblock(groupId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a desbloquear um grupo. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Desbloquear'],
    }).then(async result => {
      if (result) {
        const { data: response } = await api({
          method: 'post',
          url: 'account/group/block',
          params: {
            account_group_id: JSON.stringify([groupId]),
            action: 'unblock',
          },
        });

        if (response.status === 0) {
          searchGroups(filter, pagination);
          toast.success('Grupo desbloqueado com sucesso!');
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function handleUnblockSelected() {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a desbloquear vários grupos. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Desbloquear'],
    }).then(async result => {
      if (result) {
        const groupsToUnblock = groups
          .filter(item => item.uiChecked === true)
          .map(item => item.ID);

        if (!groupsToUnblock.length > 0) {
          toast.warning(
            `É preciso selecionar pelo menos um grupo para realizar essa ação!`
          );
          return;
        }

        const { data: response } = await api({
          method: 'post',
          url: 'account/group/block',
          params: {
            account_group_id: JSON.stringify(groupsToUnblock),
            action: 'unblock',
          },
        });

        if (response.status === 0) {
          toast.success('Grupos desbloqueados com sucesso!');
          searchGroups(filter, pagination);
        } else {
          toast.error(response.message.text);
        }
      }
    });
  }

  function renderSituationByStatus(status) {
    if (status === 'A') {
      return (
        <Label size="mini" color="green">
          Ativo
        </Label>
      );
    }
    if (status === 'B') {
      return (
        <Label size="mini" color="red">
          Bloqueado
        </Label>
      );
    }
    if (status === 'P') {
      return (
        <Label size="mini" color="yellow">
          Pendente
        </Label>
      );
    }

    return <Label size="mini">-</Label>;
  }

  function renderWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned" width="2%">
              <Checkbox onChange={handleSelectAll} checked={!!allSelected} />
            </th>
            <th className="center aligned">Grupo</th>
            <th className="center aligned">Cadastro</th>
            <th className="center aligned">Cidade</th>
            <th className="center aligned">Responsável</th>
            <th className="center aligned">Situação</th>
            <th className="center aligned" width="2%">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {groups.length > 0 &&
            !loading &&
            groups.map(group => (
              <tr
                key={group.ID}
                className={classNames({
                  active: group.uiChecked,
                })}
              >
                <td className="center aligned">
                  <Checkbox
                    checked={!!group.uiChecked}
                    onChange={() => handleSelect(group.ID)}
                  />
                </td>
                <td className="center aligned">{group.NAME || '-'}</td>
                <td className="center aligned">
                  {group.CREATED_AT_DATE_FORMAT || '-'}
                </td>
                <td className="center aligned">
                  {group.CITY_NAME
                    ? `${group.CITY_NAME}/${group.UF_NAME}`
                    : '-'}
                </td>
                <td className="center aligned">
                  {group.RESPONSIBLE_NAME || '-'}
                </td>

                <td className="center aligned">
                  {renderSituationByStatus(group.STATUS)}
                </td>

                <td className="center aligned">
                  <Dropdown
                    icon="cog"
                    floating
                    title="Ações"
                    button
                    className="mini blue icon"
                    direction="left"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Header content="Ações" />
                      <Dropdown.Divider />

                      <Dropdown.Item
                        color="blue"
                        icon="pencil"
                        text="Editar grupo"
                        title="Editar grupo"
                        onClick={() =>
                          history.push(`/group/${group.ID}?backTo=/groups`)
                        }
                      />

                      {group.STATUS === 'A' ? (
                        <Dropdown.Item
                          icon="lock"
                          text="Bloquear grupo"
                          title="Bloquear grupo"
                          onClick={() => handleBlock(group.ID)}
                        />
                      ) : (
                        <Dropdown.Item
                          icon="lock open"
                          text="Desbloquear grupo"
                          title="Desbloquear grupo"
                          onClick={() => handleUnblock(group.ID)}
                        />
                      )}

                      <Dropdown.Item
                        color="red"
                        icon="trash"
                        text="Remover grupo"
                        title="Remover grupo"
                        onClick={() => handleDelete(group.ID)}
                      />

                      {user.TYPE_KEY_ === `ROOT` && (
                        <Dropdown.Item
                          icon="lock"
                          text="Gerenciar marcas"
                          title="Gerenciar marcas"
                          onClick={() =>
                            history.push(
                              `/brands/group/${group.ID}?backTo=/groups`
                            )
                          }
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}

          {!loading && groups.length === 0 && (
            <tr>
              <td colSpan="7">
                <Message content="Nenhum grupo encontrado! Atualize os filtros e pesquisa novamente." />
              </td>
            </tr>
          )}

          {loading && (
            <tr>
              <td colSpan="7">
                <Message color="blue" content="Buscando grupos..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              {/* <button
                className="ui button label blue"
                type="button"
                disabled={loading}
                onClick={() => setShowFilter(!showFilter)}
              >
                <i className="mdi mdi-filter" />
                Filtros
              </button> */}

              <button
                className={classNames('ui button label', {
                  loading,
                })}
                type="button"
                disabled={loading}
                onClick={() => searchGroups(filter, pagination)}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            className="right aligned"
            mobile={16}
            table={8}
            computer={8}
          >
            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!groups.some(m => m.uiChecked === true)}
            >
              <Dropdown.Menu direction="left">
                <Dropdown.Header content="Ações rápidas" />
                <Dropdown.Divider />

                <Dropdown.Item
                  color="red"
                  icon="lock open"
                  text="Desbloquear selecionados"
                  title="Desbloquear selecionados"
                  onClick={handleUnblockSelected}
                />

                <Dropdown.Item
                  color="red"
                  icon="lock"
                  text="Bloquear selecionados"
                  title="Bloquear selecionados"
                  onClick={handleBlockSelected}
                />

                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  title="Excluir selecionados"
                  onClick={handleDeleteSelected}
                />
              </Dropdown.Menu>
            </Dropdown>
            <button
              type="button"
              className="ui button green"
              onClick={() => history.push(`/group/?backTo=/groups`)}
            >
              <i className="mdi mdi-account-plus"> </i> Novo grupo
            </button>
          </Grid.Column>

          {/* {showFilter && (
            <Grid.Column mobile={16} table={16} computer={16}>
              <FormFilter handleSubmit={onConfirmFilter} initialData={{}} />
            </Grid.Column>
          )} */}
        </Grid>

        {renderWithTable()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              boundaryRange={2}
              siblingRange={2}
              disabled={loading}
              defaultActivePage={1}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="ui centered grid">
        <div className="ui sixteen wide column">
          <div className="ui top attached header module">
            <span className="title"> Controle de grupos </span>
            <div>
              <button
                type="button"
                className="ui button red"
                onClick={() => history.push(`/accounts/`)}
              >
                Voltar
              </button>
            </div>
          </div>

          <div className="ui attached bottom segment">{renderContainer()}</div>
        </div>
      </div>
    </>
  );
}
