import React from 'react';

import { Label } from 'semantic-ui-react';

import { Container, Title } from './styles';

const AppliedFilters = ({ filters }) => {
  const filterDataIsArray = data => Array.isArray(data);

  const makeFiltersTags = () => {
    return filters.map(filter => (
      <Label
        key={filter.key}
        size="large"
        horizontal
        style={{ border: '1px solid #999', margin: '2px' }}
      >
        {filter.key.toUpperCase()}:{' '}
        {filterDataIsArray(filter.data) ? filter.data.join(', ') : filter.data}
      </Label>
    ));
  };

  if (filters.length === 0) {
    return null;
  }

  return (
    <Container>
      <Title>FILTRANDO POR: </Title>
      <span className="ui labels">{makeFiltersTags()}</span>
    </Container>
  );
};

export default AppliedFilters;
