import React, { useState, useRef, useEffect } from 'react';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalTransformerAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState(data.DESCRIPTION || '');
  const [power, setPower] = useState(data.POWER || 0);
  // const [image, setImage] = useState(null);

  const inputDescription = useRef(null);
  const [inputPower, setInputPower] = useState(null);

  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.focus();
    }
  }, [inputDescription]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: responseAdd } = await api({
      method: 'post',
      url: mode === 'add' ? '/transformer/add' : '/transformer/update',
      params: {
        transformer_id: data.ID || null,
        transformer: JSON.stringify({
          DESCRIPTION: description,
          POWER: power,
        }),
      },
    });

    if (responseAdd.status === 0) {
      toast.success(responseAdd.message.text);

      if (onConfirm) onConfirm(responseAdd.data);

      handleClose();
    } else {
      toast.error(
        `Erro ao tentar adicionar o transformer: ${responseAdd.message.text}`
      );
    }

    setIsSaving(false);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo transformador' : 'Editar transformador'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Descrição</label>
            <input
              type="text"
              ref={inputDescription}
              value={description}
              onChange={e => setDescription(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && inputPower.focus()}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Potência (kVA)</label>
            <InputNumberFormat
              type="tel"
              value={power || ''}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              onValueChange={v => setPower(v.floatValue)}
              getInputRef={el => setInputPower(el)}
              disabled={isSaving}
            />
          </div>

          {/* <div className="field">
            <label>Imagem</label>

            <label htmlFor="image" className="ui icon button">
              <Icon name={image ? 'check' : 'upload'} />
              <span className="m-l-sm">
                {image ? 'Arquivo selecionado' : 'Carregar imagem'}
              </span>
            </label>

            <input
              type="file"
              accept="image/png,image/jpg,image/jpeg"
              id="image"
              className="ui file input"
              style={{ display: 'none' }}
              onChange={event => setImage(event.target.files[0])}
            />
          </div> */}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
