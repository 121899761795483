/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import classNames from 'classnames';
import { Modal, Button } from 'semantic-ui-react';

export default function ModalProjectMaterialSelect({
  onClose,
  handleClose,
  onConfirm,
}) {
  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>Configurar tipo de rede</Modal.Header>

      <Modal.Content>
        <h3 className="text-center">
          É necessário configurar o tipo de rede dessa conta, deseja configurar?
        </h3>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose}>Não</Button>
        <Button
          positive
          onClick={() => {
            onConfirm();
            handleClose();
          }}
        >
          Sim
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
