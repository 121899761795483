/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Modal,
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
} from 'semantic-ui-react';

import ClientCard from '~/components/Card/Client';
// import FormFilter from '~/pages/Client/Search/components/Filter';
import api from '~/services/api';

export default function ModalClientSelect({
  open,
  onClose,
  handleClose,
  onSelect,
}) {
  const [showTable, setShowTable] = useState(!isMobile);

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter, setFilter] = useState({
    LIMIT: '8',
    FIELD: 'DATEHOUR_CREATE',
    ORDER: 'DESC',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total_page: 1,
    orderby: [{ field: 'ID', mode: 'ASC' }],
  });

  const searchClients = useCallback(async (filters, pag) => {
    setIsLoading(true);

    const { data: responseSearch } = await api({
      method: 'post',
      url: '/client/search',
      params: {
        pagination: JSON.stringify(pag),
        filter: JSON.stringify(filters),
      },
    });

    if (responseSearch.status === 0) {
      setClients(responseSearch.data);
    } else {
      setClients([]);
    }

    setPagination(responseSearch.pagination);

    setIsLoading(false);
  }, []);

  const setPaginationKeyword = useRef(
    debounce(key => {
      const newPagination = {
        ...pagination,
        keyword: key,
      };

      searchClients(filter, newPagination);
    }, 1000)
  ).current;

  // const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    searchClients(filter, pagination);
  }, [searchClients]);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);
    setPaginationKeyword(e.target.value);
  }

  function handleSearch() {
    searchClients(filter, pagination);
  }

  function handlePaginationChange(e, { activePage }) {
    searchClients(filter, {
      ...pagination,
      page: activePage,
    });
  }

  function onConfirmFilter(data) {
    const newOrderBy = [];

    setFilter({ ...filter, data });

    newOrderBy.push({
      field: data.FIELD,
      mode: data.ORDER,
    });

    searchClients(
      { ...filter, data },
      {
        ...pagination,
        ...{
          limit: data.LIMIT,
          orderby: newOrderBy,
        },
      }
    );
  }

  function onCardSelect(client) {
    onSelect(client);
    handleClose();
  }

  function renderClientsWithCard() {
    return (
      <Grid centered>
        {clients.length > 0 &&
          !isLoading &&
          clients.map(client => (
            <Grid.Column key={client.ID} mobile={16} tablet={8} computer={4}>
              <ClientCard
                type="select"
                handleSelect={() => onCardSelect(client)}
                client={client}
              />
            </Grid.Column>
          ))}

        {!isLoading && clients.length === 0 && (
          <Grid.Column>
            <Message content="Nenhum cliente encontrado! Atualize os filtros e pesquisa novamente." />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando clientes..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function getNameByType(client) {
    if (client.TYPE_FORMAT === 'PF') {
      return client.NAME || '-';
    }

    return `${client.COMPANY_NAME || '-'} (${client.FANTASY_NAME || '-'})`;
  }

  function renderClientsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">Nome do cliente</th>
            <th className="center aligned">E-mail</th>
            <th className="center aligned">Cidade</th>
            <th className="center aligned">Responsável</th>
            <th className="center aligned">Data de cadastro</th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {clients.length > 0 &&
            !isLoading &&
            clients.map(client => (
              <tr key={client.ID}>
                <td className="center aligned">{getNameByType(client)}</td>
                <td className="center aligned">{client.EMAIL || '-'}</td>
                <td className="center aligned">
                  {client.CITY_NAME && client.UF_ABBREVIATION
                    ? `${client.CITY_NAME}-${client.UF_ABBREVIATION}`
                    : client.CITY_NAME || '-'}
                </td>
                <td className="center aligned">
                  {client.CONTACT || '-'} <br />{' '}
                  {client.PHONE && client.CELL_PHONE
                    ? `${client.PHONE} / ${client.CELL_PHONE}`
                    : client.PHONE}{' '}
                </td>
                <td className="center aligned">
                  {client.DATE_CREATE_FORMAT || '-'}
                </td>
                <td className="center aligned">
                  <Button
                    size="mini"
                    icon
                    color="green"
                    title="Selecionar cliente"
                    onClick={() => onCardSelect(client)}
                  >
                    <Icon name="check" /> Selecionar
                  </Button>
                </td>
              </tr>
            ))}

          {!isLoading && clients.length === 0 && (
            <tr>
              <td colSpan="6">
                <Message content="Nenhum cliente encontrado! Atualize os filtros e pesquisa novamente." />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="6">
                <Message color="blue" content="Buscando clientes..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderClientsContainer() {
    return (
      <>
        <Grid centered>
          <Grid.Column mobile={8} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />

              {/* <button
                className="ui button label blue"
                type="button"
                disabled={isLoading}
                onClick={() => setShowFilter(!showFilter)}
              >
                <i className="mdi mdi-filter" />
                Filtros
              </button> */}

              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={handleSearch}
              >
                Pesquisar
              </button>
            </div>

            {keyword.length !== 0 && keyword.length < 3 && (
              <span>Digite pelo menos 3 caracteres</span>
            )}
          </Grid.Column>

          <Grid.Column mobile={8} className="right aligned">
            {!isMobile && (
              <button
                type="button"
                className="ui button icon"
                title="Alterar visualização"
                onClick={() => setShowTable(!showTable)}
              >
                <i
                  className={classNames('mdi icon', {
                    'mdi-table': showTable,
                    'mdi-cards': !showTable,
                  })}
                />
              </button>
            )}
          </Grid.Column>

          {/* {showFilter && (
            <Grid.Column mobile={16} table={16} computer={16}>
              <FormFilter handleSubmit={onConfirmFilter} initialData={filter} />
            </Grid.Column>
          )} */}
        </Grid>

        {showTable ? renderClientsWithTable() : renderClientsWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              // boundaryRange={2}
              // siblingRange={2}
              disabled={isLoading}
              // defaultActivePage={1}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Modal size="fullscreen" open={open} onClose={onClose}>
        <a href="#" className="close" onClick={handleClose}>
          <i className="mdi mdi-close" />
        </a>

        <Modal.Header>Selecionar cliente</Modal.Header>

        <Modal.Content>{renderClientsContainer()}</Modal.Content>
      </Modal>
    </>
  );
}
