import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalAccountActivation({
  onClose,
  handleClose,
  onConfirm,
  data = {},
}) {
  const [accountId] = useState(data.ID || '');
  const [name] = useState(data.RESPONSIBLE_NAME || '');
  const [email] = useState(data.EMAIL || '');
  const [accessCode, setAccessCode] = useState('');

  const [loading, setLoading] = useState(false);

  function handleConfirm() {
    if (onConfirm) onConfirm();

    handleClose();
  }

  const handleActivateAccount = useCallback(async () => {
    setLoading(true);

    const { data: response } = await api({
      method: 'post',
      url: 'credential/default',
      params: {
        credential: {
          ACCOUNT_ID: accountId,
          ACCOUNT_EMAIL: email,
        },
      },
    });

    if (response.status === 0) {
      toast.success(response.message.text);
      setAccessCode(response.newpass);
    } else {
      toast.error(response.message.text);
    }

    setLoading(false);
  }, []);

  const handleCopyToClipboard = useCallback(async () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(accessCode);
      toast.success('Copiado para área de transferência');
    } else {
      toast.error('Copiar automáticamente não é suportado neste navegador.');
    }
  }, [accessCode]);

  return (
    <Modal size="tiny" open onClose={onClose}>
      <Modal.Header>Ativar conta</Modal.Header>

      <Modal.Content>
        <h3 className="text-center">
          Esta conta pertence a {name} e o email cadastrado é {email}.
        </h3>

        {accessCode ? (
          <div className="ui form">
            <div className="field">
              <label>Código de acesso</label>
              <div className="ui action input">
                <input type="text" value={accessCode} readOnly />
                <button
                  type="button"
                  className={classNames('ui icon button', {
                    loading,
                  })}
                  onClick={handleCopyToClipboard}
                >
                  <i className="mdi mdi-clipboard icon" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <h4 className="text-center">Gerar senha numérica temporária?</h4>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          positive
          className={classNames({
            loading,
          })}
          onClick={() =>
            accessCode ? handleConfirm() : handleActivateAccount()
          }
        >
          {accessCode ? 'Confirmar' : 'Sim'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
