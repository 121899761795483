import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import swal from 'sweetalert';

import api from '~/services/api';

import { Container } from './styles';

export default function Forgot() {
  const { register, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);

  async function onSubmit({ user: login }) {
    setLoading(true);

    const { data: response } = await api({
      method: 'POST',
      url: 'credential/password/resend',
      params: {
        login,
      },
    });

    setLoading(false);

    const { title, text, type } = response.message;

    swal(title, text, type);
  }

  return (
    <Container>
      <form className="ui form segment" onSubmit={handleSubmit(onSubmit)}>
        <div className="ui error message" />

        <div className="field required">
          <label>Usuário ou e-mail</label>
          <input
            type="text"
            name="user"
            placeholder="exemplo@exemplo.com"
            ref={register({ required: true })}
          />
          {errors.user && <span>Usuário é obrigatório!</span>}
        </div>

        <button
          className={classNames('ui fluid button yellow', {
            loading,
          })}
          disabled={loading}
          type="submit"
        >
          Recuperar senha
        </button>

        <p>
          <Link to="/login">Lembrou a senha? Voltar ao login.</Link>
        </p>
      </form>
    </Container>
  );
}
