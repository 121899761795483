import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, Icon, Button } from 'semantic-ui-react';

export default function ModalBrandItems({ onClose, handleClose, data = {} }) {
  return (
    <Modal size="tiny" open onClose={onClose}>
      <Modal.Header>Atenção</Modal.Header>

      <Modal.Content>
        <div className="text-center">
          <Icon name="warning sign" size="huge" color="yellow" />
        </div>
        <h3 className="text-center">{data.message}</h3>
      </Modal.Content>

      <Modal.Actions>
        {data.hasModules && (
          <Link
            to={{
              pathname: '/modules',
              search: `?keyword=${data.brandName}`,
            }}
            target="_blank"
          >
            <Button color="instagram" onClick={handleClose}>
              Ver módulos
            </Button>
          </Link>
        )}

        {data.hasInverters && (
          <Link
            to={{
              pathname: '/inverters',
              search: `?keyword=${data.brandName}`,
            }}
            target="_blank"
          >
            <Button color="green" onClick={handleClose}>
              Ver inversores
            </Button>
          </Link>
        )}
        <Button onClick={handleClose}>Cancelar</Button>
      </Modal.Actions>
    </Modal>
  );
}
