/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import InputNumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Modal, Button } from 'semantic-ui-react';

import api from '~/services/api';

export default function ModalInvestmentAdd({
  onClose,
  handleClose,
  mode = 'add',
  onConfirm,
  data = {},
}) {
  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState(data.DESCRIPTION || '');
  const [perc, setPerc] = useState(data.PERC || 0);
  const [value, setValue] = useState(data.VALUE || 0);
  const [total, setTotal] = useState(data.TOTAL || 0);

  const inputDescription = useRef(null);
  const [inputPerc, setInputPerc] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [inputTotal, setInputTotal] = useState(null);

  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.focus();
    }
  }, [inputDescription]);

  async function handleConfirm() {
    setIsSaving(true);

    const { data: response } = await api({
      method: 'post',
      url:
        mode === 'add'
          ? '/project/investment/add'
          : '/project/investment/update',
      params: {
        project_id: data.PROJECT_ID,
        project_investment_id: mode === 'edit' ? data.ID : undefined,
        project_investment: JSON.stringify({
          DESCRIPTION: description,
          PERC: perc,
          VALUE: value,
        }),
      },
    });

    if (response.status === 0) {
      toast.success(response.message.text);

      if (onConfirm) onConfirm(response.data);

      handleClose();
    } else {
      toast.error(`Erro ao salvar o investimento: ${response.message.text}`);
    }

    setIsSaving(false);
  }

  function onChangeTotal(t) {
    setPerc((t / value) * 100);
    setTotal(t);
  }

  function onChangeValue(v) {
    setValue(v);

    setTotal((v * perc) / 100);
  }

  function onChangePerc(p) {
    setPerc(p);

    setTotal((p * value) / 100);
  }

  return (
    <Modal size="mini" open onClose={onClose}>
      <Modal.Header>
        {mode === 'add' ? 'Novo investimento' : 'Editar investimento'}
      </Modal.Header>

      <Modal.Content>
        <div className="ui form">
          <div className="field required">
            <label>Descrição</label>
            <input
              type="text"
              ref={inputDescription}
              value={description}
              onChange={e => setDescription(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && inputValue.focus()}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Valor</label>
            <InputNumberFormat
              type="tel"
              value={value}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setValue(v.floatValue)}
              onBlur={() => onChangeValue(value)}
              onKeyPress={e => e.key === 'Enter' && inputPerc.focus()}
              getInputRef={el => setInputValue(el)}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Rendimento (% a.m)</label>
            <InputNumberFormat
              type="tel"
              value={perc}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              suffix=" %"
              decimalScale={2}
              onValueChange={v => setPerc(v.floatValue)}
              onBlur={() => onChangePerc(perc)}
              onKeyPress={e => e.key === 'Enter' && inputTotal.focus()}
              getInputRef={el => setInputPerc(el)}
              disabled={isSaving}
            />
          </div>

          <div className="field required">
            <label>Rendimento (R$)</label>
            <InputNumberFormat
              type="tel"
              value={total}
              displayType="input"
              className="text-right"
              fixedDecimalScale
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              onValueChange={v => setTotal(v.floatValue)}
              onBlur={() => onChangeTotal(total)}
              onKeyPress={e => e.key === 'Enter' && handleConfirm()}
              getInputRef={el => setInputTotal(el)}
              disabled={isSaving}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={handleConfirm}
          disabled={isSaving}
          loading={isSaving}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
