import ModalAccountActivation from '~/components/Modal/Account/Activation';
import ModalAccountSelect from '~/components/Modal/Account/Select';
import ModalBrandAdd from '~/components/Modal/Brand/Add';
import ModalBrandItems from '~/components/Modal/Brand/Items';
import ModalBrandSelect from '~/components/Modal/Brand/Select';
import ModalCitySelect from '~/components/Modal/City/Select';
import ModalClientAdd from '~/components/Modal/Client/Add';
import ModalClientSelect from '~/components/Modal/Client/Select';
import ModalEnergyProviderAdd from '~/components/Modal/EnergyProvider/Add';
import ModalGroupSelect from '~/components/Modal/Group/Select';
import ModalHomologValueAdd from '~/components/Modal/HomologValue/Add';
import ModalInverterAdd from '~/components/Modal/Inverter/Add';
import ModalInvestmentAdd from '~/components/Modal/Investment/Add';
import ModalItemAdd from '~/components/Modal/Item/Add';
import ModalItemSelect from '~/components/Modal/Item/Select';
import ModalKitReferenceAdd from '~/components/Modal/KitReference/Add';
import ModalMaterialAdd from '~/components/Modal/Material/Add';
import ModalMaterialSelect from '~/components/Modal/Material/Select';
import ModalModuleAdd from '~/components/Modal/Module/Add';
import ModalProjectEnergyBillValidate from '~/components/Modal/Project/EnergyBill/Validate';
import ModalProjectHistory from '~/components/Modal/Project/History';
import ModalProjectItemSelect from '~/components/Modal/Project/ItemSelect';
import ModalProjectKitSelect from '~/components/Modal/Project/KitSelect';
import ModalProjectMaterialSelect from '~/components/Modal/Project/MaterialSelect';
import ModalProjectTransformerSelect from '~/components/Modal/Project/TransformerSelect';
import ModalProviderAdd from '~/components/Modal/Provider/Add';
import ModalProviderSelect from '~/components/Modal/Provider/Select';
import ModalRoyaltyAdd from '~/components/Modal/Royalty/Add';
import ModalRoyaltyStatusSelect from '~/components/Modal/Royalty/Status/Select';
import ModalStatusAdd from '~/components/Modal/Status/Add';
import ModalStatusSelect from '~/components/Modal/Status/Select';
import ModalStepSelect from '~/components/Modal/Step/Select';
import ModalTransformerAdd from '~/components/Modal/Transformer/Add';
import ModalActivationMail from '~/components/Modal/User/Activation';

export default {
  clientAdd: ModalClientAdd,
  clientSelect: ModalClientSelect,
  material: ModalMaterialSelect,
  materialAdd: ModalMaterialAdd,
  item: ModalItemSelect,
  itemAdd: ModalItemAdd,
  provider: ModalProviderSelect,
  providerAdd: ModalProviderAdd,
  projectMaterialSelect: ModalProjectMaterialSelect,
  projectItemSelect: ModalProjectItemSelect,
  accountSelect: ModalAccountSelect,
  investmentAdd: ModalInvestmentAdd,
  brandAdd: ModalBrandAdd,
  inverterAdd: ModalInverterAdd,
  moduleAdd: ModalModuleAdd,
  activationMail: ModalActivationMail,
  modalBrandItems: ModalBrandItems,
  statusAdd: ModalStatusAdd,
  statusSelect: ModalStatusSelect,
  stepSelect: ModalStepSelect,
  energyProviderAdd: ModalEnergyProviderAdd,
  citySelect: ModalCitySelect,
  royaltiesAdd: ModalRoyaltyAdd,
  projectHistory: ModalProjectHistory,
  royaltyStatusSelect: ModalRoyaltyStatusSelect,
  accountActivation: ModalAccountActivation,
  homologValueAdd: ModalHomologValueAdd,
  transformerAdd: ModalTransformerAdd,
  projectTransformerSelect: ModalProjectTransformerSelect,
  projectKitSelect: ModalProjectKitSelect,
  kitReferenceAdd: ModalKitReferenceAdd,
  projectEnergyBillValidate: ModalProjectEnergyBillValidate,
  groupSelect: ModalGroupSelect,
  brandSelect: ModalBrandSelect,
};
