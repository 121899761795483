import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Message } from 'semantic-ui-react';
import swal from 'sweetalert';

import AuthContext from '~/contexts/AuthContext';
import api from '~/services/api';

import Form from './Form';

function Me() {
  const history = useHistory();
  const { user, getUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [self, setSelf] = useState(null);

  const updateSelf = useCallback(async () => {
    setLoading(true);

    const { data: credential } = await api({
      method: 'get',
      url: 'credential/self',
      params: { credential_id: user.ID },
    });

    if (credential.status === 0) {
      setSelf(credential.data);
    } else {
      setSelf(user);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    updateSelf();
  }, [updateSelf]);

  function normalizeData(data) {
    return {
      ID: data.ID || '',
      NAME: data.NAME || '',
      EMAIL: data.EMAIL || '',
      UF_ID: Number(data.UF_ID) || '',
      CITY_ID: Number(data.CITY_ID) || '',
      RESPONSIBLE_PHONE: data.RESPONSIBLE_PHONE || '',
      RESPONSIBLE_MOBILE: data.RESPONSIBLE_MOBILE || '',
    };
  }

  async function onSubmit(data) {
    setSaving(true);

    const { data: response } = await api({
      method: 'post',
      url: 'credential/save',
      params: {
        credential_id: user.ID,
        credential: data,
      },
    });

    const { title, text, type } = response.message;

    if (response.status === 0) {
      swal(title, text, type).then(() => {
        history.push('/');
      });
      getUser();
    } else {
      swal(title, text, type);
    }

    setSaving(false);
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">Meus dados</span>
          <div>
            <button
              className="ui button red"
              type="button"
              onClick={() => history.push('/')}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="ui attached bottom segment">
          {loading ? (
            <Message color="blue" content="Carregando..." />
          ) : (
            <Form
              initialData={normalizeData(self)}
              onSubmit={onSubmit}
              saving={saving}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Me;
