import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import useForm from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { merge } from 'lodash/object';
import { Message, Button, Icon, Dropdown } from 'semantic-ui-react';

import api from '~/services/api';

export default function SubPageAddress({ store, updateStore }) {
  const { self, clientId, billId } = store;

  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const projectId = query.get('projectId');

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    triggerValidation,
  } = useForm({
    mode: 'onChange',
    defaultValues: self,
  });

  const [loadingClientAddress, setLoadingClientAddress] = useState(false);
  const [loadingProjectAddress, setLoadingProjectAddress] = useState(false);

  const inputNumberFef = useRef();

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [selectedCEP, setSelectedCEP] = useState(self.POSTAL_CODE);
  const [stateSelected, setStateSelected] = useState(self.UF_ID);
  const [citySelected, setCitySelected] = useState(self.CITY_ID);

  const filteredCities = useMemo(() => {
    if (stateSelected) {
      return cityList.map(city => ({
        key: city.ID,
        value: city.ID,
        text: city.NAME,
      }));
    }

    return [];
  }, [cityList, stateSelected]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFindingCEP, setIsFindingCEP] = useState(false);
  const [isFindingHSP, setIsFindingHSP] = useState(false);

  const loadStatesAndCities = useCallback(async () => {
    setIsLoading(true);

    const states = await getState();
    const cities = await getCity();

    const statesMap = states.map(state => ({
      key: state.ID,
      value: state.ID,
      text: state.NAME,
    }));

    setStateList(statesMap);
    setCityList(cities);

    setValue('UF_ID', self.UF_ID);
    setValue('CITY_ID', self.CITY_ID);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadStatesAndCities();
  }, [loadStatesAndCities]);

  useEffect(() => {
    (async () => {
      const cities = await getCity();

      setCityList(cities);
    })();
  }, [stateSelected]);

  useEffect(() => {
    (async () => {
      if (citySelected) {
        await handleFindHSP(citySelected);
      }
    })();
  }, [citySelected]);

  async function handleFormSubmit(data) {
    setIsLoading(true);
    const { data: response } = await api({
      method: 'post',
      url: '/energy/bill/address',
      params: {
        energy_bill_id: billId,
        energy_bill: data,
        filter: JSON.stringify({ ...(projectId && { PROJECT_ID: projectId }) }),
      },
    });

    if (response.status === 0) {
      toast.success('Endereço salvo com sucesso!');

      updateStore(merge(store, { self: merge(store.self, data) }));

      return true;
    }
    toast.error(response.message.text);

    setIsLoading(false);
    return false;
  }

  async function handleFindCEP() {
    const { POSTAL_CODE: cep } = getValues();

    if (!cep) return;

    setIsFindingCEP(true);

    const { data } = await api({
      method: 'get',
      url: 'cep/lookup',
      params: {
        cep,
      },
    });

    setIsFindingCEP(false);

    if (data.status === 0) {
      const { UF, CITY, ADDRESS } = data.data;

      setStateSelected(+UF.ID);
      setCitySelected(+CITY.ID);
      setValue('UF_ID', +UF.ID);
      setValue('CITY_ID', +CITY.ID);
      setValue('ADDRESS', ADDRESS.VALUE);
      setValue('DISTRICT', ADDRESS.DISTRICT);

      if (citySelected) {
        handleFindHSP(+CITY.ID);
      }

      if (inputNumberFef.current) {
        inputNumberFef.current.focus();
      }
    }
  }

  async function handleFindHSP(cityId) {
    setIsFindingHSP(true);

    const { data } = await api({
      method: 'get',
      url: 'hsp/select',
      params: {
        city_id: cityId,
      },
    });

    setIsFindingHSP(false);

    if (data.status === 0) {
      const { MEAN } = data.data;

      setValue('HOURLY_SUNNY_PEAK', +MEAN);
    } else {
      toast.error(data.message.text);
    }
  }

  async function submitAndNavigate(navigateTo) {
    const data = getValues();

    if (await triggerValidation()) {
      const isSave = await handleFormSubmit({ CLIENT_ID: clientId, ...data });

      if (isSave) {
        updateStore();
        history.push(navigateTo);
      }
    }
  }

  async function getState() {
    const { data: uf } = await api({
      method: 'get',
      url: 'uf/list',
      params: {
        filter: JSON.stringify({}),
      },
    });

    if (uf.status === 0) {
      return uf.data;
    }

    return [];
  }

  async function getCity() {
    if (stateSelected) {
      const { data: city } = await api({
        method: 'get',
        url: 'uf/city/list',
        params: {
          filter: JSON.stringify({ UF_ID: stateSelected }),
        },
      });

      if (city.status === 0) {
        return city.data;
      }
    }
    return [];
  }

  function updateCity(cityId) {
    setValue('CITY_ID', cityId);
    setCitySelected(cityId);
  }

  function updateState(stateId) {
    setValue('UF_ID', stateId);
    setStateSelected(stateId);
  }

  async function handleCopyAddressClient() {
    setLoadingClientAddress(true);

    const { data: response } = await api({
      method: 'post',
      url: '/client/self',
      params: {
        client_id: clientId,
      },
    });

    if (response.status === 0) {
      const { data } = response;

      setValue('POSTAL_CODE', data.POSTAL_CODE);
      setSelectedCEP(data.POSTAL_CODE);

      setStateSelected(data.UF_ID);
      setCitySelected(data.CITY_ID);

      setValue('UF_ID', data.UF_ID);

      setValue('CITY_ID', data.CITY_ID);
      setValue('ADDRESS', data.ADDRESS);
      setValue('NUMBER', data.NUMBER);
      setValue('COMPLEMENT', data.COMPLEMENT);
      setValue('DISTRICT', data.DISTRICT);
    } else {
      toast.error(response.message.text);
    }

    setLoadingClientAddress(false);
  }

  async function handleCopyAddressProject() {
    setLoadingProjectAddress(true);

    if (!projectId) {
      toast.error(`Não foi possível encontrar o projeto.`);

      setLoadingProjectAddress(false);

      return;
    }

    const { data: response } = await api({
      method: 'post',
      url: '/project/self',
      params: {
        project_id: projectId,
      },
    });

    if (response.status === 0) {
      const { data } = response;

      setValue('POSTAL_CODE', data.POSTAL_CODE);

      setStateSelected(data.UF_ID);

      setValue('UF_ID', data.UF_ID);
      setValue('CITY_ID', data.CITY_ID);
      setValue('ADDRESS', data.ADDRESS);
      setValue('NUMBER', data.NUMBER);
      setValue('COMPLEMENT', data.COMPLEMENT);
      setValue('DISTRICT', data.DISTRICT);
    } else {
      toast.error(response.message.text);
    }

    setLoadingProjectAddress(false);
  }

  return (
    <form
      className={classNames('ui form')}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <h4 className="ui horizontal divider">Endereço</h4>

      {isLoading ? (
        <Message content="Carregando informações" color="blue" />
      ) : (
        <>
          <div className="field text-center">
            <Button
              color="instagram"
              icon
              onClick={handleCopyAddressClient}
              disabled={loadingClientAddress}
              loading={loadingClientAddress}
            >
              <Icon name="copy" /> Copiar endereço do cliente
            </Button>

            {projectId && (
              <Button
                color="instagram"
                icon
                onClick={handleCopyAddressProject}
                disabled={loadingProjectAddress}
                loading={loadingProjectAddress}
              >
                <Icon name="copy" /> Copiar endereço do projeto
              </Button>
            )}
          </div>
          <div className="field">
            <div className="four fields">
              <div className="field">
                <label>CEP</label>
                <div className="ui action input">
                  <InputNumberFormat
                    type="tel"
                    format="#####-###"
                    mask="_"
                    value={selectedCEP}
                    onChange={e => setSelectedCEP(e.target.value)}
                    displayType="input"
                    onBlur={handleFindCEP}
                    onValueChange={v => setValue('POSTAL_CODE', v.value)}
                    onKeyPress={e => e.key === 'Enter' && handleFindCEP(e)}
                  />
                  <input type="hidden" name="POSTAL_CODE" ref={register} />
                  <button
                    type="button"
                    className={classNames('ui button', {
                      loading: isFindingCEP,
                    })}
                    disabled={isFindingCEP}
                    onClick={handleFindCEP}
                  >
                    Buscar
                  </button>
                </div>
                {errors.POSTAL_CODE && <span>Código postal é obrigatório</span>}
              </div>

              <div
                className={classNames('field required', {
                  error: errors.UF_ID,
                })}
              >
                <label>Estado</label>

                <Dropdown
                  fluid
                  search
                  selection
                  options={stateList}
                  value={stateSelected}
                  placeholder="Seleciona um estado."
                  noResultsMessage="Nenhum estado encontrado."
                  onChange={(e, data) => updateState(data.value)}
                />
                <input
                  type="hidden"
                  name="UF_ID"
                  ref={register({ required: true })}
                />
                {errors.UF_ID && <span>Estado é obrigatório</span>}
              </div>

              <div
                className={classNames('field required', {
                  error: errors.CITY_ID,
                })}
              >
                <label>Cidade</label>

                <Dropdown
                  fluid
                  search
                  selection
                  options={filteredCities}
                  value={citySelected}
                  placeholder="Seleciona uma cidade."
                  noResultsMessage="Nenhuma cidade encontrada."
                  onChange={(e, data) => {
                    updateCity(data.value);
                    handleFindHSP(data.value);
                  }}
                />
                <input
                  type="hidden"
                  name="CITY_ID"
                  ref={register({ required: true })}
                />
                {errors.CITY_ID && <span>Cidade é obrigatória</span>}
              </div>

              <div
                className={classNames('field required', {
                  error: errors.HOURLY_SUNNY_PEAK,
                })}
              >
                <label>HSP (Média anual da cidade do projeto)</label>
                <input
                  type="number"
                  name="HOURLY_SUNNY_PEAK"
                  disabled={isFindingHSP}
                  readOnly
                  ref={register({ required: true })}
                />
                {errors.HOURLY_SUNNY_PEAK && <span>HSP é obrigatório</span>}
              </div>
            </div>

            <div className="two fields">
              <div className="twelve wide field">
                <label>Endereço</label>
                <input
                  type="text"
                  name="ADDRESS"
                  placeholder="Ex: Rua. Padre João"
                  ref={register({ required: false })}
                />
                {errors.ADDRESS && <span>Endereço é obrigatóri</span>}
              </div>

              <div className="four wide field">
                <label>Número</label>
                <input
                  type="text"
                  name="NUMBER"
                  placeholder="Ex: 123"
                  ref={e => {
                    inputNumberFef.current = e;
                    register(e);
                  }}
                />
              </div>

              <div className="field">
                <label>Complemento</label>
                <input
                  type="text"
                  name="COMPLEMENT"
                  placeholder="Ex: Apartamento 10"
                  ref={register}
                />
              </div>

              <div className="field">
                <label>Bairro</label>
                <input
                  type="text"
                  name="DISTRICT"
                  placeholder="Ex: Vila Mariana"
                  ref={register({ required: false })}
                />
                {errors.DISTRICT && <span>Bairro é obrigatório</span>}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="two fields">
        <div className="field">
          <button
            type="button"
            className={classNames('ui button blue', {
              'left floated': !isMobile,
              fluid: isMobile,
              laoding: isLoading,
            })}
            disabled={isLoading}
            onClick={() => submitAndNavigate('identification')}
          >
            Voltar
          </button>
        </div>

        <div className="field">
          <button
            type="button"
            className={classNames('ui button green', {
              'right floated': !isMobile,
              fluid: isMobile,
              laoding: isLoading,
            })}
            disabled={isLoading}
            onClick={() => submitAndNavigate('history')}
          >
            Avançar
          </button>
        </div>
      </div>
    </form>
  );
}
