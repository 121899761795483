import { createGlobalStyle } from 'styled-components';

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@mdi/font/css/materialdesignicons.min.css';

import './margins.css';
import './animations.css';

export const sidebarWidth = 250;
export const headerHeight = 50;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    /* min-height: 100%; */
    height: 100%;
    background: #FFF;

  }

  body {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-rendering: optimizeLegibility !important;
  }

  body ::-webkit-scrollbar-thumb {
    background: rgba(128, 135, 139, 0.8) !important;
  }

  select[readonly] {
    background: #eee; /*Simular campo inativo - Sugestão @GabrielRodrigues*/
    pointer-events: none;
    touch-action: none;
  }

  table,
  .ui.table {
    font-size: 12px !important;
  }

  table thead tr th.clickable {
    cursor: pointer !important;
  }

  table thead tr th.clickable:hover {
    color: #1678C2 !important;
  }

  table thead tr th.selected {
    color: #1678C2 !important;
  }

  table tr td input:not([type=checkbox]),
  table tr th input:not([type=checkbox]),
  table tr td input:not([type=radio]),
  table tr th input:not([type=radio]),
  table tr td textarea,
  table tr th textarea {
    width: 100%;
    margin: 0em;
    outline: none;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #FFF;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    box-shadow: 0em 0em 0em 0em transparent inset;
  }

  select.ui.dropdown:focus,
  table tr td input:not([type=checkbox]):focus,
  table tr th input:not([type=checkbox]):focus,
  table tr td input:not([type=radio]):focus,
  table tr th input:not([type=radio]):focus,
  table tr td textarea:focus,
  table tr th textarea:focus {
    border-color: #96C8DA;
  }

  select.ui.dropdown:focus,
  table tr td input:not([type=checkbox]):focus,
  table tr th input:not([type=checkbox]):focus,
  table tr td input:not([type=radio]):focus,
  table tr th input:not([type=radio]):focus,
  table tr td textarea:focus,
  table tr th textarea:focus {
    border-color: #96C8DA;
  }

  .ui.form.centered .fields {
    justify-content: center;
  }

  .ui.action.input ~ span,
  .ui.form .field > span,
  .ui.grid.form .field > span,
  .ui.grid.form input ~ span,
  .ui.grid input ~ span, .field-error {
    font-size: 10px;
    color: red;
  }

  input[type],
  textarea,
  select,
  .ui.dropdown:not([role=listbox]) {
    background-color: rgb(235,222,198, 0.5) !important;
  }

  .ui.action.input:not([class*="left action"])>input {
    border-right-color: rgba(34, 36, 38, 0.15) !important;
  }

  .header.module {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header.module .title {
    font-size: 18px;
  }

  .header.module .title .subtitle {
    font-size: 14px;
  }

  @media screen and (max-width: 370px) {
    .header.module {
      flex-direction: column;
    }

    .header.module .title {
      margin-bottom: 5px;
    }
  }

  .swal-modal .swal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .swal-modal .swal-text {
    text-align: center;
  }

  .ui.fullscreen.modal {
    right: 0;
  }

  .ui.modal > .header.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-right: 20px;
  }

  button, .button {
    cursor:pointer;
  }

  .button.transparent {
    background-color: transparent !important;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;

    &:hover {
      background-color: transparent !important;
    }
  }

  button.no-style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .ui.grid.form {
    margin-left: 0 !important;
  }

  .card .extra.content,
  .card .meta {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .color-red {
    color: red;
  }

  .color-green {
    color: green;
  }

  .color-orange {
    color: orange;
  }

  .ui.statistics.centered {
    justify-content: center;
  }

  .ui.statistics.space-between {
    justify-content: space-between;
  }

  .ui.button.icon .icon.status.success {
    background:#21ba45;
    color: #fff;
  }

  /* .ui.statistics .statistic {
    margin: 16px !important;
  } */

  .text-line {
    text-decoration: line-through;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-red {
    color: red !important;
  }

  .text-green {
    color: green !important;
  }

  .text-yellow {
    color: yellow !important;
  }

  .text-white {
    color: white !important;
  }

  .ui.inverted.header.bg-blue {
    background-color: #2185d0 !important;
  }
  .ui.inverted.header.bg-orange {
    background-color: #f2711c !important;
  }
  .ui.inverted.header.bg-olive {
    background-color: #b5cc18 !important;
  }
  .ui.inverted.header.bg-yellow {
    background-color: #fbbd08 !important;
  }
  .ui.inverted.header.bg-teal {
    background-color: #00b5ad !important;
  }

  .ui.segment.bg-grey {
    background-color: #eee !important;
  }
`;
