import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Message } from 'semantic-ui-react';
import swal from 'sweetalert';

import api from '~/services/api';

import Form from './Form';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ClientAdd() {
  const history = useHistory();
  const query = useQuery();
  const { clientId } = useParams();

  const [backTo] = useState(query.get('backTo'));
  const [projectId] = useState(query.get('projectId'));

  const [editMode, setEditMode] = useState(false);

  const [self, setSelf] = useState(null);

  const updateSelf = useCallback(async () => {
    const { data: client } = await api({
      method: 'post',
      url: 'client/self',
      params: { client_id: clientId },
    });

    if (client.status === 0) {
      setSelf(client.data);
    } else {
      setSelf({});
    }
  }, []);

  const getClientId = useCallback(async () => {
    async function requestAdd(id) {
      const { data: client } = await api({
        method: 'post',
        url: 'client/add',
        params: { client_id: id },
      });

      if (client.status === 0) {
        if (!client.data.validated) {
          history.replace(`/client/${client.data.ID}`);
        } else {
          setEditMode(true);
        }
      } else {
        await requestAdd(null);
      }
    }

    await requestAdd(clientId);
  }, []);

  useEffect(() => {
    getClientId();
    updateSelf();
  }, [getClientId, updateSelf]);

  function normalizeData(data) {
    return {
      ID: data.ID || '',
      NAME: data.NAME || '',
      PERSON: data.PERSON || data.TYPE_FORMAT || 'PF',
      PERSON_DOCUMENT: data.PERSON_DOCUMENT || data.CNPJ || data.CPF,
      STATE_REGISTRATION: data.STATE_REGISTRATION,
      TOWN_REGISTRATION: data.TOWN_REGISTRATION,
      HOLDER: data.HOLDER,
      FANTASY_NAME: data.FANTASY_NAME,
      COMPANY_NAME: data.COMPANY_NAME,
      PHONE: data.PHONE,
      CELL_PHONE: data.CELL_PHONE,
      EMAIL: data.EMAIL,
      POSTAL_CODE: data.POSTAL_CODE,
      ADDRESS: data.ADDRESS,
      NUMBER: data.NUMBER,
      COMPLEMENT: data.COMPLEMENT,
      DISTRICT: data.DISTRICT,
      UF_ID: Number(data.UF_ID) || '',
      CITY_ID: Number(data.CITY_ID) || '',
      CONTACT: data.CONTACT,
      OBSERVATION: data.OBSERVATION,
    };
  }

  async function onSubmit(data) {
    const { data: responseSave } = await api({
      method: 'post',
      url: 'client/save',
      params: {
        client_id: clientId,
        client: data,
      },
    });

    const { title, text, type } = responseSave.message;

    if (responseSave.status === 0) {
      toast.success(text);

      if (projectId) {
        const { data: responseProject } = await api({
          method: 'post',
          url: '/project/client',
          params: {
            project_id: projectId,
            client_id: clientId,
          },
        });

        if (responseProject.status === 0) {
          toast.success(responseProject.message.text);
        } else {
          toast.error(responseProject.message.text);
        }
      }

      if (backTo) {
        history.push(backTo);
      } else {
        history.push('/clients');
      }
    } else {
      swal(title, text, type);
    }

    // if (backTo) {
    //   history.push(backTo);
    // }
  }

  return (
    <div className="ui centered grid">
      <div className="ui sixteen wide column">
        <div className="ui top attached header module">
          <span className="title">
            {editMode ? 'Editar cliente' : 'Novo cliente'}
          </span>

          {backTo && (
            <div>
              <button
                className="ui button red"
                type="button"
                onClick={() => history.push(backTo)}
              >
                Cancelar
              </button>
            </div>
          )}
        </div>

        <div className="ui attached bottom segment">
          {self === null ? (
            <Message color="blue" content="Carregando informações..." />
          ) : (
            <Form initialData={normalizeData(self)} onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientAdd;
