import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import InputNumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
// import numberFormat from 'locutus/php/strings/number_format';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  Pagination,
  Icon,
  Grid,
  Message,
  Button,
  Checkbox,
  Dropdown,
  Tab,
  Menu,
  Label,
} from 'semantic-ui-react';
import swal from 'sweetalert';

import ItemCard from '~/components/Card/Item';
import ModalContext from '~/contexts/Modal';
import api from '~/services/api';

export default function ProjectItemSelect({ projectId, project, updateStore }) {
  const { openModal } = useContext(ModalContext);
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(0);

  const [showTable, setShowTable] = useState(!isMobile);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [keyword, setKeyword] = useState('');

  const [filter] = useState({
    PROJECT_ID: projectId,
    CLIENT_ID: project.CLIENT_ID,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    current_page: 1,
    limit: 20,
    total_page: 1,
    orderby: [{ field: 'DESCRIPTION', mode: 'ASC' }],
  });

  const searchItems = useCallback(
    useRef(
      throttle(async (filters, pag) => {
        setIsLoading(true);

        const { data: response } = await api({
          method: 'post',
          url: '/item/search',
          params: {
            filter: filters,
            pagination: pag,
          },
        });

        if (response.status === 0) {
          setItems(response.data);
          setPagination(response.pagination);
        } else {
          toast.error(response.message.text);
          setItems([]);
        }

        setIsLoading(false);
      }, 1000)
    ).current,
    []
  );

  const setPaginationKeyword = useRef(
    debounce(
      key =>
        searchItems(filter, {
          ...pagination,
          keyword: key,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    searchItems(filter, pagination);
  }, [searchItems]);

  const [isSaving, setIsSaving] = useState(false);

  function handleKeywordChange(e) {
    setKeyword(e.target.value);

    setPagination({
      ...pagination,
      keyword: e.target.value,
    });

    setPaginationKeyword(e.target.value);
  }

  function handlePaginationChange(e, { activePage }) {
    const newPagination = {
      ...pagination,
      page: activePage,
    };

    searchItems(filter, newPagination);
  }

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState({});

  const allSelected = useMemo(
    () =>
      selectedItems.length && selectedItems.every(b => b.uiChecked === true),
    [selectedItems]
  );

  async function handleSave() {
    setIsSaving(true);

    if (selectedItems.length === 0) {
      swal(
        'Atenção',
        'Nenhum item será adicionado ao projeto, pois nenhum foi selecionado!',
        'warning'
      ).then(() => {
        setIsSaving(false);

        history.push(`/project/${projectId}/item/search`);
      });

      return;
    }

    const selectedToAdd = selectedItems.map(item => ({
      ITEM_ID: item.ID,
      ...item,
    }));

    const { data: responseAddItem } = await api({
      method: 'post',
      url: 'project/item/add',
      params: {
        project_id: projectId,
        provider_id: selectedProvider.ID,
        project_item: JSON.stringify(selectedToAdd),
      },
    });

    if (responseAddItem.status === 0) {
      toast.success('Itens adicionados com sucesso!');
      history.push(`/project/${projectId}/item/search`);
      updateStore();
    } else {
      toast.error(responseAddItem.message.text);
    }

    setIsSaving(false);
  }

  function handleItemDelete(itemId) {
    swal({
      dangerMode: true,
      title: 'Atencão',
      text:
        'Você está prestes a excluir um item permanentemente. Deseja mesmo realizar essa acão?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then(async result => {
      if (result) {
        const { data: responseDelete } = await api({
          method: 'post',
          url: 'item/delete',
          params: {
            item_id: JSON.stringify([itemId]),
          },
        });

        if (responseDelete.status === 0) {
          searchItems(filter, { ...pagination, keyword });
          toast.success('Item excluído com sucesso!');
        } else {
          toast.error(responseDelete.message.text);
        }
      }
    });
  }

  function handleSelectSingleItem(item) {
    if (!selectedItems.some(i => i.ID === item.ID)) {
      openModal('projectItemSelect', {
        data: item,
        onConfirm: data =>
          setSelectedItems([
            ...selectedItems,
            {
              ID: item.ID,
              DESCRIPTION: item.DESCRIPTION,
              UNIT_VALUE: data.UNIT_VALUE,
              QUANTITY: data.QUANTITY,
            },
          ]),
      });
    }
  }

  function renderItemsWithCard() {
    return (
      <Grid centered>
        {items.length > 0 &&
          !isLoading &&
          items.map(item => (
            <Grid.Column key={item.ID} mobile={16} tablet={8} computer={4}>
              <ItemCard
                type="select"
                handleSelect={() => handleSelectSingleItem(item)}
                handleDelete={() => handleItemDelete(item.ID)}
                handleEdit={() => {
                  openModal('itemAdd', {
                    mode: 'edit',
                    data: {
                      ID: item.ID,
                      PRICE: item.PRICE,
                      DESCRIPTION: item.DESCRIPTION,
                    },
                    onConfirm: () =>
                      searchItems(filter, { ...pagination, keyword }),
                  });
                }}
                item={item}
              />
            </Grid.Column>
          ))}

        {!isLoading && items.length === 0 && (
          <Grid.Column>
            <Message content="Nenhum item adicionado!" />
          </Grid.Column>
        )}

        {isLoading && (
          <Grid.Column mobile={16}>
            <Message color="blue" content="Buscando itens..." />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderItemsWithTable() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned">Descrição</th>
            {/* <th className="center aligned">Valor unitário</th> */}
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 &&
            !isLoading &&
            items.map(item => {
              return (
                <tr
                  key={item.ID}
                  className={classNames({
                    active: selectedItems.some(m => m.ID === item.ID),
                  })}
                >
                  <td className="center aligned">
                    {item.DESCRIPTION || item.ITEM_DESCRIPTION || '-'}
                  </td>
                  {/* <td className="center aligned">
                    R$ {numberFormat(item.PRICE, 2, ',', '.')}
                  </td> */}
                  <td className="center aligned">
                    <Button
                      size="mini"
                      icon
                      color="green"
                      title="Selecionar item"
                      onClick={() => handleSelectSingleItem(item)}
                      disabled={selectedItems.some(m => m.ID === item.ID)}
                    >
                      <Icon name="check" /> Selecionar
                    </Button>

                    {!item.KEY_ && (
                      <>
                        <Button
                          size="mini"
                          icon
                          color="blue"
                          title="Editar item"
                          onClick={() => {
                            openModal('itemAdd', {
                              mode: 'edit',
                              data: {
                                ID: item.ID,
                                PRICE: item.PRICE,
                                DESCRIPTION: item.DESCRIPTION,
                              },
                              onConfirm: () =>
                                searchItems(filter, { ...pagination, keyword }),
                            });
                          }}
                        >
                          <Icon name="pencil" />
                        </Button>

                        <Button
                          size="mini"
                          icon
                          color="red"
                          title="Excluir item"
                          onClick={() => handleItemDelete(item.ID)}
                        >
                          <Icon name="trash" />
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}

          {!isLoading && items.length === 0 && (
            <tr>
              <td colSpan="10">
                <Message content="Nenhum item encontrado!" />
              </td>
            </tr>
          )}

          {isLoading && (
            <tr>
              <td colSpan="10">
                <Message color="blue" content="Buscando itens..." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderTabSearch() {
    return (
      <Tab.Pane>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action fluid input">
              <input
                type="text"
                placeholder="Pesquisar por"
                value={keyword}
                onChange={handleKeywordChange}
              />
              <button
                className={classNames('ui button label', {
                  loading: isLoading,
                })}
                type="button"
                disabled={isLoading}
                onClick={() => searchItems(filter, { ...pagination, keyword })}
              >
                Pesquisar
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            <button
              type="button"
              disabled={isSaving}
              className={classNames('ui button red icon', {
                loading: isSaving,
              })}
              onClick={() => history.push(`/project/${projectId}/item/search`)}
            >
              Cancelar
            </button>

            {!isMobile && (
              <button
                type="button"
                className="ui button icon"
                title="Alterar visualização"
                onClick={() => setShowTable(!showTable)}
              >
                <i
                  className={classNames('mdi icon', {
                    'mdi-table': showTable,
                    'mdi-cards': !showTable,
                  })}
                />
              </button>
            )}

            <button
              type="button"
              className="ui button blue icon"
              title="Novo item"
              onClick={() => {
                openModal('itemAdd', {
                  mode: 'add',
                  onConfirm: () =>
                    searchItems(filter, { ...pagination, keyword }),
                });
              }}
            >
              <i className="mdi mdi-plus icon" /> Novo item
            </button>

            <button
              type="button"
              className="ui button green icon"
              title="Avançar"
              onClick={() => setActiveTab(1)}
            >
              Avançar
            </button>
          </Grid.Column>
        </Grid>

        {showTable ? renderItemsWithTable() : renderItemsWithCard()}

        <Grid centered>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Pagination
              disabled={isLoading}
              activePage={pagination.page}
              onPageChange={handlePaginationChange}
              totalPages={pagination.total_page}
              ellipsisItem={false}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
              }}
            />
          </Grid.Column>
        </Grid>
      </Tab.Pane>
    );
  }

  function renderItemsWithCardSelected() {
    return (
      <Grid centered>
        {selectedItems.length > 0 &&
          selectedItems.map(item => (
            <Grid.Column key={item.ID} mobile={16} tablet={8} computer={4}>
              <ItemCard
                type="projectSelected"
                handleDelete={() =>
                  setSelectedItems(oldItems =>
                    oldItems.filter(b => b.ID !== item.ID)
                  )
                }
                handleEdit={() => {
                  openModal('projectItemSelect', {
                    data: item,
                    onConfirm: data =>
                      setSelectedItems(m =>
                        m.map(mi =>
                          mi.ID === item.ID
                            ? {
                                ...mi,
                                UNIT_VALUE: data.UNIT_VALUE,
                                QUANTITY: data.QUANTITY,
                              }
                            : mi
                        )
                      ),
                  });
                }}
                item={item}
              />
            </Grid.Column>
          ))}

        {selectedItems.length === 0 && (
          <Grid.Column>
            <Message content="Nenhuma conta selecionada!" />
          </Grid.Column>
        )}
      </Grid>
    );
  }

  function renderItemsWithTableSelected() {
    return (
      <table className="ui compact striped celled table">
        <thead>
          <tr>
            <th className="center aligned" width="2%">
              <Checkbox
                title="Selecionar"
                disabled={selectedItems.length === 0}
                onChange={(e, { checked }) =>
                  setSelectedItems(oldItems =>
                    oldItems.map(item => ({
                      ...item,
                      uiChecked: checked,
                    }))
                  )
                }
                checked={Boolean(allSelected)}
              />
            </th>
            <th className="center aligned" width="50%">
              Descrição
            </th>
            <th className="center aligned">Quantidade</th>
            <th className="center aligned">Valor unitário</th>
            <th className="center aligned">Ações</th>
          </tr>
        </thead>
        <tbody>
          {selectedItems.length > 0 &&
            selectedItems.map(item => {
              return (
                <tr
                  key={item.ID}
                  className={classNames({
                    active: item.uiChecked,
                  })}
                >
                  <td className="center aligned">
                    <div className="ui checkbox">
                      <Checkbox
                        checked={Boolean(item.uiChecked)}
                        onChange={() =>
                          setSelectedItems(oldItems =>
                            oldItems.map(i => {
                              return i.ID === item.ID
                                ? { ...i, uiChecked: !i.uiChecked }
                                : i;
                            })
                          )
                        }
                      />
                    </div>
                  </td>
                  <td className="center aligned">
                    {item.DESCRIPTION || item.ITEM_DESCRIPTION || '-'}
                  </td>
                  <td className="center aligned">
                    <InputNumberFormat
                      type="tel"
                      value={item.QUANTITY}
                      className="text-right"
                      displayType="input"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={5}
                      onValueChange={v =>
                        setSelectedItems(om =>
                          om.map(m =>
                            m.ID === item.ID
                              ? {
                                  ...m,
                                  QUANTITY: v.floatValue,
                                }
                              : m
                          )
                        )
                      }
                    />
                  </td>
                  <td className="center aligned">
                    <InputNumberFormat
                      type="tel"
                      value={item.UNIT_VALUE}
                      className="text-right"
                      displayType="input"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      prefix="R$ "
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={v =>
                        setSelectedItems(om =>
                          om.map(m =>
                            m.ID === item.ID
                              ? {
                                  ...m,
                                  UNIT_VALUE: v.floatValue,
                                }
                              : m
                          )
                        )
                      }
                    />
                  </td>
                  <td className="center aligned">
                    <Button
                      size="mini"
                      icon
                      color="red"
                      title="Excluir item"
                      onClick={() =>
                        setSelectedItems(ms => ms.filter(m => m.ID !== item.ID))
                      }
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}

          {selectedItems.length === 0 && (
            <tr>
              <td colSpan="10">
                <Message content="Nenhum item selecionado!" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderTabSelected() {
    return (
      <Tab.Pane>
        <Grid centered>
          <Grid.Column mobile={16} table={8} computer={8}>
            <div className="ui action labeled fluid input">
              <div className="ui basic label">Fornecedor:</div>
              <input
                type="text"
                placeholder="Selecionar fornecedor"
                defaultValue={selectedProvider.DESCRIPTION}
                readOnly
              />

              {selectedProvider.ID && (
                <button
                  className={classNames('ui button red label icon', {
                    loading: isLoading,
                  })}
                  type="button"
                  onClick={() =>
                    setSelectedProvider({
                      ID: null,
                      DESCRIPTION: null,
                    })
                  }
                >
                  <i className="mdi mdi-delete icon" />
                </button>
              )}

              <button
                className={classNames('ui button label icon', {
                  loading: isLoading,
                })}
                type="button"
                onClick={() => {
                  openModal('provider', {
                    onSelect: p => setSelectedProvider(p),
                  });
                }}
              >
                <i className="mdi mdi-account-search icon" />
              </button>
            </div>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            table={8}
            computer={8}
            className="right aligned"
          >
            {!isMobile && (
              <button
                type="button"
                className="ui button icon"
                title="Alterar visualização"
                onClick={() => setShowTable(!showTable)}
              >
                <i
                  className={classNames('mdi icon', {
                    'mdi-table': showTable,
                    'mdi-cards': !showTable,
                  })}
                />
              </button>
            )}

            <Dropdown
              icon="cogs"
              floating
              title="Ações rápidas"
              button
              className="blue icon"
              disabled={!selectedItems.some(b => b.uiChecked === true)}
            >
              <Dropdown.Menu direction="left">
                <Dropdown.Header content="Ações rápidas" />

                <Dropdown.Divider />

                <Dropdown.Item
                  color="red"
                  icon="trash"
                  text="Excluir selecionados"
                  onClick={() =>
                    setSelectedItems(b => b.filter(i => i.uiChecked !== true))
                  }
                />
              </Dropdown.Menu>
            </Dropdown>

            <button
              type="button"
              disabled={isSaving}
              className={classNames('ui button green icon', {
                loading: isSaving,
              })}
              onClick={handleSave}
            >
              Finalizar
            </button>
          </Grid.Column>
        </Grid>

        {showTable
          ? renderItemsWithTableSelected()
          : renderItemsWithCardSelected()}
      </Tab.Pane>
    );
  }

  return (
    <>
      <h4 className="ui horizontal divider">Selecionar itens</h4>

      <Tab
        activeIndex={activeTab}
        onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)}
        panes={[
          {
            menuItem: 'Pesquisar itens',
            render: renderTabSearch,
          },
          {
            menuItem: (
              <Menu.Item key="selected">
                Selecionados <Label>{selectedItems.length}</Label>
              </Menu.Item>
            ),
            render: renderTabSelected,
          },
        ]}
      />
    </>
  );
}
